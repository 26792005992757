import { useLocation, useNavigate } from "react-router";
import { DataTagIdInterface, DataTagIdTicketsInterface, TokenInterface } from "../../../../../services/requestsInterfacesModel";
import { useDispatch, useSelector } from "react-redux";
import { getIdCompany } from "../../../../../store/company";
import { getIdEmployee } from "../../../../../store/employee";
import { getIdUser, getNameUser, getUserLastName } from "../../../../../store/user";
import { getToken } from "../../../../../store/token";
import { getOrderByList, getShowConfirmationModal, getShowCreateConsumersModal, getShowEditConsumersModal, getShowEditTicketsModal, getSortByList, setRegisterCustomersModal, setShowEditTagsModal, setShowEditTicketsModal } from "../../../../../store/internal";
import { useEffect, useState } from "react";
import BackendConstants from "../../../../../core/constants/backend-constants";
import constsRouters from "../../../../../routes/constsRouter";
import { ListName } from "../../../../../core/enums/order-sort-by";
import UserService from "../../../../../services/user-service";
import { AppRequesterController } from "../../../../../services/appRequester/appRequesterController";
import { ErrorRequestController } from "../../../../../services/errorsInterfaceModel";
import ViewTags from ".";
import { useTranslation } from "react-i18next";

const ViewTagsController = (props) => {

    const AppRequesterConst = new AppRequesterController();
    const emp = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation()
    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        },
        employee: {
            id: useSelector(getIdEmployee)
        },
        user: {
            id: useSelector(getIdUser),
            name: useSelector(getNameUser),
            lastname: useSelector(getUserLastName),
        },
        token: {
            value: useSelector(getToken)
        },
        internal: {
            getShowCreateConsumersModal: useSelector(getShowCreateConsumersModal),
            getShowEditConsumersModal: useSelector(getShowEditConsumersModal),
            getShowConfirmationModal: useSelector(getShowConfirmationModal),
            getShowEditTicketsModal: useSelector(getShowEditTicketsModal),
            getSortByList: useSelector(getSortByList),
            getOrderByList: useSelector(getOrderByList),
        }
    };

    const [isLoading, setIsLoading] = useState(false);
    const [currentTags, setCurrentTags] = useState(undefined);
    const [currentTag, setCurrentTag] = useState(null);
    const [currentFilterSearch, SetCurrentFilterSearch] = useState(null);
    const [currentParams, SetCurrentParams] = useState({});
    const [aux, setAux] = useState([]);
    const [auxConsumer, setAuxConsumer] = useState([]);
    const [currentPage, SetCurrentPage] = useState(1);
    const [currentLimit, /*SetCurrentLimit*/] = useState(BackendConstants.limitMin);
    const [currentState, setCurrentState] = useState("");
    const [resetingFilters, setResetingFilters] = useState(false);
    const [yoursTickets, setYoursTickets] = useState(false);
    const [currentTicket, setCurrentTicket] = useState(null);
    const [currentHasMoreInformation, setCurrentHasMoreInformation] = useState(true);
    const [/*currentConsumer*/, setCurrentConsumer] = useState([]);

    const updateListfunction = () => {
        SetCurrentPage(currentPage + 1);
    }

    const updateListAfterDelete = () => {
        setAux([]);
        setAuxConsumer([])
        SetCurrentPage(1);

        setCurrentState("deactivating");

        if (currentState === "deactivating") {
            setCurrentState("");
        }
    }

    useEffect(() => {
        const controller = new AbortController();

        setCurrentTag((state: { name: string, id: string }) => ({
            ...state,
            name: state ? state.name : emp.state['name'],
            id: state ? state.id : emp.state['id'],
        }));

        getAllTicketsToPagination(Object.assign(currentParams, { limit: currentLimit, page: currentPage }), controller);

        return () => {
            controller.abort();
        }
    }, [currentPage, currentState]);

    const getTag = async (userID: string) => {
        const headers = UserService.getHeaders();
        await AppRequesterConst.Get(
            emp.state['consumers'] ? '/consumer-tag/' + userID : '/tag/' + userID, { headers },
            (response: Object) => {
            },
            (data) => {
                if (data.status === 200) {
                    if (emp.state['consumers']) {
                        setCurrentTag({
                            id: data?.data?.tag.id,
                            active: data?.data?.tag.active,
                            name: data?.data?.tag.name,
                        });
                    }
                    else {
                        setCurrentTag({
                            id: data.data.tags[0].id,
                            active: data.data.tags[0].active,
                            name: data.data.tags[0].name,
                        });
                    }
                }

            },
            (error: Object) => {
                setIsLoading(false);
            }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const getAllTickets = async (params: { limit: number; page: number; }, setData: Function, aux: any[], setAux: Function, controller: AbortController) => {
        if (values.internal.getSortByList.listName === ListName.ListOfTickets && values.internal.getSortByList.sort_by !== null && values.internal.getOrderByList !== null) {
            params = Object.assign(params, { sort_by: values.internal.getSortByList.sort_by, order_by: values.internal.getOrderByList });
        }

        const headers = UserService.getHeaders();
        // const signal = controller.signal;

        await AppRequesterConst.Get(
            '/tag/' + emp.state['id'] + '/tickets', { headers, /*signal*/ params: params },
            (response: Object) => { },
            (data: DataTagIdTicketsInterface) => {
                if (data.status === 200 && data.data?.tickets?.length > 0) {
                    setAux((state: any[]) => {
    /* if (!state) {
        state = []
    }

    let aux = state.concat(data.data.tickets);
    // setAux(aux);
    setData(aux);
    // return state; */

                        if (state?.length >= params?.limit) {
                            let newListTickets = state.concat(data.data.tickets);

                            setData(newListTickets);
                            return newListTickets;
                        } else if (state?.length === 0) {
                            setData(data.data.tickets);
                            return data.data.tickets
                        }
                    });
                } /* else if (aux.length === 0) {
                    setData(data);
                } */

            },
            (error: ErrorRequestController) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }


    const getAllConsumer = async (params: { limit: number; page: number; }, setData: Function, controller: AbortController) => {

        const headers = UserService.getHeaders();
        // const signal = controller.signal;

        await AppRequesterConst.Get(
            '/consumer-tag/' + emp.state['id'] + '/consumers', { headers, /*signal*/ params: params },
            (response: Object) => { },
            (data) => {
                if (data.status === 200 && data) {
                    setCurrentHasMoreInformation(true);
                    setAux((state) => {
                        let newListConsumer = state.concat(data.data.consumers);
                        setAux(newListConsumer)
                        setAuxConsumer(newListConsumer)
                        return state
                    });
                } else if(aux.length === 0) {
                    setAuxConsumer([])
                }

            },
            (error: ErrorRequestController) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const getAllTicketsToPagination = (params: { limit: number; page: number; }, controller: AbortController) => {
        if (emp.state['consumers']) {
            getAllConsumer(params, setCurrentTags, controller);
        } else {
            getAllTickets(params, setCurrentTags, aux, setAux, controller);
        }
    }

    const callbackModalCustomers = () => {
        SetCurrentPage(1);
        setAuxConsumer([]);
        const controller = new AbortController();
        getAllTicketsToPagination(Object.assign(currentParams, { limit: currentLimit, page: currentPage }), controller);
    }

    const setCurrentTicketFunction = (value: any) => {
        setCurrentTicket({id: value?.id.split("*ID*")[0].split("*who_is_viewing*")[0]});
    }

    const filteringTickets = (e: { currentTarget: { id: string; }; }, auxObj: Object, action: string) => {
        SetCurrentPage(1);
        setAux([]);

        if (document.getElementById("listOfTickets") != null) {
            document.getElementById("listOfTickets").scrollTop = 0;
        }

        if (action === "clean") {
            SetCurrentFilterSearch("");
            auxObj = {};
            setCurrentState("searching");
            if (currentState === "searching") {
                setCurrentState("");
            }
        } else if (e.currentTarget.id === "searchButton" || e.currentTarget.id === "SearchInput") {
            auxObj = {};
            setCurrentState("searching");

            if (currentState === "searching") {
                setCurrentState("");
            }

            if (currentFilterSearch !== null && currentFilterSearch !== "") {
                auxObj["search"] = currentFilterSearch;
            }

        } else {
            setCurrentState("filtering");

            if (currentState === "filtering") {
                setCurrentState("");
            }
        }

        dispatch(setShowEditTicketsModal(false));

        SetCurrentParams(auxObj);
    }

    const listOfTagsPage = () => {
        if (values.internal.getShowEditTicketsModal === false) {
            navigate(constsRouters.routers.configurationTags.path);
        } else {
            dispatch(setShowEditTicketsModal(false));
            SetCurrentPage(1);
            setAux([]);
            SetCurrentParams({});
            setResetingFilters(true);

            setCurrentState("returning");
            if (currentState === "returning") {
                setCurrentState("");
            }
        }
    }

    const cleanList = () => {
        SetCurrentParams({});
        SetCurrentPage(1);
        setAux([]);
        setAuxConsumer([]);
        setResetingFilters(true);

        setCurrentState("filtering");

        if (currentState === "filtering") {
            setCurrentState("");
        }
    }

    const showEditTagModal = () => {
        SetCurrentPage(1);
        setAux([]);

        getTag(emp.state['id']).finally(() => {
            setCurrentState("editing");

            if (currentState === "editing") {
                setCurrentState("");
            }
            dispatch(setShowEditTagsModal(true));

        })
    }
    const callbackModalTags = () => {
        SetCurrentPage(1);
        setAux([]);

        getTag(emp.state['id']).finally(() => {
            setCurrentState("editing");

            if (currentState === "editing") {
                setCurrentState("");
            }
        });
    }

    const showCreateTicket = () => {
        navigate(constsRouters.routers.tickets.path);
    }

    const showCreateConsumer = () => {
        SetCurrentPage(1);
        // setAux([]);
        dispatch(setRegisterCustomersModal(true));
    }

    const callbackOrderByTickets = (column: any, order: any) => {
        SetCurrentPage(1);
        setAux([]);
        setCurrentState(column + order);
        document.getElementById(ListName.ListOfTickets).scrollTop = 0;
    }

    const setCurrentConsumerFunction = (value: any) => {
        setCurrentConsumer(value);
    }

    const callbackOrderByConsumers = (column: any, order: any) => {
        SetCurrentPage(1);
        setAuxConsumer([]);
        // const auxObj = {};

        // if (column != null) {
        //     auxObj["sort_by"] = column;
        // }

        // if (order != null) {
        //     auxObj["order_by"] = order;
        // }
        setCurrentState(column + order);
        // SetCurrentParams(auxObj);
        document.getElementById("ListOfConsumers").scrollTop = 0;
    }

    return <ViewTags
        isLoading={isLoading}
        token={values.token.value}
        callbackModalTags={callbackModalTags}
        SetCurrentFilterSearch={SetCurrentFilterSearch}
        currentFilterSearch={currentFilterSearch}
        updateListfunction={updateListfunction}
        updateListAfterDelete={updateListAfterDelete}
        getShowEditTicketsModal={values.internal.getShowEditTicketsModal}
        currentTag={currentTag}
        listOfTagsPage={listOfTagsPage}
        filteringTickets={filteringTickets}
        showEditTagModal={showEditTagModal}
        currentState={currentState}
        currentParams={currentParams}
        currentTicket={currentTicket}
        getAllTickets={getAllTickets}
        setCurrentState={setCurrentState}
        cleanList={cleanList}
        user={values.user}
        currentTags={currentTags}
        callbackOrderByTickets={callbackOrderByTickets}
        showCreateTicket={showCreateTicket}
        setCurrentTicketFunction={setCurrentTicketFunction}
        resetingFilters={resetingFilters}
        setResetingFilters={setResetingFilters}
        yoursTickets={yoursTickets}
        setYoursTickets={setYoursTickets}
        id={values.company.id}
        t={t}
        tagConsumer={emp.state['consumers']}
        auxConsumer={auxConsumer}
        callbackModalCustomers={callbackModalCustomers}
        currentHasMoreInformation={currentHasMoreInformation}
        showCreateConsumer={showCreateConsumer}
        setCurrentConsumerFunction={setCurrentConsumerFunction}
        callbackOrderByConsumers={callbackOrderByConsumers}
    />

}

export default ViewTagsController
