import { useEffect, useState } from "react";
import { CurrentTriggerTabEnum, Message_SendingType_Interface, Template_Message_hatsapp_Interface } from "./indexModel";
import { useDispatch, useSelector } from "react-redux";
import { getOrderByList, getShowCreateMessageTriggerModal, getShowCreateTriggerTypeModal, getSortByList, setShowCreateMessageTriggerModal, setShowCreateTriggerTypeModal } from "../../../store/internal";
import BackendConstants from "../../../core/constants/backend-constants";
import HomeMessageTrigger from ".";
import UserService from "../../../services/user-service";
import { MessageSendingInterface, TokenInterface, TokenInterfaceControlPanel } from "../../../services/requestsInterfacesModel";
import { getNameUser, getProfileId, getUserLastName } from "../../../store/user";
import { getToken } from "../../../store/token";
import { getIdCompany } from "../../../store/company";
import { AppRequesterController } from "../../../services/appRequester/appRequesterController";
import { useNavigate } from "react-router";
import constsRouters from "../../../routes/constsRouter";
import { useTranslation } from "react-i18next";
import { ListName, SortBy } from '../../../core/enums/order-sort-by';
import { TicketFilterType } from '../../../core/enums/ticket-filter-type';

const HomeMessageTriggerController = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState(false);
    const [currentTriggerTab, setCurrentTriggerTab] = useState(CurrentTriggerTabEnum.Trigger)
    const [currentPage, setCurrentPage] = useState(1)
    const [currentState, setCurrentState] = useState("")
    const [currentParams, setCurrentParams] = useState({})
    const [currentLimit, setCurrentLimit] = useState(BackendConstants.messagesTriggersLimit.typesLimit)
    const [auxTrigger, setAuxTrigger] = useState([]);
    const [currentFilteredTriggers, setCurrentFilteredTriggers] = useState(undefined);

    const [ticketStateLevelType, setTicketStateLevelType] = useState(TicketFilterType.Finalized)
    const [currentFilteredTriggerTypes, setCurrentFilteredTriggerTypes] = useState(undefined);
    const [currentFilterSearch, setCurrentFilterSearch] = useState(null);
    const [auxTypes, setAuxTypes] = useState([]);
    const [auxActivesTypes, setAuxActivesTypes] = useState([]);
    const [emailPrincipal, setEmailPrincipal] = useState(null);
    const [templateMessageList, setTemplateMessageList] = useState(null);
    const [emailList, setEmailList] = useState(null);
    const [currentActiveTriggerTypes, setCurrentActiveTriggerTypes] = useState(undefined);
    const [openModalPhone, setOpenModalPhone] = useState(undefined);
    const [phoneNumber, setPhoneNumber] = useState("");

    const values: TokenInterface = {
        user: {
            name: useSelector(getNameUser),
            lastname: useSelector(getUserLastName),
            profile_id: useSelector(getProfileId)
        },
        token: {
            value: useSelector(getToken)
        },
        company: {
            id: useSelector(getIdCompany)
        },
        internal: {
            getSortByList: useSelector(getSortByList),
            getOrderByList: useSelector(getOrderByList),
            getShowCreateMessageTriggerModal: useSelector(getShowCreateMessageTriggerModal),
            getShowCreateTriggerTypeModal: useSelector(getShowCreateTriggerTypeModal),
        }
    }

    const AppRequesterConst = new AppRequesterController()

    useEffect(() => {
        if (values.internal.getShowCreateMessageTriggerModal) {
            dispatch(setShowCreateMessageTriggerModal(false));
        }

        if (values.internal.getShowCreateTriggerTypeModal) {
            dispatch(setShowCreateTriggerTypeModal(false));
        }
    }, []);

    useEffect(() => {
        const controller = new AbortController();

        if (values.token.value) {
            setCurrentPage((state) => {
                setCurrentParams((state2) => {
                    getAllInformationsToPagination(Object.assign(state2, { limit: currentLimit, page: state }), getAllMessageTriggers, getAllMessageTriggerTypesActive, getAllMessageTriggerTypes, controller);
                    return state2;
                });
                return state;
            });
        } else {
            navigate(constsRouters.routers.register.path);
        }

        if (templateMessageList === null) {
            getAllTemplateMessage(controller)
        }

        if (emailList === null) {
            getCompanyEmailAuthenticated(controller)
            getCompanyEmail(controller)
        }

        return () => {
            controller.abort();
        }
    }, [currentPage, currentState, currentTriggerTab]);

    const getAllInformationsToPagination = (params: { limit: number; page: number; }, getAllMessageTriggers: Function, getAllMessageTriggerTypesActive: Function, getAllMessageTriggerTypes: Function, controller: AbortController) => {
        if (currentTriggerTab === CurrentTriggerTabEnum.Trigger) {
            getAllMessageTriggers(params, controller)
            getAllMessageTriggerTypesActive({ active: true }, controller)
        } else {
            getAllMessageTriggerTypes(params, controller);
        }
    }

    const updateListfunction = () => {
        setCurrentPage(currentPage + 1);
    }

    const updateListAfterEditStatus = () => {
        resetAux();
        setCurrentPage(1);

        setCurrentState("deactivating");

        if (currentState === "deactivating") {
            setCurrentState("");
        }
    }

    const showCreateMessageTrigger = () => {
        setCurrentPage(1);
        // this.resetAux();
        dispatch(setShowCreateMessageTriggerModal(true));
    }

    const showCreateTriggerType = () => {
        setCurrentPage(1);
        // this.resetAux();
        dispatch(setShowCreateTriggerTypeModal(true));
    }

    const callbackModalMessageTrigger = (getAllMessageTriggers: Function, getAllMessageTriggerTypesActive: Function, getAllMessageTriggerTypes: Function) => {
        setCurrentPage(1);
        resetAux();
        const controller = new AbortController();
        getAllInformationsToPagination(Object.assign(currentParams, { limit: currentLimit, page: currentPage }), getAllMessageTriggers, getAllMessageTriggerTypesActive, getAllMessageTriggerTypes, controller);
    }

    const callbackOrderByTypes = (column: any, order: any) => {
        setCurrentPage(1);
        resetAux();
        const auxObj = {};

        if (column != null) {
            auxObj["sort_by"] = column;
        }

        if (order != null) {
            auxObj["order_by"] = order;
        }

        if (currentFilterSearch !== null && currentFilterSearch !== "") {
            auxObj["search"] = currentFilterSearch;
        }
        
        setCurrentState(column + order);
        setCurrentParams(auxObj);
        document.getElementById("listOfTriggerTypes").scrollTop = 0;
    }

    /**
     * identifies the active tab to apply correct filter in list
     */
    const applyselectedTabFilterMethod = (e: { currentTarget: { id: string; }; }, auxObj: Object, action: string) => {
        if (currentTriggerTab === CurrentTriggerTabEnum.Trigger) {
            setCurrentPage(1);
            setAuxTrigger([]);
            setCurrentFilteredTriggers(undefined);

            if (document.getElementById("listOfTriggers") != null) {
                document.getElementById("listOfTriggers").scrollTop = 0;
            }
        } else if (currentTriggerTab === CurrentTriggerTabEnum.TriggerType) {
            setCurrentPage(1);
            resetAux();
            setCurrentFilteredTriggerTypes(undefined);

            if (document.getElementById("listOfTriggerTypes") != null) {
                document.getElementById("listOfTriggerTypes").scrollTop = 0;
            }
        }

        if (action === "clean") {
            setCurrentFilterSearch("");
            auxObj = {};
            setCurrentState("searching");
            if (currentState === "searching") {
                setCurrentState("");
            }
        } else if (e.currentTarget.id === "searchButton" || e.currentTarget.id === "SearchInput") {
            auxObj = {};
            setCurrentState("searching");

            if (currentState === "searching") {
                setCurrentState("");
            }

            if (currentFilterSearch !== null && currentFilterSearch !== "") {
                auxObj["search"] = currentFilterSearch;
            }

        } else {
            setCurrentState("filtering");

            if (currentState === "filtering") {
                setCurrentState("");
            }
        }

        /* dispatch(setShowEditTicketsModal(false));
        dispatch(setShowCreateTicketsModal(false)); */
        setCurrentParams(auxObj);
    }

    const cleanList = () => {
        setCurrentParams({});
        setCurrentPage(1);
        resetAux();
        setCurrentFilteredTriggers(undefined);
        setCurrentState("filtering");

        if (currentState === "filtering") {
            setCurrentState("");
        }
    }

    const handleCurrentTab = (eventKey) => {
        setCurrentTriggerTab((state) => {
            if (state !== eventKey) {
                setCurrentParams({});
                setCurrentPage(1);
                setCurrentState("");
                resetAux();
                setCurrentFilteredTriggers(undefined);
                setCurrentFilteredTriggerTypes(undefined);
                setCurrentFilterSearch("");

                return eventKey;
            }

            return state;
        });
    }

    const callbackOrderByMessageTriggers = (column: any, order: any) => {
        setCurrentPage(1);
        resetAuxTrigger();
        const auxObj = currentParams;

        if (column != null) {
            auxObj["sort_by"] = column;
        }

        if (order != null) {
            auxObj["order_by"] = order;
        }

        if (currentFilterSearch !== null && currentFilterSearch !== "") {
            auxObj["search"] = currentFilterSearch;
        }
        
        setCurrentState(column + order);
        setCurrentParams((prevParams) => ({ ...prevParams, ...auxObj }));
        document.getElementById("listOfTriggers").scrollTop = 0;
    }

    const resetAuxTrigger = () => {
        setAuxTrigger([]);
        //setCurrentFilteredTriggers(undefined);
    }

    const resetAux = () => {
        setAuxTypes([])
        setAuxTrigger([])
        setAuxActivesTypes([])
    }

    const getAllMessageTriggers = async (params: { limit: number; page: number; }, controller: AbortController) => {
        // if (values.internal.getSortByList.sort_by !== null && values.internal.getSortByList.sort_by === SortBy.date_scheduled) {
        //     params = Object.assign(params, { sort_by: values.internal.getSortByList.sort_by, order_by: values.internal.getOrderByList });
        // }

        const headers = UserService.getHeaders(), signal = controller.signal;

        await AppRequesterConst.Get(
            "/message-sending",
            { headers: headers, signal, params: params },
            (response: Object) => { },
            (data: MessageSendingInterface) => {
                if (data.status === 200 && data && data.data && Object.keys(data.data).length > 0) {
                    setAuxTrigger((state) => {
                        let auxTrigger = state.concat(data.data.message_sending);
                        setCurrentFilteredTriggers(auxTrigger);
                        return auxTrigger;
                    });
                }
            },
            (error: Object) => { },
            navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const getAllMessageTriggerTypes = async (params: { limit: number; page: number; }, controller: AbortController) => {
        const headers = UserService.getHeaders(), signal = controller.signal;

        await AppRequesterConst.Get(
            "/message-sending-type",
            { headers: headers, signal, params: params },
            (response: Object) => { },
            (data: Message_SendingType_Interface) => {
                if (data.status === 200 &&  data && data.data && data.data.messageSendingTypes.length > 0) {
                    setAuxTypes((state) => {
                        let aux = state.concat(data.data.messageSendingTypes);
                        // setAux(aux);
                        setCurrentFilteredTriggerTypes(aux);
                        return aux;
                    });
                }
            },
            (error: Object) => { },
            navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const getAllTemplateMessage = async (controller: AbortController) => {
        const headers = UserService.getHeaders(), signal = controller.signal;

        const router = `/template-message/whatsapp/${values.company.id}`;
        await AppRequesterConst.Get(
            router,
            { headers, signal },
            (response: Object) => {
                return response;
            },
            (data: Template_Message_hatsapp_Interface) => {
                if (data.status === 200 && data.data.templateMessages.length > 0) {
                    setTemplateMessageList(data.data.templateMessages);
                }
            },
            (error: { response: { status: number } }) => {
                if (error.response !== undefined) {
                    if (error.response.status === 400) {
                        //document.getElementById("feedbackemail").innerHTML = "Usuário ou senha incorreto";
                        //setShowfeedback(true);
                    }
                    if (error.response.status === 401) {
                        //document.getElementById("feedbackpassword").innerHTML = "Usuário ou senha incorreto";
                        //setShowfeedbackPassword(true);
                    }
                }
            }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const getAllMessageTriggerTypesActive = async (params: { active: boolean; }, controller: AbortController) => {
        const headers = UserService.getHeaders(), signal = controller.signal;

        await AppRequesterConst.Get(
            "/message-sending-type",
            { headers: headers, signal, params: params },
            (response: Object) => { },
            (data: Message_SendingType_Interface) => {
                if(data.status === 200) {
                    setCurrentActiveTriggerTypes(data.data.messageSendingTypes);
                }

            },
            (error: Object) => { },
            navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const getCompanyEmailAuthenticated = async (controller: AbortController) => {
        const headers = UserService.getHeaders(), signal = controller.signal

        const router = `/company/email/authenticated`;
        
        await AppRequesterConst.Get(
            router,
            { headers, signal },
            (response: Object) => {
                return response;
            },
            (data: any) => {
                if (data.status === 200 && data.data.emails.length > 0) {
                    setEmailList(data.data.emails)
                }
            },
            (error) => {
                if (error?.response?.status === 400) {

                }
                if (error?.response?.status === 401) {

                }
            }, navigate, dispatch, setIsLoading, { values: values }
        )
    }

    const getCompanyEmail = async (controller: AbortController) => {
        const headers = UserService.getHeaders(), signal = controller.signal

        const router = `/company/email`;

        await AppRequesterConst.Get(
            router, { headers, signal },
            (response: Object) => {
                return response
            },
            (data: any) => {
                setEmailPrincipal(data.data.emails)
            },
            (error) => {
                if (error.response?.status === 400) {

                }
                if (error.response?.status === 401) {
                    
                }
            }, navigate, dispatch, setIsLoading, { values: values }
        )
    }

    return (
        <HomeMessageTrigger
            t={t}
            values={values}
            callbackModalMessageTrigger={callbackModalMessageTrigger}
            getAllMessageTriggers={getAllMessageTriggers}
            isLoading={isLoading}
            getAllMessageTriggerTypesActive={getAllMessageTriggerTypesActive}
            getAllMessageTriggerTypes={getAllMessageTriggerTypes}
            currentActiveTriggerTypes={currentActiveTriggerTypes}
            templateMessageList={templateMessageList}
            emailList={emailList}
            emailPrincipal={emailPrincipal}
            currentTriggerTab={currentTriggerTab}
            applyselectedTabFilterMethod={applyselectedTabFilterMethod}
            setCurrentFilterSearch={setCurrentFilterSearch}
            currentFilterSearch={currentFilterSearch}
            phoneNumber={phoneNumber}
            openModalPhone={openModalPhone}
            setOpenModalPhone={setOpenModalPhone}
            handleCurrentTab={handleCurrentTab}
            showCreateMessageTrigger={showCreateMessageTrigger}
            currentState={currentState}
            setCurrentState={setCurrentState}
            cleanList={cleanList}
            currentFilteredTriggers={currentFilteredTriggers}
            updateListfunction={updateListfunction}
            updateListAfterEditStatus={updateListAfterEditStatus}
            callbackOrderByMessageTriggers={callbackOrderByMessageTriggers}
            showCreateTriggerType={showCreateTriggerType}
            currentFilteredTriggerTypes={currentFilteredTriggerTypes}
            callbackOrderByTypes={callbackOrderByTypes}
        />
    )
}

export default HomeMessageTriggerController