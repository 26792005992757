import React, { FC } from 'react';
import { Row, Col, Popover, Form, Button, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import InfiniteScroll from 'react-infinite-scroll-component';

import Loading from '../../../../components/loading'
import { ListName, OrderBy } from '../../../../core/enums/order-sort-by';
import ButtonOrderByList from '../../components/buttonOrderByList';
import { TicketFilterType } from '../../../../core/enums/ticket-filter-type';
import { ListTicketsInterface } from './indexModel';
import { TicketStatus } from '../../../../core/enums/ticket-status';
import ChannelLabel from '../../components/channelLabel';
import ModalListTasksController from '../../components/modalTasks/indexController';
import ModalSendTicketCopy from '../../components/modalSendTicketCopy';
import ModalManageTicketsController from '../../components/modalManageTickets/indexController';
import { useSelector } from 'react-redux';
import { getShowManageTicketsModal, getShowExportAllTicketModal } from '../../../../store/internal';
import Utils from '../../../../core/shared/utils';
import ModalExportAllTicketsController from '../../components/modalExportAllTickets/indexController';

const ListTickets: FC<ListTicketsInterface> = (props) => {
    const popover = (data) => (
        <Popover id={props.currentTicketId} style={{ zIndex: '500', backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }}>
            <Popover.Body className="nopadding">
                <Button onClick={(e) => props.showEditModal2(e)} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{props.t("home_tickets.list_tickets.action_popover.edit_ticket")}</Button>
                <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                <Button onClick={() => props.deleteTicket()} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}> {props.t("home_tickets.list_tickets.action_popover.delete_ticket")} </Button>
                <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                <Button onClick={(e) => props.selectModalTicket(e, data)} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}> {props.t("home_tickets.list_tickets.action_popover.send_ticket_copy")} </Button>
                <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                <Button onClick={() => props.handleShowCustomFieldModalEdit()} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{ props.t("home_tickets.list_tickets.action_popover.ticket_tasks") }</Button>
            </Popover.Body >
        </Popover >
    );

    return (
        <>
            <ModalManageTicketsController visibility={useSelector(getShowManageTicketsModal)} deleteAllSelected={props.deleteAllSelected} resolveAllSelected={props.resolveAllSelected} removeAllAssigned={props.removeAllAssigned} deleteListTickets={props.deleteListTickets} setDeleteListTickets={props.setDeleteListTickets} callback={props.updateListAfterDelete} addAllTags={props.addAllTags}/>
            <ModalListTasksController show={props.showCustomFieldModalEdit} handleClose={props.handleCloseCustomFieldModalEdit} data={props.currentTicketId} callBackModalCustomField={props.callBackModalCustomField}
                updateListfunction={props.updateListfunction} />
            <ModalExportAllTicketsController visibility={useSelector(getShowExportAllTicketModal)} />
            <Loading visibility={props.isLoading}></Loading>
            {props.values.showSendTicketCopyModal && <ModalSendTicketCopy token={props.values.token.value} visibility={props.values.showSendTicketCopyModal} data={props.ticketModal}></ModalSendTicketCopy>}
            <div style={{ overflow: 'auto', margin: '2.5vh 0.5vw 0px', padding: '0' }} className="ps-3">
                {
                    props?.tickets && props?.tickets.length > 0 && props?.tickets[0] !== undefined ? /* verifica se a lista de tickets existe ou vem uma lista undefined */
                        <ListGroup variant="flux" className="TicketsRow">
                            <ListGroup.Item className="TicketsRowCollums mb-3" style={{ border: 'none' }} id="headerList" key="headerList">
                                <Row name="listOfTicketsHead">
                                    {props.checkboxes === true &&
                                        <Col className="nopadding" md={1} style={{ width: '3%' }} >
                                            <Form.Check
                                                type='checkbox'
                                                id='Allcheckboxes'
                                                onClick={(e) => props.AllCheckboxesClick(e)}
                                                style={{ marginLeft: '2.5px' }}
                                            />
                                        </Col>
                                    }
                                    <Col md={1} className="nopadding d-flex" style={{ width: '7%' }}>
                                        {props.t("home_tickets.list_tickets.headers.id")}
                                    </Col>
                                    <Col md={4} style={{ width: '13%' }} className="nopadding">
                                        {/* {props.dataText === undefined ? "Data de criação" : props.dataText} */}
                                        {props.ticketStateLevelType === TicketFilterType.Deleted ?
                                            props.t("history_tickets.excluded_date") : 
                                            props.ticketStateLevelType === TicketFilterType.Finalized ?
                                            props.t("history_tickets.finalized_date") :
                                            <ButtonOrderByList
                                                defaultOrder={OrderBy.desc}
                                                title={props.dataText === undefined ? props.t("home_tickets.list_tickets.headers.order_by_create_date_title") : props.dataText}
                                                columnIdName={
                                                //     // props.ticketStateLevelType && props.ticketStateLevelType === TicketFilterType.Finalized? 
                                                //     //     SortBy.updated_date:
                                                //     // props.ticketStateLevelType && props.ticketStateLevelType === TicketFilterType.Deleted?
                                                //     //     SortBy.deleted_date:
                                                    // SortBy.updated_level2_date
                                                    props.sortList
                                                }
                                                callbackOrderBy={props.callbackOrderByTickets}
                                                listName={ListName.ListOfTickets}
                                                twoOptions={true}
                                            />
                                        }
                                    </Col>
                                    {props.requesterUserActiveColumn === true &&
                                        <Col md={1} className="nopadding" style={{ width: '14%' }} >
                                            {props.t("home_tickets.list_tickets.headers.requester")}
                                        </Col>
                                    }
                                    <Col md={1} className="nopadding" style={{ width: '16%' }}>
                                        {props.t("home_tickets.list_tickets.headers.subject")}
                                    </Col>
                                    <Col md={1} className="nopadding" style={{ width: '9%' }}>
                                        {props.t("home_tickets.list_tickets.headers.channel")}
                                    </Col>
                                    {props.assignedEmployeeActiveColumn === true &&
                                        <Col md={2} className="nopadding" style={{ width: '13%' }}>
                                            {props.t("home_tickets.list_tickets.headers.assigned")}
                                        </Col>
                                    }
                                    <Col md={1} className="nopadding" style={{ width: '6%' }}>
                                        {props.t("home_tickets.list_tickets.headers.status")}
                                    </Col>
                                    <Col md={1} className="nopadding" style={{ width: '8%' }}>
                                        {props.t("home_tickets.list_tickets.headers.department")}
                                    </Col>
                                    {/* <Col md={1} style={{ width: '7%', backgroundColor: 'red' }}></Col> */}
                                    {/* <Col md={2} className="nopadding" style={{ width: '13%' }}>
                                        Última atualização
                                    </Col> */}
                                    <Col className='d-flex justify-content-end' >
                                        {props.t("home_tickets.list_tickets.headers.action")}
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                            <div id="listOfTickets" style={{
                                overflow: 'auto', height: props.height ? props.height : '42vh', display: 'flex',
                                flexDirection: 'column',
                            }}>
                                <InfiniteScroll
                                    dataLength={props?.tickets?.length}
                                    next={props?.updateListfunction}
                                    hasMore={true}
                                    loader={<h4> </h4>}
                                    scrollableTarget={"listOfTickets"}
                                    onScroll={() => Utils.hidePopoverOnScroll(ListName.ListOfTickets)}
                                >
                                    {
                                        props.tickets[0] !== undefined ? 
                                        <ListGroup variant="flush" className="TicketsRow">
                                            {
                                                props.tickets.map((item, index) => {
                                                    let date: Date | null = null;
                                                    let hour: string = null;
                                                    let newDate: Date | null = null;

                                                    if (item?.status_id === TicketStatus.Abandoned || item?.status_id === TicketStatus.Resolved) {
                                                        newDate = new Date(item?.updated_at);
                                                    } else {
                                                        if(item?.deleted_at){
                                                            newDate = new Date(item?.deleted_at);
                                                        }else if (item?.updated_level2_at) {
                                                            if(localStorage.getItem('filterOptionTicketsCx') === 'home_tickets.list_tickets.headers.order_by_update_date_title') {
                                                                newDate = new Date(item?.consumer_last_message || item?.updated_level2_at);
                                                            }else{
                                                                newDate = new Date(item?.updated_level2_at); // data de criação
                                                            }
                                                        } else {
                                                            if(localStorage.getItem('filterOptionTicketsCx') === 'home_tickets.list_tickets.headers.order_by_update_date_title') {
                                                                newDate = new Date(item?.consumer_last_message || item?.updated_at);
                                                            }else{
                                                                newDate = new Date(item?.updated_at);
                                                            }
                                                        }
                                                    }
                                                    date = newDate;
                                                    hour = `${newDate.getHours() < 10 ? `0${newDate.getHours()}` : newDate.getHours()}:${newDate.getMinutes() < 10 ? `0${newDate.getMinutes()}` : newDate.getMinutes()}:${newDate.getSeconds() < 10 ? `0${newDate.getSeconds()}` : newDate.getSeconds()}`;

                                                    let channels = "N/A";
                                                    let channelId = item?.channel_id;
                                                    let status = "N/A"
                                                    switch (item?.channel_id) {
                                                        case '3f3fa530-fd3e-4018-ab98-4d9c3cd5406a':
                                                            // channels = 'E-mail';
                                                            channels = props.t("channels_names.email");
                                                            break;
                                                        case '6977996d-11d1-44cc-a863-2e1d9c24e046':
                                                            // channels = 'Telefonia';
                                                            channels = props.t("channels_names.telephony");
                                                            break;
                                                        case '33be81d8-88ac-4e08-bb4d-cf69fd23267f':
                                                            // channels = 'WhatsApp';
                                                            channels = props.t("channels_names.whatsapp");
                                                            break;
                                                        case 'aa1cea93-23de-46aa-af14-d6f766acf5c8':
                                                            // channels = 'Facebook';
                                                            channels = props.t("channels_names.facebook");
                                                            break;
                                                        case 'f28cb4a4-e42b-425a-8011-59d5f9c1d0f2':
                                                            // channels = 'Instagram';
                                                            channels = props.t("channels_names.instagram");
                                                            break;
                                                        case '4a7b71bb-6432-4794-ad11-f978b067871d':
                                                            // channels = 'Webchat';
                                                            channels = props.t("channels_names.webchat");
                                                            break;
                                                        default:
                                                            break;
                                                    }
                                                    switch (item?.status_id) {
                                                        case TicketStatus.Open:
                                                            status = 'A';
                                                            break;
                                                        case TicketStatus.Resolved:
                                                            status = 'R';
                                                            break;
                                                        case TicketStatus.Pending:
                                                            status = 'P';
                                                            break;
                                                        case TicketStatus.OnHold:
                                                            status = 'E';
                                                            break;
                                                        case TicketStatus.New:
                                                            status = 'N';
                                                            break;
                                                        case TicketStatus.Abandoned:
                                                            status = 'B';
                                                            break;
                                                        default:
                                                            break;
                                                    }

                                                    let department_name = null;
                                                    if (item?.sector) {
                                                        department_name = item?.sector?.name;
                                                    }

                                                    return (
                                                        <ListGroup.Item style={{ color: '#707070' }} id={item?.id + "*who_is_viewing*" + item?.who_is_viewing + "*ticket_reference_id*" + item?.ticket_reference_id} key={index}>
                                                            <Row className='TicketsRowHeight' style={item?.processing ? {backgroundColor: '#f36a6b'} : {}}>
                                                                {props.checkboxes === true &&
                                                                    <Col md={1} className='d-flex nopadding  align-self-center' style={{ width: '3%' }}>
                                                                        {(item.deleted_at === null && !item.who_is_viewing && item.status_id !== TicketStatus.Resolved && item.status_id !== TicketStatus.Abandoned) ? /* retirando seleção para tickets abandonados ou resolvidos */
                                                                            <Form.Check
                                                                                type='checkbox'
                                                                                id={"checkboxes" + index}
                                                                                onClick={(e) => props.CheckboxesClick(e, index)}
                                                                                className="form-check-input-2"
                                                                                style={{ paddingLeft: '2.5px' }}
                                                                                disabled={props.allCheckboxesIndex.length >= 100 && !props.allCheckboxesIndex.includes(index) ? true : false}
                                                                            />
                                                                            :
                                                                            <Form.Check
                                                                                type='checkbox'
                                                                                id={"checkboxes" + index}
                                                                                //onClick={(e) => props.CheckboxesClick(e, index)}
                                                                                className="form-check-input-2"
                                                                                style={{ paddingLeft: '2.5px' }}
                                                                                disabled={true} /* caso ele esteja abandonado ou resolvido, desabilitar checkbox apenas */
                                                                            />
                                                                        }
                                                                    </Col>
                                                                }
                                                                <Col md={1} name={"ticket_reference_col"} id={"ticket_reference_col"+item?.ticket_reference_id} className={'align-self-center nopadding' + (item?.bold ? ' bold-list-item' : '')} onClick={(e) => { props.openViewTicketConfirm(e, item?.processing, item?.who_is_viewing_user_id); } } style={{ cursor: 'pointer', width: '7%' }}>
                                                                    <div className='oneLineClampEllipsis'>
                                                                        <OverlayTrigger
                                                                            key='top'
                                                                            placement='top'
                                                                            overlay={
                                                                                <Tooltip id='tooltip'>
                                                                                    #{item.ticket_reference_id}
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <span>
                                                                                #{item.ticket_reference_id}
                                                                            </span>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                </Col>

                                                                <Col md={2} className={'align-self-center nopadding' + (item?.bold ? ' bold-list-item' : '')} onClick={(e) => props.openViewTicketConfirm(e, item?.processing, item?.who_is_viewing_user_id)} style={{ cursor: 'pointer', width: '13%' }}>
                                                                    <OverlayTrigger
                                                                        key='top'
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id='tooltip'>
                                                                                {props.formatedDate(date)} - {hour}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {props.formatedDate(date)} - {hour}
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </Col>

                                                                {props.requesterUserActiveColumn === true &&
                                                                    <Col md={1} className={'align-self-center nopadding' + (item?.bold ? ' bold-list-item' : '')} onClick={(e) => props.openViewTicketConfirm(e, item?.processing, item?.who_is_viewing_user_id)} style={{ cursor: 'pointer', width: '14%' }}>
                                                                        <div className='oneLineClampEllipsis'>
                                                                            <OverlayTrigger
                                                                                key='top'
                                                                                placement='top'
                                                                                overlay={
                                                                                    <Tooltip id='tooltip'>
                                                                                        {item?.requester_user?.name?
                                                                                            <>{item.requester_user.name} {item?.requester_user?.lastname ? item?.requester_user?.lastname : ""}</>
                                                                                            :
                                                                                            <>N/A</>
                                                                                        }
                                                                                    </Tooltip>
                                                                                }
                                                                            >
                                                                                <span>
                                                                                    {item?.requester_user && item?.requester_user?.name ? item?.requester_user?.name + (item?.requester_user?.lastname ? " " + item?.requester_user?.lastname : "") : <>N/A</>}
                                                                                </span>
                                                                            </OverlayTrigger>
                                                                        </div>
                                                                    </Col>
                                                                }
                                                                <Col md={1} className={'align-self-center nopadding' + (item?.bold ? ' bold-list-item' : '')} onClick={(e) => props.openViewTicketConfirm(e, item?.processing, item?.who_is_viewing_user_id)} style={{ cursor: 'pointer', width: '16%' }}>
                                                                    <div className='oneLineClampEllipsis me-3'>
                                                                        <OverlayTrigger
                                                                            key='top'
                                                                            placement='top'
                                                                            overlay={
                                                                                <Tooltip id='tooltip'>
                                                                                    {(item?.subject != null) ?
                                                                                        <>{item?.subject}</>
                                                                                        : null
                                                                                    }
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <span>
                                                                                {item?.subject ? item?.subject : 'N/A'}
                                                                            </span>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                </Col>
                                                                <Col md={1} className={'d-flex align-self-center nopadding' + (item?.bold ? ' bold-list-item' : '')} onClick={(e) => props.openViewTicketConfirm(e, item?.processing, item?.who_is_viewing_user_id)} style={{ cursor: 'pointer', width: '9%' }}>
                                                                    <OverlayTrigger
                                                                        key='top'
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id='tooltip'>
                                                                                {channels != null ?
                                                                                    <>{channels}</>
                                                                                    // ChannelLabel(channels, channelId, { color: '#fff' })
                                                                                    :
                                                                                    <>N/A</>
                                                                                }
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {channels != null ?
                                                                                // <>{channels}</>
                                                                                ChannelLabel(channels, channelId, { color: item?.bold ? "#1E1E1E" : 'rgb(112, 112, 112)' })
                                                                                :
                                                                                <>N/A</>
                                                                            }
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </Col>

                                                                {props.assignedEmployeeActiveColumn === true &&
                                                                    <Col md={2} className={'align-self-center nopadding' + (item?.bold ? ' bold-list-item' : '')} onClick={(e) => props.openViewTicketConfirm(e, item?.processing, item?.who_is_viewing_user_id)} style={{ cursor: 'pointer', width: '13%' }}>
                                                                        <div className='oneLineClampEllipsis me-3'>
                                                                            <OverlayTrigger
                                                                                key='top'
                                                                                placement='top'
                                                                                overlay={
                                                                                    <Tooltip id='tooltip'>
                                                                                        {(item?.assigned_employee?.name) ?
                                                                                            <>{item?.assigned_employee?.name} {item?.assigned_employee?.lastname ? item?.assigned_employee?.lastname : ""}</>
                                                                                            : <>N/A</>
                                                                                        }
                                                                                    </Tooltip>
                                                                                }
                                                                            >
                                                                                <span>
                                                                                    {item?.assigned_employee != null && item?.assigned_employee?.name ? (item?.assigned_employee?.name + (item?.assigned_employee?.lastname ? (" " + item?.assigned_employee?.lastname) : "")) : <>N/A</>}
                                                                                </span>
                                                                            </OverlayTrigger>
                                                                        </div>
                                                                    </Col>
                                                                }
                                                                <Col md={1} className='align-self-center justify-content-center ps-0' onClick={(e) => props.openViewTicketConfirm(e, item?.processing, item?.who_is_viewing_user_id)} style={{ cursor: 'pointer', width: '6%' }} >
                                                                    <Col md={3}>
                                                                        {status === "R" ?
                                                                            <Button style={{ backgroundColor: '#707070', border: 'none', width: '20px', height: '20px', lineHeight: '1', textIndent: '-2', padding: '0rem 0rem' }}>
                                                                                <OverlayTrigger
                                                                                    key='top'
                                                                                    placement='top'
                                                                                    overlay={
                                                                                        <Tooltip id='tooltip'>
                                                                                            {props.t("ticket_status.resolved")}
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <span className='FilterTicketsRow '>{props.t("ticket_status_symbol.resolved")}</span>
                                                                                </OverlayTrigger>
                                                                            </Button>
                                                                            :
                                                                            status === "P" ?
                                                                                <Button style={{ backgroundColor: '#DE2450', border: 'none', width: '20px', height: '20px', lineHeight: '1', textIndent: '-2', padding: '0rem 0rem' }}>
                                                                                    <OverlayTrigger
                                                                                        key='top'
                                                                                        placement='top'
                                                                                        overlay={
                                                                                            <Tooltip id='tooltip'>
                                                                                                {props.t("ticket_status.pending")}
                                                                                            </Tooltip>
                                                                                        }
                                                                                    >
                                                                                        <span className='FilterTicketsRow '>{props.t("ticket_status_symbol.pending")}</span>
                                                                                    </OverlayTrigger>
                                                                                </Button>
                                                                                :
                                                                                status === "E" ?
                                                                                    <Button style={{ backgroundColor: '#FBBD4E', border: 'none', width: '20px', height: '20px', lineHeight: '1', textIndent: '-2', padding: '0rem 0rem' }}>
                                                                                        <OverlayTrigger
                                                                                            key='top'
                                                                                            placement='top'
                                                                                            overlay={
                                                                                                <Tooltip id='tooltip'>
                                                                                                    {props.t("ticket_status.on_hold")}
                                                                                                </Tooltip>
                                                                                            }
                                                                                        >
                                                                                            <span className='FilterTicketsRow '>{props.t("ticket_status_symbol.on_hold")}</span>
                                                                                        </OverlayTrigger>
                                                                                    </Button>
                                                                                    :
                                                                                    status === "A" ?
                                                                                        <Button style={{ backgroundColor: '#4A69BD', border: 'none', width: '20px', height: '20px', lineHeight: '1', textIndent: '-2', padding: '0rem 0rem' }}>
                                                                                            <OverlayTrigger
                                                                                                key='top'
                                                                                                placement='top'
                                                                                                overlay={
                                                                                                    <Tooltip id='tooltip'>
                                                                                                        {props.t("ticket_status.open")}
                                                                                                    </Tooltip>
                                                                                                }
                                                                                            >
                                                                                                <span className='FilterTicketsRow '>{props.t("ticket_status_symbol.open")}</span>
                                                                                            </OverlayTrigger>
                                                                                        </Button>
                                                                                        :
                                                                                        status === "N" ?
                                                                                            <Button style={{ backgroundColor: '#17a589', border: 'none', width: '20px', height: '20px', lineHeight: '1', textIndent: '-2', padding: '0rem 0rem' }}>
                                                                                                <OverlayTrigger
                                                                                                    key='top'
                                                                                                    placement='top'
                                                                                                    overlay={
                                                                                                        <Tooltip id='tooltip'>
                                                                                                            {props.t("ticket_status.new")}
                                                                                                        </Tooltip>
                                                                                                    }
                                                                                                >
                                                                                                    <span className='FilterTicketsRow '>{props.t("ticket_status_symbol.new")}</span>
                                                                                                </OverlayTrigger>
                                                                                            </Button>
                                                                                            :
                                                                                            status === "B" ?
                                                                                                <Button style={{ backgroundColor: '#2D3233', border: 'none', width: '20px', height: '20px', lineHeight: '1', textIndent: '-2', padding: '0rem 0rem' }}>
                                                                                                    <OverlayTrigger
                                                                                                        key='top'
                                                                                                        placement='top'
                                                                                                        overlay={
                                                                                                            <Tooltip id='tooltip'>
                                                                                                                {props.t("ticket_status.abandoned")}
                                                                                                            </Tooltip>
                                                                                                        }
                                                                                                    >
                                                                                                        <span className='FilterTicketsRow '>{props.t("ticket_status_symbol.abandoned")}</span>
                                                                                                    </OverlayTrigger>
                                                                                                </Button> :
                                                                                                null
                                                                        }
                                                                    </Col>
                                                                </Col>

                                                                <Col md={1} className={'align-self-center nopadding' + (item?.bold ? ' bold-list-item' : '')} onClick={(e) => props.openViewTicketConfirm(e, item?.processing, item?.who_is_viewing_user_id)} style={{ cursor: 'pointer', width: '8%' }}>
                                                                    <div className='oneLineClampEllipsis'>
                                                                        <OverlayTrigger
                                                                            key='top'
                                                                            placement='top'
                                                                            overlay={
                                                                                <Tooltip id='tooltip'>
                                                                                    {department_name != null ?
                                                                                        <>{department_name}</>
                                                                                        :
                                                                                        <>N/A</>
                                                                                    }
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <span>
                                                                                {department_name != null ?
                                                                                    <>{department_name}</>
                                                                                    :
                                                                                    <>N/A</>
                                                                                }
                                                                            </span>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                </Col>
                                                                <Col md={1} className='align-self-center' style={{ width: '7%' }}>
                                                                    {item?.who_is_viewing &&
                                                                        <OverlayTrigger
                                                                            key='top'
                                                                            placement='top'
                                                                            overlay={
                                                                                <Tooltip id='tooltip'>
                                                                                    {props.t("home_tickets.list_tickets.body.who_is_viewing", { who_is_viewing: item.who_is_viewing })}
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye" width="25%" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#707070" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                <circle cx="12" cy="12" r="2" />
                                                                                <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                                                                            </svg>
                                                                        </OverlayTrigger>
                                                                    }
                                                                </Col>

                                                                <Col className='d-flex justify-content-end ps-0' style={{/* width: '5%' */ }}>
                                                                    { item?.processing === false || item?.processing === undefined ? /* aparece caso o processamento seja falso, ou não exista */
                                                                    <OverlayTrigger rootClose={true} trigger={['click']} placement="left" overlay={props.actionsActiveColumn ? popover(item) : props.popoverHistory(item)} /* show={ !scrolling && ((item.ticket_reference_id + "*ID*" + item.id) === currentTicketId) } */ >
                                                                        <Button id={item?.ticket_reference_id + "*ID*" + item?.id + "*who_is_viewing*" + item?.who_is_viewing} onClick={(e) => props.setCurrentTicketInfo(e)} className='nopadding d-flex justify-content-start align-self-center' style={{ marginTop: '', backgroundColor: 'transparent', border: 'none', width: "24px" }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="90%" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#6A6A6A" fill="#6A6A6A" strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                <circle cx="12" cy="12" r="1" />
                                                                                <circle cx="12" cy="19" r="1" />
                                                                                <circle cx="12" cy="5" r="1" />
                                                                            </svg>
                                                                        </Button>
                                                                    </OverlayTrigger>
                                                                    : 
                                                                    <></> }
                                                                </Col>
                                                            </Row>
                                                        </ListGroup.Item >
                                                    );
                                                })
                                            }
                                        </ListGroup >
                                        : 
                                        <div className='align-self-center' style={{ overflow: 'hidden', height: '48vh', backgroundColor: '#F9F9F9' }}>
                                            <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '17vh', backgroundColor: '#F9F9F9' }}>
                                                <Col className='d-flex justify-content-center' md={1}>
                                                    <svg id="Grupo_11197" data-name="Grupo 11197" xmlns="http://www.w3.org/2000/svg" width="4vw" viewBox="0 0 24 24">
                                                        <path id="Caminho_9561" data-name="Caminho 9561" d="M0,0H24V24H0Z" fill="none" />
                                                        <line id="Linha_144" data-name="Linha 144" y2="2" transform="translate(15 5)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <line id="Linha_145" data-name="Linha 145" y2="2" transform="translate(15 11)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <line id="Linha_146" data-name="Linha 146" y2="2" transform="translate(15 17)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <path id="Caminho_9562" data-name="Caminho 9562" d="M5,5H19a2,2,0,0,1,2,2v3a2,2,0,0,0,0,4v3a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V14a2,2,0,0,0,0-4V7A2,2,0,0,1,5,5" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                    </svg>
                                                </Col>
                                            </Row>
                                            <Row className='d-flex justify-content-center align-content-center mt-2' style={{ backgroundColor: '#F9F9F9' }}>
                                                <Col className='d-flex justify-content-center'>
                                                    <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>
                                                        {props.isAssociatedTriggerList ?
                                                            <span>{props.t("home_tickets.list_tickets.body.empty_list")}</span> :
                                                            props?.fromHistory && props?.fromHistory === true ?
                                                                <span>{props.t("home_tickets.list_tickets.body.empty_list")}</span> :
                                                                <span>
                                                                    {props.t("home_tickets.list_tickets.body.empty_list")}, <Button className="buttonAsText3 nopadding mb-1" onClick={props.showCreateTicket}>{props.t("home_tickets.list_tickets.body.add_ticket")}</Button>
                                                                </span>
                                                        }
                                                    </p>
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                </InfiniteScroll >
                            </div >
                        </ListGroup >
                        :
                        <div className='align-self-center' style={{ overflow: 'hidden', height: '48vh', backgroundColor: '#F9F9F9' }}>
                            <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '17vh', backgroundColor: '#F9F9F9' }}>
                                <Col className='d-flex justify-content-center' md={1}>
                                    <svg id="Grupo_11197" data-name="Grupo 11197" xmlns="http://www.w3.org/2000/svg" width="4vw" viewBox="0 0 24 24">
                                        <path id="Caminho_9561" data-name="Caminho 9561" d="M0,0H24V24H0Z" fill="none" />
                                        <line id="Linha_144" data-name="Linha 144" y2="2" transform="translate(15 5)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <line id="Linha_145" data-name="Linha 145" y2="2" transform="translate(15 11)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <line id="Linha_146" data-name="Linha 146" y2="2" transform="translate(15 17)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Caminho_9562" data-name="Caminho 9562" d="M5,5H19a2,2,0,0,1,2,2v3a2,2,0,0,0,0,4v3a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V14a2,2,0,0,0,0-4V7A2,2,0,0,1,5,5" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    </svg>
                                </Col>
                            </Row>
                            <Row className='d-flex justify-content-center align-content-center mt-2' style={{ backgroundColor: '#F9F9F9' }}>
                                <Col className='d-flex justify-content-center'>
                                    <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>
                                        {props.isAssociatedTriggerList ?
                                            <span>{props.t("home_tickets.list_tickets.body.empty_list")}</span> :
                                            props?.fromHistory && props?.fromHistory === true || props.isMaster ?
                                                <span>{props.t("home_tickets.list_tickets.body.empty_list")}</span> :
                                                <span>
                                                    {props.t("home_tickets.list_tickets.body.empty_list")}, <Button className="buttonAsText3 nopadding mb-1" onClick={props.showCreateTicket}>{props.t("home_tickets.list_tickets.body.add_ticket")}</Button>
                                                </span>
                                        }
                                    </p>
                                </Col>
                            </Row>
                        </div>
                }

                {
                    props?.tickets && props?.tickets.length > 0 && props?.tickets[0] !== undefined ? /* mostra apenas se existir */
                        <Row style={{ marginTop: '4vh', width: '100%' }} >
                            <Col md={3}>
                                <Button style={{ display: props?.fromHistory ? 'none' : 'block', height: '5.5vh', maxWidth: '20vw' }} id="buttonExportAllTickets" className="buttonBlue" onClick={props.showExportAllTicketModal}>{props.t("history_tickets.modals.ticket_export.ticket_export")}</Button>
                                {/* <Button style={{ display: 'none', height: '5.5vh', maxWidth: '20vw' }} id="buttonManageTickets" className="buttonBlue" onClick={props.showManageTicketsModal}>{props.t("home_tickets.list_tickets.manage_tickets")}</Button> */}
                            </Col >
                        </Row >
                        :
                        <></>
                }
            </div >
        </>
    );

}

export default ListTickets;