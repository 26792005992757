type CSSProperties = {
  [key: string]: React.CSSProperties;
};

export class StyleSheet {
  static create<Styles extends CSSProperties>(styles: Styles): Styles {
    return styles;
  };
};

const styles = StyleSheet.create({

  textFontGlay: {
    textAlign: 'left',
    font: 'normal normal normal calc(0.2em + 0.7vw) Montserrat',
    letterSpacing: '0px',
    color: '#707070',
    opacity: '1',
    marginBottom: '1.2vh',
  },

  textBlack: {
    textAlign: 'left',
    font: 'normal normal bold calc(0.5em + 0.7vw) Montserrat',
    letterSpacing: '0px',
    color: '#1E1E1E',
    opacity: '1',
  },

  textBlackSmaller: {
    textAlign: 'left',
    font: 'normal normal bold calc(0.4em + 0.6vw) Montserrat',
    letterSpacing: '0px',
    color: '#1E1E1E',
    opacity: '1',
    marginTop: '8%',
  },
  inputGray: {
    height: '1vh',
  },

  textBlackSmallerCustom: {
    textAlign: 'left',
    font: 'normal normal bold calc(0.4em + 0.6vw) Montserrat',
    letterSpacing: '0px',
    color: '#1E1E1E',
    opacity: '1',
    marginTop: '8%',
    cursor: 'pointer'
  },

  pointer: {
    cursor: 'pointer',
  },
  btnActive: {
    padding: '4px 6px',
    borderRadius: '6px',
    color: '#fff',
    background: '#0a58ca',
    font: 'normal normal calc(0.4em + 0.6vw) Montserrat',
    marginLeft: '5px',
    cursor: 'pointer'
  },

  btnDisabled: {
    padding: '4px 6px',
    borderRadius: '6px',
    color: '#111',
    background: '#0000000d',
    font: 'normal normal calc(0.4em + 0.6vw) Montserrat',
    marginLeft: '5px',
    cursor: 'pointer'
  },

  dragDropzone: {
    width: '100%',
    height: '19vh',
    backgroundColor: 'white',
    border: '0.13rem dashed #E8E8E8',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'height 0.2s ease',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'hidden',
    font: 'normal normal normal calc(0.05em + 0.7vw) Montserrat',
    letterSpacing: '0px',
    color: '#BCBCBC',
    opacity: '1',
  },
  changeEditorDropzone: { 
    width: '100%', 
    display: 'flex', 
    overflowY: 'hidden'
  },
});

export default styles;