export enum OrderBy {
    asc = "asc",
    desc = "desc",
}

export enum SortBy {
    created_date = "created_at",
    updated_date = "updated_at",
    deleted_date = "deleted_at",
    updated_level2_date = "updated_level2_at",
    consumer_last_message = "consumer_last_message",
    date_scheduled = "date_scheduled",
    name = "name",
    status = "status"
}


export enum ListName {
    ListOfConsumers = "ListOfConsumers",
    ListOfEmails = "listOfEmails",
    ListOfTickets = "listOfTickets",
    ListOfFacebooks = "listOfFacebooks",
    listOfInstagram = "listOfInstagram",
    ListOfPhones = "listOfPhones",
    ListOfTags = "listOfTags",
    ListOfAgents = "ListOfAgents",
    ListOfTriggers = "listOfTriggers",
    ListOfTriggerTypes = "listOfTriggerTypes",
    ListOfDepartments = "ListOfDepartments",
    ListOfConsumersCustomFields = "ListOfConsumersCustomFields",
    ListOfTicketsCustomFields = "ListOfTicketsCustomFields",
    ListOfTasks = "ListOfTasks",
    ListOfTemplateMessages = "ListOfTemplateMessages",
    ListOfQuickMessage = "listOfQuickMessage",
    ListOfSummary = "ListOfSummary",
}