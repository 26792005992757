import React from "react"

export class TicketService {
    private ticket;
    private ticketDeleteId;
    private ticketDeleteWhoIsViewing;
    private ticketDeleteTicketReferenceId;
    private ticketCreate;
    private channelSelected;
    private ticketWhoIsViewingId;
    private clickListTickets;

    public setTicket(ticket: string) {
        this.ticket = ticket
    }

    public getTicket() {
        return this.ticket
    }

    public setTicketId(ticketId: any) {
        this.ticketDeleteId = ticketId
    }

    public getTicketId() {
        return this.ticketDeleteId
    }

    public setTicketWhoIsViewing(ticketWhoIsViewing: any) {
        this.ticketDeleteWhoIsViewing = ticketWhoIsViewing
    }

    public getTicketWhoIsViewing() {
        return this.ticketDeleteWhoIsViewing
    }

    public setTicketWhoIsViewingId(ticketWhoIsViewingId: any) {
        this.ticketWhoIsViewingId = ticketWhoIsViewingId
    }

    public getTicketWhoIsViewingId() {
        return this.ticketWhoIsViewingId
    }

    public setTicketReferenceId(ticketReferenceId: any) {
        this.ticketDeleteTicketReferenceId = ticketReferenceId
    }

    public getTicketReferenceId() {
        return this.ticketDeleteTicketReferenceId
    }

    public getTicketCreate() {
        return this.ticketCreate
    }

    public setTicketCreate(ticketCreate: boolean) {
        this.ticketCreate = ticketCreate
    }

    public getClickListTickets() {
        return this.clickListTickets
    }

    public setClickListTickets(clickListTickets: boolean) {
        this.clickListTickets = clickListTickets
    }
}

export const ticket = new TicketService()

export const TicketContext = React.createContext(ticket)