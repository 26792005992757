import { FC, useEffect, useState } from "react";
import ModalCreateTemplateMessage from ".";
import { IModalCreateTemplateMessageController } from "./indexModal";
import { useDispatch, useSelector } from "react-redux";
import { TokenInterface } from "../../../../../../services/requestsInterfacesModel";
import { getShowCreateTemplateMessage, getShowTemplateTagModal, setShowAlertFeedback, setShowCreateTemplateMessage, setShowTemplateTagModal } from "../../../../../../store/internal";
import { AppRequesterControlPanelController } from "../../../../../../services/appRequesterControlPanel/appRequesterControlPanelController";
import { FormValues } from "../formOfModalsTemplateMessage/indexModel";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../../../../../store/token";
import { useTranslation } from "react-i18next";

const ModalCreateTemplateMessageController:FC<IModalCreateTemplateMessageController> = (props) => {
    const [showModal, setShowModal] = useState(false);

    const dispatch = useDispatch();
    const appRequesterConst = new AppRequesterControlPanelController();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const [tags, setTags] = useState([]);

    const [examples, setExamples] = useState([]);

    const { t } = useTranslation();

    const values: TokenInterface = {
        internal: {
            getShowCreateTemplateMessage: useSelector(getShowCreateTemplateMessage),
            getShowTemplateTagModal: useSelector(getShowTemplateTagModal),
        },
        token: {
            value: useSelector(getToken)
        }
    }

    useEffect(() => {
        if (values.internal.getShowCreateTemplateMessage)
            setShowModal(true);
        else {
          setShowModal(false);
          setTags([]);
        }
    }, [values.internal.getShowCreateTemplateMessage]);

    const hide = () => {
      setExamples([]);
      if (values.internal.getShowTemplateTagModal)
        dispatch(setShowTemplateTagModal(false))
      else 
        dispatch(setShowCreateTemplateMessage(false));
    }

    const postTemplateMessage = async (formValues: FormValues) => {
        const headers = {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + values.token.value
        };

        const path = props.createInCompany ? 'template-message/whatsapp/keys' : '/template-message/whatsapp';
        await appRequesterConst.Post(
          path, formValues, { headers },
          (response: Object) => {
            return response;
          },
          (data) => {
            dispatch(setShowAlertFeedback({ message: t('home_departments.template_message_tab.template_message_create_sucess'), visibility: true, signalIcon: true }));
            setTags([]);
            hide();
            props.callback();
          },
          (error: any) => {
            if (formValues?.example?.length > 0) {
              setExamples(formValues.example);
            }
            if (error.response.status === 400) {
              if (error.response.data.code_cxpress === 2202) {
                dispatch(setShowAlertFeedback({ message: t('home_departments.template_message_tab.template_message_create_error'), visibility: true, signalIcon: false }));
              } else if (error.response.data.code_cxpress === 2207) {
                dispatch(setShowAlertFeedback({ message: t('home_departments.template_message_tab.template_message_attachment_error'), visibility: true, signalIcon: false }));
              } else if (error.response.data.code_cxpress === 2212 || error.response.data.code_cxpress === 2213) {
                dispatch(setShowAlertFeedback({ message: t('home_departments.template_message_tab.template_message_check_fields'), visibility: true, signalIcon: false }));
              } else if (error.response.data.code_cxpress === 2215) {
                dispatch(setShowAlertFeedback({ message: t('home_departments.template_message_tab.template_message_invalid_variables_error'), visibility: true, signalIcon: false }));
              } else if (error.response.data.code_cxpress === 2216) {
                dispatch(setShowAlertFeedback({ message: t('home_departments.template_message_tab.template_message_invalid_character'), visibility: true, signalIcon: false }));
              } else if (error.response.data.code_cxpress === 2217) {
                dispatch(setShowAlertFeedback({ message: t('home_departments.template_message_tab.template_message_duplicated_name_error'), visibility: true, signalIcon: false }));
              } else if (error.response.data.code_cxpress === 2218) {
                dispatch(setShowAlertFeedback({ message: t('home_departments.template_message_tab.template_message_smarters_error'), visibility: true, signalIcon: false }));
              }
            } else {
              dispatch(setShowAlertFeedback({ message: t('home_departments.template_message_tab.template_message_error'), visibility: true, signalIcon: false }));
            }
          }, navigate, dispatch, setIsLoading,
        );
      }

    return <ModalCreateTemplateMessage 
        showModal={showModal} 
        hide={hide} 
        onSubmit={postTemplateMessage}
        currentCompany={props.currentCompany}
        dispatch={dispatch}
        tags={tags}
        setTags={setTags}
        isLoading={isLoading}
        createInCompany={props.createInCompany}
        t={t}
        examples={examples}
        setExamples={setExamples}
    />
}

export default ModalCreateTemplateMessageController;