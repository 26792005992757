import { FC, useState } from "react";
import { IMiniChat } from "./indexModel";
import { Button, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import styles from "./styles.module.css"; // O CSS já está importado
import { IconX } from "@tabler/icons-react";
import Utils from "../../../../../../core/shared/utils";

const MiniChat: FC<IMiniChat> = (props) => {
    return (
        /* Este row serve como o container */
        <Row style={{ width: '100%', height: '100%', overflow: "hidden" }} className="d-flex align-items-end justify-content-end nopadding">
            {props.showChat || props.isAnimating ? (
                <Col
                    id="chat-container"
                    md={4}
                    className={`nopadding ${props.showChat ? styles.slideIn : styles.slideOut}`} // Classes do styles.module.css
                    style={{ borderWidth: "0px 2px", borderStyle: "solid", borderColor: props.headerColor || "#000000", borderRadius: "5px" }}
                >
                    <Row
                        style={{ backgroundColor: props.headerColor || '#1E1E1E', borderRadius: '3.2px 3.2px 0px 0px', color: '#FFFFFF', font: 'var(--px15_19_Bold-font)' }}
                        className="nopadding py-1 d-flex align-items-center justify-content-end"
                    >
                        <Col style={{ paddingLeft: '0px' }} className="d-flex align-items-center">
                            {props.previewImageURL && (
                                <img src={props.previewImageURL} className={`my-1 mx-1 me-2 ${styles['img-iframe']}`} style={{ backgroundColor: '#fff', borderRadius: '50%', padding: '2px' }} alt="" width={'24px'} height={"24px"} />
                            )}
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                        {props.botData !== null && props.botData.bot_name}
                                    </Tooltip>
                                }
                            >
                                <span className='oneLineClampEllipsis' style={{ color: props.nameColor || "#fff" }}>
                                    {props.botData !== null && props.botData.bot_name}
                                </span>
                            </OverlayTrigger>
                        </Col>
                        <Col md={4} className="d-flex justify-content-end" style={{ height: '100%' }}>
                            <Button onClick={props.handleChatToggle} className="nopadding" style={{ border: 'none', background: 'none' }}>
                                <IconX color="#FFFFFF" />
                            </Button>
                        </Col>
                    </Row>
                    <Row
                        id="#chat"
                        className="d-flex align-items-center justify-content-start nopadding"
                        style={{
                            height: '60vh',
                            backgroundColor: props.backgroundColor || '#f9f9f9',
                            overflowY: 'auto',
                            overflowX: 'hidden',
                        }}
                    >
                        {props.hasBot ? (
                            <>
                                <div className="chat-wrapper" style={{ minHeight: '100%' }}>
                                    {props.renderMessages()}
                                </div>
                                <div ref={props.messagesEndRef} />
                            </>
                        ) : (
                            props.noBot()
                        )}
                    </Row>
                    {props.botData !== null ? (
                        <Row
                            className="d-flex justify-content-start py-2 mt-0 nopadding"
                            onClick={() => { props.inputRef.current.focus() }}
                            style={{
                                backgroundColor: '#f8f9fa',
                                font: 'normal normal bold calc(0.2em + 0.7vw) Roboto',
                                color: '#707070',
                                borderWidth: "2px 0px",
                                borderStyle: "solid",
                                borderColor: props.headerColor || "#1E1E1E",
                                borderRadius: "0px 0px 5px 5px",
                            }}
                        >
                            <Col className="nopadding me-3">
                                <textarea
                                    placeholder={props.t("configurations.chatbots.view.custom_chat_bot.write_message")}
                                    className={styles.editor + " nopadding align-items-center"}
                                    onKeyUp={props.enterClick}
                                    ref={props.inputRef}
                                    style={{ border: 'none', background: 'transparent' }}
                                ></textarea>
                            </Col>
                            <Col md={2} className="nopadding d-flex align-items-center justify-content-end me-1">
                                <Button
                                    id="sendButtonMessage"
                                    type="submit"
                                    onClick={props.handleClickSendMessage}
                                    className={styles.buttonBlueSendMessage + " d-flex justify-content-center align-items-center"}
                                    style={{ minWidth: '60px', backgroundColor: props.sendButtonColor || "#707070", color: Utils.getTextColorForBackground(props.sendButtonColor) }}
                                >
                                    {props.t("configurations.chatbots.view.iframe.send")}
                                </Button>
                            </Col>
                        </Row>
                    ) : <></>}
                </Col>
            ) : (
                <Col className="d-flex justify-content-end">
                        {props.previewImageURL && (
                            <img
                                onClick={props.handleChatToggle}
                                src={props.previewImageURL}
                                className={`my-1 mx-1 me-2 ${styles['img-iframe']}`}
                                style={{ backgroundColor: '#fff', borderRadius: '50%', border: `2px solid ${props.headerColor || '#000000'}`, padding: '2px', cursor: 'pointer' }}
                                alt=""
                                width={'60px'}
                                height={"60px"}
                            />
                        )}
                </Col>
            )}
        </Row>
    );
}

export default MiniChat;