import { useLocation, useNavigate } from "react-router-dom";
import { AppRequesterController } from "../../../../../services/appRequester/appRequesterController";
import ModalChangeUser from "./index";
import { useDispatch, useSelector } from "react-redux";
import { TokenInterfaceControlPanel } from "../../../../../services/requestsInterfacesModel";
import { getToken } from "../../../../../store/token";
import { useEffect, useState } from "react";
import { setShowAlertFeedback } from "../../../../../store/internal";

const ModalChangeUserController = (props) => {

  const AppRequesterConst = new AppRequesterController()

  const navigate = useNavigate()
  const dispatch = useDispatch()
  let { state } = useLocation()

  const values: TokenInterfaceControlPanel = {
    token: {
      value: useSelector(getToken)
    }
  }

  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [listEmails, setListEmails] = useState([]);
  const [listEmailsData, setListEmailsData] = useState([]);
  const [selectedEmailData, setSelectedEmailData] = useState({});
  const [selectEmail, setSelectEmail] = useState([]);
  const [onSelectEmail, setOnSelectEmail] = useState([]);

  const [email, setEmail] = useState("");
  
  const [showFeedbackEmail, setShowFeedbackEmail] = useState(false);
  
  const [selectedSubdomain, setSelectedSubdomain] = useState('nothing changes');

  useEffect(() => {
    setListEmails([]);
    setSelectEmail([]);
    setOnSelectEmail([]);
    setStep(0)
  }, [props.show]);

  const onSetSelectEmail = (value: string | any[]) => {
    if (value.length > 0) {
      if (value[value.length - 1].value === 'NA') {
        setOnSelectEmail([])
      } else {
        setOnSelectEmail([value[value.length - 1]])
        let emailValue = value[value.length - 1]?.value.split('-')[0];
        let emailId = value[value.length - 1]?.value.split('-')[1];
        setSelectedEmailData(listEmailsData.find(item => item.email === emailValue && item._id === emailId))
      }
    } else {
      setOnSelectEmail([])
    }
  }

  const searchForEmail = async () => {
    setIsLoading(true);

    const headers = {
      "Content-Type": "application/json; charset=utf-8",
      "Authorization": "Bearer " + values.token.value
    };

    await AppRequesterConst.Get(
      `/panel/vozxpress/account/search?user=${email}`, { headers },
      (response: Object) => {},
      (data: any) => {
        if(data.status === 200) {
          setListEmails([])
          setListEmailsData([])
          let list = data.data.users;
          setListEmailsData(list);
          setListEmails(list.map(i => ({label: `${i.email} - ${i.company}`, value: `${i.email}-${i._id}`, key: i._id})));
          setStep(2);
        }   
      },
      (error: { response: { status: number } }) => {
        if (error.response?.status === 400) {

        }
        if (error.response?.status === 401) {

        }
      }, navigate, dispatch, setIsLoading, { }
    )
  
  }


  const submitChanges = async () => {
    if (onSelectEmail.length > 0) {

      let emailSelect = onSelectEmail[0]?.value.split('-')[0];
      let emailId = onSelectEmail[0]?.value.split('-')[1];
      let userVxSelected = listEmailsData.find(item => item.email === emailSelect && item._id === emailId);
      const headers = {
        "Content-Type": "application/json; charset=utf-8",
        "Authorization": "Bearer " + values.token.value
      };

      const data = {
        company_id: state.id,
        user_id: userVxSelected._id,
        email: emailSelect,
        action_subdomain: selectedSubdomain,
        subdomain: userVxSelected.subdomain,
        token_integration: userVxSelected?.token_integration || undefined
      }
      setIsLoading(true);
      await AppRequesterConst.Put('/panel/vozxpress/update-user-company', data, { headers },
        (response: Object) => {},
        (data: any) => {
          if(data.status === 200) {
            navigate("/clients");
            props.onHide();
            dispatch(setShowAlertFeedback({ message: "O usuário principal da conta foi alterado com sucesso", visibility: true, signalIcon: true}))
          }
        },
        (error: { response: { status: number; data: { message: { message: string, path: [] }, code_cxpress: number; }; } }) => {
          dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }));
          props.onHide();
        }, navigate, dispatch, setIsLoading,
      )
    }
  }
  return (
    <ModalChangeUser 
      show={props.show} 
      onHide={props.onHide}
      listEmails={listEmails}
      setListEmails={setListEmails}
      selectEmail={selectEmail}
      setSelectEmail={setSelectEmail}
      onSelectEmail={onSelectEmail}
      setOnSelectEmail={setOnSelectEmail}
      step={step}
      setStep={setStep}
      isLoading={isLoading}
      state={state}
      setEmail={setEmail}
      showFeedbackEmail={showFeedbackEmail}
      setShowFeedbackEmail={setShowFeedbackEmail}
      submitChanges={submitChanges}
      searchForEmail={searchForEmail}
      onSetSelectEmail={onSetSelectEmail}
      selectedSubdomain={selectedSubdomain}
      setSelectedSubdomain={setSelectedSubdomain}
      selectedEmailData={selectedEmailData}
    />
  )
}

export default ModalChangeUserController;

