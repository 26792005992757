import React, { FC } from "react";
import { Button, Col, ListGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import Utils from "../../../../../core/shared/utils";
import { ListName } from "../../../../../core/enums/order-sort-by";
import IListOfCustomFields from "./indexModel";
import ModalEditCustomFieldsConsumersController from "../../../components/modalEditCustomFieldConsumer/indexController";
import Loading from "../../../../../components/loading";

const ListOfCustomFields: FC<IListOfCustomFields> = ({
    t,
    showCustomFieldModalEdit,
    handleCloseCustomFieldModalEdit,
    customEdit,
    callBackModalCustomField,
    customData,
    updateListfunction,
    HasMoreInformation,
    setCurrentCustomInfo,
    popover,
    showCreateCustomField,
    isLoading
}) => {
    return (
        <>
            <ModalEditCustomFieldsConsumersController show={showCustomFieldModalEdit} handleClose={handleCloseCustomFieldModalEdit} data={customEdit} callBackModalCustomField={callBackModalCustomField} />
            <Loading visibility={isLoading}></Loading>
            {/* <ModalEditTags token={props.token} visibility={useSelector(getShowEditTagsModal)} callback={props.callbackModalTags} data={currentTagData}></ModalEditTags> */}
            {
                customData !== undefined && customData.length !== 0 ?
                    <div style={{ marginTop: '2vh' }}>
                        <ListGroup variant="flush">
                            <ListGroup.Item className="TicketsRowCollums">
                                <Row name={ListName.ListOfConsumersCustomFields + "Head"}>
                                    <Col md={3} className="nopadding">
                                        {t('configurations_consumers.list_of_custom_fields.name')}
                                    </Col>
                                    <Col md={2} className="nopadding">
                                        {t('configurations_consumers.list_of_custom_fields.type')}
                                    </Col>
                                    <Col className='d-flex justify-content-end' >
                                        <p>{t('configurations_consumers.list_of_custom_fields.actions')}</p>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        </ListGroup>

                        <div id={ListName.ListOfConsumersCustomFields} style={{ overflowY: 'auto', height: '50vh' }}>
                            <InfiniteScroll
                                dataLength={customData.length}
                                next={updateListfunction}
                                hasMore={HasMoreInformation as boolean}
                                loader={<h4> </h4>}
                                scrollableTarget={ListName.ListOfConsumersCustomFields}
                                onScroll={() => { Utils.hidePopoverOnScroll(ListName.ListOfConsumersCustomFields) }}
                            >
                                {
                                    <ListGroup variant="flush" className="TicketsRow" >
                                        {

                                            customData.map((item, index) => {
                                                let date = new Date(item.created_at);

                                                return (
                                                    <ListGroup.Item style={{ color: '#707070' }} id={item.name + " " + (item.name && item.lastname ? item.lastname : "") + "*ID*" + item.user_id} key={"keyAll" + index.toString()} >
                                                        <Row className='TicketsRowHeight'>
                                                            <Col md={3} className='d-flex align-self-center nopadding'>
                                                                <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                    <OverlayTrigger
                                                                        key='top'
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id='tooltip'>
                                                                                {item.name}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {item.name}
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </Col>
                                                            <Col md={2} className='d-flex  align-self-center nopadding'>
                                                                {item.type === 'd31ebd9e-af9f-4559-b04a-729439218a6f' && <span>{t('configurations_consumers.list_of_custom_fields.simple_text')}</span>}
                                                                {item.type === '2982a738-4de3-440a-8ed1-cc1fdb51bc9b' && <span>{t('configurations_consumers.list_of_custom_fields.multiline_text')}</span>}
                                                                {item.type === '74bf4b70-3415-4e8a-ac81-7ebe435c1424' && <span>{t('configurations_consumers.list_of_custom_fields.simple_select')}</span>}
                                                                {item.type === '49d495a8-50d4-4d61-9d62-e1cc03227573' && <span>{t('configurations_consumers.list_of_custom_fields.multiple_select')}</span>}
                                                            </Col>
                                                            <Col className='d-flex justify-content-end' >
                                                                <OverlayTrigger rootClose={true} trigger={['click']} placement="left" overlay={popover}>
                                                                    <Button id={item.name + "*ID*" + item.id} onClick={setCurrentCustomInfo} className='nopadding d-flex justify-content-start align-self-center' style={{ marginTop: '', backgroundColor: 'transparent', border: 'none', width: "24px" }}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="90%" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#6A6A6A" fill="#6A6A6A" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                            <circle cx="12" cy="12" r="1" />
                                                                            <circle cx="12" cy="19" r="1" />
                                                                            <circle cx="12" cy="5" r="1" />
                                                                        </svg>
                                                                    </Button>

                                                                </OverlayTrigger>
                                                            </Col>
                                                        </Row>
                                                    </ListGroup.Item>
                                                );
                                            })
                                        }
                                    </ListGroup>
                                }
                            </InfiniteScroll>
                        </div>
                    </div>
                    :
                    <div className='align-self-center' style={{ overflow: 'hidden', height: '50vh', backgroundColor: '#F9F9F9' }}>
                        <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '17vh', backgroundColor: '#F9F9F9' }}>
                            <Col md={1}>
                                <svg id="Grupo_11212" data-name="Grupo 11212" xmlns="http://www.w3.org/2000/svg" width="4vw" viewBox="0 0 24 24">
                                    <path id="Caminho_9563" data-name="Caminho 9563" d="M0,0H24V24H0Z" fill="none" />
                                    <circle id="Elipse_157" data-name="Elipse 157" cx="4" cy="4" r="4" transform="translate(5 3)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    <path id="Caminho_9564" data-name="Caminho 9564" d="M3,21V19a4,4,0,0,1,4-4h4a4,4,0,0,1,4,4v2" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    <path id="Caminho_9565" data-name="Caminho 9565" d="M16,3.13a4,4,0,0,1,0,7.75" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    <path id="Caminho_9566" data-name="Caminho 9566" d="M21,21V19a4,4,0,0,0-3-3.85" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                </svg>
                            </Col>
                        </Row>
                        <Row className='d-flex justify-content-center align-content-center mt-2' style={{ marginLeft: '8%', backgroundColor: '#F9F9F9' }}>
                            <Col md={5}>
                                <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>{t('configurations_consumers.list_of_custom_fields.no_results')} <Button className="buttonAsText3 nopadding mb-1" onClick={showCreateCustomField}>{t('configurations_consumers.list_of_custom_fields.add_custom_field')}</Button></p>
                            </Col>
                        </Row>
                    </div>
                }
        </>
    );
}

export default ListOfCustomFields;