import React, { FC } from 'react';
import { useSelector } from 'react-redux'

import { getShowCallNotificationBar, getShowAcquirePlatformComponent } from '../../../store/internal'

import {
    Container,
    Row,
    Col,
    Button,
    OverlayTrigger,
    Tooltip,
    ListGroup,
} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import "react-datepicker/dist/react-datepicker.css";

import Loading from '../../../components/loading/index';

import CallNotificationBar from '../components/callNotificationBar';
import ButtonSearchCleanInput from '../components/buttonSearchCleanInputComponent';
import AcquirePlatformNotificationBar from '../components/acquirePlatformNotificationBar';

import ButtonListNotificationController from '../../../components/buttonListNotification/indexController';
import ButtonStatusAgent from '../components/buttonStatusAgent';
import FilterOptionComponentController from '../components/filterOptionComponent/indexController';
import { IHomeTickets } from './indexModel';
import ViewTicketController from './viewTicket/indexController';
import ButtonCountNumberTicketsController from './components/buttonCountNumberTickets/indexController';
import ListOfTicketsController from './listOfTickets/indexController';

declare module 'react' {
    interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
        name?: string;
    }
}

const HomeTickets: FC<IHomeTickets> = (props) => {
    return (
        <>
            {!props.loadingList && (<Loading visibility={true} />)}
            <Loading visibility={props.isLoading} />
            <Container fluid className="nopadding" style={{ overflow: 'hidden' }}>
                <div className="wrapper">
                    {/* <SidebarController setCurrentState={props.setCurrentState} setAux={props.setAux} currentPage={SidebarButtonPage.Tickets} isClosingCreateTicket={props.isClosingCreateTicket} setIsClosingCreateTicket={props.setIsClosingCreateTicket} closeWithoutSavingConfirmation={(e) => props.closeWithoutSaving(e)} filledCreatingTicket={props.filledCreatingTicket} setFilledCreatingTicket={props.setFilledCreatingTicket}></SidebarController> */}
                    <div className="content py-0 ps-0">
                        <CallNotificationBar visibility={useSelector(getShowCallNotificationBar)} setCurrentTicketFunction={(value) => props.setCurrentTicketFunction(value)} updateListfunction={() => props.updateListfunction()}></CallNotificationBar>
                        <AcquirePlatformNotificationBar visibility={useSelector(getShowAcquirePlatformComponent).visibility} limitDays={useSelector(getShowAcquirePlatformComponent).limitDays} />
                        <Row className="nopadding mt-3 ps-3" style={{ display: "flex" }}>
                            <Col md={7} style={{ marginRight: '-8vw', marginBottom: '2vh' }}>
                                <Row>
                                    <Col className="d-flex justify-content-start align-self-start">
                                        <div style={{ font: 'var(--px20_24_Bold-font)' }}>{ props.t("home_tickets.welcome") }</div>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <div style={{ font: 'var(--px15_19-font)', color: '#707070' }}>{ props.t("home_tickets.header_info") }</div>
                                </Row>
                            </Col>
                            <Col className="d-flex justify-content-end align-items-start">
                                <ButtonSearchCleanInput isClosingCreateTicket={props.isClosingCreateTicket} setIsClosingCreateTicket={props.setIsClosingCreateTicket} filledCreatingTicket={props.filledCreatingTicket} closeWithoutSavingConfirmation={(e: any) => props.closeWithoutSaving(e)} inputPlaceholder={props.t("home_tickets.button_search_placeholder")} filteringTickets={(e, auxObj, action) => props.filteringTickets(e, auxObj, action)} setCurrentFilterSearch={props.setCurrentFilterSearch} currentFilterSearch={props.currentFilterSearch} setCurrentState={props.setCurrentState}></ButtonSearchCleanInput>
                                <ButtonListNotificationController setCurrentTicketFunction={props.setCurrentTicketFunction}/>
                                <ButtonStatusAgent/>
                            </Col>
                        </Row>
                        <Row className='d-flex nopadding ps-3'>
                            {/* <Col className="d-flex" style={{ marginTop: "0vh", marginLeft: '0.5vw' }} >
                                    <p className="nopadding" style={{ font: 'normal normal normal calc(0.3rem + 0.7vw) Montserrat', color: '#1E1E1E', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => {}}>
                                        <span>
                                            <svg id="Grupo_11161" data-name="Grupo 11161" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 2 24 24">
                                                <path id="Caminho_9641" data-name="Caminho 9641" d="M0,0H24V24H0Z" fill="none" />
                                                <path id="Caminho_9642" data-name="Caminho 9642" d="M9,13,5,9,9,5M5,9H16a4,4,0,0,1,0,8H15" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            </svg>
                                        </span>
                                        Voltar para a listagem
                                    </p>
                                </Col> */}
                            {props.values.internal.getShowEditTicketsModal?
                                <></>:
                                <Col>
                                    <ButtonCountNumberTicketsController closeWithoutSavingConfirmation={(e: any) => props.closeWithoutSaving(e)} numberOfAllTickets={props.numberOfAllTickets} numberOfYoursTickets={props.numberOfYoursTickets} numberOfNewTickets={props.numberOfNewTickets} numberOfUnreadTickets={props.numberOfUnreadTickets} isLoading={props.isLoading} setNoAtendence={props?.setNoAtendence}  />
                                </Col>
                            } 
                        </Row>
                        {
                            props.values.internal.getShowEditTicketsModal === true ?
                                <div style={{ marginTop: '3vh' }} className="">
                                    <ViewTicketController setPhoneNumber={(value: React.SetStateAction<string>) => { props.setPhoneNumber(value) }} setOpenModalPhone={(value) => { props.setOpenModalPhone(value) }} homeTicketsResetReturn={props.allFiltersClick} currentTicket={props.currentTicket} ticketPreviewFormData={props.ticketData} filters={props.currentParams} setFilledEditTicket={props.setFilledCreatingTicket} resetCloseCreateTicketState={() => props.resetCloseCreateTicketState()} currentViewFilter={props.currentViewFilter} onLoadingViewTicket={props.onLoadingViewTicket} allFiltersClick={props.allFiltersClick} setCurrentParams={props.setCurrentParams} ></ViewTicketController>
                                </div>
                                :
                                <>
                                    <Row className="ps-3" id="buttonCreateTicket" style={{ margin: '2.5vh 0.5vw 0px', padding: '0' }}>
                                        <Col md={9}>
                                            <p className="nopadding" style={{ color: '#0C2461', font: 'normal normal bold min(max(10px, calc(0.2em + 0.7vw)), 19px) Montserrat' }}>
                                                TICKETS
                                                {!props.isMaster() && 
                                                    <OverlayTrigger
                                                    key='top'
                                                    placement='top'
                                                    overlay={
                                                        <Tooltip id='tooltip' className='tooltip-arrow-Top'>
                                                            {props.t("home_tickets.create_ticket")}
                                                        </Tooltip>
                                                    }
                                                >

                                                    <Button className="mx-3 nopadding buttonTicketsSec"
                                                        onClick={() => props.showCreateTicket()}>
                                                        <svg id="Grupo_11079" data-name="Grupo 11079"
                                                            xmlns="http://www.w3.org/2000/svg" width="50%"
                                                            viewBox="0 0 24 24">
                                                            <path id="Caminho_9572" data-name="Caminho 9572"
                                                                d="M0,0H24V24H0Z" fill="none" />
                                                            <line id="Linha_147" data-name="Linha 147" y2="14"
                                                                transform="translate(12 5)" fill="none"
                                                                stroke="#0c2461" strokeLinecap="round"
                                                                strokeLinejoin="round" strokeWidth="1.5" />
                                                            <line id="Linha_148" data-name="Linha 148" x2="14"
                                                                transform="translate(5 12)" fill="none"
                                                                stroke="#0c2461" strokeLinecap="round"
                                                                strokeLinejoin="round" strokeWidth="1.5" />
                                                        </svg>
                                                    </Button>
                                                </OverlayTrigger>
                                                }
                                            </p>
                                        </Col>
                                        {/* <Col md={3} className="d-flex justify-content-end me-0 pe-0" >
                                        <ButtonOrderByList />
                                    </Col> */}
                                    </Row>
                                    <div className='FilterTicketsRow ps-3' style={{ margin: '2vh 0.5vw 0px', padding: '0' }}>
                                        <ListGroup variant="flush" >
                                            <ListGroup.Item style={{ backgroundColor: 'rgba(207, 227, 255,0.25)', padding: '0.6%' }}>
                                                <FilterOptionComponentController
                                                    optionNames={props.isAgent() ? ["Canal", "Automação", "Status", "Setor", "Data"] : ["Canal", "Automação", "Atribuido", "Status", "Setor", "Data"]}
                                                    disableSubOptions={{ 'status': { "resolved": true } }}
                                                    currentState={props.currentState}
                                                    setCurrentState={props.setCurrentState}
                                                    values_user={props.values.user}
                                                    values_internal_getShowEditTicketsModal={props.values.internal.getShowEditTicketsModal}
                                                    filteringTickets={(e, auxObj, action) => props.filteringTickets(e, auxObj, action)}
                                                    cleanList={() => props.cleanList()}
                                                    values_token_value={props.values.token.value}
                                                    values_company_id={props.values.company.id}
                                                    yoursTickets={props.yoursTickets}
                                                    newTickets={props.newTickets}
                                                    screen={'home_tickets'}
                                                />
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </div>
                                    <ListOfTicketsController statusPage={props.currentState} datas={props.aux} updateListAfterDelete={() => props.updateListAfterDelete()} updateListfunction={() => props.updateListfunction()} showCreateTicket={props.showCreateTicket} setCurrentTicketFunction={(value) => props.setCurrentTicketFunction(value)} assignedEmployeeActiveColumn={true} requesterUserActiveColumn={true} actionsActiveColumn={true} checkboxes={true} callbackOrderByTickets={(column, order) => props.callbackOrderByTickets(column, order)} clickNotification={props.clickNotification} setClickNotification={props.setClickNotification}></ListOfTicketsController>
                                </>
                        }
                    </div>
                </div >
            </Container>
        </>
        
    );
}

export default HomeTickets;
