import { useState, useEffect, FC, SetStateAction } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
    setShowAlertFeedback,
    setShowEditDepartmentModal,
} from '../../../../store/internal'
import { getToken } from '../../../../store/token';
import { getIdUser, getNameUser, getProfileId } from '../../../../store/user';
import { getIdCompany } from '../../../../store/company';
import { IModalEditDepartmentsController } from './indexModel';
import { TokenInterface } from '../../../../services/requestsInterfacesModel';

import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import { useNavigate } from 'react-router-dom';

import "@yaireo/tagify/dist/tagify.css"
import { DataAgentInterface, DataCompanyEmailAvailableInterface } from '../../../../services/requestsInterfacesModel';
import UserService from '../../../../services/user-service';
import { useTranslation } from 'react-i18next';
import ModalEditDepartments from '.';
import { verifyCode } from '../../../../services/codeCxpressInterface';
import { Channels } from '../../../../core/enums/channels';

const ModalEditDepartmentsController: FC<IModalEditDepartmentsController> = (props) => {
    const { t } = useTranslation();
    const [validated] = useState(false);
    const appRequesterConst = new AppRequesterController();

    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        },
        user: {
            id: useSelector(getIdUser),
            name: useSelector(getNameUser),
            profile_id: useSelector(getProfileId)
        },
        token: {
            value: useSelector(getToken)
        },
        internal: {
        }
    };

    const [/*isLoading*/, setIsLoading] = useState(false)
    const [department_name, setDepartment_name] = useState(null as string || null);
    const [selected_keyWords, setSelected_keyWords] = useState(null);
    const [department_isActive, setDepartment_isActive] = useState(false);

    const [showfeedbackName, setShowfeedbackName] = useState(false);
    const [selected_agents, setSelected_agents] = useState([]);
    const [selected_emails, setSelected_emails] = useState([]);

    const [options_agents, setOptions_agents] = useState([]);
    const [options_emails, setOptions_emails] = useState([]);

    const [modalShow, setModalShow] = useState(props.visibility);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [channelKeys, setChannelKeys] = useState([]);
    const [selectedChannelKeys, setSelectedChannelKeys] = useState([]);
    const [whatsappKeys, setWhatsappKeys] = useState([]);
    const [facebookKeys, setFacebookKeys] = useState([]);
    const [instagramKeys, setInstagramKeys] = useState([]);

    // concatenando keys
    useEffect(() => {
      setChannelKeys([...whatsappKeys, ...facebookKeys, ...instagramKeys]);
    }, [whatsappKeys, facebookKeys, instagramKeys]);

    /* useEffect(() => {
      console.log("Setor", props.data);
    }, [props.data]); */

    useEffect(() => {
      if (props.data !== null && props.visibility === true) {
        Promise.allSettled([
          getCompanyEmail(),
          getAgents(),
          getChannelKeys()
        ]).then(() => {
          setSelected_emails(props.data?.emails && (props.data?.emails?.length > 0 && props.data?.emails[0] !== null) ? props.data?.emails?.map((item) => ({ label: item.email, value: item.id })) : [])
          setSelected_agents(props.data?.agents ? props.data?.agents?.map((item) => ({ label: `${item.name} ${item.lastname ? item.lastname : ""}`, value: item.user_id })) : []);
          setDepartment_name(props.data?.sector_name);
          setSelected_keyWords(props.data?.keyword);
          setDepartment_isActive(props.data?.active);
          settingSelectedsKeys(props?.data);
          setModalShow(props.visibility);
        });
      }
    }, [props.visibility, props.data]);

    // concatenando as facebook, instagram e whatsapp keys para selecionadas
    const settingSelectedsKeys = (data: any) => {
      let keys = data?.facebook_keys? data.facebook_keys: [];
      keys = keys.concat(data?.instagram_keys? data.instagram_keys: []);

      let facebook = [];
      let instagram = [];
      let whatsapp = [];
      
      setSelectedChannelKeys([]);

      setWhatsappKeys((whatsappKeys) => {
        /* if (typeof (data?.whatsapp_keys_id) === 'string')
          whatsapp = whatsappKeys.filter((key) => data.whatsapp_keys_id === key.value);
        else */
        whatsapp = whatsappKeys.filter((key) => data?.whatsapp_keys?.find((what) => what.id === key.value));

        setSelectedChannelKeys((selectedKeys) => {
          return [...selectedKeys, ...whatsapp];
        })

        return whatsappKeys;
      });

      setInstagramKeys((instagramKeys) => {
        instagram = instagramKeys.filter((key) => data?.instagram_keys?.find((insta) => insta.id === key.value));
        
        setSelectedChannelKeys((selectedKeys) => {
          return [...selectedKeys, ...instagram];
        });
        
        return instagramKeys;
      });

      setFacebookKeys((facebookKeys) => {
        facebook = facebookKeys.filter((key) => data?.facebook_keys?.find((face) => face.id === key.value));
        
        setSelectedChannelKeys((selectedKeys) => {
          return [...selectedKeys, ...facebook];
        });
        
        return facebookKeys;
      });
    }

    const getCompanyEmail = async () => {
        const headers = UserService.getHeaders();
        await appRequesterConst.Get(
            `/company/email/available/${props.data.sector_id}`, { headers },
            (response: Object) => {
                return response;
            },
            (data: DataCompanyEmailAvailableInterface) => {
                setOptions_emails([]);
                if (data.status === 200) {
                    let option = (data.data.emails.length > 0 ? data.data.emails.map((item) => ({ label: item.email, value: item.id })) : []);
                    option = option.concat(selected_emails);
                    option.sort((a, b) => a.label.localeCompare(b.label));
                    setOptions_emails(option);
                }
            },
            (error: Object) => {
                console.error("Erro ao buscar emails disponíveis:", error);
            }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const getAgents = async () => {
        const headers = UserService.getHeaders();
        await appRequesterConst.Get(
            '/agent', { headers },
            (response: Object) => {
                return response;
            },
            (data: DataAgentInterface) => {
                if (data.status === 200) {
                    setOptions_agents(data.data.employees.length > 0 ? data.data.employees.map((item) => ({ label: `${item.name} ${item.lastname ? item.lastname : ""}`, value: item.user_id })) : []);
                }
            },
            (error: Object) => {
                console.error("Erro ao buscar agentes:", error);
            }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const hide = () => {
        setSelected_agents([]);
        setSelected_emails([]);
        setFacebookKeys([]);
        setInstagramKeys([]);
        setWhatsappKeys([]);
        setOptions_emails([]);
        dispatch(setShowEditDepartmentModal(false));
        setModalShow(false);
    }

    const onChangeTags = (e: { detail: { tagify: { getCleanValue: Function; }; }; }) => {
        setSelected_keyWords(e.detail.tagify.getCleanValue().map((tag) => {
            return tag.value;
        }));
    }

    const validate = (values) => {
        setDepartment_name(values.name);

        let errors = {};

        if (!values.name) {
            errors["name"] = t('home_departments.modal_register_departments.validate.empty_field');
            setShowfeedbackName(true);
        } else {
            setShowfeedbackName(false);
        }

        return errors;
    }

    const onSubmit = async () => {
      let JsonSend_sector = {
        "name": department_name,
        "active": department_isActive,
        "id": props.data.sector_id
      }

      if (selected_emails) {
        JsonSend_sector["emails_id"] = selected_emails.map(({ value }) => value);
      }

      let whatsapp_keys = [];
      let facebook_keys = [];
      let instagram_keys = [];
      if (selectedChannelKeys?.length > 0) {
        selectedChannelKeys.map((item) => {
          if (item.key_type === "instagram") {
            instagram_keys.push(item.value);
          } else if (item.key_type === "facebook") {
            facebook_keys.push(item.value);
          } else {
            whatsapp_keys.push(item.value);
          }
        });
      }
      JsonSend_sector['instagram_keys_id'] = instagram_keys;
      JsonSend_sector['facebook_keys_id'] = facebook_keys;
      JsonSend_sector['whatsapp_keys_id'] = whatsapp_keys;

      if (selected_agents && selected_agents.length > 0) {
        JsonSend_sector["employees_user_ids"] = selected_agents.map(({ value }) => value);
      }
      if (selected_keyWords && selected_keyWords.length > 0) {
        JsonSend_sector["key_words"] = selected_keyWords;
      }

      const headers = {
        "Content-Type": "application/json; charset=utf-8",
        "Authorization": "Bearer " + props.token
      };

      await appRequesterConst.Put(
        '/sector', JsonSend_sector, { headers },
        (response) => {
          return response;
        },
        (data) => {
          dispatch(setShowAlertFeedback({ message: t('home_departments.modal_edit_departments.save_success'), visibility: true, signalIcon: true }));
          props.callback(data.data.sector.id);
          hide();
        },
        (error: { response: { status: number; data: { message: any[]; code_cxpress: number } } }) => {
          if (error.response.status == 400) {
            if (error.response.data.code_cxpress === 2708) {
              dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
            } else if (error.response.data.code_cxpress === 2711) {
              dispatch(setShowAlertFeedback({ message: t('errors.SectorNumberRegistered'), visibility: true, signalIcon: false }));
            } else if(error.response.data.code_cxpress === 2709) {
              dispatch(setShowAlertFeedback({ message: t('home_departments.modal_register_departments.existent_department') + department_name + ".", visibility: true, signalIcon: false }));
            } else if(error.response.data.code_cxpress === 2714) {
              dispatch(setShowAlertFeedback({ message: t('home_departments.modal_register_departments.existent_key'), visibility: true, signalIcon: false }));
            } else if(error.response.data.code_cxpress === 2715) {
              dispatch(setShowAlertFeedback({ message: t('home_departments.modal_register_departments.inactive_key'), visibility: true, signalIcon: false }));
            } else {
              dispatch(setShowAlertFeedback({ message: t('errors.defaultErrorMessage'), visibility: true, signalIcon: false }));
            }
          } else {
            dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }))
          }
        }, navigate, dispatch, setIsLoading
      );
    }

    const getChannelKeys = async () => {
      const headers = UserService.getHeaders();

      await appRequesterConst.Get(
        'channel-whatsapp/whatsapp-numbers', { headers },
        (response: Object) => {
          return response;
        },
        (data) => {
          if (data.status === 200) {
            setWhatsappKeys(data.data.whatsapp_numbers.map((item) => {
              let label = (`${item.subaccount_name} - ${item.phone_number}`)

              return {
                label: label,
                value: item.id,
                channel_icon: Channels.WhatsApp,
                key_type: "whatsapp",
              }
            }));
          }
        },
        (error: Object) => {
        }, navigate, dispatch, setIsLoading, { values: values }
      );

      // listagem de instagram
      await appRequesterConst.Get(
        'channel-instagram/list-pages-by-user', { headers },
        (response: Object) => {
          return response;
        },
        (data) => {
          if (data.status === 200) {
            setInstagramKeys(data.data.keys.filter((item) => item.isActive).map((item) => {
              return {
                label: item.pageName,
                value: item.id,
                key_type: "instagram",
                channel_icon: Channels.Instagram,
              }
            }));
          }
        },
        (error: Object) => {
        }, navigate, dispatch, setIsLoading, { values: values }
      ); 

      // listagem de facebook
      await appRequesterConst.Get(
        'channel-facebook/list-pages-by-user', { headers },
        (response: Object) => {
          return response;
        },
        (data) => {
          if (data.status === 200) {
            setFacebookKeys(
              data.data.keys.filter((item) => item.isActive).map((item) => (
                {
                  label: item.pageName,
                  value: item.id,
                  key_type: "facebook",
                  channel_icon: Channels.Facebook,
                }
              ))
            );
          }
        },
        (error: Object) => {
        }, navigate, dispatch, setIsLoading, { values: values }
      );
    }

    return <ModalEditDepartments
        modalShow={modalShow}
        hide={hide}
        validate={validate}
        onChangeTags={onChangeTags}
        selected_keyWords={selected_keyWords}
        options_agents={options_agents}
        setOptions_agents={setOptions_agents}
        selected_agents={selected_agents}
        setSelected_agents={setSelected_agents}
        options_emails={options_emails}
        setOptions_emails={setOptions_emails}
        selected_emails={selected_emails}
        setSelected_emails={setSelected_emails}
        department_name={department_name}
        setDepartment_name={setDepartment_name}
        department_isActive={department_isActive}
        setDepartment_isActive={setDepartment_isActive}
        showfeedbackName={showfeedbackName}
        validated={validated}
        dispatch={dispatch}
        navigate={navigate}
        setIsLoading={setIsLoading}
        t={t}
        appRequesterConst={appRequesterConst}
        callback={props.callback}
        data={props.data}
        token={props.token}
        onSubmit={onSubmit}
        selectedChannelKeys={selectedChannelKeys}
        setSelectedChannelKeys={setSelectedChannelKeys} 
        channelKeys={channelKeys} 
        setChannelKeys={setChannelKeys}    
    />

}

export default ModalEditDepartmentsController;