import { Container, Row, Col, Button, OverlayTrigger, Form, InputGroup, FormControl, Tooltip } from "react-bootstrap"
import allStyles from "./styles"
import BellNotification from "../../../../components/notificationSoundComponent"
import { StatusNotification, TriggerType } from "../../../../core/enums/status-notification"
import Loading from "../../../../components/loading";
import ButtonSearchCleanInput from "../buttonSearchCleanInputComponent";
import { Formik } from "formik";
import { TokenInterface } from "../../../../services/requestsInterfacesModel";
import { useDispatch, useSelector } from "react-redux";
import { getIdUser } from "../../../../store/user";
import { AppRequesterController } from "../../../../services/appRequester/appRequesterController";
import { setShowAlertFeedback } from "../../../../store/internal";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../../../store/token";
import { IModalTemplateListNotifications } from "./indexModel";
import { FC } from "react";

const TemplateListNotification: FC<IModalTemplateListNotifications> = (props) => {
    const values: TokenInterface = {
        token: {
            value: useSelector(getToken)
        },
        user: {
            id: useSelector(getIdUser),
        }
    };

    const AppRequesterConst = new AppRequesterController();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const statusTicketAgent = (field_ticket_id, ticket_reference, sender_name, trigger_type, ticket_id, task_id, has_expired) => {
        const span_ticket = <span style={allStyles.spanTicket} onClick={(e) => props.showEditModal(e, ticket_id)}>#{ticket_reference}</span>;
        // notificações ticket --------------------------------------------------------------------
        if (trigger_type === TriggerType.Add && field_ticket_id === StatusNotification.Atribuido && task_id === null) {
            const msg = `${sender_name} ${props.t('modal_template_list_notification.notification_ticket.assigned_the_ticket')} #${ticket_reference} ${props.t('modal_template_list_notification.notification_ticket.to_you')}`;
            return {
                element: <div className="my-1 mx-2" style={allStyles.responseText}>{props.t('modal_template_list_notification.notification_ticket.assigned_the_ticket')} {span_ticket} {props.t('modal_template_list_notification.notification_ticket.to_you')}</div>,
                id: `${msg}*ID*${field_ticket_id}*TICKET*${ticket_id}`,
                msg
            }
        } else if (trigger_type === TriggerType.Add && field_ticket_id === StatusNotification.Seguidores && task_id === null) {
            const msg = `${sender_name} ${props.t('modal_template_list_notification.notification_ticket.added_you_as_a_ticket_follower')} #${ticket_reference}`;
            return {
                element: <div className="my-1 mx-2" style={allStyles.responseText}>{props.t('modal_template_list_notification.notification_ticket.added_you_as_a_ticket_follower')} {span_ticket}</div>,
                id: `${msg}*ID*${field_ticket_id}*TICKET*${ticket_id}`,
                msg
            }
        } else if (trigger_type === TriggerType.Remove && field_ticket_id === StatusNotification.Atribuido) {
            const msg = `${sender_name} ${props.t('modal_template_list_notification.notification_ticket.unassigned_you_from_the_ticket')} #${ticket_reference}`;
            return {
                element: <div className="my-1 mx-2" style={allStyles.responseText}>{props.t('modal_template_list_notification.notification_ticket.unassigned_you_from_the_ticket')} {span_ticket}</div>,
                id: `${msg}*ID*${field_ticket_id}*TICKET*${ticket_id}`,
                msg
            }
        } else if (trigger_type === TriggerType.Remove && field_ticket_id === StatusNotification.Seguidores) {
            const msg = `${sender_name} ${props.t('modal_template_list_notification.notification_ticket.removed_you_as_a_follower_of_the_ticket')} #${ticket_reference}`;
            return {
                element: <div className="my-1 mx-2" style={allStyles.responseText}>{props.t('modal_template_list_notification.notification_ticket.removed_you_as_a_follower_of_the_ticket')} {span_ticket}</div>,
                id: `${msg}*ID*${field_ticket_id}*TICKET*${ticket_id}`,
                msg
            }
        } else if (trigger_type === TriggerType.Replace && field_ticket_id === StatusNotification.Status && task_id === null) {
            const msg = `${props.t('modal_template_list_notification.notification_ticket.the_ticket')} #${ticket_reference} ${props.t('modal_template_list_notification.notification_ticket.was_resolved_by_abandonment')}`;
            return {
                element: <div className="my-1 mx-2" style={allStyles.responseText}> {props.t('modal_template_list_notification.notification_ticket.the_ticket')} {span_ticket} {props.t('modal_template_list_notification.notification_ticket.was_resolved_by_abandonment')}</div>,
                id: `${msg}*ID*${field_ticket_id}*TICKET*${ticket_id}`,
                msg,
                sender_name: props.t('modal_template_list_notification.notification_ticket.system')
            }
        }
        // notificações tasks----------------------------------------------
        if (trigger_type === TriggerType.Add && field_ticket_id === null) {
            const msg = `${sender_name} ${props.t('modal_template_list_notification.notification_task.assigned_a_task_on_the_ticket')} #${ticket_reference} ${props.t('modal_template_list_notification.notification_task.for_you')}`;
            return {
                element: <div className="my-1 mx-2" style={allStyles.responseText}>{props.t('modal_template_list_notification.notification_task.assigned_a_task_on_the_ticket')} {span_ticket} {props.t('modal_template_list_notification.notification_task.for_you')}</div>,
                id: `${msg}*ID*${field_ticket_id}*TICKET*${ticket_id}`,
                msg
            }
        } else if (trigger_type === TriggerType.Add && field_ticket_id === StatusNotification.Atribuido && task_id !== null) {
            const msg = `${sender_name} ${props.t('modal_template_list_notification.notification_task.assigned_a_task_on_the_ticket')} #${ticket_reference} ${props.t('modal_template_list_notification.notification_task.for_you')}`;
            return {
                element: <div className="my-1 mx-2" style={allStyles.responseText}>{props.t('modal_template_list_notification.notification_task.assigned_a_task_on_the_ticket')} {span_ticket} {props.t('modal_template_list_notification.notification_task.for_you')}</div>,
                id: `${msg}*ID*${field_ticket_id}*TICKET*${ticket_id}`,
                msg
            }
        } else if (trigger_type === TriggerType.Add && field_ticket_id === StatusNotification.Seguidores && task_id !== null) {
            const msg = `${sender_name} ${props.t('modal_template_list_notification.notification_task.added_you_as_a_follower')} #${ticket_reference}`;
            return {
                element: <div className="my-1 mx-2" style={allStyles.responseText}>{props.t('modal_template_list_notification.notification_task.added_you_as_a_follower')} {span_ticket}</div>,
                id: `${msg}*ID*${field_ticket_id}*TICKET*${ticket_id}`,
                msg
            }
        } else if (trigger_type === TriggerType.Replace && field_ticket_id === StatusNotification.Seguidores) {
            const msg = `${sender_name} ${props.t('modal_template_list_notification.notification_task.removed_you_as_a_follower_from_a_task')} #${ticket_reference}`;
            return {
                element: <div className="my-1 mx-2" style={allStyles.responseText}>{props.t('modal_template_list_notification.notification_task.removed_you_as_a_follower_from_a_task')} {span_ticket}</div>,
                id: `${msg}*ID*${field_ticket_id}*TICKET*${ticket_id}`,
                msg
            }
        } else if (trigger_type === TriggerType.Replace && field_ticket_id === StatusNotification.Atribuido) {
            const msg = `${sender_name} ${props.t('modal_template_list_notification.notification_task.unassigned_you_from_the_task')} #${ticket_reference}`;
            return {
                element: <div className="my-1 mx-2" style={allStyles.responseText}>{props.t('modal_template_list_notification.notification_task.unassigned_you_from_the_task')} {span_ticket} </div>,
                id: `${msg}*ID*${field_ticket_id}*TICKET*${ticket_id}`,
                msg
            }

        } else if (trigger_type === TriggerType.Replace && field_ticket_id === StatusNotification.Status && has_expired) {
            const msg = `${props.t('modal_template_list_notification.notification_task.a_task_in_the_ticket')} #${ticket_reference} ${props.t('modal_template_list_notification.notification_task.is_late')}`;
            return {
                element: <div className="my-1 mx-2" style={allStyles.responseText}>{props.t('modal_template_list_notification.notification_task.a_task_in_the_ticket')} {span_ticket} {props.t('modal_template_list_notification.notification_task.is_late')}</div>,
                id: `${msg}*ID*${field_ticket_id}*TICKET*${ticket_id}`,
                msg
            }
        } else if (trigger_type === TriggerType.Notification && field_ticket_id !== null) {
            const msg = `${props.t('modal_template_list_notification.notification_task.the_deadline_for_a_task_in_the_ticket')} #${ticket_reference} ${props.t('modal_template_list_notification.notification_task.its_ending_today')}`;
            return {
                element: <div className="my-1 mx-2" style={allStyles.responseText}>{props.t('modal_template_list_notification.notification_task.the_deadline_for_a_task_in_the_ticket')} {span_ticket} {props.t('modal_template_list_notification.notification_task.its_ending_today')}</div>,
                id: `${msg}*ID*${field_ticket_id}*TICKET*${ticket_id}`,
                msg
            }
        } else if (trigger_type === TriggerType.Replace && (field_ticket_id === StatusNotification.dataLimite || field_ticket_id === StatusNotification.HoraLimite)) {
            const msg = props.t("modal_template_list_notification.notification_task.there_was_change_in_limite_time", { ticket_reference: ticket_reference });
            return {
                element: <div className="my-1 mx-2" style={allStyles.responseText}>{props.t('modal_template_list_notification.notification_task.edited_a_task_in_the_ticket')} {span_ticket} {'.'} {props.t("modal_template_list_notification.notification_task.change_in_limite_time")}</div>,
                id: `${msg}*ID*${field_ticket_id}*TICKET*${ticket_id}`,
                msg
            }
        } else if (trigger_type === TriggerType.Replace && field_ticket_id === StatusNotification.AssuntoTicket) {
            const msg = props.t("modal_template_list_notification.notification_task.there_was_change_in_description", { ticket_reference: ticket_reference });
            return {
                element: <div className="my-1 mx-2" style={allStyles.responseText}>{props.t('modal_template_list_notification.notification_task.edited_a_task_in_the_ticket')} {span_ticket}{'.'} {props.t("modal_template_list_notification.notification_task.change_in_description")}</div>,
                id: `${msg}*ID*${field_ticket_id}*TICKET*${ticket_id}`,
                msg
            }
        } else if (trigger_type === TriggerType.Replace && field_ticket_id === StatusNotification.Status ) {
            const msg = props.t("modal_template_list_notification.notification_task.there_was_change_in_status", { ticket_reference: ticket_reference });
            return {
                element: <div className="my-1 mx-2" style={allStyles.responseText}>{props.t('modal_template_list_notification.notification_task.edited_a_task_in_the_ticket')} {span_ticket} {props.t("modal_template_list_notification.notification_task.change_in_status")}</div>,
                id: `${msg}*ID*${field_ticket_id}*TICKET*${ticket_id}`,
                msg
            }
        } else if (trigger_type === TriggerType.Replace && field_ticket_id === StatusNotification.Status ) {
            const msg = props.t("modal_template_list_notification.notification_task.there_was_change_in_status", { ticket_reference: ticket_reference });
            return {
                element: <div className="my-1 mx-2" style={allStyles.responseText}>{props.t('modal_template_list_notification.notification_task.edited_a_task_in_the_ticket')} {span_ticket} {props.t("modal_template_list_notification.notification_task.change_in_status")}</div>,
                id: `${msg}*ID*${field_ticket_id}*TICKET*${ticket_id}`,
                msg
            }
        } else if (trigger_type === TriggerType.Replace && field_ticket_id === StatusNotification.Observacao ) {
            const msg = props.t("modal_template_list_notification.notification_task.there_was_change_in_observation", { ticket_reference: ticket_reference });
            return {
                element: <div className="my-1 mx-2" style={allStyles.responseText}>{props.t('modal_template_list_notification.notification_task.edited_a_task_in_the_ticket')} {span_ticket} {props.t("modal_template_list_notification.notification_task.change_in_observation")}</div>,
                id: `${msg}*ID*${field_ticket_id}*TICKET*${ticket_id}`,
                msg
            }
        }
    }

    const getFormattedDate = (created_at) => {
        const date = new Date(created_at);
        const formatted = date.toLocaleDateString() + ' às ' + date.toLocaleTimeString().slice(0, 5);
        return formatted;
    }

    return (
        <Container className="d-flex justify-content-end nopadding">
            <Row className="nopadding d-flex align-items-center">
                <Col className="nopadding px-2" sm={12} md={12} style={props.config ? allStyles.bodyPhoneDialer1 : allStyles.bodyPhoneDialer}>
                    <Row className="d-flex justify-content-start align-items-center nopadding mt-3 me-2 ">
                        <Col md={10} style={allStyles.title} >
                            {!props.config &&
                                <span>{props.t('modal_template_list_notification.title_notification')}</span>
                            }
                            {props.config &&
                                <span>{props.t('modal_template_list_notification.title_config_notification')}</span>
                            }
                        </Col>
                        <Col md={1} className='justify-content-end px-2' >
                            <Button onClick={() => props.setConfig(!props.config)} className="buttonTicketsSec3 nopadding">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-settings" width="22" height="22" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#707070" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z" />
                                    <path d="M9 12a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                                </svg>
                            </Button>
                        </Col>
                        <Col md={1} className="justify-content-end px-2">
                            <BellNotification></BellNotification>
                        </Col>

                    </Row>
                    {!props.config &&
                        <>
                            <Row className="mt-2 me-0 d-flex justify-content-end">
                                <Button style={{ backgroundColor: "transparent", border: "none", color: "#90a4d6", margin: "0px", fontSize: "0.8rem", display: "flex", justifyContent: "center", width: "20%" }} onClick={() => props.readAllNotification()}>{props.t("modal_template_list_notification.mark_as_read")}</Button>
                            </Row>

                            <Row className="mt-2 me-0">
                                <ButtonSearchCleanInput inputPlaceholder={props.t('modal_template_list_notification.search_input')} filteringTickets={() => { }} setCurrentFilterSearch={(e) => props.setCurrentFilterSearch(e)} currentFilterSearch={props.currentFilterSearch} desactiveSearchButton={true}></ButtonSearchCleanInput>
                            </Row>

                            {props.isLoading &&
                                <Row style={{ position: 'relative', marginTop: '30px' }}>
                                    <Loading visibility={true} customStyle={{ position: 'absolute', height: 'fit-content' }} shapeStyle={{ margin: '-20px' }} />
                                </Row>
                            }
                            <Row className="nopadding" style={{ width: '100%' }}>
                                <Row className="nopadding mt-1 mb-2" >
                                    <Row className="nopadding" style={{ overflowY: 'auto', overflowX: 'hidden', height: 'min(max(50px, 35vh), 30vh)'}}>
                                        {
                                            !props.isLoading &&
                                            props.notifications?.notifications?.map((item, index) => {
                                                const notifyInfo = statusTicketAgent(item?.field_ticket_id, item?.ticket_reference, item?.sender_name, item?.trigger_type, item?.ticket_id, item?.task_id, item?.has_expired);
                                                if (notifyInfo) {
                                                    item.message = notifyInfo?.msg;
                                                    return (
                                                        item?.deleted_at == null ?
                                                            <Row key={index} style={{ cursor: 'default', display: "flex", alignItems: "center" }}>
                                                                <Col
                                                                    id={item.id}
                                                                    xs={11} style={{ display: "flex", alignItems: "center" }}>
                                                                    {item.read_at == null
                                                                        ?
                                                                        <div style={{ backgroundColor: "#4a69bd", borderRadius: "25px", width: '1%', height: '8vh' }}></div>
                                                                        :
                                                                        <div style={{ backgroundColor: "transparent", borderRadius: "25px", width: '1%', height: '8vh' }}></div>
                                                                    }
                                                                    <div>
                                                                        <Row className="my-1 mx-2" style={allStyles.responseTextBold}> {item.sender_name ?? notifyInfo?.sender_name}</Row>
                                                                        {notifyInfo.element}
                                                                        <Row className="my-1 mx-2" style={allStyles.dateTime}>{getFormattedDate(item.created_at)}</Row>
                                                                    </div>
                                                                </Col>
                                                                <Col style={{ margin: "0px", padding: "0px", display: "flex", justifyContent: "flex-end" }}>
                                                                    <OverlayTrigger rootClose={true} trigger={['click']} placement="left" overlay={props.popover(item.read_at)}>
                                                                        <Button id={item._id}
                                                                            onClick={(e) => props.setCurrentNotificationMessageInfo(e, item)} style={{ backgroundColor: "transparent", border: 'none', margin: "0px", padding: "0px" }}>
                                                                            <svg
                                                                                fill="#888888"
                                                                                viewBox="0 0 16 16"
                                                                                height="1em"
                                                                                width="1em">
                                                                                <path d="M9.5 13a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm0-5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm0-5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
                                                                            </svg>
                                                                        </Button>
                                                                    </OverlayTrigger>
                                                                </Col>
                                                                <hr style={{ border: index === props.notifications.notifications.length - 1 ? '0' : '1px solid #E8E8E8' }} /> {/* verifica se é o ultimo, e tira a borda */}
                                                            </Row>
                                                            :
                                                            <></>
                                                    );
                                                }
                                            })
                                        }
                                    </Row>
                                </Row>
                            </Row>
                        </>
                    }
                    {props.config &&
                        <>
                            <Formik
                                initialValues={{ days: props.days }}
                                validate={values => {
                                    let errors = {};
                                    props.setDaysExpiration(values.days);

                                    const daysInt = parseInt(values.days, 10);
                                    if (!values.days) {
                                        errors['days'] = props.t('modal_template_list_notification.fill_in_the_expiration_time')
                                        props.setShowFeedbackDay(true);
                                    } else if (isNaN(daysInt) || daysInt < 1 || daysInt > 60) {
                                        props.setShowFeedbackDay(true);
                                        errors['days'] = props.t("modal_template_list_notification.expiration_time_must_be_a_number");
                                    } else if (!Number.isInteger(values.days)) {
                                        props.setShowFeedbackDay(true);
                                        errors['days'] = props.t("modal_template_list_notification.the_expiration_time_must_be_an_integer");
                                    } else {
                                        props.setShowFeedbackDay(false);
                                    }

                                    return errors;
                                }}

                                onSubmit={async () => {

                                    let JsonSend = {
                                        user_id: values.user.id,
                                        days: props.daysExpiration
                                    }

                                    const headers = {
                                        "Content-Type": "application/json; charset=utf-8",
                                        "Authorization": "Bearer " + values.token.value
                                    };

                                    await AppRequesterConst.Patch(
                                        '/agent/update/days-for-notification-expiration', JsonSend, { headers: headers },
                                        (response: Object) => { },
                                        (data: Object) => {
                                            dispatch(setShowAlertFeedback({ message: `${props.t('modal_template_list_notification.notification_changue')}`, visibility: true, signalIcon: true }));
                                            props.getTimeExpiration()
                                        },
                                        (error: any) => {
                                            dispatch(setShowAlertFeedback({ message: `${props.t('modal_template_list_notification.an_unexpected_error_occurred')}`, visibility: true, signalIcon: false }));
                                        }, navigate, dispatch, props.setIsLoading,
                                    );
                                    props.typeNotificationRequest()
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit
                                }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <div>
                                            <Row style={{ height: '100%' }} className=" d-flex justify-content-start my-3 px-3">
                                                <Col sm={6} md={12} style={{ backgroundColor: 'white', borderRadius: '10px' }} >
                                                    <Row>
                                                        <Col md={12} style={{ marginTop: '-1vh' }}>
                                                            <p style={{ font: 'normal normal normal calc(0.3rem + 0.55vw) Roboto', color: '#707070' }}>{props.t('modal_template_list_notification.enter_the_time_after_which_notifications')}</p>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={2} >
                                                            <InputGroup className="mb-3" style={{ width: '6.5vw' }}>
                                                                <FormControl className="form-control-Default  text-center"
                                                                    placeholder={""}
                                                                    aria-label={""}
                                                                    aria-describedby={""}
                                                                    type='number'
                                                                    id="days"
                                                                    name="days"
                                                                    maxLength={2}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.days}
                                                                    isInvalid={props.showFeedbackDay}
                                                                >
                                                                </FormControl>

                                                                <p style={{ font: 'normal normal normal calc(0.3rem + 0.55vw) Roboto', color: '#707070', marginTop: '1.4vh', marginLeft: '5px' }}>{props.t('modal_template_list_notification.days')}</p>
                                                                <Form.Control.Feedback type="invalid" id="feedbackmessage">
                                                                    {errors.days}
                                                                </Form.Control.Feedback>
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Row>
                                                    <Col md={12} style={allStyles.title3} >
                                                        <span>{props.t('modal_template_list_notification.notify_sound')}</span>
                                                        {
                                                            <OverlayTrigger
                                                                key='top'
                                                                placement='top'
                                                                overlay={
                                                                    <Tooltip id='tooltip'>
                                                                        {props.t('modal_template_list_notification.tooltip_sound')}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="me-3" style={{ marginTop: '-0.1rem' }}>
                                                                    <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                                                    <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                                    <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                                    <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                                </svg>
                                                            </OverlayTrigger>
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row >
                                                    <Col md={12}>
                                                        <div className="switch-input" >
                                                            <span style={{ fontWeight: '500' }}>{props.t('modal_template_list_notification.new_ticket')}</span>
                                                            <label className="switch">
                                                                <input
                                                                    disabled={props.isLoading}
                                                                    type="checkbox"
                                                                    checked={props.somTicket}
                                                                    onChange={() => {
                                                                        if (props.somMessage && !props.somTicket) {
                                                                            props.setSomTicket(true);
                                                                            props.setSomMessage(false);
                                                                        } else {
                                                                            props.setSomTicket(!props.somTicket);
                                                                        }
                                                                    }}
                                                                />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </div>
                                                    </Col>
                                                    <Col md={12}>
                                                        <div className="switch-input" >
                                                            <span style={{ fontWeight: '500' }}>{props.t('modal_template_list_notification.new_message')}</span>
                                                            <label className="switch">
                                                                <input
                                                                    disabled={props.isLoading}
                                                                    type="checkbox"
                                                                    checked={props.somMessage}
                                                                    onChange={() => {
                                                                        if (props.somTicket && !props.somMessage) {
                                                                            props.setSomMessage(true);
                                                                            props.setSomTicket(false);
                                                                        } else {
                                                                            props.setSomMessage(!props.somMessage);
                                                                        }
                                                                    }}
                                                                />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12} style={allStyles.title3} >
                                                        <span>{props.t('modal_template_list_notification.notify_visual')}</span>
                                                        {
                                                            <OverlayTrigger
                                                                key='top'
                                                                placement='top'
                                                                overlay={
                                                                    <Tooltip id='tooltip'>
                                                                        {props.t('modal_template_list_notification.tooltip_visual')}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="me-3" style={{ marginTop: '-0.1rem' }}>
                                                                    <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                                                    <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                                    <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                                    <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                                </svg>
                                                            </OverlayTrigger>
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row >
                                                    <Col md={12}>
                                                        <div className="switch-input" >
                                                            <span style={{ fontWeight: '500' }}>{props.t('modal_template_list_notification.new_ticket')}</span>
                                                            <label className="switch">
                                                                <input
                                                                    disabled={props.isLoading}
                                                                    type="checkbox"
                                                                    checked={props.visualTicket}
                                                                    onChange={() => {
                                                                        if (props.visualMessage && !props.visualTicket) {
                                                                            props.setVisualTicket(true);
                                                                            props.setVisualMessage(false);
                                                                        } else {
                                                                            /* props.setVisualTicket(!props.visualTicket); */
                                                                            props.setVisualTicket(false);
                                                                            props.setVisualMessage(true);
                                                                        }
                                                                    }}
                                                                />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </div>
                                                    </Col>
                                                    <Col md={12}>
                                                        <div className="switch-input" >
                                                            <span style={{ fontWeight: '500' }}>{props.t('modal_template_list_notification.new_message')}</span>
                                                            <label className="switch">
                                                                <input
                                                                    disabled={props.isLoading}
                                                                    type="checkbox"
                                                                    checked={props.visualMessage}
                                                                    onChange={() => {
                                                                        if (props.visualTicket && !props.visualMessage) {
                                                                            props.setVisualMessage(true);
                                                                            props.setVisualTicket(false);
                                                                        } else {
                                                                            /* props.setVisualMessage(!props.visualMessage); */
                                                                            props.setVisualMessage(false);
                                                                            props.setVisualTicket(true);
                                                                        }
                                                                    }} />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Row>
                                            <Row style={allStyles.textFontGlay} className="d-flex mt-3 ms-1 mb-4 ">
                                                <Col md={6} className="d-flex justify-content-start" style={{ width: '40vh' }}>
                                                    <Button type='submit' className="buttonBlue" style={{ width: '60%' }}>{props.t('modal_template_list_notification.save')}</Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </>
                    }
                </Col>
            </Row>
        </Container>
    )
}

export default TemplateListNotification;
