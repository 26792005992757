import { IconRotateClockwise } from "@tabler/icons-react";
import { Formik } from "formik";
import { t } from "i18next";
import { Col, Row, Form, OverlayTrigger, Tooltip, InputGroup, FormControl, Button } from "react-bootstrap";
import InputMask from 'react-input-mask';
import UserService from "../../../../../services/user-service";

const ChatbotSurveyTab = ({
    ableDisableLevelOne,
    setAbleDisableLevelOne,
    initialBotMessage,
    setInitialBotMessage,
    hourLevelOne,
    showFeedbackTimeLevelOne,
    setShowFeedbackTimeLevelOne,
    finalBotMessage,
    setFinalBotMessage,
    resetBotMessages,
    showFeedbackInitialBotMessage,
    showFeedbackFinalBotMessage,
    setShowFeedbackInitialBotMessage,
    setShowFeedbackFinalBotMessage,
    lifeTimeLevelOne,
    lifeTime,
    convertNumToHour,
    resetInitialBotMessage,
    resetFinalBotMessage,
    consumerDowntime,
    consumerDowntimeHour,
    showFeedbackTimeConsumerDowntime,
    setShowFeedbackTimeConsumerDowntime,
    converterMinuto,
    converterHora,
    onSubmit,
    validate,
    npsWaitTimeSurvey,
    setShowFeedbackTimeWaitSurvey,
    showFeedbackTimeWaitSurvey,
    timeStringChatbotWait,
    timeStringChatbotSend,
    timeStringDowntime,
    inactivityMessageNps,
    setInactivityMessageNps,
    ableDisableInactivityMessageNps,
    setAbleDisableInactivityMessageNps,
    resetInactivityMessage,
    showFeedbackInactivityMessage,
    blockButton
}) => {
    return (
        <>
            <Row>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        hourLevelOne: hourLevelOne,
                        npsWaitTimeSurvey: npsWaitTimeSurvey,
                        consumerDowntime: consumerDowntime,
                        initial_bot_message: initialBotMessage,
                        final_bot_message: finalBotMessage,
                        inactivity_message_nps: inactivityMessageNps
                    }}
                    validate={validate}
                    onSubmit={onSubmit}
                >
                    {({
                        values,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setValues,
                    }) => (
                        <Form onSubmit={handleSubmit}>
                            <div style={{ overflow: 'auto', overflowX: 'hidden', height: '70vh', flexDirection: 'column', display: 'flex' }}>
                                <Row style={{ /* height: '36%' */ }} className=" d-flex justify-content-start my-3">
                                    <Col sm={6} md={6} style={{ backgroundColor: 'white', borderRadius: '10px' }} >
                                        <Row>
                                            <p style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461', marginTop: '4vh' }}>{t('configurations_ticket.survey_tab.downtime_title')}</p>
                                        </Row>
                                        <Row>
                                        <Form.Label htmlFor="html" >{ableDisableInactivityMessageNps ? t('configurations_ticket.survey_tab.disable') : t('configurations_ticket.survey_tab.enable')} {t('configurations_ticket.survey_tab.subtitle_inactivity')}</Form.Label>
                                            <Col md={1} style={{ width: '5%', marginTop: '0.4vh' }}>
                                                <Form.Check
                                                    type='checkbox'
                                                    id={"checkbox"}
                                                    checked={ableDisableInactivityMessageNps}
                                                    onChange={() => setAbleDisableInactivityMessageNps(!ableDisableInactivityMessageNps)}
                                                    className="form-check-input-2"
                                                />
                                            </Col>
                                            <Col >
                                                <p style={{ font: 'normal normal normal calc(0.3rem + 0.55vw) Roboto', color: '#707070', marginTop: '0.4vh', marginLeft: '5px' }}>
                                                    {ableDisableInactivityMessageNps ?
                                                        t('configurations_ticket.survey_tab.enabled_inactivity_survey')
                                                        :
                                                        t('configurations_ticket.survey_tab.disabled_inactivity_survey')
                                                    }
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Form.Label htmlFor="html" >{t('configurations_ticket.survey_tab.downtime_description')}
                                                <OverlayTrigger
                                                    key='top'
                                                    placement='top'
                                                    overlay={
                                                        <Tooltip id='tooltip'>
                                                            {t('configurations_ticket.survey_tab.time_tooltip_downtime')} {convertNumToHour(lifeTimeLevelOne)}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="mx-1">
                                                        <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                                        <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                    </svg>
                                                </OverlayTrigger>
                                            </Form.Label>
                                            <InputMask
                                                mask="99:99"
                                                maskChar="_"
                                                value={values.consumerDowntime}
                                                onChange={handleChange}
                                            >
                                                {(inputProps: any) => (
                                                    <InputGroup className="mb-3" style={{ width: '8vw' }}>
                                                        <FormControl className="form-control-Default  text-center"
                                                            placeholder="HH:MM"
                                                            aria-label="HH:MM"
                                                            aria-describedby="HH:MM"
                                                            type='text'
                                                            id="consumerDowntime"
                                                            name="consumerDowntime"
                                                            isInvalid={showFeedbackTimeConsumerDowntime}
                                                            disabled={!ableDisableInactivityMessageNps}
                                                        >
                                                        </FormControl>
                                                        <Form.Control.Feedback
                                                            type="invalid"
                                                            id="feedbackConsumerDowntime"
                                                        >
                                                            {errors.consumerDowntime}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                )}
                                            </InputMask>
                                            <Col className='mt-3' style={{ font: 'normal normal normal calc(0.3rem + 0.55vw) Roboto', color: '#0C2461' }}>
                                                {timeStringDowntime}
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Label htmlFor="initial_bot_msg">
                                                {t('configurations_ticket.survey_tab.message_inactivity_title')}
                                                <OverlayTrigger
                                                    key='top'
                                                    placement='top'
                                                    overlay={
                                                        <Tooltip id='tooltip'>
                                                            {/* TRADUZIR */}
                                                            {t('configurations_ticket.survey_tab.message_inactivity_tooltip')}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="mx-1">
                                                        <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                                        <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                    </svg>
                                                </OverlayTrigger>
                                                <OverlayTrigger
                                                    key="buttonInitialBotMessage"
                                                    overlay={
                                                        <Tooltip >
                                                            <>{t('configurations_ticket.survey_tab.message_inactivity_reload_tooltip')}</>
                                                        </Tooltip>
                                                    }
                                                    placement="bottom"
                                                >
                                                    <Button className="px-2" style={{ color: '#9e9e9e', background: 'none', border: 'none' }} onClick={(e) => resetInactivityMessage(inactivityMessageNps, setInactivityMessageNps)} >
                                                        <IconRotateClockwise />
                                                    </Button>
                                                </OverlayTrigger>
                                            </Form.Label>
                                            <FormControl
                                                className="form-control-Default-text-area ms-3"
                                                style={{ height: '10vh' }}
                                                as="textarea"
                                                placeholder={t('configurations_ticket.survey_tab.message_placeholder')}
                                                aria-label={t('configurations_ticket.survey_tab.message_placeholder')}
                                                aria-describedby={t('configurations_ticket.survey_tab.message_placeholder')}
                                                size='sm'
                                                type='text'
                                                // componentClass="textarea"
                                                id="inactivity_message_nps"
                                                name="inactivity_message_nps"
                                                // maxLength={1000}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.inactivity_message_nps}
                                                isInvalid={showFeedbackInactivityMessage}
                                                // disabled={resetBotMessages}
                                                disabled={!ableDisableInactivityMessageNps}
                                            />
                                            <Form.Control.Feedback type="invalid" className="ms-1" id="showFeedbackInactivityMessage">
                                                {errors['inactivity_message_nps']}
                                            </Form.Control.Feedback>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row style={{ /* height: '36%' */ }} className=" d-flex justify-content-start my-3">
                                    <Col sm={6} md={6} style={{ backgroundColor: 'white', borderRadius: '10px' }} >
                                        <Row>
                                            <p style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461'/* , marginTop: '4vh' */ }}>{t('configurations_ticket.survey_tab.chatbot_survey_title')}</p>
                                        </Row>
                                        <Row>
                                            <Form.Label htmlFor="html" >{ableDisableLevelOne ? t('configurations_ticket.survey_tab.disable') : t('configurations_ticket.survey_tab.enable')} {t('configurations_ticket.survey_tab.chatbot_survey_description')}</Form.Label>
                                            <Col md={1} style={{ width: '5%', marginTop: '0.4vh' }}>
                                                <Form.Check
                                                    type='checkbox'
                                                    id={"checkbox"}
                                                    checked={ableDisableLevelOne}
                                                    onChange={() => setAbleDisableLevelOne(!ableDisableLevelOne)}
                                                    className="form-check-input-2"
                                                />
                                            </Col>
                                            <Col >
                                                <p style={{ font: 'normal normal normal calc(0.3rem + 0.55vw) Roboto', color: '#707070', marginTop: '0.4vh', marginLeft: '5px' }}>
                                                    {ableDisableLevelOne ?
                                                        t('configurations_ticket.survey_tab.enabled_survey')
                                                        :
                                                        t('configurations_ticket.survey_tab.disabled_survey')
                                                    }
                                                </p>
                                            </Col>
                                        </Row>
                                        <div className="d-flex flex-row justify-content-between" style={{ width: '90vh' }}>

                                            <Row>
                                                <Form.Label htmlFor="html" >{t('configurations_ticket.survey_tab.time_send_survey_chatbot')}
                                                    <OverlayTrigger
                                                        key='top'
                                                        placement='top'
                                                        overlay={
                                                            <Tooltip id='tooltip'>
                                                                {/* {t('configurations_ticket.survey_tab.time_tooltip_chatbot')} {convertNumToHour(lifeTimeLevelOne)} */}
                                                                {t('configurations_ticket.survey_tab.time_tooltip_chatbot_send')}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="mx-1">
                                                            <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                                            <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                            <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                            <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        </svg>
                                                    </OverlayTrigger>
                                                </Form.Label>
                                                <InputMask
                                                    mask="99:99"
                                                    maskChar="_"
                                                    value={values.hourLevelOne}
                                                    onChange={handleChange}
                                                >
                                                    {(inputProps: any) => (
                                                        <InputGroup className="mb-3" style={{ width: '8vw' }}>
                                                            <FormControl className="form-control-Default  text-center"
                                                                placeholder="HH:MM"
                                                                aria-label="HH:MM"
                                                                aria-describedby="HH:MM"
                                                                type='text'
                                                                id="hourLevelOne"
                                                                name="hourLevelOne"
                                                                isInvalid={ableDisableLevelOne ? showFeedbackTimeLevelOne : false}
                                                                disabled={!ableDisableLevelOne}
                                                            >
                                                            </FormControl>
                                                            <Form.Control.Feedback
                                                                type="invalid"
                                                                id="feedbackHourLevelOne"
                                                            >
                                                                {errors.hourLevelOne}
                                                            </Form.Control.Feedback>
                                                        </InputGroup>
                                                    )}
                                                </InputMask>
                                                <Col className='mt-3' style={{ font: 'normal normal normal calc(0.3rem + 0.55vw) Roboto', color: '#0C2461' }}>
                                                    {timeStringChatbotSend}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Form.Label htmlFor="html" >{t('configurations_ticket.survey_tab.time_wait_survey_chatbot')}
                                                    <OverlayTrigger
                                                        key='top'
                                                        placement='top'
                                                        overlay={
                                                            <Tooltip id='tooltip'>
                                                                {/* {t('configurations_ticket.survey_tab.time_tooltip_chatbot')} {convertNumToHour(lifeTimeLevelOne)} */}
                                                                {t('configurations_ticket.survey_tab.time_tooltip_chatbot_wait')}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="mx-1">
                                                            <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                                            <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                            <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                            <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        </svg>
                                                    </OverlayTrigger>
                                                </Form.Label>
                                                <InputMask
                                                    mask="99:99"
                                                    maskChar="_"
                                                    value={values.npsWaitTimeSurvey}
                                                    onChange={handleChange}
                                                >
                                                    {(inputProps: any) => (
                                                        <InputGroup className="mb-3" style={{ width: '8vw' }}>
                                                            <FormControl className="form-control-Default  text-center"
                                                                placeholder="HH:MM"
                                                                aria-label="HH:MM"
                                                                aria-describedby="HH:MM"
                                                                type='text'
                                                                id="npsWaitTimeSurvey"
                                                                name="npsWaitTimeSurvey"
                                                                isInvalid={ableDisableLevelOne ? showFeedbackTimeWaitSurvey : false}
                                                                disabled={!ableDisableLevelOne}
                                                            >
                                                            </FormControl>
                                                            <Form.Control.Feedback
                                                                type="invalid"
                                                                id="feedbackTimeWaitSurvey"
                                                            >
                                                                {errors.npsWaitTimeSurvey}
                                                            </Form.Control.Feedback>
                                                        </InputGroup>
                                                    )}
                                                </InputMask>
                                                <Col className='mt-3' style={{ font: 'normal normal normal calc(0.3rem + 0.55vw) Roboto', color: '#0C2461' }}>
                                                    {timeStringChatbotWait}
                                                </Col>
                                            </Row>
                                        </div>
                                        <Row className="mb-3">
                                            <Form.Label htmlFor="initial_bot_msg">
                                                {t('configurations_ticket.survey_tab.initial_message_chatbot')}
                                                <OverlayTrigger
                                                    key='top'
                                                    placement='top'
                                                    overlay={
                                                        <Tooltip id='tooltip'>
                                                            {t('configurations_ticket.survey_tab.initial_message_tooltip')}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="mx-1">
                                                        <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                                        <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                    </svg>
                                                </OverlayTrigger>
                                                <OverlayTrigger
                                                    key="buttonInitialBotMessage"
                                                    overlay={
                                                        <Tooltip >
                                                            <>{t('configurations_ticket.survey_tab.reset_message')}</>
                                                        </Tooltip>
                                                    }
                                                    placement="bottom"
                                                >
                                                    <Button className="px-2" style={{ color: '#9e9e9e', background: 'none', border: 'none' }} onClick={(e) => resetInitialBotMessage(initialBotMessage, setInitialBotMessage)} >
                                                        <IconRotateClockwise />
                                                    </Button>
                                                </OverlayTrigger>
                                            </Form.Label>
                                            <FormControl
                                                className="form-control-Default-text-area ms-3"
                                                style={{ height: '10vh' }}
                                                as="textarea"
                                                placeholder={t('configurations_ticket.survey_tab.message_placeholder')}
                                                aria-label={t('configurations_ticket.survey_tab.message_placeholder')}
                                                aria-describedby={t('configurations_ticket.survey_tab.message_placeholder')}
                                                size='sm'
                                                type='text'
                                                // componentClass="textarea"
                                                id="initial_bot_message"
                                                name="initial_bot_message"
                                                // maxLength={1000}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.initial_bot_message}
                                                isInvalid={showFeedbackInitialBotMessage && !resetBotMessages}
                                                // disabled={resetBotMessages}
                                                disabled={!ableDisableLevelOne}
                                            />
                                            <Form.Control.Feedback type="invalid" className="ms-1" id="feedebackInitialBotMessage">
                                                {errors['initial_bot_message']}
                                            </Form.Control.Feedback>
                                        </Row>
                                        <Row>
                                            <Form.Label htmlFor="final_bot_message">
                                                {t('configurations_ticket.survey_tab.final_message_chatbot')}
                                                <OverlayTrigger
                                                    key="buttonFinalBotMessage"
                                                    overlay={
                                                        <Tooltip >
                                                            <>{t('configurations_ticket.survey_tab.reset_message')}</>
                                                        </Tooltip>
                                                    }
                                                    placement="bottom"
                                                >
                                                    <Button className="px-2" style={{ color: '#9e9e9e', background: 'none', border: 'none' }} onClick={(e) => resetFinalBotMessage(finalBotMessage, setFinalBotMessage)} >
                                                        <IconRotateClockwise />
                                                    </Button>
                                                </OverlayTrigger>
                                            </Form.Label>
                                            <FormControl
                                                className="form-control-Default-text-area ms-3"
                                                style={{ height: '10vh' }}
                                                as="textarea"
                                                placeholder={t('configurations_ticket.survey_tab.message_placeholder')}
                                                aria-label={t('configurations_ticket.survey_tab.message_placeholder')}
                                                aria-describedby={t('configurations_ticket.survey_tab.message_placeholder')}
                                                size='sm'
                                                type='text'
                                                // componentClass="textarea"
                                                id="final_bot_message"
                                                name="final_bot_message"
                                                // maxLength={1000}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.final_bot_message}
                                                isInvalid={showFeedbackFinalBotMessage && !resetBotMessages}
                                                // disabled={resetBotMessages}
                                                disabled={!ableDisableLevelOne}
                                            />
                                            <Form.Control.Feedback type="invalid" className="ms-1" id="feedbackFinalBotMessage">
                                                {errors['final_bot_message']}
                                            </Form.Control.Feedback>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            <Row /* style={allStyles.textFontGlay} */ className="d-flex mt-4">
                                <Col md={6} className="d-flex justify-content-start" style={{ width: '40vh' }}>
                                    <Button className="buttonWhite" style={{ width: '60%' }} onClick={() => { document.location.reload(); }} >{t('configurations_ticket.survey_tab.cancel')}</Button>
                                </Col>
                                <Col md={6} className="d-flex justify-content-end" style={{ width: '40vh' }}>
                                    <Button type='submit' disabled={blockButton} className="buttonBlue" style={{ width: '60%' }}>{t('configurations_ticket.survey_tab.save')}</Button>
                                </Col>
                            </Row>
                        </Form>

                    )}

                </Formik>
            </Row>
        </>
    )
}

export default ChatbotSurveyTab;