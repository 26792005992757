import React, { useState, useEffect, FC, } from 'react';
import { useDispatch, useSelector } from 'react-redux'

import 'bootstrap/dist/css/bootstrap.min.css';

import { getToken } from '../../../../store/token';
import { getIdUser, getNameUser, getProfileId } from '../../../../store/user';
import { getIdCompany } from '../../../../store/company';

import {
  setShowCreateTriageModal,
} from '../../../../store/internal'
import { ModalRegisterTriageControllerInterface } from './indexModel';
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import { useNavigate } from 'react-router-dom';
import { TokenInterface } from '../../../../services/requestsInterfacesModel';

import "@yaireo/tagify/dist/tagify.css"

import ModalRegisterTriage from './index';
import UserService from '../../../../services/user-service';
import { useTranslation } from 'react-i18next';

import { IconMessage2, IconRobotFace, IconBrandFacebook, IconBrandInstagram, IconBrandWhatsapp } from '@tabler/icons-react';

const AppRequesterConst = new AppRequesterController();

const ModalRegisterTriageController: FC<ModalRegisterTriageControllerInterface> = (props) => {
  const values: TokenInterface = {
    company: {
      id: useSelector(getIdCompany)
    },
    user: {
      id: useSelector(getIdUser),
      name: useSelector(getNameUser),
      profile_id: useSelector(getProfileId)
    },
    token: {
      value: useSelector(getToken)
    },
    internal: {
    }
  };
  const [isLoading, setIsLoading] = useState(false)
  const [validated] = useState(false);

  const [triageName, setTriageName] = useState("");
  const [initialMessage, setInitialMessage] = useState("");
  const [finalMessage, setFinalMessage] = useState("");
  const [ticketAvailable, setTicketAvailable] = useState<any>([])
  const [triageFields, setTriageFields] = useState<any>([])

  const [triageIsActive, setTriageIsActive] = useState(true);

  const [showfeedbackName, setShowfeedbackName] = useState(false);
  const [showfeedbackInitialMessage, setShowfeedbackInitialMessage] = useState(false);
  const [showfeedbackFinalMessage, setShowfeedbackFinalMessage] = useState(false);
  const [selectedChatbots, setSelectedChatbots] = useState([]);
  const [triageIsMandatory, setTriageIsMandatory] = useState(false)

  const [optionsChatbots, setOptionsChatbots] = useState([]);

  const { t } = useTranslation()

  const [modalShow, setModalShow] = useState(props.visibility);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (props.visibility) {
      getTriageFields()
      getChanels()
    }
    setModalShow(props.visibility);
    setShowfeedbackName(false);
    setShowfeedbackInitialMessage(false);
    setShowfeedbackFinalMessage(false);
  }, [props.visibility])

  useEffect(()=>{

  },[triageIsActive])

  const getTriageFields = async () => {

    const headers = UserService.getHeaders();

    await AppRequesterConst.Get(
      '/triage/avaliable_fields', { headers },
      (response: Object) => {
        return response;
      },
      (data) => {
        if (data.status === 200) {
          setTicketAvailable(data.data.fields.length > 0 ? data.data.fields.map((item) => ({ id: item.id, name: item.name, is_custom: item.is_custom })) : []);
        }
      },
      (error: Object) => {
      }, navigate, dispatch, setIsLoading, { values: values }
    );
  }

  const getChanels = async () => {

    const headers = UserService.getHeaders();

    const params = {
      is_listing_triage: true
    }

    await AppRequesterConst.Get(
      'channel/keys', { headers, params: params },
      (response: Object) => {
        return response;
      },
      (data) => {

        if (data.status === 200) {
          setOptionsChatbots(data.data.channel_keys.map((item) => ({
            label: (
              item.whatsapp_key ? `${item.name} - ${item.whatsapp_key.phone_number}` : item.name

            ),
            value: item 
          })));
        }
      },
      (error: Object) => {
      }, navigate, dispatch, setIsLoading, { values: values }
    );
  }
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    if (droppableSource.droppableId === "droppable") {
      setTicketAvailable(sourceClone);
      setTriageFields(destClone);
    } else {
      setTriageFields(sourceClone);
      setTicketAvailable(destClone);
    }
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    // quando é dropado fora da lista
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        source.droppableId === "droppable" ? ticketAvailable : triageFields,
        source.index,
        destination.index
      );

      if (source.droppableId === "droppable") {
        setTicketAvailable(items);
      } else {
        setTriageFields(items);
      }
    } else {
      move(
        source.droppableId === "droppable" ? ticketAvailable : triageFields,
        source.droppableId === "droppable" ? triageFields : ticketAvailable,
        source,
        destination
      );
    }
  }

  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: grid * 1,
    margin: `0 0 ${grid}px 0`,
    borderRadius: '4px',
    height: 'min(max(35px, 4.7vh), 70px)',
    textOverflow: 'clip',

    // muda a cor do backgroun quando tá arrastando
    background: isDragging ? "#D8E2FF" : "#FFFFFF",

    // temos que aplicar o style nos arrastáveis
    ...draggableStyle
  });

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "#4A69BD" : "#E5ECFF",
    padding: grid,
    width: 200,
    borderRadius: '4px',
  });

  const Hide = () => {
    setTicketAvailable([]);
    setTriageFields([]);
    setSelectedChatbots([]);
    dispatch(setShowCreateTriageModal(false));
    setModalShow(false);
    setTriageIsActive(true)
    setTriageIsMandatory(false)
  }

  return (
    <ModalRegisterTriage
      modalShow={modalShow}
      Hide={Hide}
      setTriageName={setTriageName}
      setShowfeedbackName={setShowfeedbackName}
      setShowfeedbackInitialMessage={setShowfeedbackInitialMessage}
      setShowfeedbackFinalMessage={setShowfeedbackFinalMessage}
      triageName={triageName}
      triageIsActive={triageIsActive}
      initialMessage={initialMessage}
      finalMessage={finalMessage}
      selectedChatbots={selectedChatbots}
      values={values}
      dispatch={dispatch}
      props={props}
      navigate={navigate}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      validated={validated}
      showfeedbackName={showfeedbackName}
      showfeedbackInitialMessage={showfeedbackInitialMessage}
      showfeedbackFinalMessage={showfeedbackFinalMessage}
      optionsChatbots={optionsChatbots}
      setOptionsChatbots={setOptionsChatbots}
      setSelectedChatbots={setSelectedChatbots}
      setTriageIsActive={setTriageIsActive}
      setInitialMessage={setInitialMessage}
      setFinalMessage={setFinalMessage}
      ticketAvailable={ticketAvailable}
      triageFields={triageFields}
      onDragEnd={onDragEnd}
      getItemStyle={getItemStyle}
      getListStyle={getListStyle}
      t={t}
      triageIsMandatory={triageIsMandatory}
      setTriageIsMandatory={setTriageIsMandatory}
    />
  );
}

export default ModalRegisterTriageController;
