import { useDispatch, useSelector } from "react-redux";
import ViewMessageTriggers from ".";
import { ListName } from "../../../../core/enums/order-sort-by";
import constsRouters from "../../../../routes/constsRouter";
import { useLocation, useNavigate } from "react-router";
import { AppRequesterController } from "../../../../services/appRequester/appRequesterController";
import { FC, useEffect, useState } from "react";
import { MessageSendingTicketsInterface, TokenInterface } from "../../../../services/requestsInterfacesModel";
import { getIdUser, getNameUser, getUserLastName } from "../../../../store/user";
import { getOrderByList, getShowAcquirePlatformComponent, getShowAlertFeedback, getShowCallNotificationBar, getShowEditTicketsModal, getSortByList, setShowEditTicketsModal } from "../../../../store/internal";
import { getToken } from "../../../../store/token";
import { getIdCompany } from "../../../../store/company";
import UserService from "../../../../services/user-service";
import BackendConstants from "../../../../core/constants/backend-constants";
import { ViewMessageTriggersInterface } from "./indexModel";

const ViewMessageTriggersController: FC<ViewMessageTriggersInterface> = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const AppRequesterConst = new AppRequesterController();
    
    const [isLoading, setIsLoading] = useState(false);
    
    const [currentPage, setCurrentPage] = useState(1);
    const [aux, setAux] = useState([]);
    const [currentFilterSearch, setCurrentFilterSearch] = useState(null);
    const [currentState, setCurrentState] = useState("");
    const [currentParams, setCurrentParams] = useState({});
    const [currentLimit, /*SetCurrentLimit*/] = useState(BackendConstants.limitMin);
    const [currentMessageTrigger, setCurrentMessageTrigger] = useState(null);
    const [openModalPhone, setOpenModalPhone] = useState(undefined);
    const [phoneNumber, setPhoneNumber] = useState("");
    
    const [currentTickets, setCurrentTickets] = useState(undefined);
    const [yoursTickets, setYoursTickets] = useState(false);
    const [resetingFilters, setResetingFilters] = useState(false);
    const [currentTicket, setCurrentTicket] = useState(null);

    const values: TokenInterface = {
        user: {
            id: useSelector(getIdUser),
            name: useSelector(getNameUser),
            lastname: useSelector(getUserLastName),
        },
        internal: {
            getShowCallNotificationBar: useSelector(getShowCallNotificationBar),
            getShowAcquirePlatformComponent: useSelector(getShowAcquirePlatformComponent),
            getShowAlertFeedback: useSelector(getShowAlertFeedback),
            getShowEditTicketsModal: useSelector(getShowEditTicketsModal),
            getSortByList: useSelector(getSortByList),
            getOrderByList: useSelector(getOrderByList),
        },
        token: {
            value: useSelector(getToken),
        },
        company: {
            id: useSelector(getIdCompany),
        }
    
    }

    useEffect(() => {
        const controller = new AbortController();

        setCurrentMessageTrigger((state: { id: any; status_id: any; channel_id: any; responsible_name: any; }) => ({
            ...state,
            id: state ? state.id : location.state['id'],
            status_id: state ? state.status_id : location.state['status_id'],
            channel_id: state && state.channel_id ? state.channel_id : location.state['channel_id'],
            responsible_name: state && state.responsible_name ? state.responsible_name : location.state['responsible_name'],
        
        }));
        if(currentMessageTrigger != null){
            getAllTicketsToPagination(Object.assign(currentParams, { limit: currentLimit, page: currentPage }), getAllTickets, controller);
        }

        return () => {
            controller.abort();
        }
    }, [currentPage, currentState, currentMessageTrigger && currentMessageTrigger.id]);

    const getAllTickets = async (params: { limit: number; page: number; }, setData: (value: React.SetStateAction<any[]>) => void, aux: any[], setAux: (value: React.SetStateAction<any[]>) => void, controller: AbortController) => {
        if (values.internal.getSortByList.listName === ListName.ListOfTickets && values.internal.getSortByList.sort_by !== null && values.internal.getOrderByList !== null) {
            params = Object.assign(params, { sort_by: values.internal.getSortByList.sort_by, order_by: values.internal.getOrderByList });
        }

        const headers = UserService.getHeaders(), signal = controller.signal;

        await AppRequesterConst.Get(
            // "/ticket",
            `/message-sending/${currentMessageTrigger.id}/tickets`,
            //'/tag/' + emp.state['id'] + '/tickets', 
            { headers, signal, params: params },
            (response: Object) => {},
            (data:MessageSendingTicketsInterface) => {

                if (data.status === 200 &&  data.data !== undefined) {
                    setAux((state) => {
                        let aux = state.concat(data.data.tickets);
                        setAux(aux);
                        setData(aux);
                        return state;
                    });
                } else if (aux.length === 0) {
                    setData(undefined);
                }

            },
            (error:Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const getAllTicketsToPagination = (params: { limit: number; page: number; }, getAllTickets: Function, controller: AbortController) => {
        getAllTickets(params, setCurrentTickets, aux, setAux, controller);
    }

    const filteringTickets = (e: { currentTarget: { id: string; }; }, auxObj: Object, action: string) => {
        setCurrentPage(1);
        setAux([]);

        if (document.getElementById("listOfTickets") != null) {
            document.getElementById("listOfTickets").scrollTop = 0;
        }

        if (action === "clean") {
            setCurrentFilterSearch("");
            auxObj = {};
            setCurrentState("searching");
            if (currentState === "searching") {
                setCurrentState("");
            }
        } else if (e.currentTarget.id === "searchButton" || e.currentTarget.id === "SearchInput") {
            auxObj = {};
            setCurrentState("searching");

            if (currentState === "searching") {
                setCurrentState("");
            }

            if (currentFilterSearch != null && currentFilterSearch != "") {
                auxObj["search"] = currentFilterSearch;
            }

        } else {
            setCurrentState("filtering");

            if (currentState === "filtering") {
                setCurrentState("");
            }
        }

        dispatch(setShowEditTicketsModal(false));

        setCurrentParams(auxObj);
    }

    const cleanList = () => {
        setCurrentParams({});
        setCurrentPage(1);
        setAux([]);
        setResetingFilters(true);

        setCurrentState("filtering");

        if (currentState === "filtering") {
            setCurrentState("");
        }
    }

    const listOfMessageTriggersPage = () => {
        if (values.internal.getShowEditTicketsModal === false) {
            navigate(constsRouters.routers.ticketsMessageTrigger.path);
        } else {
            dispatch(setShowEditTicketsModal(false));
            setCurrentPage(1);
            setAux([]);
            setCurrentParams({});
            setResetingFilters(true);

            setCurrentState("returning");
            if (currentState === "returning") {
                setCurrentState("");
            }
        }
    }

    const updateListfunction = () => {
        setCurrentPage(currentPage + 1);
    };

    const updateListAfterDelete = () => {
        setAux([]);
        setCurrentPage(1);

        setCurrentState("deactivating");

        if (currentState === "deactivating") {
            setCurrentState("");
        }
    };

    const showCreateTicket = () => {
        navigate(constsRouters.routers.createTicket.path);
    }

    const setCurrentTicketFunction = (value: any) => {
        setCurrentTicket(value?.id?.split("*ID*")[0].split("*who_is_viewing*")[0]);
    }

    const listOfTickets = () => {
        dispatch(setShowEditTicketsModal(false));
    }

    const callbackOrderByTickets = (column: any, order: any) => {
        setCurrentPage(1);
        setAux([]);
        setCurrentState(column + order);
        document.getElementById(ListName.ListOfTickets).scrollTop = 0;
    }

    return (
        <ViewMessageTriggers
            values={values}
            visibility={isLoading}
            listOfTickets={listOfTickets}
            filteringTickets={filteringTickets}
            setCurrentFilterSearch={setCurrentFilterSearch}
            currentFilterSearch={currentFilterSearch}
            phoneNumber={phoneNumber}
            openModalPhone={openModalPhone}
            setOpenModalPhone={setOpenModalPhone}
            setPhoneNumber={setPhoneNumber}
            currentState={currentState}
            currentTicket={currentTicket}
            currentParams={currentParams}
            listOfMessageTriggersPage={listOfMessageTriggersPage}
            currentMessageTrigger={currentMessageTrigger}
            setCurrentState={setCurrentState}
            cleanList={cleanList}
            resetingFilters={resetingFilters}
            setResetingFilters={setResetingFilters}
            yoursTickets={yoursTickets}
            setYoursTickets={setYoursTickets}
            currentTickets={currentTickets}
            updateListAfterDelete={updateListAfterDelete}
            updateListfunction={updateListfunction}
            showCreateTicket={showCreateTicket}
            setCurrentTicketFunction={setCurrentTicketFunction}
            callbackOrderByTickets={callbackOrderByTickets}
        />
    )
}

export default ViewMessageTriggersController;