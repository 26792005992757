import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Colors } from '../../../../../../core/shared/constants/colors';
import Utils from '../../../../../../core/shared/utils';
import constsApi from '../../../../../../services/constsApi';
import '../../styles.css';
// import ImgsViewer from "react-images-viewer";
import ImageViewerController from '../imageViewer/indexController';
import { IconAlertCircle } from '@tabler/icons-react';

const MessageCardComponent = (props: { elem: any; key: string; isAllowedMessage: boolean; setIsAllowedMessage: Dispatch<SetStateAction<boolean>>}) => {
    const elem = props.elem?.message ? props.elem.message : props.elem;
    const key = props.key;

    const imagesExt = [ 'jpeg', 'jpg', 'png', 'gif', 'ico', 'bmp', 'tif', 'tiff', 'psd', 'svg' ];

    useEffect(() => {
        if (elem.date === undefined) {
            const divContent = document.createElement("div");
            let content = elem.content;
            let aux = 0;
            let qntChar = 0;
            if (elem.channel_id !== '3f3fa530-fd3e-4018-ab98-4d9c3cd5406a') {
                content = getUrls(elem.content.startsWith('<') || elem.content.startsWith('*') ? elem.content : `${elem.content.replace(/\\n/g, '\n').replace(/\n/g, '<br>\n')}`)
            }
            if(props.isAllowedMessage === false && elem.is_agent !== true){
                props.setIsAllowedMessage(true)
            }

            if (content) {
                let textTag;
                let splitStr;

                if (content.includes("<p>")) { // pega as mensagens antigas com o <p>
                    textTag = "<p>";
                    splitStr = "</p>";
                } else if (content.includes("<span>")) { // pega as mensagens novas com o <span>
                    textTag = "<span>";
                    splitStr = "</span>";
                } else if (content.includes("<div>")) {
                    textTag = "<div>";
                    splitStr = "</div>";
                } else {
                    textTag = `<div dir="ltr">`;
                    splitStr = "</div>";
                }

                content.replace(textTag, "").split(splitStr).map((item: string | any[], index: any) => {
                    aux = item.length
                    if (qntChar < aux) {
                        qntChar = aux;
                    }
                })
                // content = content.replace(/\<p/g, "<p class='chatMessageBox'");
            }
            // content = content.replace(/\<p/g, "<p class='chatMessageBox'");

            if (divContent) {
                divContent.innerHTML = elem.content !== undefined ? content : "";
            }

            if (document.getElementById(elem._id)) {
                document.getElementById(elem._id).innerHTML = '';
                document.getElementById(elem._id).appendChild(divContent);
                document.getElementById(elem._id).style.minWidth = qntChar < 12 ? '90px' : '230px';
            }
        }
    }, [elem.content])

    const getUrls = (content) => {

        let regex = /\b\S\S+\.()*\S+\S\b/gi;
        let cleanHtml = content.startsWith('<') ? content.replace(/<[^>]*>?/gm, '') : content.replace(/<br>/g, ''); // remove tags html se começar com < e substitui <br> por ''
        let urls = cleanHtml?.match(regex);
        let https = "https://"

        if (urls) {

            urls.map((item, index) => {
                if (item.substr(0, 5) !== "https") {
                    https = https.concat(item)
                } else {
                    https = item
                }

                let hyper = `<a style='color: #000' target='_blank' href='${https}'>${item}</a>`
                content = content.replace(item, hyper);

            });
        }

        return content;
    }

    const backgroundColor = () => {
        if (elem.content.startsWith('<html>')) {
            return undefined;
        }
        if (elem.status === 'sent') {
            if (elem.is_internal_response && elem.is_agent) {
                return Colors.LightOrange;
            } else {
                if (elem.is_agent) {
                    return Colors.Blue100;
                } else {
                    return Colors.Grey200;
                }
            }
        } else {
            if (elem.is_agent) {
                return Colors.Grey500;
            }
        }
    }

    return (
        elem.createdAt !== undefined ?
            <Row key={key} id={key} name={key} className={`my-4 mx-2 d-flex ${elem.is_agent ? 'justify-content-end' : 'justify-content-start'}`} style={{ borderRadius: '3px' }}>
                <Col className={`d-flex ${elem.is_agent ? 'justify-content-end' : 'justify-content-start'}`} md={8}>
                    <div>
                        <div>
                            <p className="my-2 ps-0" style={{ color: '#1E1E1E', font: 'var(--px15_19_Bold-font)' }}>
                                {elem.status === 'sent' ?
                                    <></>
                                    :
                                    <OverlayTrigger
                                        key={"all_tickets"}
                                        placement='top'
                                        overlay={
                                            <Tooltip>
                                                {elem.whyNotSend ?? elem.whyNotSend }
                                            </Tooltip>
                                        }
                                        trigger={['hover', 'focus']}
                                        delay={400}
                                    >
                                        <IconAlertCircle size={20} color='#1E1E1E' />
                                    </OverlayTrigger>
                                }
                                {elem.is_bot ? 'Bot' : `${elem.user_name} ${elem.user_lastname ?? ""}`}

                                {
                                    elem.is_internal_response && elem.is_agent ?
                                        <span className="ms-2">
                                            <svg id="Grupo_11312" data-name="Grupo 11312" xmlns="http://www.w3.org/2000/svg" width="18.69" height="18.69" viewBox="0 0 18.69 18.69">
                                                <path id="Caminho_9691" data-name="Caminho 9691" d="M0,0H18.69V18.69H0Z" fill="none" />
                                                <path id="Caminho_9692" data-name="Caminho 9692" d="M18.972,13.4V4.486a1.486,1.486,0,1,0-2.972,0V13.4l1.486,1.486Z" transform="translate(-3.426 -0.771)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                                <path id="Caminho_9693" data-name="Caminho 9693" d="M16,7h2.972" transform="translate(-3.426 -1.549)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                                <path id="Caminho_9694" data-name="Caminho 9694" d="M14.145,16.944H4.486a1.486,1.486,0,1,1,0-2.972H7.458a1.486,1.486,0,0,0,0-2.972H5.229" transform="translate(-0.771 -2.237)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                            </svg>
                                        </span>
                                        :
                                        elem.channel_id === constsApi.channel[0].id ?
                                            <span className="ms-1">
                                                <svg id="Grupo_11322" data-name="Grupo 11322" xmlns="http://www.w3.org/2000/svg" width="18.69" height="18.69" viewBox="0 0 18.69 18.69">
                                                    <path id="Caminho_9699" data-name="Caminho 9699" d="M0,0H18.69V18.69H0Z" fill="none" />
                                                    <rect id="Retângulo_18003" data-name="Retângulo 18003" width="13.897" height="10.897" rx="2" transform="translate(2 3.793)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                                    <path id="Caminho_9700" data-name="Caminho 9700" d="M3,7l7,4.7,7-4.7" transform="translate(-1.048 -1.559)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                                </svg>
                                            </span>
                                            :
                                            elem.channel_id === constsApi.channel[1].id ?
                                                <span className="ms-1">
                                                    <svg id="Grupo_11310" data-name="Grupo 11310" xmlns="http://www.w3.org/2000/svg" width="18.692" height="18.692" viewBox="0 1.5 18.692 18.692">
                                                        <path id="Caminho_9669" data-name="Caminho 9669" d="M0,0H18.692V18.692H0Z" fill="none" />
                                                        <path id="Caminho_9670" data-name="Caminho 9670" d="M4.558,4H7.673L9.231,7.894,7.284,9.063a8.567,8.567,0,0,0,3.894,3.894l1.168-1.947,3.894,1.558v3.115a1.558,1.558,0,0,1-1.558,1.558A12.462,12.462,0,0,1,3,5.558,1.558,1.558,0,0,1,4.558,4" transform="translate(-0.663 -0.885)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                                        <line id="Linha_197" data-name="Linha 197" y1="3.894" x2="3.894" transform="translate(11.683 3.115)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                                        <path id="Caminho_9671" data-name="Caminho 9671" d="M16,4h3.115V7.115" transform="translate(-3.538 -0.885)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                                    </svg>
                                                </span>
                                                :
                                                elem.channel_id === constsApi.channel[2].id ?
                                                    <span className="ms-1">
                                                        <svg id="Grupo_11512" data-name="Grupo 11512" xmlns="http://www.w3.org/2000/svg" width="17.117" height="17.117" viewBox="0 1.5 17.117 17.117">
                                                            <path id="Caminho_9745" data-name="Caminho 9745" d="M0,0H17.117V17.117H0Z" fill="none" />
                                                            <path id="Caminho_9746" data-name="Caminho 9746" d="M3,15.843l1.177-2.71A6.419,6.419,0,1,1,6.6,15.2L3,15.843" transform="translate(-0.86 -0.865)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                                            <path id="Caminho_9747" data-name="Caminho 9747" d="M9,9.57a.357.357,0,1,0,.713,0V8.857a.357.357,0,1,0-.713,0V9.57a3.566,3.566,0,0,0,3.566,3.566h.713a.357.357,0,0,0,0-.713h-.713a.357.357,0,0,0,0,.713" transform="translate(-2.581 -2.438)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                                        </svg>
                                                    </span>
                                                    :
                                                    elem.channel_id === constsApi.channel[3].id ?
                                                        <span className="ms-1">
                                                            <svg id="Grupo_11496" data-name="Grupo 11496" xmlns="http://www.w3.org/2000/svg" width="17.281" height="17.281" viewBox="0 2 17.281 17.281">
                                                                <path id="Caminho_9748" data-name="Caminho 9748" d="M0,0H17.281V17.281H0Z" fill="none" />
                                                                <path id="Caminho_9749" data-name="Caminho 9749" d="M7,8.04v2.88H9.16v5.04h2.88V10.92H14.2l.72-2.88H12.04V6.6a.72.72,0,0,1,.72-.72h2.16V3H12.76a3.6,3.6,0,0,0-3.6,3.6V8.04H7" transform="translate(-1.96 -0.84)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                                            </svg>
                                                        </span>
                                                        :
                                                        elem.channel_id === constsApi.channel[4].id ?
                                                            <span className="ms-1">
                                                                <svg id="Grupo_11497" data-name="Grupo 11497" xmlns="http://www.w3.org/2000/svg" width="18.91" height="18.91" viewBox="0 1 18.91 18.91">
                                                                    <path id="Caminho_9750" data-name="Caminho 9750" d="M0,0H18.91V18.91H0Z" fill="none" />
                                                                    <rect id="Retângulo_18238" data-name="Retângulo 18238" width="13" height="13" rx="4" transform="translate(3 2.91)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                                                    <circle id="Elipse_1529" data-name="Elipse 1529" cx="2.5" cy="2.5" r="2.5" transform="translate(7 6.91)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                                                    <line id="Linha_421" data-name="Linha 421" y2="0.001" transform="translate(13 5.909)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                                                </svg>
                                                            </span>
                                                            :
                                                            elem.channel_id === constsApi.channel[5].id ?
                                                                <span className="ms-1">
                                                                    <svg id="Grupo_12897" data-name="Grupo 12897" xmlns="http://www.w3.org/2000/svg" width="17.974" height="17.974" viewBox="0 0 17.974 17.974">
                                                                        <path id="Caminho_10311" data-name="Caminho 10311" d="M0,0H17.974V17.974H0Z" fill="none" />
                                                                        <path id="Caminho_10312" data-name="Caminho 10312" d="M18.238,11.238,15.991,8.991H10.749A.749.749,0,0,1,10,8.242V3.749A.749.749,0,0,1,10.749,3h6.74a.749.749,0,0,1,.749.749v7.489" transform="translate(-2.511 -0.753)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                                                        <path id="Caminho_10313" data-name="Caminho 10313" d="M11.238,13.745v1.5a.749.749,0,0,1-.749.749H5.247L3,18.238V10.749A.749.749,0,0,1,3.749,10h1.5" transform="translate(-0.753 -2.511)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                                                    </svg>
                                                                </span>
                                                                :
                                                                ""
                                }
                            </p>
                        </div>

                        <Container >
                            <div className="" style={!elem.content.startsWith('<html>') ? { padding: '2px 5px', borderRadius: '0.2rem', backgroundColor: backgroundColor() } :  {padding: '2px 5px', borderRadius: '0.2rem', backgroundColor: backgroundColor(), display: 'flex', overflowX: 'auto', whiteSpace: 'nowrap'}}>
                                <Row className="m-2 d-flex">
                                    <Row>
                                        <p id={elem._id} className="" style={{ marginBottom: "2px", marginLeft: '0px', marginRight: '0px', padding: '0', color: '#1E1E1E', font: 'var(--px15_19-font-Roboto)', wordBreak: 'break-word', maxWidth: '400px' }}>

                                        </p>
                                    </Row>
                                    {elem.attachments?.length > 0 ?
                                        <>
                                            <ImageViewerController
                                                attachments={
                                                    elem.attachments.map((attachment) => {
                                                        let type = attachment.type.toString().split("/")[0];

                                                        if (type === "image" || (type !== "image" && imagesExt.includes(type))) {
                                                            return attachment;
                                                        }
                                                    })
                                                }
                                            />

                                            {elem.attachments.map((attachment, index) => {
                                                let imgFormat = attachment.type.toString().split("/")[0];

                                                return <>
                                                    {imgFormat !== "image" && (imgFormat !== "image" && !imagesExt.includes(imgFormat)) ?
                                                        <Col key={index} className="me-2 my-1" style={{ backgroundColor: 'white', height: '120px', borderRadius: '3px', minWidth: '130px', maxWidth: '130px' }} >
                                                            <a href={attachment.url} target="_blank" style={{ textDecoration: 'none' }}>
                                                                <Row className="align-content-center" style={{
                                                                    // height: '70%',
                                                                    height: '84px',
                                                                    border: '1px solid #E8E8E8',
                                                                }}>
                                                                    <svg id="Grupo_11515" data-name="Grupo 11515" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                        <path id="Caminho_9753" data-name="Caminho 9753" d="M0,0H24V24H0Z" fill="none" />
                                                                        <path id="Caminho_9754" data-name="Caminho 9754" d="M14,3V7a1,1,0,0,0,1,1h4" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                                        <path id="Caminho_9755" data-name="Caminho 9755" d="M17,21H7a2,2,0,0,1-2-2V5A2,2,0,0,1,7,3h7l5,5V19A2,2,0,0,1,17,21Z" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                                        <line id="Linha_422" data-name="Linha 422" y2="6" transform="translate(12 11)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                                        <path id="Caminho_9756" data-name="Caminho 9756" d="M9,14l3-3,3,3" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                                    </svg>
                                                                </Row>
                                                                <Row /* className='mx-0' */
                                                                    style={{
                                                                        backgroundColor: '#707070', color: '#F9F9F9',
                                                                        // height: '30%',
                                                                        height: '36px',
                                                                        borderRadius: '3px',
                                                                    }} /* draggable download_url={attachment.url} */
                                                                >
                                                                    <div className=""
                                                                        style={{
                                                                            height: '35%',
                                                                            overflowY: 'hidden',
                                                                            textOverflow: 'ellipsis',
                                                                            display: '-webkit-box',
                                                                            WebkitLineClamp: '1',
                                                                            lineClamp: '1',
                                                                            WebkitBoxOrient: 'vertical',
                                                                            wordBreak: 'break-word',
                                                                            font: 'var( --px10_13-font-Roboto)'
                                                                        }}>
                                                                        {attachment.filename}
                                                                    </div>
                                                                    <div className=""
                                                                        style={{
                                                                            height: '35%',
                                                                            overflowY: 'hidden',
                                                                            textOverflow: 'ellipsis',
                                                                            display: '-webkit-box',
                                                                            WebkitLineClamp: '1',
                                                                            lineClamp: '1',
                                                                            WebkitBoxOrient: 'vertical',
                                                                            wordBreak: 'break-word',
                                                                            font: 'var( --px10_13-font-Roboto)'
                                                                        }}>
                                                                        {attachment.type}
                                                                    </div>
                                                                </Row>
                                                            </a>
                                                        </Col>
                                                        :
                                                        <></>
                                                    }
                                                </>
                                            })}
                                        </> :
                                        <></>
                                    }
                                </Row>
                                <div className="d-flex justify-content-end mx-2 mt-2" style={{
                                    backgroundColor: '', textAlign: 'left', font: 'var(--px15_19-font-Roboto)', letterSpacing: '0px', color: '#707070', opacity: '1', padding: '5px 0px',
                                    //marginTop: '-3vh'
                                    //marginTop: '-3vh' 

                                }}>
                                    {Utils.formatTime(elem.createdAt)}
                                </div>
                            </div>
                        </Container>
                    </div>
                </Col>
            </Row >
            :
            <Row className="d-flex justify-content-center align-items-center mt-2" style={{ height: '2vh' }}>
                <Col className="d-flex justify-content-center" md={2} style={{ backgroundColor: '#E8E8E8', borderRadius: '3px', width: '13%', font: "var(--px15_19_Bold-font-Roboto)" }} >
                    {new Date(elem.date).toLocaleDateString()}
                </Col>
            </Row>
    )
}

export default MessageCardComponent;