import React, { useContext, useEffect, useRef, useState } from "react";

import { AppRequesterController } from "../../../../../services/appRequester/appRequesterController";
import { axiosCxpress } from "./servers/botxpress";
import { getIdCompany } from "../../../../../store/company";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../../../../../store/token";
import { TokenInterface } from "../../../../../services/requestsInterfacesModel";
import MessageSocketClientChatbot from "../../../../../sockets/messages-socket-chatbot";
import { SocketContextChatbot } from "../../../../../core/context/socket-context-chatbot";
import { Message } from "../../../../../domain/entities/messages";
import Utils from "../../../../../core/shared/utils";
import BackendConstants from "../../../../../core/constants/backend-constants";
import { setShowAlertFeedback, setUserWebchatId, getUserWebchatId, setInAttendance, getInAttendance, setStartAttendance, getStartAttendance } from "../../../../../store/internal";
import MessageCardBot from "./messageCardBot/message-card-bot";
import { useNavigate } from "react-router-dom";
import ChatBotIFrame from "./index";
import { verifyCode } from "../../../../../services/codeCxpressInterface";
import { useTranslation } from "react-i18next";
import i18n from '../../../../../i18next';
import { registerLocale } from 'react-datepicker';
import { es } from 'date-fns/locale/es';
import { enUS } from 'date-fns/locale/en-US';
import { fr } from 'date-fns/locale/fr';
import { ptBR } from 'date-fns/locale/pt-BR';

registerLocale('es', es);
registerLocale('en', enUS);
registerLocale('fr', fr);
registerLocale('pt-br', ptBR);
registerLocale('pt-pt', ptBR);

enum Sender {
  Bot = 'bot',
  User = 'user',
  Agente = 'agent',
  Consumer = 'consumer',
}

/**
 * Iframe do chat de bot funcionando de forma similar a uma home do sistema
 * @returns 
 */
const ChatBotIframeController = () => {
  const appRequester = new AppRequesterController();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [botId, setBotId] = useState(null);
  const [botData, setBotData] = useState(null);
  const [userId, setUserId] = useState(null as string || null);
  const [page, setPage] = useState(1);
  const [fromCx, setFromCx] = useState(false);

  const inputRef = React.createRef<HTMLTextAreaElement>();
  const [messages, setMessages] = useState([]);
  const [messagesChat, setMessagesChat] = useState([]);
  const [lastBotResponse, setLastBotResponse] = useState("");
  const messagesEndRef = useRef(null);

  const [messageSocket, setMessageSocket] = useState(() => null);
  const [pendingMessage, setPendingMessage] = useState(null as Object || null);
  const [/*requestMoreMessages*/, setRequestMoreMessages] = useState(false);
  const [oldMessages, setOldMessages] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [hasBot, setHasBot] = useState(true);
  const [startAttendanceLoaded, setStartAttendanceLoaded] = useState(false);

  const [activateScrollToBottom, setActivateScrollToBottom] = useState(false);

  const [previewImageURL, setPreviewImageURL] = useState("");

  const [nameColor, setNameColor] = useState("#AFAFAF");
  const [headerColor, setHeaderColor] = useState("#1E1E1E");
  const [backgroundColor, setBackgroundColor] = useState("#F9F9F9");
  const [consumerMessageColor, setConsumerMessageColor] = useState("#707070");
  const [agentColor, setAgentColor] = useState("#E8E8E8");
  const [sendButtonColor, setSendButtonColor] = useState("#707070");

  const [isLoading, setIsLoading] = useState(false);
  const [chatbotLanguage, setChatbotLanguage] = useState('pt-br');

  const socket = useContext(SocketContextChatbot);
  
  const values: TokenInterface = {
    company: {
      id: useSelector(getIdCompany),
    },
    token: {
      value: useSelector(getToken)
    },
    userWebchatId: useSelector(getUserWebchatId),
    inAttendance: useSelector(getInAttendance),
    startAttendance: useSelector(getStartAttendance),
  };

  const headers = {
    "Content-Type": "application/json; charset=utf-8",
    "Authorization": "Basic " + process.env.REACT_APP_TOKEN_BASIC_AUTH,
  };
  const iframekey = {
    token: process.env.REACT_APP_IFRAME_KEY,
  };

  // comportamento default ou flutuante
  const [behavior, setBehavior] = useState('default');
  const urlParams = new URLSearchParams(window.location.search);
  const country = urlParams.get('country');

  useEffect(() => {
    if (chatbotLanguage === 'pt-br' || chatbotLanguage === 'pt-BR' || country === 'BR') {
      i18n.changeLanguage('pt-br');
      localStorage.setItem("i18nextLng", 'pt-br');
    } else if (chatbotLanguage === 'pt-pt' || chatbotLanguage === 'pt-PT' || country === 'PT') {
      i18n.changeLanguage('pt-pt');
      localStorage.setItem("i18nextLng", 'pt-pt');
    } else if (chatbotLanguage === 'en' || country === 'GB') {
      i18n.changeLanguage('en');
      localStorage.setItem("i18nextLng", 'en');
    } else if (chatbotLanguage === 'es' || country === 'ES') {
      i18n.changeLanguage('es');
      localStorage.setItem("i18nextLng", 'es');
    } else if (chatbotLanguage) {
      i18n.changeLanguage(chatbotLanguage);
      localStorage.setItem("i18nextLng", chatbotLanguage);
    }
  }, [chatbotLanguage]);

  // carregando customizações
  useEffect(() => {
    if (botData && botData?.webchat_keys) {
      let data = botData?.webchat_keys;
      setBehavior(data?.behavior === "default"? "default": "floating");
      
      if (data?.bot_logo_url)
        setPreviewImageURL(data?.bot_logo_url || "");

      setNameColor(data?.bot_name_color || "");
      setConsumerMessageColor(data?.balloon_consumer_color || "");
      setAgentColor(data?.balloon_agent_color || "");
      setSendButtonColor(data?.button_color || "");
      setHeaderColor(data?.header_background_color || "");
      setBackgroundColor(data?.background_color || "");
    }
  }, [botData]);

  // carregando customizações vindas de agente da cx
  useEffect(() => {
    // postmessage para atualizar o comportamento
    window.addEventListener('message', (event) => {
      if (event.origin !== window.origin) return;
  
      if (event.data.behavior)
        setBehavior((behavior) => {
          if (event.data.behavior !== behavior) {
            behavior = event.data.behavior;
          }

          return behavior;
        });

      setPreviewImageURL((preview) => {
        if (event.data.previewImageURL) {
          preview = event.data.previewImageURL;
        }

        return preview;
      });

      if (event.data.nameColor)
          setNameColor(event.data.nameColor);

      if (event.data.headerColor)
        setHeaderColor(event.data.headerColor);

      if (event.data.backgroundColor)
        setBackgroundColor(event.data.backgroundColor);

      if (event.data.consumerMessageColor)
        setConsumerMessageColor(event.data.consumerMessageColor);

      if (event.data.agentColor)
        setAgentColor(event.data.agentColor);

      if (event.data.sendButtonColor)
        setSendButtonColor(event.data.sendButtonColor);
    });
  }, []);

  useEffect(() => {
    setStartAttendanceLoaded(values.startAttendance)
  }, [values.startAttendance]);

  useEffect(() => {
    scrollToBottom();
  }, [messagesChat]);

  useEffect(() => {

    // verificando parametros
    let search = window.location.search;

    let id;
    // tratando existencia do sina de parametros na url
    if (search) {
      id = window.location.href.split("/?")[0].split("/").splice(-1)[0];

      let params = new URLSearchParams(search);

      // verifica se foi carregado da página de testes da cx
      if (params.get('origin') === "cx_test")
        setFromCx(true);
      else
        setFromCx(false);
    } else {
      id = window.location.href.split("/").splice(-1)[0];
      setFromCx(false);
    }

    if (id)
      setBotId(id);
    else
      setBotId(null);

  }, []);

  /// obtendo dados com o id do bot
  useEffect(() => {
    if (botId != null) {
      getBotData(botId);
    }
  }, [botId]);

  /// gerando id o usuario depois de obter o id do bot
  useEffect(() => {

    // Dados do bot existem, já existe um user_id e não está sendo usado como teste na cx
    if (botData !== null && (values.userWebchatId !== null && values.userWebchatId !== '') && !fromCx) {
      setUserId(values.userWebchatId);
    } else if (botData !== null && userId === null) {
       // dados do bot existem mas o userId previo não existe, então é criado um novo userid e resetados o inAttendance e startAttendance
      const user_id = uuidv4();
      setUserId(user_id);
      dispatch(setUserWebchatId(user_id));
      dispatch(setInAttendance(false));
      dispatch(setStartAttendance(false));
    } else {

    }
  }, [botData]);


  // criação do socket para obter mensagens do usuário

  useEffect(() => {
    let controller = new AbortController();

    if (userId !== null) {
      createNewSocketOnChangeTicket();

      setUserId((ticket) => {
        if (ticket) {
          setMessages((messages) => {
            // alterando ticket as mensagens estão vazias
            if (messages?.length === 0) {
              getAllMessages(ticket, 1, controller.signal);
            } else {
              // alterando pagina com scroll
              setPage((page) => {
                getAllMessages(ticket, page, controller.signal);
                return page;
              });
            }
            return messages;
          });
        }
        return ticket;
      });
    }
    return () => {
      controller.abort();
    }
  }, [userId, page]);

  const createNewSocketOnChangeTicket = () => {
    socket.createSocket(iframekey.token);
    socket.addOnConnectCallback(() => {
      createSocketInstance(userId as string);
    });
    createSocketInstance(userId as string);

  }

  const createSocketInstance = (id: string) => {
    const onReceiveMessage = (sentMessage) => {
      if (sentMessage.message) {
        if (sentMessage?.message?.nps === true) {
          dispatch(setInAttendance(true));
        }
      } else if (sentMessage) {
        if (sentMessage?.nps === true) {
          dispatch(setInAttendance(true));
        }
      }

      setPendingMessage(sentMessage);
      setMessagesChat(messagesList => [
        ...messagesList, 
        { 
          content: sentMessage.message.content, 
          _id: Math.random().toString(36).substring(7), 
          createdAt: new Date(sentMessage.message.timestamp), 
          is_bot: sentMessage.message.is_bot, 
          is_agent: sentMessage.message.is_agent, 
          user_name: sentMessage.message.user_name, 
          is_internal_response: sentMessage.message.is_internal_response, 
          channel_id: sentMessage.message.channel_id, 
          date: new Date(sentMessage.message.timestamp), 
          attachments: sentMessage.message.attachments
        }
      ]);
    }
    const onListMessages = (newMessages: Message[]) => {
      let auxArray = [];
      let auxDate = new Date();
      for (let i = 0; i < newMessages.length; i++) {
        const element = newMessages[i];
        let messageDate = new Date(element.createdAt);
        if (auxDate.getDay() !== messageDate.getDay() || auxDate.getMonth() !== messageDate.getMonth() || auxDate.getFullYear() !== messageDate.getFullYear()) {
          auxDate = messageDate
          auxArray.push({ date: Utils.formatedDate(messageDate) });
        }
        auxArray.push(element);
      }

      setMessages(auxArray);

      let hasMoreMessages = newMessages.length == BackendConstants.limit;
      setRequestMoreMessages(hasMoreMessages);

      scrollToBottom();
      setIsLoading(false);
    }

    const onGetOlderMessages = (oldMessages: Message[]) => {
      if (oldMessages.length > 0) {
        setOldMessages(oldMessages);
      }
      setIsLoading(false);
    }

    const onBotcxpressReactivate = (response: any) => {
      dispatch(setInAttendance(false))
      dispatch(setStartAttendance(false))
      setStartAttendanceLoaded(false)
      setMessagesChat(messagesList => [...messagesList, { content: response.content, _id: Math.random().toString(36).substring(7), createdAt: new Date(Date.now()), is_bot: true, is_agent: true, user_name: botData.bot_name, is_internal_response: false, channel_id: botData.channel_id, date: new Date(Date.now()), attachments: [] }]);
    }

    const onError = (error: { response: { status: number; data: { message: any[]; code_cxpress: number } } }) => {
      setHasError(true);

      dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
    }

    const messageSocket = new MessageSocketClientChatbot(
      socket,
      id,
      messages,
      onReceiveMessage,
      onListMessages,
      onGetOlderMessages,
      onBotcxpressReactivate,
      onError,
      () => onConnect(),
      botData.company_id,
      botData.webchat_keys_id
    );

    setMessageSocket(messageSocket);
  }

  const onConnect = () => {
    console.log('onConnect iframe')
    setMessages([]);
    setIsLoading(true);
    getCompanyLanguage();
  }

  const scrollToBottom = () => {
    const chatContainer = document.getElementById("#chat")
    if(chatContainer){
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
    //messagesEndRef.current?.scrollIntoView();
  }

  function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
      .replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0,
          v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
  }

  /**
   * envia mensagem para o bot e atualiza o in_attendance e start_attendace
   * @param message 
   * @returns Promise 
   */
  const sendMessageToBotx = async (message: string) => {
    return await axiosCxpress.post('/bot-cxpress/basic/message/get_answer/' + botId, { message: message, user_id: userId }, { headers: headers })
      .then(data => {
        if (data?.data && String(data.data.start_attendance).toLowerCase() === 'true') {
          dispatch(setStartAttendance(true));
          dispatch(setInAttendance(true));
          setStartAttendanceLoaded(true);
        }

        if (data && data.data && data.data.bot_message)
          setLastBotResponse(data.data.bot_message);
        else
          setLastBotResponse("");

      }).catch((error) => {
        console.log("Bot", error.response);
      }).finally(() => {

      });
  }

  const sendMessageToCx = async (message: string, sender: Sender, start?: boolean) => {
    setMessagesChat(messagesList => [...messagesList, { content: `<p>${message.replace(/\n/g, "<br>")}</p>`, _id: Math.random().toString(36).substring(7), createdAt: new Date(Date.now()), is_bot: sender === Sender.Bot ? true : false, is_agent: sender === Sender.Bot ? true : false, user_name: sender === Sender.Bot ? botData.bot_name : t("configurations.chatbots.view.iframe.you"), is_internal_response: false, channel_id: botData.channel_id, date: new Date(Date.now()), attachments: [] }]);
    let webchatToken = botData.webchat_keys ? botData.webchat_keys.auth_token_cxpress : null;

    if (webchatToken === null)
      return null;

    const headersToCX = {
      "Content-Type": "application/json; charset=utf-8",
      "Authorization": `Bearer ${webchatToken}`
    };

    let start_attendace = false;
    if (start !== undefined && start === true) {
      start_attendace = fromCx ? false : true
    } else if (values.startAttendance) {
      start_attendace = fromCx ? false : true
    }
    const jsonSend = {
      user_id: userId,
      type: 'text',
      body: {
        text: message,
      },
      from: sender === Sender.Bot ? 'chat' : 'user',
      start_attendance: start_attendace,
      end_attendance: false
    };

    return await axiosCxpress.post('/channel-webchat/webchat-webhook/',
      jsonSend, { headers: headersToCX })
      .then(data => {
        // console.log("CX", data);
      }).catch((error) => {
        console.log("CX", error.response);
      });
  }

  const getBotData = async (bot_id: string) => {
    await axiosCxpress.get('/bot-cxpress/basic/' + botId, { headers: headers })
      .then(data => {
        if (data.data && data.data.botCxpress) {
          setBotData(data.data.botCxpress);
        } else
          setBotData(null);
      },
        error => {
          setHasBot(false);
        }
      );
  }

  const renderMessages = () => {
    return messagesChat.map((msg, index) => {
      return (
        <MessageCardBot elem={msg} key={index} consumerColor={consumerMessageColor} agentColor={agentColor} behavior={behavior}></MessageCardBot>
      );
    });
  }

  const sendMessage = (message: string) => {

    if (message && message.length > 0) {
      if (!values.inAttendance) {
        let responseBot = sendMessageToBotx(message);
        let responseCx = sendMessageToCx(message, Sender.User);
        let sendMessageAllowed = true;
        // manter ordem de envio, primeiro usuario depois bot
        responseCx.finally(() => {
          responseBot.finally(() => {
            setLastBotResponse((last) => {
              if (last !== "" && sendMessageAllowed) {
                setStartAttendanceLoaded((lastStatus) => {
                  if (sendMessageAllowed) {
                    sendMessageAllowed = false;
                    sendMessageToCx(last, Sender.Bot, lastStatus);
                  }
                  return lastStatus
                })
              }
              return last;
            });
          });
        });
      } else {
        sendMessageToCx(message, Sender.User);
      }
    }
  }

  const getCompanyLanguage = async () => {
    await appRequester.Get(
      `company/iframe/company-language/${botId}`, {headers},
      (response: Object) => {
      },
      (data: any) => {
        if (data.status === 200) {
          localStorage.setItem("i18nextLng", data.data.companyLanguageByBotId);
          setChatbotLanguage(data.data.companyLanguageByBotId);
        }
      },
      (error: any) => { 
        console.log(error.response);
      }, navigate, dispatch, setIsLoading, { values: values }
    );
  }

  const getAllMessages = async (ticketId: string, page: number, signal: AbortSignal) => {
    const config = { headers, signal };
    if (botData?.webchat_keys_id && !fromCx) {
      await appRequester.Get(
        `chat/${ticketId}/${botData?.webchat_keys_id}/messages/iframe?page=${page}&limit=15`,
        config,
        (response: Object) => {
        },
        (data: any) => {
          if (data.status === 200 && data?.data?.chat_messages?.length > 0) {
            setMessagesChat((currentMessages) => {
              var tranformObj = data.data.chat_messages.map(obj => {
                if (obj.is_agent === false) {
                  obj.user_name = t("configurations.chatbots.view.iframe.you")
                }
                return obj
              })
              if (currentMessages.length > 0 && currentMessages.length >= 15) {
                return tranformObj.concat(currentMessages);
              } else {
                setActivateScrollToBottom(true);
                scrollToBottom();
                return tranformObj;
              }
            });
          }
        },
        (error: any) => { console.log(error.response) }, navigate, dispatch, setIsLoading, { values: values }
      );
    }
  }

  const handleClickSendMessage = () => {
    let message = inputRef.current.value;

    if (message.length > 0 && message !== "\n") {
      sendMessage(message);
    }

    inputRef.current.value = "";
  }

  const enterClick = (e) => {
    if (e.key === "Enter")
      handleClickSendMessage();
  }

  return (
    <ChatBotIFrame
      t={t}
      botData={botData}
      hasBot={hasBot}
      renderMessages={renderMessages}
      messagesEndRef={messagesEndRef}
      inputRef={inputRef}
      enterClick={enterClick}
      handleClickSendMessage={handleClickSendMessage}
      fromCx={fromCx}
      behavior={behavior}
      previewImageURL={previewImageURL}
      nameColor={nameColor} 
      headerColor={headerColor} 
      backgroundColor={backgroundColor} 
      consumerMessageColor={consumerMessageColor} 
      agentColor={consumerMessageColor} 
      sendButtonColor={sendButtonColor}
    />
  );
}

export default ChatBotIframeController;