import { Container, Row, Col, Button, OverlayTrigger, Tooltip, Tabs, Tab } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    getShowRegisterTagsModal,
    getShowCallNotificationBar,
    getShowAcquirePlatformComponent,
} from '../../../../store/internal'
import Loading from '../../../../components/loading/index'
import CallNotificationBar from '../../components/callNotificationBar';
import ButtonSearchCleanInput from '../../components/buttonSearchCleanInputComponent';
import AcquirePlatformNotificationBar from '../../components/acquirePlatformNotificationBar';
import ButtonStatusAgent from '../../components/buttonStatusAgent';
import { useSelector } from 'react-redux';
import ListOfTagsController from './listOfTags/indexController';
import ModalRegisterTagsController from '../../components/modalRegisterTags/indexController';
import ButtonListNotificationController from '../../../../components/buttonListNotification/indexController';


declare module 'react' {
    interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
        name?: string;
    }
}

const HomeTags = ({
    t,
    getAllTags,
    isLoading,
    token,
    callbackModalTags,
    SetCurrentFilterSearch,
    search,
    currentFilterSearch,
    aux,
    showCreateTag,
    updateListfunction,
    currentHasMoreInformation,
    updateListAfterDelete,
    callbackOrderBy,
    auxConsumer,
    getAllConsumerTags,
    activeTab,
    setActiveTab,
    consumer,
    setConsumer
}) => {

    return (
        <>
            <Container fluid className="nopadding" style={{ overflow: "hidden" }}>
                <Loading visibility={isLoading}></Loading>
                <ModalRegisterTagsController visibility={useSelector(getShowRegisterTagsModal)} token={token} callback={() => callbackModalTags(getAllTags)} consumer={consumer} setConsumer={setConsumer}></ModalRegisterTagsController>
                <div className="wrapper">
                    {/* <SidebarController currentPage={SidebarButtonPage.Configurations} subPage={SidebarButtonPage.SubTags}></SidebarController> */}
                    <div className="content pt-0 ps-0" >
                        <CallNotificationBar visibility={useSelector(getShowCallNotificationBar)}></CallNotificationBar>
                        <AcquirePlatformNotificationBar visibility={useSelector(getShowAcquirePlatformComponent).visibility} limitDays={useSelector(getShowAcquirePlatformComponent).limitDays} />
                        <Row className="nopadding mt-3 ps-3">
                            <Col md={7} style={{ marginRight: '-8vw', marginBottom: '2vh' }}>
                                <Row>
                                    <Col className="d-flex justify-content-start align-self-start">
                                        <div style={{ font: 'var(--px20_24_Bold-font)' }}>{t('home_tags.title')}</div>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <div style={{ font: 'var(--px15_19-font)', color: '#707070' }}>{t('home_tags.subtitle')}</div>
                                </Row>
                            </Col>
                            <Col className="d-flex justify-content-end">
                                <ButtonSearchCleanInput inputPlaceholder={t('filter_option.searchTag')} filteringTickets={(e, auxObj, action) => search(e, auxObj, action)} setCurrentFilterSearch={SetCurrentFilterSearch} currentFilterSearch={currentFilterSearch} ></ButtonSearchCleanInput>
                                <ButtonListNotificationController />
                                <ButtonStatusAgent />
                            </Col>
                        </Row>
                        <Tabs defaultActiveKey={"ticket"} id="TagsTabs" onSelect={(eventKey) => setActiveTab(eventKey)} style={{ padding: 0, margin: '3vh 0px 0px 4vh' }}>
                            <Tab eventKey={"ticket"} title="Ticket" >
                                <Row className="mt-3 ps-3" id="buttonCreateConsumer" style={{ marginTop: '5vh', padding: 0, margin: '1vw 0vw 0px 0vw' }}>
                                    <Col md={3} style={{ marginLeft: '1vw' }}>
                                        <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat' }}>
                                            {t('home_tags.label_tag')}
                                            <OverlayTrigger
                                                key='top'
                                                placement='top'
                                                overlay={
                                                    <Tooltip id='tooltip' className='tooltip-arrow-Top'>
                                                        {t('home_tags.tooltip_add_tag')}
                                                    </Tooltip>
                                                }
                                            >
                                                <Button className="mx-3 nopadding buttonTicketsSec" onClick={() => showCreateTag()}>
                                                    <svg id="Grupo_11079" data-name="Grupo 11079" xmlns="http://www.w3.org/2000/svg" width="50%" viewBox="0 0 24 24">
                                                        <path id="Caminho_9572" data-name="Caminho 9572" d="M0,0H24V24H0Z" fill="none" />
                                                        <line id="Linha_147" data-name="Linha 147" y2="14" transform="translate(12 5)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <line id="Linha_148" data-name="Linha 148" x2="14" transform="translate(5 12)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                    </svg>
                                                </Button>

                                            </OverlayTrigger>
                                        </p>

                                    </Col>
                                </Row>
                                {activeTab === "ticket" && (
                                    <ListOfTagsController datas={aux} showCreateTag={() => showCreateTag()} updateListfunction={() => updateListfunction()} HasMoreInformation={currentHasMoreInformation} token={token} updateListAfterDelete={() => updateListAfterDelete()} callbackModalTags={() => callbackModalTags(getAllTags)} callbackOrderBy={(column, order) => callbackOrderBy(column, order)} />
                                )} 
                                </Tab>
                            <Tab eventKey={"consumer"} title={t('home_tags.tab_consumer')} >
                                <Row className="mt-3 ps-3" id="buttonCreateConsumer" style={{ marginTop: '5vh', padding: 0, margin: '1vw 0vw 0px 0vw' }}>
                                    <Col md={3} style={{ marginLeft: '1vw' }}>
                                        <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat' }}>
                                            {t('home_tags.label_tag')}
                                            <OverlayTrigger
                                                key='top'
                                                placement='top'
                                                overlay={
                                                    <Tooltip id='tooltip' className='tooltip-arrow-Top'>
                                                        {t('home_tags.tooltip_add_tag')}
                                                    </Tooltip>
                                                }
                                            >
                                                <Button className="mx-3 nopadding buttonTicketsSec" onClick={() => { showCreateTag(); setConsumer(true) }}>
                                                    <svg id="Grupo_11079" data-name="Grupo 11079" xmlns="http://www.w3.org/2000/svg" width="50%" viewBox="0 0 24 24">
                                                        <path id="Caminho_9572" data-name="Caminho 9572" d="M0,0H24V24H0Z" fill="none" />
                                                        <line id="Linha_147" data-name="Linha 147" y2="14" transform="translate(12 5)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <line id="Linha_148" data-name="Linha 148" x2="14" transform="translate(5 12)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                    </svg>
                                                </Button>

                                            </OverlayTrigger>
                                        </p>

                                    </Col>
                                </Row>

                                {activeTab === "consumer" && (
                                    <ListOfTagsController datas={auxConsumer} showCreateTag={() => { showCreateTag(); setConsumer(true) }} updateListfunction={() => updateListfunction()} HasMoreInformation={currentHasMoreInformation} token={token} updateListAfterDelete={() => updateListAfterDelete()} callbackModalTags={() => callbackModalTags(getAllConsumerTags)} callbackOrderBy={(column, order) => callbackOrderBy(column, order)} consumer={consumer} setConsumer={setConsumer} tagConsumer={true} />
                                )}
                            </Tab>
                        </Tabs >
                    </div>

                </div>

            </Container >
        </>
    );


}

export default HomeTags;
