import { Socket } from "socket.io-client";
import SocketEvents from "../core/constants/socket-events";
import { socket, SocketService } from "../core/context/socket-context";
import { Ticket } from "../domain/entities/ticket";

export default class NotificationSocket {
    private socket!: Socket;
    // notifyUpdate!: (ticket: Ticket) => void;
    private ticketAccessCallback: (response: any) => void;
    private callbacks: Map<string, (state: any) => void>;
    private deleteCallbacks: Map<string, (state: any) => void>;
    queue!: Ticket[];

    constructor() {
        this.queue = [];
        this.callbacks = new Map<string, (state: any) => void>();
        this.deleteCallbacks = new Map<string, (state: any) => void>();
    }

    public setSocketContext(socket: SocketService) {
        this.socket = socket.getInstance();
    }

    public init() {
        if (socket) {

            this.onNewConsumerMessage();
            this.onTicketUpdate();
            this.onTicketDeleted();
            this.onTicketAccess();
            this.onNewTicketFronConsumer();
            this.onBotCxpressUpdated();
            this.onStatusAgentNotification();
            this.onAgentNotification();
            this.onTaskNotification();
            this.onDesconnectOldLoggedUsers();
            this.onNewAutomaticMessage();
        }
    }

    public setTicketAccessCallback(callback: (state: any) => void) {
        this.onTicketAccessUpdate = callback;
    }

    private onUpdateTicket(state: any) {
        this.callbacks.forEach((callback) => {
            callback(state)
        })
        //console.log(this.callbacks.size);
    }

    private onUpdateBot(state: any) {
        this.callbacks.forEach((callback) => {
            callback(state)
        })
    }

    private onUpdateBlockedTicket(state: any) {
        this.callbacks.forEach((callback) => {
            callback(state)
        })
    }


    private onDeleteTicket(state: any) {
        this.deleteCallbacks.forEach((callback) => {
            callback(state)
        })
        //console.log(this.deleteCallbacks.size);
    }

    private onUpdateTask(state: any) {
        this.callbacks.forEach((callback) => {
            callback(state)
        })
    }

    private onTicketAccessUpdate(state: any) {
        if (this.ticketAccessCallback)
            this.ticketAccessCallback(state);
    }

    public addCallback(name: string, callback: (state: any) => void) {
        if (!this.callbacks.has(name)) {
            ////console.log('add callback ', name)
            this.callbacks.set(name, callback);
        }
    }

    public removeCallback(name: string) {
        if (this.callbacks.has(name)) {
            ////console.log('remove callback ', name)
            this.callbacks.delete(name)
        }
    }

    public addDeleteCallback(name: string, callback: (state: any) => void) {
        if (!this.deleteCallbacks.has(name)) {
            ////console.log('add delete callback ', name)
            this.deleteCallbacks.set(name, callback);
        }
        //console.log(this.deleteCallbacks.size);
    }

    public removeDeleteCallback(name: string) {
        if (this.deleteCallbacks.has(name)) {
            ////console.log('remove delete callback ', name)
            this.deleteCallbacks.delete(name)
        }
    }

    private onNewConsumerMessage() {
        this.socket.on(SocketEvents.newConsumerMessage, (ticket: Ticket) => {
            console.log("O consumidor enviou uma mensagem: ", ticket.id);
            this.onUpdateTicket(ticket);
        });
    }

    private onNewTicketFronConsumer() {
        this.socket.on(SocketEvents.newTicketFromConsumer, (ticket: Ticket) => {
            console.log("O consumidor enviou uma mensagem: ", ticket.id);
            this.onUpdateTicket(ticket);
        });
    }

    private onTicketUpdate() {
        this.socket.on(SocketEvents.ticketUpdate, (ticket: Ticket) => {
            console.log("UPDATE ", ticket.id, " foi atualizado.");
            // this.queue.push(ticket);
            this.onUpdateTicket(ticket)
        });
    }

    private onTicketDeleted() {
        this.socket.on(SocketEvents.ticketDeleted, (ticket_id: string) => {
            console.log("O ticket ", ticket_id, " foi deletado.");
            this.onDeleteTicket(ticket_id)
        });
    }

    private onTicketAccess() {
        this.socket.on(SocketEvents.ticketBlock, (response: any) => {
           // console.log("O ticket ", response.ticket_id!, " is_block: ", response.is_block);
            this.onUpdateBlockedTicket(response)
        });
    }

    public onBotCxpressUpdated() {
        this.socket.on(SocketEvents.botCxpressUpdatedFiles, (response: any) => {
            this.onUpdateBot(response)
        })
    }

    public onStatusAgentNotification() {
        this.socket.on(SocketEvents.statusEmployee, (response: any) => {
            // console.log(response, "UPDATE STATUS AGENT")
            this.onUpdateTicket(response)
        })
    }

    public onAgentNotification() {
        this.socket.on(SocketEvents.ticketNotification, (response: any) => {
            // console.log(response, "NOTIFICATION SOCKET")
            this.onUpdateTicket(response)
        })
    }

    public onTaskNotification() {
        this.socket.on(SocketEvents.taskNotification, (response: any) => {
            this.onUpdateTask(response)
        })
    }

    public onDesconnectOldLoggedUsers() {
        this.socket.on(SocketEvents.disconnectOldLoggedUsers, (response: any) => {
            console.log( "DISCONNECT SOCKET")
            // this.onUpdateTicket(response)
        })
    }

    private onNewAutomaticMessage() {
        this.socket.on(SocketEvents.newAutomaticMessage, (ticket: Ticket) => {
            console.log("O consumidor enviou uma mensagem: ", ticket.id);
            this.onUpdateTicket(ticket);
        });
    }
}
