import { FC } from "react";
import { listTemplateMessageTabInterface } from "./indexModel";
import { Button, Col, ListGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { IconMessageBolt } from "@tabler/icons-react";
import InfiniteScroll from "react-infinite-scroll-component";
import Utils from "../../../../../../core/shared/utils";
import { ListName } from '../../../../../../core/enums/order-sort-by';

const ListTemplateMessage: FC<listTemplateMessageTabInterface> = (props) => (
  <>
    <div style={{ marginTop: '2vh' }} >
        <ListGroup variant="flush">
          <ListGroup.Item className="TicketsRowCollums">
            <Row name="ListOfDepartmentsHead">
              <Col md={3} className="nopadding">
                <p>{props.t('home_departments.template_message_tab.title')}</p>
              </Col>
              <Col md={2} className="nopadding">
                <p>{props.t('home_departments.triage_tab.status')}</p>
              </Col>
              <Col md={3} className="nopadding">
                <p>{props.t('home_departments.template_message_tab.whatsapp')}</p>
              </Col>
              <Col className='d-flex justify-content-end '>
                <p>{props.t('home_departments.triage_tab.actions')}</p>
              </Col>
            </Row>
          </ListGroup.Item>
        </ListGroup>
    {
      props.listTemplateMesage?.length > 0 ?
      <div id="ListOfDepartments" style={{ overflowY: 'auto', height: '58vh' }}>
      <InfiniteScroll
        dataLength={props.listTemplateMesage.length}
        next={props.updateListFunction}
        hasMore={false}
        loader={<h4> </h4>}
        scrollableTarget={"ListOfDepartments"}
        onScroll={() => { Utils.hidePopoverOnScroll(ListName.ListOfDepartments) }}
      >
        {
          <ListGroup variant="flush" className="TicketsRow" >
            {props.listTemplateMesage.map((item, index) => {
              return (
                <ListGroup.Item style={{ color: '#707070' }} id={item.title + "*ID*" + item.id} key={"keyAll" + index.toString()} >
                  <Row className='TicketsRowHeight'>
                    <Col md={3} className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item.title + "*ID*" + item.id + "*ID*" + item.bot_enable.toString()} onClick={() => { }}>
                      <div className='oneLineClampEllipsis me-3'>
                        <OverlayTrigger
                          key='top'
                          placement='top'
                          overlay={
                            <Tooltip id='tooltip'>
                              {item.title != null ?
                                <>{item.title}</>
                                :
                                <>{props.t('home_departments.triage_tab.na')}</>
                              }
                            </Tooltip>
                          }
                        >
                          <span>
                            {item.title ? item.title : <>{props.t('home_departments.triage_tab.na')}</>}
                          </span>
                        </OverlayTrigger>
                      </div>
                    </Col>
                    <Col md={2} className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item.tilte + "*ID*" + item.id + "*ID*"} onClick={() => { }}>
                      <OverlayTrigger
                        key='top'
                        placement='top'
                        overlay={
                          <Tooltip id='tooltip'>
                            {item.status?.toLowerCase() === 'rejected' ?
                              <>{item.rejected_reason || props.t('home_departments.template_message_tab.rejected') }</> : item.status?.toLowerCase() === 'pending' ? <>{props.t('home_departments.template_message_tab.pending')}</> : <>{props.t('home_departments.template_message_tab.approved')}</>
                            }
                          </Tooltip>
                        }
                      >
                        <div style={{ width: '43%' }}>

                          {
                            item.status?.toLowerCase() === 'rejected' ?
                            <div className="d-flex justify-content-center py-1"
                              style={{
                                width: '100%',
                                background: '#FFD4B0 0% 0% no-repeat padding-box',
                                borderRadius: '3px',
                                font: 'normal normal bold calc(0.2em + 0.7vw) Roboto',
                                letterSpacing: '0px',
                                color: '#FF8119',
                              }}>
                              {props.t('home_departments.template_message_tab.rejected')}
                            </div>
                            : item.status?.toLowerCase() === 'pending' ?
                            <div className="d-flex justify-content-center py-1"
                              style={{
                                width: '100%',
                                background: '#EEF3FF 0% 0% no-repeat padding-box',
                                borderRadius: '3px',
                                font: 'normal normal bold calc(0.2em + 0.7vw) Roboto',
                                letterSpacing: '0px',
                                color: '#0C2461',
                              }}>
                              {props.t('home_departments.template_message_tab.pending')}
                            </div>
                            : 
                            <div className="d-flex justify-content-center py-1"
                              style={{
                                width: '100%',
                                background: '#EEFFF7 0% 0% no-repeat padding-box',
                                borderRadius: '3px',
                                font: 'normal normal bold calc(0.2em + 0.7vw) Roboto',
                                letterSpacing: '0px',
                                color:'#2A9562',
                              }}>
                              {props.t('home_departments.template_message_tab.approved')}
                            </div>
                          }
                        </div>
                      </OverlayTrigger>
                    </Col>
                    <Col md={3} className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item.title + "*ID*" + item.id + "*ID*" + item.bot_enable.toString()} onClick={() => { }}>
                      <div className='oneLineClampEllipsis'>
                        <OverlayTrigger
                          key='top'
                          placement='top'
                          overlay={
                            <Tooltip id='tooltip'>
                              {
                                item.title_and_number ? item.title_and_number.split(" - ")[1] : props.t('home_departments.triage_tab.na')
                              }
                            </Tooltip>
                          }
                        >
                          <span>
                            {
                              item.title_and_number ? item.title_and_number.split(" - ")[1] : props.t('home_departments.triage_tab.na')
                            }
                          </span>
                        </OverlayTrigger>
                      </div>
                    </Col>
                    <Col className='d-flex justify-content-end' >
                      <OverlayTrigger rootClose={true} trigger={['click']} placement="left" overlay={props.popoverActions(item.id)}>
                        <Button id={item.id} name={item.title} onClick={(e) => { }} className='nopadding d-flex justify-content-end align-self-center' style={{ marginTop: '', backgroundColor: 'transparent', border: 'none', width: "24px" }}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="90%" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#6A6A6A" fill="#6A6A6A" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <circle cx="12" cy="12" r="1" />
                            <circle cx="12" cy="19" r="1" />
                            <circle cx="12" cy="5" r="1" />
                          </svg>
                        </Button>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                </ListGroup.Item>);
            })}
          </ListGroup>
        }
        </InfiniteScroll>
      </div>
      :
      <div className='align-self-center' style={{ overflow: 'hidden', height: '57vh', backgroundColor: '#F9F9F9' }}>
        <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '17vh', backgroundColor: '#F9F9F9' }}>
          <Col md={1} className="d-flex justify-content-center">
            <IconMessageBolt color="#4a69bd" size={50} stroke={1.5} />
          </Col>
        </Row>
        <Row className='d-flex justify-content-center align-content-center mt-2' style={{ marginLeft: '8%', backgroundColor: '#F9F9F9' }}>
          <Col md={4}>
            <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>{props.t('home_departments.triage_tab.no_results')} <Button className="buttonAsText3 nopadding mb-1" onClick={()=> props.showCreateTemplateMessage()}>{props.t('home_departments.template_message_tab.add_template')}</Button></p>
          </Col>
        </Row>
      </div>
    }
    </div>
  </>
)

export default ListTemplateMessage;