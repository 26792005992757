import React from 'react';
import ReactDOM from 'react-dom';
//import store from './store/index.tsx'
import { Provider } from 'react-redux'
import App from './app';
import { PersistGate } from 'redux-persist/integration/react'
import factory from './store/index'
import { AppProvider } from './providers';
import './i18next';
import { QueryClient, QueryClientProvider } from 'react-query';

const { store, persistor } = factory();

const queryClient = new QueryClient()

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppProvider>
          <App />
        </AppProvider>
      </PersistGate>
    </Provider>
  </QueryClientProvider>,
  document.getElementById('root')
)
