import { Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import ButtonListNotificationController from "../../../../../components/buttonListNotification/indexController";
import ButtonStatusAgent from "../../../components/buttonStatusAgent";
import { t } from "i18next";
import './styles.scss'
import { IconBulb, IconBrandWhatsapp, IconBrandInstagram, IconBrandFacebook, IconMessages, IconMail, IconPhone } from "@tabler/icons-react";
import Loading from "../../../../../components/loading";

const HomeViewSummary = ({
    listOfSummary,
    summary,
    dateConvert,
    isLoading,
    removePrefix,
    getChannelsIcons,
}
) => {
   
    return (
        <>
            <Container fluid className="nopadding viewSummaryPage" >
            <Loading visibility={isLoading}></Loading>
                <div className="wrapper" >
                    <div className="pt-0 ps-0" style={{ width: '100%' }}>
                        <Row className="nopadding mt-3 ps-3" >
                            <Col md={12} style={{ marginRight: '-8vw', marginBottom: '2vh' }}>
                                <Row>
                                    <Col className="d-flex justify-content-start align-self-start">
                                        <div style={{ font: 'var(--px20_24_Bold-font)' }}>{`${t("summary_dashboard.title_summary")}`}</div>
                                    </Col>
                                    <Col className="d-flex justify-content-end" style={{marginRight: "12px"}}> {/* alinhar com o conteudo */}
                                        <ButtonListNotificationController />
                                        <ButtonStatusAgent />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <div style={{ font: 'var(--px15_19-font)', color: '#707070' }}>{`${t("summary_dashboard.view_summary.view_summary_subtitle")}`}</div>
                                </Row>
                            </Col>
                            <Row >
                                <Col className="d-flex" style={{ marginBottom: '0.5vw' }} >
                                    <p style={{ font: 'var(--px16_19-font)', color: '#1E1E1E', textDecoration: 'underline', cursor: 'pointer' }} onClick={listOfSummary}>
                                        <span>
                                            <svg id="Grupo_11161" data-name="Grupo 11161" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 2 24 24">
                                                <path id="Caminho_9641" data-name="Caminho 9641" d="M0,0H24V24H0Z" fill="none" />
                                                <path id="Caminho_9642" data-name="Caminho 9642" d="M9,13,5,9,9,5M5,9H16a4,4,0,0,1,0,8H15" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            </svg>
                                        </span>
                                        {t('home_consumers.view_consumers.back_to_list')}
                                    </p>
                                </Col>
                            </Row>
                        </Row>
                    </div>

                    <div className="divContent">
                        <Row>
                            <Col md>
                                <p className="fontDarkTitle" >
                                    {summary?.title} {/* titulo do sumário */}
                                </p>

                            </Col>
                        </Row>

                        <Col>
                        
                        {/* cards com as informações */}
                        <Row className="info">
                            <Col className="cardInsights d-flex align-items-start flex-column bd-highlight">
                                    <p className="nopadding fontDarkBlueTitleCard mb-3" >
                                        {t("summary_dashboard.view_summary.date_created")}
                                    </p> 
                                    <p className="d-flex align-items-end nopadding mt-auto bd-highlight fontInfoBlue" >
                                        {dateConvert(summary?.created_at)} {/* data de criação */}
                                    </p>
                            </Col>
                            <Col className="cardInsights d-flex align-items-start flex-column bd-highlight">
                                    <p className="nopadding fontDarkBlueTitleCard mb-3" >
                                    {t("summary_dashboard.view_summary.date_range")}
                                    </p> 
                                    <p className="d-flex align-items-end nopadding mt-auto bd-highlight fontInfoBlue" >
                                        {dateConvert(summary?.start_date)} {t("summary_dashboard.view_summary.date_to")} {dateConvert(summary?.end_date)}
                                    </p>
                            </Col>
                            <Col className="cardInsights d-flex align-items-start flex-column bd-highlight">
                                    <p className="nopadding fontDarkBlueTitleCard mb-3">
                                        {t("summary_dashboard.view_summary.channels_title")}
                                    </p> 
                                    <div className="d-flex mt-auto bd-highlight nopadding">
                                        {
                                            getChannelsIcons().map(item => (
                                                <OverlayTrigger key='top'
                                                    placement='top'
                                                    overlay={
                                                        <Tooltip id='tooltip'>
                                                            {item.name}
                                                        </Tooltip>
                                                    }>
                                                    <div style={{marginRight: '4px'}}>{item.icon}</div>
                                                </OverlayTrigger>
                                            ))
                                        }
                                    </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="cardInsights d-flex align-items-start flex-column bd-highlight">
                                    <p className="nopadding fontDarkBlueTitleCard" >
                                        {t("summary_dashboard.view_summary.tags_title")}
                                    </p>
                                    
                                    <div className="d-flex align-items-end mt-auto bd-highlight listTags">
                                    {
                                        summary?.tags ?
                                            summary?.tags.map(item => (
                                                <OverlayTrigger
                                                    key='top'
                                                    placement='top'
                                                    overlay={
                                                        <Tooltip id='tooltip'>
                                                            {item.name}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <p>
                                                        {item.name}
                                                    </p>                                        
                                                </OverlayTrigger>
                                        ))
                                       :
                                                <OverlayTrigger
                                                    key='top'
                                                    placement='top'
                                                    overlay={
                                                        <Tooltip id='tooltip'>
                                                            N/A
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span style={{marginLeft: '10px', color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat' }}>
                                                        --
                                                    </span>
                                                </OverlayTrigger>
                                    }
                                    </div>
                            </Col>
                            <Col className="cardInsights d-flex align-items-start flex-column bd-highlight">
                                    <p className="nopadding fontDarkBlueTitleCard mb-3" >
                                        {t("summary_dashboard.view_summary.tickets_processed")}
                                    </p> 
                                    <p className="d-flex align-items-end nopadding mt-auto bd-highlight fontInfoBlue" >
                                        {summary?.ticket_volume}
                                    </p>
                            </Col>
                            <Col className="cardInsights d-flex align-items-start flex-column bd-highlight">
                                    <p className="nopadding fontDarkBlueTitleCard mb-3">
                                        {t("summary_dashboard.view_summary.messages_processed")}
                                    </p> 
                                    <p className="d-flex align-items-end nopadding mt-auto bd-highlight fontInfoBlue">
                                    {summary?.message_volume}
                                    </p>
                            </Col>
                        </Row>

                        </Col>

                        <Row style={{margin: '2vh 6px 0'}}>
                            <hr className="nopadding" ></hr>
                        </Row>

                        {/* conteudo do sumario */}
                        <Row>
                            <Col className="cardInsights content">
                                <Row>
                                    <Col className="fontDarkBlueTitleCard">
                                        {t("summary_dashboard.view_summary.content_summary_title")}
                                    </Col>
                                    <Col className="d-flex justify-content-end align-self-end nopadding" style={{width: '4vw', height: '4vh'}}>
                                        <svg id="Grupo_11214" data-name="Grupo 11214" xmlns="http://www.w3.org/2000/svg " width="4vw" viewBox="0 0 10 30">
                                            <path id="Caminho_9570" data-name="Caminho 9570" d="M0,0H24V24H0Z" fill="none" />
                                            <rect id="Retângulo_159" data-name="Retângulo 159" x="5" y="3" width="14" height="18" rx="2" ry="2" fill="none" stroke="#0C2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            <line id="Linha_960" data-name="Linha 960" x1="10" transform="translate(7 8)" fill="none" stroke="#0C2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            <line id="Linha_961" data-name="Linha 961" x1="10" transform="translate(7 12)" fill="none" stroke="#0C2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            <line id="Linha_962" data-name="Linha 962" x1="6" transform="translate(7 16)" fill="none" stroke="#0C2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        </svg>
                                    </Col>
                                </Row>
                                <div style={{maxHeight: '12vh', overflow: 'auto'}}>
                                    <p className="d-flex align-items-end nopadding mt-auto bd-highlight" >
                                        {summary?.content ? summary?.content : "N/A"}
                                    </p>
                                    
                                </div>
                            </Col>
                        </Row>
                        
                        {/* listagem dos insigths */}
                        <Row>
                            {
                                summary?.insights?.map((item, index) => {
                                    return (
                                        <Col className="cardInsights insights">
                                            <Row >
                                                <Col className="fontDarkBlueTitleCard">
                                                    {t("summary_dashboard.view_summary.insights_summary_title")} {index + 1}
                                                </Col>
                                                <Col className="d-flex justify-content-end align-self-start">
                                                    <IconBulb  style={{color: '#0C2461'}}/>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="d-flex justify-content-start align-self-start mt-3">
                                                    <p className="nopadding" >
                                                        {removePrefix(item)}
                                                    </p>
                                                </Col>
                                            </Row>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default HomeViewSummary;