import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import Loading from '../../../../components/loading';
import AcquirePlatformNotificationBar from '../../components/acquirePlatformNotificationBar';
import ButtonSearchCleanInput from '../../components/buttonSearchCleanInputComponent';
import CallNotificationBar from '../../components/callNotificationBar';
import { MessageTriggerStatus } from '../../../../core/enums/message-trigger-status';

import ButtonStatusAgent from '../../components/buttonStatusAgent';
import ButtonListNotificationController from '../../../../components/buttonListNotification/indexController';
import FilterOptionComponentController from '../../components/filterOptionComponent/indexController';
import { t } from 'i18next';

import ViewTicketController from '../../homeTickets/viewTicket/indexController';
import ListOfTicketsController from '../../homeTickets/listOfTickets/indexController';

const ViewMessageTriggers = ({
    values,
    visibility,
    listOfTickets,
    filteringTickets,
    setCurrentFilterSearch,
    currentFilterSearch,
    phoneNumber,
    openModalPhone,
    setOpenModalPhone,
    setPhoneNumber,
    currentState,
    currentTicket,
    currentParams,
    listOfMessageTriggersPage,
    currentMessageTrigger,
    setCurrentState,
    cleanList,
    resetingFilters,
    setResetingFilters,
    yoursTickets,
    setYoursTickets,
    currentTickets,
    updateListAfterDelete,
    updateListfunction,
    showCreateTicket,
    setCurrentTicketFunction,
    callbackOrderByTickets
}) => {
    return (
        <>
            <Container fluid className='nopadding'>
                <Loading visibility={visibility} />
                <div className='wrapper'>
                    {/* <SidebarController currentPage={SidebarButtonPage.MessageTriggers} className='nopadding'/> */}
                    <div className='content py-0 ps-0' style={{ overflow: 'hidden'}}>
                        <CallNotificationBar visibility={values.internal.getShowCallNotificationBar}></CallNotificationBar>
                        <AcquirePlatformNotificationBar visibility={values.internal.getShowAcquirePlatformComponent.visibility} limitDays={values.internal.getShowAcquirePlatformComponent.limitDays} />
                        {
                            values.internal.getShowEditTicketsModal === true ?
                                <>
                                    <Row className="nopadding ps-4 mt-3 mb-1">
                                        <Col md={7} style={{ marginRight: '2vh' }}>
                                            <Row>
                                                <Col className="d-flex justify-content-start align-self-start ps-0">
                                                    <div style={{ font: 'var(--px20_24_Bold-font)' }}>{t('home_message_trigger.view_message_triggers.wellcome_message_trigger')}</div>
                                                </Col>
                                            </Row>
                                            <Row className="ps-0" style={{ marginTop: '1.5vh' }}>
                                                <Col className="d-flex nopadding mb-2"  >
                                                    <div className="nopadding" style={{ font: 'var(--px16_19-font)', color: '#1E1E1E', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => listOfTickets()}>
                                                        <span>
                                                            <svg id="Grupo_11161" data-name="Grupo 11161" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 2 24 24">
                                                                <path id="Caminho_9641" data-name="Caminho 9641" d="M0,0H24V24H0Z" fill="none" />
                                                                <path id="Caminho_9642" data-name="Caminho 9642" d="M9,13,5,9,9,5M5,9H16a4,4,0,0,1,0,8H15" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                            </svg>
                                                        </span>
                                                        {t('home_message_trigger.view_message_triggers.return_to_listing')}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="d-flex justify-content-end">
                                            <ButtonSearchCleanInput inputPlaceholder={t("home_message_trigger.list_of_trigger_types.search_trigger")} filteringTickets={(e, auxObj, action) => filteringTickets(e, auxObj, action)} setCurrentFilterSearch={setCurrentFilterSearch} currentFilterSearch={currentFilterSearch} ></ButtonSearchCleanInput>
                                            <ButtonListNotificationController/>
                                            <ButtonStatusAgent/>
                                        </Col>
                                        {/* <Row style={{ margin: '0vh 0vh 0vh 0vh' }}>
                                            <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat' }}>
                                                {currentMessageTrigger != null && currentMessageTrigger.tipo}
                                                <OverlayTrigger
                                                    key='top'
                                                    placement='top'
                                                    overlay={
                                                        <Tooltip id='tooltip' className='tooltip-arrow-Top'>
                                                            Dados do disparo ativo
                                                        </Tooltip>
                                                    }
                                                >
                                                    <Button className="mx-3 nopadding buttonTicketsSec" onClick={showEditMess}>
                                                        <svg id="Grupo_11248" data-name="Grupo 11248" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                            <path id="Caminho_9655" data-name="Caminho 9655" d="M0,0H24V24H0Z" fill="none" />
                                                            <path id="Caminho_9656" data-name="Caminho 9656" d="M9,7H6A2,2,0,0,0,4,9v9a2,2,0,0,0,2,2h9a2,2,0,0,0,2-2V15" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                            <path id="Caminho_9657" data-name="Caminho 9657" d="M9,15h3l8.5-8.5a2.121,2.121,0,0,0-3-3L9,12v3" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                            <line id="Linha_183" data-name="Linha 183" x2="3" y2="3" transform="translate(16 5)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        </svg>
                                                    </Button>

                                                </OverlayTrigger>
                                            </p>
                                        </Row> */}
                                    </Row>
                                    <ViewTicketController setOpenModalPhone={(value) => { setOpenModalPhone(value) }} setPhoneNumber={(value) => { setPhoneNumber(value) }} currentTicket={currentTicket} filters={currentParams} setCurrentParams={() => {}}></ViewTicketController>
                                </>
                                :
                                <>
                                    <Row className="nopadding mt-3 ps-4">
                                        <Col md={7} style={{ marginRight: '2vh', /* marginBottom: '2vh' */ }}>
                                            <Row>
                                                <Col className="d-flex justify-content-start align-self-start ps-0">
                                                    <div style={{ font: 'var(--px20_24_Bold-font)' }}>{t('home_message_trigger.view_message_triggers.wellcome_message_trigger')}</div>
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <div className='ps-0' style={{ font: 'var(--px15_19-font)', color: '#707070', marginBottom: '0vh' }}>{t('home_message_trigger.wellcome_message_trigger_subtitle')}</div>
                                            </Row>
                                        </Col>
                                        <Col className="d-flex justify-content-end">
                                            <ButtonSearchCleanInput inputPlaceholder={"pesquisar ticket"} filteringTickets={(e, auxObj, action) => filteringTickets(e, auxObj, action)} setCurrentFilterSearch={setCurrentFilterSearch} currentFilterSearch={currentFilterSearch} ></ButtonSearchCleanInput>
                                            <ButtonListNotificationController/>
                                            <ButtonStatusAgent/>
                                        </Col>
                                    </Row>
                                    <Row className="ps-4" style={{ width: '70%',marginTop: '1.5vh' }}>
                                        <Col className="d-flex" >
                                            <p style={{ font: 'var(--px16_19-font)', color: '#1E1E1E', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => listOfMessageTriggersPage()}>
                                                <span>
                                                    <svg id="Grupo_11161" data-name="Grupo 11161" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 2 24 24">
                                                        <path id="Caminho_9641" data-name="Caminho 9641" d="M0,0H24V24H0Z" fill="none" />
                                                        <path id="Caminho_9642" data-name="Caminho 9642" d="M9,13,5,9,9,5M5,9H16a4,4,0,0,1,0,8H15" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                    </svg>
                                                </span>
                                                {t('home_message_trigger.view_message_triggers.return_to_listing')}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="ps-3" id="buttonEditTag" style={{ margin: '1vh 0vw 0px 0vw' }}>
                                        <Col md style={{ marginLeft: '1vw' }}>
                                            <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat' }}>
                                                {currentTicket != null && currentTicket.name}
                                                {/* <OverlayTrigger
                                                    key='top'
                                                    placement='top'
                                                    overlay={
                                                        <Tooltip id='tooltip' className='tooltip-arrow-Top'>
                                                            Dados da tag
                                                        </Tooltip>
                                                    }
                                                >
                                                    <Button className="mx-3 nopadding buttonTicketsSec" onClick={showEditTagModal}>
                                                        <svg id="Grupo_11248" data-name="Grupo 11248" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                            <path id="Caminho_9655" data-name="Caminho 9655" d="M0,0H24V24H0Z" fill="none" />
                                                            <path id="Caminho_9656" data-name="Caminho 9656" d="M9,7H6A2,2,0,0,0,4,9v9a2,2,0,0,0,2,2h9a2,2,0,0,0,2-2V15" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                            <path id="Caminho_9657" data-name="Caminho 9657" d="M9,15h3l8.5-8.5a2.121,2.121,0,0,0-3-3L9,12v3" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                            <line id="Linha_183" data-name="Linha 183" x2="3" y2="3" transform="translate(16 5)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        </svg>
                                                    </Button>

                                                </OverlayTrigger> */}
                                            </p>
                                        </Col>
                                    </Row>
                                    {currentMessageTrigger && currentMessageTrigger.status_id === MessageTriggerStatus.Triggered ?
                                        <>
                                            <div className='FilterTicketsRow ps-3' style={{ marginTop: '2vh' }}>
                                                <ListGroup variant="flush" >
                                                    <ListGroup.Item style={{ backgroundColor: 'rgba(207, 227, 255,0.25)', marginLeft: '1vh' }}>
                                                        <FilterOptionComponentController
                                                            optionNames={["Canal", "Atribuido", "Automação", "Status", "Data"]}
                                                            currentState={currentState}
                                                            setCurrentState={setCurrentState}
                                                            values_user={values.user}
                                                            values_internal_getShowEditTicketsModal={values.internal.getShowEditTicketsModal}
                                                            values_internal_getShowCreateTicketsModal={false}
                                                            filteringTickets={(e, auxObj, action) => filteringTickets(e, auxObj, action)}
                                                            cleanList={() => cleanList()}
                                                            values_token_value={values.token.value}
                                                            values_company_id={values.company.id}
                                                            resetingFilters={resetingFilters}
                                                            setResetingFilters={setResetingFilters}
                                                            yoursTickets={yoursTickets}
                                                            setYoursTickets={setYoursTickets}
                                                            fixedColumns={{ fixedChannel: currentMessageTrigger.channel_id, fixedResponsibleName: currentMessageTrigger.responsible_name }}
                                                            screen={'view_message_trigger'}
                                                        />
                                                    </ListGroup.Item>
                                                </ListGroup>
                                            </div>
                                            <ListOfTicketsController 
                                                isAssociatedTriggerList={true} 
                                                datas={currentTickets} 
                                                updateListAfterDelete={() => updateListAfterDelete()} 
                                                updateListfunction={() => updateListfunction()} 
                                                showCreateTicket={() => showCreateTicket()} 
                                                setCurrentTicketFunction={(value) => setCurrentTicketFunction(value)} 
                                                assignedEmployeeActiveColumn={true} 
                                                requesterUserActiveColumn={true} 
                                                actionsActiveColumn={true} 
                                                checkboxes={true} 
                                                callbackOrderByTickets={(column, order) => callbackOrderByTickets(column, order)}/>
                                        </> :
                                        <>
                                            {currentMessageTrigger && currentMessageTrigger.status_id === MessageTriggerStatus.Scheduled ?
                                                <div className='align-self-center' style={{ overflow: 'hidden', height: '48vh', backgroundColor: '#F9F9F9' }}>
                                                    <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '17vh', backgroundColor: '#F9F9F9' }}>
                                                        <Col className='d-flex justify-content-center' md={1}>
                                                            <svg id="Grupo_11197" data-name="Grupo 11197" xmlns="http://www.w3.org/2000/svg" width="4vw" viewBox="0 0 24 24">
                                                                <path id="Caminho_9561" data-name="Caminho 9561" d="M0,0H24V24H0Z" fill="none" />
                                                                <line id="Linha_144" data-name="Linha 144" y2="2" transform="translate(15 5)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                                <line id="Linha_145" data-name="Linha 145" y2="2" transform="translate(15 11)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                                <line id="Linha_146" data-name="Linha 146" y2="2" transform="translate(15 17)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                                <path id="Caminho_9562" data-name="Caminho 9562" d="M5,5H19a2,2,0,0,1,2,2v3a2,2,0,0,0,0,4v3a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V14a2,2,0,0,0,0-4V7A2,2,0,0,1,5,5" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                            </svg>
                                                        </Col>
                                                    </Row>
                                                    <Row className='d-flex justify-content-center align-content-center mt-2' style={{ backgroundColor: '#F9F9F9' }}>
                                                        <Col className='d-flex justify-content-center'>
                                                            <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>{t("home_message_trigger.view_message_triggers.no_results")}</p>
                                                        </Col>
                                                    </Row>
                                                </div> :
                                                <div className='align-self-center' style={{ overflow: 'hidden', height: '48vh', backgroundColor: '#F9F9F9' }}>
                                                    <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '17vh', backgroundColor: '#F9F9F9' }}>
                                                        <Col className='d-flex justify-content-center' md={1}>
                                                            <svg id="Grupo_11197" data-name="Grupo 11197" xmlns="http://www.w3.org/2000/svg" width="4vw" viewBox="0 0 24 24">
                                                                <path id="Caminho_9561" data-name="Caminho 9561" d="M0,0H24V24H0Z" fill="none" />
                                                                <line id="Linha_144" data-name="Linha 144" y2="2" transform="translate(15 5)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                                <line id="Linha_145" data-name="Linha 145" y2="2" transform="translate(15 11)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                                <line id="Linha_146" data-name="Linha 146" y2="2" transform="translate(15 17)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                                <path id="Caminho_9562" data-name="Caminho 9562" d="M5,5H19a2,2,0,0,1,2,2v3a2,2,0,0,0,0,4v3a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V14a2,2,0,0,0,0-4V7A2,2,0,0,1,5,5" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                            </svg>
                                                        </Col>
                                                    </Row>
                                                    <Row className='d-flex justify-content-center align-content-center mt-2' style={{ backgroundColor: '#F9F9F9' }}>
                                                        <Col className='d-flex justify-content-center'>
                                                            <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>{t("home_message_trigger.view_message_triggers.no_result_message_trigger")}</p>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }
                                        </>
                                    }
                                </>
                        }
                    </div>
                </div>
            </Container>
        </>
    );
}

export default ViewMessageTriggers;