import { FC, useState } from "react";
import { IMiniChatController } from "./indexModel";
import MiniChat from ".";
import { useTranslation } from "react-i18next";

const MiniChatController: FC<IMiniChatController> = (props) => {
    const { t } = useTranslation();
    const [showChat, setShowChat] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);

    const handleChatToggle = () => {
        setIsAnimating(true);
        if (showChat) {
            setShowChat(false);
            setIsAnimating(false);
        } else {
            setShowChat(true);
            setTimeout(() => {
                setIsAnimating(false);
            }, 300); // Aguardar a animação de entrada
        }
    };

    return <MiniChat 
        showChat={showChat}
        renderMessages={props.renderMessages}
        messagesEndRef={props.messagesEndRef}
        botData={props.botData}
        handleClickSendMessage={props.handleClickSendMessage}
        enterClick={props.enterClick}
        inputRef={props.inputRef}
        t={t}
        fromCx={props.fromCx}
        hasBot={props.hasBot}
        noBot={props.noBot}
        previewImageURL={props.previewImageURL} 
        nameColor={props.nameColor} 
        headerColor={props.headerColor} 
        backgroundColor={props.backgroundColor} 
        consumerMessageColor={props.consumerMessageColor} 
        agentColor={props.agentColor} 
        sendButtonColor={props.sendButtonColor}
        handleChatToggle={handleChatToggle}
        isAnimating={isAnimating}
    />
}

export default MiniChatController;