import { Button, Col, Container, ListGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import AcquirePlatformNotificationBar from "../../components/acquirePlatformNotificationBar";
import CallNotificationBar from "../../components/callNotificationBar";
import Loading from "../../../../components/loading";
import { useSelector, useDispatch } from "react-redux";
import { getShowAcquirePlatformComponent, getShowCallNotificationBar } from "../../../../store/internal";
import ButtonSearchCleanInput from "../../components/buttonSearchCleanInputComponent";
import ButtonListNotificationController from "../../../../components/buttonListNotification/indexController";
import ButtonStatusAgent from "../../components/buttonStatusAgent";
import FilterOptionComponentController from "../../components/filterOptionComponent/indexController";
import ViewTicketController from '../../homeTickets/viewTicket/indexController';
import ListOfTicketsController from "../../homeTickets/listOfTickets/indexController";
import constsRouters from "../../../../routes/constsRouter";

import {
    getShowExportTicketModal,
} from '../../../../store/internal'
import ModalExportTicketsController from "../../components/modalExportTickets/indexController";

const HomeHistory = ({
    t,
    navigate,
    isLoading,
    setCurrentTicketFunction,
    updateListfunction,
    finalizedFiltersClick,
    currentTotalFinalized,
    filteringTickets,
    SetCurrentFilterSearch,
    currentFilterSearch,
    values,
    currentState,
    SetCurrentState,
    currentViewFilter,
    currentTicket,
    currentParams,
    cleanList,
    resetingFilters,
    setResetingFilters,
    yoursTickets,
    setYoursTickets,
    callbackOrderByTickets,
    dataText,
    showCreateTicket,
    currentFilterDatas,
    ticketStateLevelType,
    currentTotalDeleted,
    deletedFiltersClick,
    returnDefaultHistoryPage,
    updateListAfterDelete,
    editTicket,
    setEditTicket,
    showExportTicketModal,
    setFilters, 
    filters,
    setCurrentParams
}) => {
    return (
        <>
            <Container fluid className="nopadding" style={{ overflow: 'hidden' }}>
                <Loading visibility={isLoading}></Loading>
                <ModalExportTicketsController visibility={useSelector(getShowExportTicketModal)} currentState={currentState} filters={filters}></ModalExportTicketsController>
                <div className="wrapper">
                    {/* <SidebarController currentPage={SidebarButtonPage.History} subPage={SidebarButtonPage.HistorySubGeral}></SidebarController> */}
                    <div className="content pt-0 ps-0" >
                        <CallNotificationBar
                            visibility={useSelector(getShowCallNotificationBar)}
                            setCurrentTicketFunction={(value) => setCurrentTicketFunction(value)}
                            updateListfunction={() => updateListfunction()}
                        />
                        <Row className="nopadding mt-3 ps-3">
                            <Col md={7} style={{ marginRight: '-8vw', marginBottom: '2vh' }}>
                                <Row>
                                    <Col className="d-flex justify-content-start align-self-start">
                                        <div style={{ font: 'var(--px20_24_Bold-font)' }}>{t("history_tickets.title")}</div>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <div style={{ font: 'var(--px15_19-font)', color: '#707070' }}>{t("history_tickets.subtitle")}</div>
                                </Row>
                            </Col>
                            <Col className="d-flex justify-content-end">
                                <ButtonSearchCleanInput
                                    inputPlaceholder={t("history_tickets.search_ticket")}
                                    filteringTickets={(e, auxObj, action) => filteringTickets(e, auxObj, action)}
                                    setCurrentFilterSearch={SetCurrentFilterSearch}
                                    currentFilterSearch={currentFilterSearch}
                                />
                                {/* <BellNotification></BellNotification> */}
                                <ButtonListNotificationController />
                                <ButtonStatusAgent />
                            </Col>
                        </Row>
                        <AcquirePlatformNotificationBar visibility={useSelector(getShowAcquirePlatformComponent).visibility} limitDays={useSelector(getShowAcquirePlatformComponent).limitDays} />
                        <Row className="nopadding mt-3 ps-3" >
                            <Col md={2} style={{ width: '220px' }}>
                                <OverlayTrigger
                                    key={"finalized_tickets"}
                                    placement='bottom'
                                    overlay={
                                        <Tooltip>
                                            {t("history_tickets.button_count_number_tickets.total_finalized_tickets")}
                                        </Tooltip>
                                    }
                                    trigger={['hover', 'focus']}
                                    delay={400}
                                >
                                    <Button className="buttonTicketsActive" onClick={() => finalizedFiltersClick()} id="filterAllTickets" disabled={isLoading}>
                                        <Row>
                                            <Col style={{ color: '#4A69BD', font: 'normal normal bold min(max(10px, calc(0.2em + 1vw)), 24px) Montserrat', marginBottom: '0.7vh' }} className="d-flex justify-content-start align-self-start">
                                                {currentTotalFinalized}
                                            </Col>
                                            <Col className="d-flex justify-content-end align-self-start">
                                                <svg id="Grupo_12271" data-name="Grupo 12271" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                    <path id="Caminho_9971" data-name="Caminho 9971" d="M0,0H24V24H0Z" fill="none" />
                                                    <path id="Caminho_9972" data-name="Caminho 9972" d="M14,3V7a1,1,0,0,0,1,1h4" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                    <path id="Caminho_9973" data-name="Caminho 9973" d="M17,21H7a2,2,0,0,1-2-2V5A2,2,0,0,1,7,3h7l5,5V19A2,2,0,0,1,17,21Z" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                    <path id="Caminho_9974" data-name="Caminho 9974" d="M9,15l2,2,4-4" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                </svg>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="d-flex justify-content-start align-self-start">
                                                <p className="nopadding" style={{ color: '#0C2461', font: 'normal normal bold min(max(10px, calc(0.2em + 0.75vw)), 15px) Montserrat' }}>{t("history_tickets.button_count_number_tickets.finalized_tickets")}</p>
                                            </Col>
                                        </Row>
                                    </Button>
                                </OverlayTrigger>
                            </Col>
                            <Col md={2} style={{ width: '220px' }}>
                                <OverlayTrigger
                                    key={"deleted_tickets"}
                                    placement='bottom'
                                    overlay={
                                        <Tooltip>
                                            {
                                                t("history_tickets.button_count_number_tickets.total_deleted_tickets")
                                            }
                                        </Tooltip>
                                    }
                                    trigger={['hover', 'focus']}
                                    delay={400}
                                >
                                    <Button className="buttonTicketsHome" onClick={() => deletedFiltersClick()} id="filterYourTickets" disabled={isLoading}>
                                        <Row>
                                            <Col style={{ color: '#4A69BD', font: 'normal normal bold min(max(10px, calc(0.2em + 1vw)), 24px) Montserrat', marginBottom: '0.7vh' }} className="d-flex justify-content-start align-self-start">
                                                {currentTotalDeleted}
                                            </Col>
                                            <Col className="d-flex justify-content-end align-self-start">
                                                <svg id="Grupo_12273" data-name="Grupo 12273" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                    <path id="Caminho_9967" data-name="Caminho 9967" d="M0,0H24V24H0Z" fill="none" />
                                                    <path id="Caminho_9968" data-name="Caminho 9968" d="M14,3V7a1,1,0,0,0,1,1h4" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                    <path id="Caminho_9969" data-name="Caminho 9969" d="M17,21H7a2,2,0,0,1-2-2V5A2,2,0,0,1,7,3h7l5,5V19A2,2,0,0,1,17,21Z" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                    <path id="Caminho_9970" data-name="Caminho 9970" d="M10,12l4,4m0-4-4,4" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                </svg>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="d-flex justify-content-start align-self-start">
                                                <p className="nopadding" style={{ color: '#0C2461', font: 'normal normal bold min(max(10px, calc(0.2em + 0.75vw)), 15px) Montserrat' }}>{t("history_tickets.button_count_number_tickets.deleted_tickets")}</p>
                                            </Col>
                                        </Row>
                                    </Button>
                                </OverlayTrigger>
                            </Col>
                        </Row>

                        {/* repeat of getShowEditTicketsModal. Avoid column divs rows below */}
                        {values.internal.getShowEditTicketsModal ?
                            <Row className="nopadding ps-3">
                                <Col className="d-flex" style={{ marginTop: "1vh", marginBottom: "1vh", marginLeft: '0.5vh' }}>
                                    <p className="nopadding" style={{ font: 'var(--px16_19-font)', color: '#1E1E1E', textDecoration: 'underline', cursor: 'pointer' }} onClick={
                                        currentState === 'deleting' ?
                                            () => navigate(constsRouters.routers.tickets.path)
                                            :
                                            () => returnDefaultHistoryPage(values)
                                    }>
                                        <span>
                                            <svg id="Grupo_11161" data-name="Grupo 11161" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 2 24 24">
                                                <path id="Caminho_9641" data-name="Caminho 9641" d="M0,0H24V24H0Z" fill="none" />
                                                <path id="Caminho_9642" data-name="Caminho 9642" d="M9,13,5,9,9,5M5,9H16a4,4,0,0,1,0,8H15" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            </svg>
                                        </span>
                                        {
                                            t("history_tickets.button_count_number_tickets.back")
                                        }
                                    </p>
                                </Col>
                            </Row> :
                            <></>
                        }
                        {values.internal.getShowEditTicketsModal ?
                            <div>
                                <ViewTicketController saveFinalizedClick={() => finalizedFiltersClick()} currentViewFilter={currentViewFilter} currentTicket={currentTicket} filters={currentParams} editTicket={editTicket} isHistoryHome={true} setCurrentParams={setCurrentParams}></ViewTicketController>
                            </div> :
                            <div>
                                <div className='FilterTicketsRow ps-3' style={{ margin: '2vh 0.5vw 0px', padding: '0' }}>
                                    <ListGroup variant="flush" >
                                        <ListGroup.Item style={{ backgroundColor: 'rgba(207, 227, 255,0.25)', padding: '0.6%' }}>
                                            <FilterOptionComponentController
                                                optionNames={["Canal", "Atribuido", "Status", "Setor", "Tags", "Data"]}
                                                disableSubOptions={{ 'status': { "history": true } }}
                                                currentState={currentState}
                                                setCurrentState={SetCurrentState}
                                                values_user={values.user}
                                                values_internal_getShowEditTicketsModal={false}
                                                values_internal_getShowCreateTicketsModal={false}
                                                filteringTickets={(e, auxObj, action) => filteringTickets(e, auxObj, action)}
                                                cleanList={() => cleanList()}
                                                values_token_value={values.token.value}
                                                values_company_id={values.company.id}
                                                resetingFilters={resetingFilters}
                                                setResetingFilters={setResetingFilters}
                                                yoursTickets={yoursTickets}
                                                setYoursTickets={setYoursTickets}
                                                filters={filters}
                                                setFilters={setFilters}
                                                currentViewFilter={currentViewFilter}
                                                screen={'home_history_tickets'}
                                            />
                                        </ListGroup.Item>
                                    </ListGroup>
                                </div>
                                <ListOfTicketsController
                                    ticketStateLevelType={ticketStateLevelType}
                                    statusPage={currentState}
                                    datas={currentFilterDatas}
                                    updateListAfterDelete={() => updateListAfterDelete()}
                                    updateListfunction={() => updateListfunction()}
                                    showCreateTicket={() => showCreateTicket()}
                                    setCurrentTicketFunction={(value) => setCurrentTicketFunction(value)}
                                    dataText={dataText} assignedEmployeeActiveColumn={true}
                                    requesterUserActiveColumn={true}
                                    actionsActiveColumn={false}
                                    checkboxes={false}
                                    callbackOrderByTickets={(column, order) => callbackOrderByTickets(column, order)}
                                    setEditTicket={setEditTicket}
                                    fromHistory={true}
                                />
                                <Col md={6} className="d-flex justify-content-start" style={{ width: '40vh', marginLeft: '16px'}}>
                                    <Button type="submit" className="buttonBlue" onClick={showExportTicketModal} style={{ width: '60%' }}>{t("history_tickets.export")}</Button>
                                </Col>
                            </div>
                        }
                    </div>
                </div>
            </Container >
        </>
    );
}


export default HomeHistory;    