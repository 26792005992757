import { TicketInterface, ViewTicketControllerInteface } from './indexModel';
import { ItemTicketInterface } from './indexModel';
import { Dispatch, FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { socket, SocketContext } from '../../../../core/context/socket-context';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { AgentInterface, ConsumerInterface, DataSectorIdInterface, GetAllTicketsInterface, SectorActiveInterface, TicketsHistoryInterface, TokenInterface, UserInterface } from '../../../../services/requestsInterfacesModel';
import { getIdCompany } from '../../../../store/company';
import { getIdEmployee } from '../../../../store/employee';
import { getIdUser, getNameUser, getProfileId, getUserLastName } from '../../../../store/user';
import { getToken } from '../../../../store/token';
import { getBlockLoadTicketBackup, getMute, getOrderByList, getUpdateButtonNumberOfTickets, getShowEditConsumersModal, getShowEditTicketsModal, getShowModalConsumersSimilarData, getShowRegisterCustomersModal, setBlockLoadTicketBackup, setCleanMessageEditor, setRegisterCustomersModal, setShowAlertFeedback, setShowConfirmationModal, setShowCreateConsumersModal, setShowEditConsumersModal, setShowModalConsumersSimilarData, setUpdateButtonNumberOfTickets, setShowEditTicketsModal, getShowAcquirePlatformComponent, getShowMessageSend, getNextTicketId, setNextTicketId } from '../../../../store/internal';
import { TicketStatus } from '../../../../core/enums/ticket-status';
import BackendConstants from '../../../../core/constants/backend-constants';
import { EmailContext } from '../../../../core/context/cc-cco-context';
import NotificationSoundController from '../../../../components/notificationSoundComponent/indexController';
import constsApi from '../../../../services/constsApi';
import { TicketFilterType } from '../../../../core/enums/ticket-filter-type';
import { OrderBy } from '../../../../core/enums/order-sort-by';
import UserService from '../../../../services/user-service';
import Utils from '../../../../core/shared/utils';
import constsRouters from '../../../../routes/constsRouter';
import { Option } from './indexModel';
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import ViewTicket from '.';
import { Channels } from '../../../../core/enums/channels';
import { TicketContext } from '../../../../core/context/ticket-context';
import { useBeforeUnload } from 'react-router-dom';
import { BlockTicketContext } from '../../../../core/context/block-ticket-context';
import useTicketUpdate from '../../../../hooks/useTicketUpdateSocket';
import useNewConsumerMessage from '../../../../hooks/useNewConsumerMessageSocket';
import useTicketDelete from '../../../../hooks/useTicketDeletedSocket';
import useTicketBlock from '../../../../hooks/useTicketBlockSocket';
import useDisconnectOldTabs from '../../../../hooks/useDisconnectOldTabsSocket';
import useLeaveTicketChat from '../../../../hooks/useLeaveTicketChatSocket';
import useWhoIsViewing from '../../../../hooks/useWhoIsViewingSocket';
import useNewAutomaticMessage from '../../../../hooks/useNewAutomaticMessageSocket';

const ViewTicketController: FC<ViewTicketControllerInteface> = (props) => {
    const ticket = useContext(TicketContext);
    const block_ticket = useContext(BlockTicketContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const abortConst = new AbortController();
    const { t } = useTranslation();
    const AppRequesterConst = new AppRequesterController();

    /**
     * Eventos de socket da página
     */
    const { ticketUpdate } = useTicketUpdate();
    const { newConsumerMessage } = useNewConsumerMessage()
    const { newAutomaticMessage } = useNewAutomaticMessage()
    const { ticketDeleted } = useTicketDelete()
    const { ticketBlock } = useTicketBlock()
    const { whoIsViewing } = useWhoIsViewing()
    const { disconnectOldTabs } = useDisconnectOldTabs()
    const { onSocketLeaveTicketChat } = useLeaveTicketChat()


    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        },
        employee: {
            id: useSelector(getIdEmployee)
        },
        user: {
            id: useSelector(getIdUser),
            name: useSelector(getNameUser),
            profile_id: useSelector(getProfileId),
            lastname: useSelector(getUserLastName),
        },
        token: {
            value: useSelector(getToken)
        },
        internal: {
            getShowEditTicketsModal: useSelector(getShowEditTicketsModal),
            getOrderByList: useSelector(getOrderByList),
            getUpdateButtonNumberOfTickets: useSelector(getUpdateButtonNumberOfTickets),
            getShowEditConsumersModal: useSelector(getShowEditConsumersModal),
            getShowCreateConsumersModal: useSelector(getShowRegisterCustomersModal),
            getShowModalConsumersSimilarData: useSelector(getShowModalConsumersSimilarData),
            getShowAcquirePlatformComponent: useSelector(getShowAcquirePlatformComponent),
            getNextTicketId: useSelector(getNextTicketId)
        },
        mute: {
            value: useSelector(getMute)
        },
        getBlockTicketBackup: useSelector(getBlockLoadTicketBackup)
    };

    const [aux, setAux] = useState([]);
    const [tags, setTags] = useState([]);
    const [followers, setFollowers] = useState([]);

    /*Current selected ticket*/
    const [currentFilterDatas, setcurrentFilterDatas] = useState(undefined);

    const [currentTicket, setCurrentTicket] = useState(null);
    const [currentLastUpdatedDate, setCurrentLastUpdatedDate] = useState(new Date());
    const [/*nextTicket*/, setNextTicket] = useState(null);
    const [currentStatus, setCurrentStatus] = useState(null);
    const [currentRequester_user, setCurrentRequester_user] = useState(null);
    const [currentAssigned_employee_user_id, setCurrentAssigned_employee_user_id] = useState("");
    const [currentFilterSearch, SetCurrentFilterSearch] = useState(null);
    const [currentFollowers, setCurrentFollowers] = useState([]);
    const [currentTags, setCurrentTags] = useState([]);
    const [currentSubject, setCurrentSubject] = useState("");
    const [disableSaveButton, setDisableSaveButton] = useState(false);

    const [departmentsOptions, setDepartmentsOptions] = useState([]);
    const [/*allDepartments*/, setAllDepartments] = useState(null);
    const [selectedDepartments, setSelectedDepartments] = useState([]);
    // id usado no useEffect sempre que é atualizado o setor selecionado
    const [selectedDepartmentId, setSelectedDepartmentId] = useState("");

    const [consumerCreateItem] = useState({ label: t("home_tickets.home_create_ticket.select_component.create_consumer"), value: "create", key: "create" });
    const [consumersOptions, setConsumersOptions] = useState([consumerCreateItem]);
    const [selectedConsumers, setSelectedConsumers] = useState([]);

    const [similarConsumers, setSimilarConsumers] = useState(undefined);
    const [currentConsumer, setCurrentConsumer] = useState(null);
    const [ticketDataBackup, setTicketDataBackup] = useState<{ subject: string, status_id: string, tags: any[], followers: any[], assigned_employee_user_id: string, sector_id: string, requester_user_id: string, requester_user: any, assigned_employee: any }>(null);

    const [agentNotItem] = useState({ label: t("home_tickets.home_create_ticket.null_option"), value: "NA", key: "NA", hide: false, sector: { id: "" }, user_id: "" });
    const [agentsOptions, setAgentsOptions] = useState([agentNotItem]);
    const [selectedAgents, setSelectedAgents] = useState([]);
    const [currentClickId, setCurrentClickId] = useState("")

    const TicketStatusMultiselectOptions = [
        { label: t('ticket_status.new'), value: TicketStatus.New }, 
        { label: t('ticket_status.open'), value: TicketStatus.Open },
        { label: t('ticket_status.pending'), value: TicketStatus.Pending },
        { label: t('ticket_status.on_hold'), value: TicketStatus.OnHold },
        { label: t('ticket_status.resolved'), value: TicketStatus.Resolved },
    ];

    const [statusOptions] = useState(TicketStatusMultiselectOptions);
    const [selectedStatus, setSelectedStatus] = useState([{ label: t('ticket_status.new'), value: TicketStatus.New }]);

    const [currentPage, SetCurrentPage] = useState(1);
    const [/*currentTicketIsUpdated*/, setCurrentTicketIsUpdated] = useState(false);
    const [currentLimit] = useState(BackendConstants.limit);
    const [currentParams, SetCurrentParams] = useState({});
    const [currentHasMoreInformation, /*SetCurrentHasMoreInformation*/] = useState(true);
    const [/*isLoading*/, setIsLoading] = useState(false);
    const [currentConsumersData, setCurrentConsumersData] = useState(null);

    const [currentStatusPage, setCurrentStatusPage] = useState("");
    const [forcedUpdate, setForcedUpdate] = useState(false);
    const [/*currentOrderBy*/, setCurrentOrderBy] = useState(values.internal.getOrderByList);

    // const [blockBool, setBlockBool] = useState(true); 

    // const inputs = [useRef(null), useRef(null), useRef(null)];

    const [overlayId, setOverlayId] = useState(null);

    const [showContent, setShowContent] = useState(false);
    const [updateNoAtendece, setUpdateNoAtendece] = useState(false);

    const [customFields, setCustomFields] = useState([])
    const entityId = '250204ce-44eb-4cdc-865a-f54eed9c6496'
    const [customFieldsGet, setCustomFieldsGet] = useState([])
    const [textareaStates, setTextareaStates] = useState({})
    const [customFieldsIds, setCustomFieldsIds] = useState([])
    const [customFieldIdErrors, setCustomFieldIdErrors] = useState<any>({});
    // const [selected_ccEmails, setSelected_ccEmails] = useState(/* props.ticketPreviewFormData? props.ticketPreviewFormData.selected_ccEmails: */(currentTicket && currentTicket.cc_list) ? currentTicket.cc_list : null);
    // const [selected_ccoEmails, setSelected_ccoEmails] = useState(/* props.ticketPreviewFormData? props.ticketPreviewFormData.selected_ccoEmails: */(currentTicket && currentTicket.cco_list) ? currentTicket.cco_list : null);
    const [audioChunks, setAudioChunks] = useState([])
    const [audio, setAudio] = useState(null)
    const [audioBlob, setAudioBlob] = useState(null)
    const [userId, setUserId] = useState("")

    const email = useContext(EmailContext)

    // const [viewing, setViewing] = useState('')

    const [defaultValue, setDefaultValue] = useState(t('home_tickets.messages_component.email'))
    const [disabledDropDown, setDisabledDropdown] = useState(false)
    const [renderMessagesChat, setRenderMessagesChat] = useState(false)
    const [gotToTicket, setGoToTicket] = useState(false)
    const [refreshPage, setRefreshPage] = useState(false);

    const NotificationSoundConst = new NotificationSoundController(values.mute.value, dispatch);

    const employee_id = new BroadcastChannel('employee_id')

    const isAdmin = constsApi.profile[0].id === values.user.profile_id;
    const isAgent = constsApi.profile[1].id === values.user.profile_id;
    const isOwner = constsApi.profile[2].id === values.user.profile_id;
    const isMaster = constsApi.profile[3].id === values.user.profile_id;

    useEffect(() => {
        employee_id.postMessage(values.employee.id)
    }, [values.employee.id])

    // select create option click
    const customCreateRequesterButtonClick = () => {
        dispatch(setRegisterCustomersModal(true));
    }

    const updateListfunction = () => {
        SetCurrentPage(currentPage + 1);
    };

    useEffect(() => {
        pageInitializationConstans.get_show_create_consumers_modal(values.internal.getShowCreateConsumersModal)
        pageInitializationConstans.get_show_edit_consumer_modal(values.internal.getShowEditConsumersModal)
        pageInitializationConstans.get_show_modal_consumers_similar_data(values.internal.getShowModalConsumersSimilarData)
        pageInitializationConstans.task_update(props?.taskUpdate)
        pageInitializationConstans.is_allowed_message()
        props.setCurrentParams({});
    }, [])

    useEffect(() => {
        let controller = new AbortController();
            getTicket({ id: ticket?.getTicketId()}, controller.signal);  
        
    }, [ticket.getTicketId])

    useEffect(() => {
        if (currentTicket?.cc?.length > 0) {
            email.setEmailCC(currentTicket.cc);
        } else {
            email.setEmailCC([]);
        }

        if (currentTicket?.cco?.length > 0) {
            email.setEmailCCO(currentTicket.cco);
        } else {
            email.setEmailCCO([]);
        }
    }, [currentTicket?.cc, currentTicket?.cco]);

    useEffect(() => {
        if (props.currentTicket !== null && props.ticketPreviewFormData !== null) {
            let controller = new AbortController();
            getTicket({ id: props?.currentTicket.id }, controller.signal);
        }
    }, [props.currentTicket, props.ticketPreviewFormData]);

    useEffect(() => {
        ticket.setTicket(props.currentTicket?.id)
    }, [props.currentTicket?.id])

    useEffect(() => {
        if (values.user.id) {
            setUserId(values.user.id)
        }
    }, [values])


    useEffect(() => {
        socket.socket?.on("ticket-block", (data) => onSocketBlockTicket(data))
        socket.socket?.on('tickets-expired', (data) => OnSocketResetExpiredTicket(data))

        return () => {
            socket.socket?.off("ticket-block", onSocketBlockTicket)
            socket.socket?.off("tickets-expired", OnSocketResetExpiredTicket)
        }
    }, [socket.socket?.active])

    // fazendo backup no localstorage sempre que o ticket é editado
    useEffect(() => {
        if (currentTicket) {
            let ticketData = {};
            if (currentSubject !== '' && currentSubject !== undefined && currentSubject !== null) {
                ticketData['subject'] = currentSubject;
            }

            if (currentRequester_user !== '' && currentRequester_user !== undefined && currentRequester_user !== null) {
                ticketData['requester_user_id'] = currentRequester_user.user_id;//.id;
                ticketData['requester_user'] = currentRequester_user;
            }

            if (currentAssigned_employee_user_id !== 'NA' && currentAssigned_employee_user_id !== '' && currentAssigned_employee_user_id !== undefined && currentAssigned_employee_user_id !== null) {
                ticketData['assigned_employee_user_id'] = currentAssigned_employee_user_id;
                ticketData['assigned_employee'] = selectedAgents[0];
            }

            if (currentFollowers.length !== 0) {
                if (currentFollowers[0] !== null) {
                    // let auxArray = [];
                    let followers = [];
                    currentFollowers.forEach((element) => {
                        // auxArray.push(element.id);
                        followers.push(element);
                    });
                    ticketData['followers'] = followers;
                    // ticketData['followers_id'] = auxArray;
                }
            }

            let datasConverted = { status: '' };
            constsApi.status.forEach((element) => {
                if (element.id === currentStatus) {
                    datasConverted.status = element.id;
                }
            });
            if (datasConverted.status !== '') {
                ticketData['status_id'] = datasConverted.status;
            }

            if (currentTags.length !== 0) {
                if (currentTags[0] !== undefined) {
                    let auxArray = [];
                    currentTags.forEach((element) => {
                        auxArray.push(element);
                    });
                    ticketData['tags'] = auxArray;
                }
            }

            if (selectedDepartments.length > 0) {
                ticketData['sector_id'] = selectedDepartments[0].value;
            }

            let edit_ticket_data = { ticketData: ticketData, currentTicket: currentTicket };

            localStorage.setItem('edit_ticket_data', JSON.stringify(edit_ticket_data));
            // setForcedUpdate(true);
        }
    }, [currentTicket, currentStatus, currentRequester_user, currentAssigned_employee_user_id, currentFollowers, currentSubject, currentTags, selectedDepartments, selectedConsumers, selectedAgents, selectedStatus]);

    // desabilitar edição do ticket
    useEffect(() => {
        let disableEdit = (currentTicket && (currentTicket.status_id === TicketStatus.Resolved || currentTicket.deleted_at !== null || currentTicket.status_id === TicketStatus.Abandoned)) ? true : false;

        if (props.currentViewFilter) {
            if (props.currentViewFilter === TicketFilterType.Deleted || props.currentViewFilter === TicketFilterType.Finalized) {
                if (props.editTicket === false){
                    block_ticket.setEditTicket(false);
                    block_ticket.setDisableEditTicket(true)
                }
                else {
                    block_ticket.setEditTicket(true);
                    block_ticket.setDisableEditTicket(false)
                }
            } else if (disableEdit) {
                block_ticket.setEditTicket(false);
                block_ticket.setDisableEditTicket(true)
            } else {
                if (gotToTicket == false) {
                    block_ticket.setEditTicket(true)
                    block_ticket.setDisableEditTicket(false)
                    block_ticket.setEditButtonsView(false)
                    block_ticket.setReadOnlyEditorComponent(false)
                }
            }
        } else if (disableEdit) {
            block_ticket.setEditTicket(false);
            block_ticket.setDisableEditTicket(true)
        } else {
            block_ticket.setEditTicket(false);
            block_ticket.setDisableEditTicket(true);
        }
    }, [currentTicket, props.currentViewFilter, gotToTicket]);

    /**
     * Constantes da montagem da página
     */
    const pageInitializationConstans = {
        get_show_create_consumers_modal: (getShowCreateConsumersModal) => {
            if (getShowCreateConsumersModal) {
                dispatch(setRegisterCustomersModal(false))
            }
        },
        get_show_edit_consumer_modal: (getShowEditConsumersModal) => {
            if (getShowEditConsumersModal) {
                dispatch(setShowEditConsumersModal(false));
            }
        },
        get_show_modal_consumers_similar_data: (getShowModalConsumersSimilarData) => {
            if (getShowModalConsumersSimilarData) {
                dispatch(setShowModalConsumersSimilarData(false))
            }
        },
        task_update: (taskUpdate) => {
            if (taskUpdate) {
                setForcedUpdate(true)
            }
        },
        is_allowed_message: () => {
            block_ticket.setIsAllowedMessage(true);
        }
    }

    /**
     * Situações de atualização a página
     */
    const updatePageSituations = {
        /**
         * Função responsável por atualizar a listagem lateral da viewTicket a cada evento do socket
         * @param controller 
         * @param getShowEditTicketsModal 
         */
        get_show_edit_tickets_modal: (controller, getShowEditTicketsModal) => {
            if (getShowEditTicketsModal) {
                setIsLoading(true);
                getInformations(controller);

                SetCurrentPage((state) => {
                    SetCurrentParams((state2) => {
                        let auxObj = Object.assign(state2, props.filters)
                        getAllTicketsToPagination(Object.assign(auxObj, Object.assign(state, { limit: currentLimit, page: state })), false, controller);
                        return Object.assign(auxObj, Object.assign(state2, { limit: currentLimit, page: state }));
                    });
                    return state;
                });
            }
        },
        forced_update: (controller, forcedUpdate) => {
            if (forcedUpdate) {
                if (document.getElementById("listOfTickets") !== null) {
                    document.getElementById("listOfTickets").scrollTop = 0;
                }
                // setAux([])

                SetCurrentPage((state) => {
                    SetCurrentParams((state2) => {
                        let auxObj = Object.assign({}, props.filters);
                        getAllTicketsToPagination(Object.assign(auxObj, Object.assign(state2, { limit: currentLimit, page: state })), true, controller);
                        return state2
                    });
                    return state
                })
            }
        }
    }

    useEffect(() => {
        if (currentTicket && currentTicket?.processing === true) {
            dispatch(setShowConfirmationModal({ visibility: true, text: { "title": t("home_tickets.view_ticket.processing.title"), "body": t("home_tickets.view_ticket.processing.body"), 'id': '', "buttonConfirmationText": t("home_tickets.view_ticket.processing.confirmation"), closeButton: false }, functionConfirmation: () => dispatch(setShowEditTicketsModal(false)) }))
            onSocketLeaveTicketChat(props.currentTicket?.id ? props.currentTicket.id : currentTicket?.id)
        }
    }, [currentTicket])

    /**
     * Eventos do socket 
     */
    const socketEvents = {
        ticket_update: (ticketUpdate) => {
            if (ticketUpdate) {
                onSocketUpdateTicketList(ticketUpdate)
            }
        },
        new_consumer_message: (newConsumerMessage) => {
            if (newConsumerMessage) {
                onSocketUpdateTicketList(newConsumerMessage.ticket)
            }
        },
        ticket_deleted: (ticketDeleted) => {
            if (ticketDeleted) {
                onSocketDeletetList(ticketDeleted)
            }
        },
        // ticket_block: (ticketBlock) => {
        //     if (ticketBlock) {
        //         onSocketBlockTicket(ticketBlock)
        //     }
        // },
        disconnect_old_tabs: (disconnectOldTabs) => {
            if (disconnectOldTabs && props.isHistoryHome !== true) {
                onSocketForceCloseTicket()
            }
        },
        who_is_viewing: (whoIsViewing) => {
            if (whoIsViewing) {
                osWhoIsViewingUpdate(whoIsViewing)
            }
        },
        new_automatic_message: (newAutomaticMessage) => {
            if (newAutomaticMessage) {
                onSocketUpdateTicketList(newAutomaticMessage)
            }
        },
    }

    /**
     * Responsável por atualizar a listagem da viewTicket
     */
    useEffect(() => {
        const controller = new AbortController();

        // currentStatusPage é usado somente no filtering tickets para campo de pesquisa
        if (currentStatusPage !== "init_searching") {
            updatePageSituations.get_show_edit_tickets_modal(controller, values.internal.getShowEditTicketsModal)
            updatePageSituations.forced_update(controller, forcedUpdate)
        }

        if (currentStatusPage === "init_searching") {
            setCurrentStatusPage("");
        }

        return () => {
            controller.abort()
        }
        // Resolvendo o problema da duplicação de tickets na listagem da viewTicket
    }, [currentPage, currentStatusPage, useSelector(getShowMessageSend), updateNoAtendece])

    /**
     * Atualização dos eventos de socket
     */
    useEffect(() => {
        socketEvents.ticket_deleted(ticketDeleted)
        // socketEvents.ticket_block(ticketBlock)
        socketEvents.disconnect_old_tabs(disconnectOldTabs)
        socketEvents.who_is_viewing(whoIsViewing)
        socketEvents.new_automatic_message(newAutomaticMessage)
    }, [ticketDeleted, disconnectOldTabs, whoIsViewing, newAutomaticMessage])

    useEffect(() => {
        socketEvents.ticket_update(ticketUpdate)
    }, [ticketUpdate])

    useEffect(() => {
        socketEvents.new_consumer_message(newConsumerMessage)
    }, [newConsumerMessage])

    // useEffect(() => {
    //     socketEvents.new_consumer_message(newConsumerMessage)
    // }, [newConsumerMessage])

    useEffect(() => {
        // atualizando data ao trocar de ticket
        getLastUpdatedDate(currentTicket);
        // setShowContent(false)
    }, [currentTicket && currentTicket?.id]);

    useEffect(() => {
        if (customFields?.length > 0)
            initializeTextareaStates(customFields);
    }, [customFields])

    useEffect(() => {
        Utils.changeActiveScrollBar("listOfTickets");
    }, [currentFilterDatas]);

    // atualizando o id do setor
    useEffect(() => {
        /*
        // esvaziando agentes no useEffect que troca o setor
        if (currentTicket?.sector?.id !== selectedDepartments[0]?.value) {
            setSelectedAgents([])
            setCurrentAssigned_employee_user_id('')
        } */

        // atualizando o selectedDepartmentId
        if (selectedDepartments.length > 0) {
            setSelectedDepartmentId(selectedDepartments[0].value);
            if (selectedDepartmentId !== selectedDepartments[0].value) {
                setSelectedAgents([]);
                setCurrentAssigned_employee_user_id("");
            }
        } else {
            setSelectedDepartmentId("");
        } 
    }, [selectedDepartments]);

    useEffect(() => {
        const controllerDepartment = new AbortController();
        const controllerAgents = new AbortController();

        setSelectedDepartmentId((departmentId) => {
            if (departmentId) {
                const signalDepartment = controllerDepartment.signal;
                // ocultando agentes que não são do setor
                const headers = UserService.getHeaders();

                AppRequesterConst.Get(
                    `/sector/${departmentId}`, { headers, params: { companyid: values.company.id }, signal: signalDepartment },
                    (response: Object) => { },
                    (data: DataSectorIdInterface) => {
                        // atualizando setor com mais informações não presentes na rota de listagem de setores
                        if (data?.data?.sectors.length > 0) {
                            setSelectedDepartments([ Object.assign(data.data.sectors[0], { label: data.data.sectors[0].sector_name, value: data.data.sectors[0].sector_id }) ]);
                        }

                        // agentes pertencentes ao setor selecionado
                        if (data?.data?.sectors?.length > 0 && data?.data?.sectors[0]?.agents?.length > 0) { 
                            let agents = [];
                            agents.push(agentNotItem);

                            // agente selecionado vazio para verificar se existe o agente selecionado na listagem do setor
                            let agentSelect = [];

                            data.data.sectors[0].agents.map((agent) => {
                                agent = Object.assign(agent, { label: agent.name, value: agent.user_id });
                                agents.push(agent);

                                if (selectedAgents?.length > 0 && selectedAgents[0].value === agent.user_id) {
                                    agentSelect.push(agent);
                                }
                            });

                            setAgentsOptions(agents);
                        } else { // o setor não tem agentes
                            // Usado para resetar o selectedAgents ao mudar de setor
                            // getAgents(controllerAgents);
                            setAgentsOptions([]);
                            setSelectedAgents([]);
                            setCurrentAssigned_employee_user_id("");

                            // dispatch(setShowAlertFeedback({ message: t("home_tickets.view_ticket.alert_feedback.department_without_agents"), visibility: true, signalIcon: false }));
                        }
                    }, (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
                );
            } else { // caso não haja setor selecionado a listagem de agentes deve ser resetada para o default
                getAgents(controllerAgents);
                setSelectedAgents([]);
                setCurrentAssigned_employee_user_id("");
            }

            return departmentId;
        });

        return () => {
            controllerDepartment.abort();
            controllerAgents.abort();
        }
    }, [selectedDepartmentId]);

    /**
     * Responsável por sair do chat quando o usuário 
     * atualiza a página
     */
    useEffect(() => {
        if (refreshPage) {
            setRefreshPage(false);
        }
    }, [refreshPage])

    /**
     * Responsável por sair do chat quando o usuário 
     * atualiza a página
     */
    useBeforeUnload(
        useCallback(() => {
            setRefreshPage(true)
            onSocketLeaveTicketChat(props.currentTicket?.id? props.currentTicket.id: currentTicket?.id)
        }, [refreshPage])
    )

    const onSocketForceCloseTicket = () => {
        onSocketLeaveTicketChat(props.currentTicket?.id? props.currentTicket.id: currentTicket?.id)
        props.allFiltersClick?.();
    }

    /**
     * Realiza o bloqueio do ticket quando mais de um agente entra no ticket
     * @param data 
     */
    const onSocketBlockTicket = async (data: { ticket_id: string, is_block: boolean, readonly: boolean }) => {
        if (isMaster) {
            if (data.readonly !== undefined && data.is_block !== undefined) {
                block_ticket.setEditTicket(false)
                block_ticket.setEditButtonsView(true)
                block_ticket.setDisableEditTicket(true)
                setGoToTicket(true)
            }
        }
        else if (isOwner || isAdmin) {
            if (data.readonly === false && data.is_block === false) {
                block_ticket.setAdmBlocked(false)
                block_ticket.setDisableEditTicket(false)
            }
            else {
                block_ticket.setAdmBlocked(true)
                block_ticket.setDisableEditTicket(true)
            }
        }
        else if (isAgent) {
            if (data.readonly !== undefined && data.is_block !== undefined) {
                block_ticket.setEditTicket(false)
            }
        }
    }

    const osWhoIsViewingUpdate = (data) => {
        setAux(currentFilterDatas)
        setAux((prevState) => {
            let newAux = [];
            if (prevState) {
                newAux = prevState.map((item) => {
                        if (item && (item?.id === data?.ticket_id)) {
                            item.who_is_viewing = data?.who_is_viewing;
                            ticket.setTicketWhoIsViewingId(data?.who_is_viewing_user_id);
                            setForcedUpdate(true);
                        }
                    return item;
                });
                return newAux.filter((item) => {
                    return item && (item?.status_id !== TicketStatus.Resolved)
                });
            }
        })
     }

    const OnSocketResetExpiredTicket = (data: any) => {
        SetCurrentPage(1);
        // setAux([]);
        if (document.getElementById("listOfTickets") !== null) {
            document.getElementById("listOfTickets").scrollTop = 0;
        }
        
        data.map(item => {
            onSocketDeletetList(item.ticket_id)
            setCurrentTicket((ticket) => {
                if (item.ticket_id === ticket.id){
                    dispatch(setShowEditTicketsModal(false));
                    props.allFiltersClick?.();
                }
                return ticket
            })
        });
    }

    const onSocketUpdateTicketList = async (ticket: { last_message_content: string; updated_level2_at: string | number | Date; status_id: string; is_bot: boolean; assigned_employee: { user_id: string; }; channel_id: string; sector_id: string; bold: boolean; id: string; who_is_viewing: string; last_message: string }) => {
        // remove o ticket se ele estiver finalizado, excluído ou abandonado
        // if ((ticket.status_id === TicketStatus.Abandoned || ticket.status_id === TicketStatus.Resolved || ticket.status_id === TicketStatus.Overdue) && props.isHistoryHome !== true) {
        //     onSocketDeletetList(ticket.id);
        //     if(ticket.id === currentTicket?.id){
        //         props.homeTicketsResetReturn();
        //     }
        // }
            // if(ticket.status_id === TicketStatus.Resolved){
            //     // setAux(currentFilterDatas)
            //     if (aux.length >= 2) {
            //         let indexNextTicket: number; //= aux.indexOf(currentTicket) + 1;
            //         aux.forEach((ticketAux, index) => {
            //             if (ticket.id === ticketAux.id) {
            //                 indexNextTicket = index + 1;
            //             }
            //             if (index === indexNextTicket) {
            //                 dispatch(setNextTicketId(ticketAux.id));
            //             }
            //         })

            //         // setCurrentTicket(aux[indexNextTicket]);
            //         getTicket({ id: values.internal.getNextTicketId }, {})
            //     } else {
            //         props.homeTicketsResetReturn();
            //     }
            // }
        // } else {
            setCurrentOrderBy(order => {
                SetCurrentParams((param) => {
                    let dateCheck = new Date(ticket?.updated_level2_at);
                    let dateStart = new Date(param["start_date"]);
                    let dateEnd = new Date(param["end_date"]);

                    dateStart = new Date(dateStart.getTime() + (1 * 86400000));
                    dateEnd = new Date(dateEnd.getTime() + (1 * 86400000));

                    if ((!param["status"] || ticket?.status_id === param["status"]) &&
                        (!param["bot"] || ticket?.is_bot === param["bot"]) &&
                        (!param["employee"] || (ticket?.assigned_employee && ticket?.assigned_employee.user_id === param["employee"])) &&
                        (!param["channel"] || ticket?.channel_id === param["channel"]) &&
                        (!param["sector"] || ticket?.sector_id === param["sector"]) &&
                        (!param["dateStart"] || (dateCheck >= dateStart && dateCheck <= dateEnd))) {
                        // setAux(currentFilterDatas)
                        setAux((prevState) => {
                            let newAux = [];
                            if (ticket?.bold) {
                                let is_new = true;
                                if (prevState) {
                                    newAux = prevState.map((item) => {
                                        if (item && (item.id === ticket.id) && item.bold === true) {
                                            item.bold = ticket.bold;
                                            item.status_id = ticket.status_id;
                                            item.last_message = ticket.last_message;
                                            is_new = false;
                                        } else if (item && (item.id === ticket.id) && item.bold === false) {
                                            item.bold = ticket.bold;
                                            item.status_id = ticket.status_id;
                                            item.last_message = ticket.last_message;
                                            is_new = true;
                                        }
                                        return item;
                                    });

                                    if (is_new && ticket.status_id !== TicketStatus.Resolved && ticket.updated_level2_at) {
                                        // NotificationSoundConst.playSound();

                                        // dispatch(setUpdateButtonNumberOfTickets(true));
                                        newAux = newAux.filter((item) => {
                                            return item && (item?.id !== ticket.id)
                                        })
                                        if (order === OrderBy.asc) {
                                            const concatArray = newAux.concat([ticket]).filter((item) => {
                                                return item && (item?.status_id !== TicketStatus.Resolved)
                                            });
                                            return concatArray;
                                        } else {
                                            // adiciona no inicio da listagem
                                            return [ticket, ...newAux].filter((item) => {
                                                return item && (item?.status_id !== TicketStatus.Resolved)
                                            });
                                        }


                                        // atualiza total de tickets                  
                                    } else {
                                        // dispatch(setUpdateButtonNumberOfTickets(true));
                                        return prevState.filter((item) => {
                                            return item && (item?.status_id !== TicketStatus.Resolved)
                                        });
                                    }
                                } else {
                                    return newAux.filter((item) => {
                                        return item && (item?.status_id !== TicketStatus.Resolved)
                                    });
                                }
                            } else {
                                //se o bold for removido atualiza a lista

                                if (prevState) {
                                    newAux = prevState.map((item) => {
                                        // setDisableEditTicket(false);
                                        // setOnlyRead(false);
                                        // setViewing(item.who_is_viewing);
                                        if (item && (item?.id === ticket?.id)) {
                                            item.status_id = ticket.status_id;
                                            // item.who_is_viewing = ticket.who_is_viewing;
                                            item.last_message = ticket.last_message;
                                            item.bold = ticket.bold;
                                            setForcedUpdate(true);

                                        }

                                        return item;
                                    });
                                    return newAux.filter((item) => {
                                        return item && (item?.status_id !== TicketStatus.Resolved)
                                    });
                                } else {
                                    return newAux.filter((item) => {
                                        return item && (item?.status_id !== TicketStatus.Resolved)
                                    });
                                }
                            }
                        });
                        // setBlockBool(false);
                        // setCurrentStatusPage("updating SOKET" + Date.now().toString());
                    }
                    else {
                        //atualização do status na listagem lateral quando esta na aba de tickets não atendidos
                        setAux((prevState) => {
                            let newAux = [];
                            if (prevState) {
                                newAux = prevState.map((item) => {
                                    if (item && (item?.id === ticket?.id)) {
                                        item.status_id = ticket.status_id;
                                        // item.who_is_viewing = ticket.who_is_viewing;
                                        item.last_message = ticket.last_message;
                                        item.bold = ticket.bold;
                                        setForcedUpdate(true);
                                    }
                                    return item;
                                });
                                return newAux.filter((item) => {
                                    return item && (item?.status_id !== TicketStatus.Resolved)
                                });
                            } else {
                                return newAux.filter((item) => {
                                    return item && (item?.status_id !== TicketStatus.Resolved)
                                });
                            }
                        });
                    }
                    return param;
                });
                return order;
            })
        // }
    }

    // const onSocketUpdateTicketListNew = async (data: { ticket: { last_message_content: string; updated_level2_at: string | number | Date; status_id: string; is_bot: boolean; assigned_employee: { user_id: string; }; channel_id: string; sector_id: string; bold: boolean; id: string; who_is_viewing: string; last_message: string }, isNewTicket: boolean }, event?: string) => {
    //     console.log(data)
    //     if (event === "new_consumer_message" && data?.isNewTicket === true) {
    //         currentFilterDatas.unshift(data?.ticket);
    //     }
    //     // remove o ticket se ele estiver finalizado, excluído ou abandonado
    //     if ((data?.ticket?.status_id === TicketStatus.Abandoned || data?.ticket?.status_id === TicketStatus.Resolved || data?.ticket?.status_id === TicketStatus.Overdue) && props.isHistoryHome !== true && data?.ticket?.who_is_viewing !== null) {

    //         onSocketDeletetList(data?.ticket?.id);

    //         if (data?.ticket?.id === currentTicket?.id) {
    //             props.homeTicketsResetReturn();
    //         }
    //         // if(ticket.status_id === TicketStatus.Resolved){
    //         //     // setAux(currentFilterDatas)
    //         //     if (aux.length >= 2) {
    //         //         let indexNextTicket: number; //= aux.indexOf(currentTicket) + 1;
    //         //         aux.forEach((ticketAux, index) => {
    //         //             if (ticket.id === ticketAux.id) {
    //         //                 indexNextTicket = index + 1;
    //         //             }
    //         //             if (index === indexNextTicket) {
    //         //                 dispatch(setNextTicketId(ticketAux.id));
    //         //             }
    //         //         })

    //         //         // setCurrentTicket(aux[indexNextTicket]);
    //         //         getTicket({ id: values.internal.getNextTicketId }, {})
    //         //     } else {
    //         //         props.homeTicketsResetReturn();
    //         //     }
    //         // }
    //     } else {
    //         setCurrentOrderBy(order => {
    //             SetCurrentParams((param) => {
    //                 let dateCheck = new Date(data?.ticket?.updated_level2_at);
    //                 let dateStart = new Date(param["start_date"]);
    //                 let dateEnd = new Date(param["end_date"]);

    //                 dateStart = new Date(dateStart.getTime() + (1 * 86400000));
    //                 dateEnd = new Date(dateEnd.getTime() + (1 * 86400000));

    //                 if ((!param["status"] || data?.ticket?.status_id === param["status"]) &&
    //                     (!param["bot"] || data?.ticket?.is_bot === param["bot"]) &&
    //                     (!param["employee"] || (data?.ticket?.assigned_employee && data?.ticket?.assigned_employee.user_id === param["employee"])) &&
    //                     (!param["channel"] || data?.ticket?.channel_id === param["channel"]) &&
    //                     (!param["sector"] || data?.ticket?.sector_id === param["sector"]) &&
    //                     (!param["dateStart"] || (dateCheck >= dateStart && dateCheck <= dateEnd))) {
    //                     // setAux(currentFilterDatas)
    //                     setAux((prevState) => {
    //                         let newAux = [];
    //                         if (data?.ticket?.bold) {
    //                             let is_new = true;
    //                             if (prevState) {
    //                                 newAux = prevState.map((item) => {
    //                                     if (item && (item.id === data?.ticket.id) && item.bold === true) {
    //                                         item.bold = data?.ticket.bold;
    //                                         item.status_id = data?.ticket.status_id;
    //                                         item.last_message = data?.ticket.last_message;
    //                                         is_new = false;
    //                                     } else if (item && (item.id === data?.ticket.id) && item.bold === false) {
    //                                         item.bold = data?.ticket.bold;
    //                                         item.status_id = data?.ticket.status_id;
    //                                         item.last_message = data?.ticket.last_message;
    //                                         is_new = true;
    //                                     }
    //                                     return item;
    //                                 });

    //                                 if (is_new && data?.ticket.status_id !== TicketStatus.Resolved && data?.ticket.updated_level2_at) {
    //                                     // NotificationSoundConst.playSound();

    //                                     // dispatch(setUpdateButtonNumberOfTickets(true));
    //                                     newAux = newAux.filter((item) => {
    //                                         return item && (item?.id !== data?.ticket.id)
    //                                     })
    //                                     if (order === OrderBy.asc) {
    //                                         const concatArray = newAux.concat([ticket]).filter((item) => {
    //                                             return item && (item?.status_id !== TicketStatus.Resolved)
    //                                         });
    //                                         return concatArray;
    //                                     } else {
    //                                         // adiciona no inicio da listagem
    //                                         return [ticket, ...newAux].filter((item) => {
    //                                             return item && (item?.status_id !== TicketStatus.Resolved)
    //                                         });
    //                                     }


    //                                     // atualiza total de tickets                  
    //                                 } else {
    //                                     // dispatch(setUpdateButtonNumberOfTickets(true));
    //                                     return prevState.filter((item) => {
    //                                         return item && (item?.status_id !== TicketStatus.Resolved)
    //                                     });
    //                                 }
    //                             } else {
    //                                 return newAux.filter((item) => {
    //                                     return item && (item?.status_id !== TicketStatus.Resolved)
    //                                 });
    //                             }
    //                         } else {
    //                             //se o bold for removido atualiza a lista

    //                             if (prevState) {
    //                                 newAux = prevState.map((item) => {
    //                                     // setDisableEditTicket(false);
    //                                     // setOnlyRead(false);
    //                                     // setViewing(item.who_is_viewing);
    //                                     if (item && (item?.id === data?.ticket?.id)) {
    //                                         item.status_id = data?.ticket.status_id;
    //                                         item.who_is_viewing = data?.ticket.who_is_viewing;
    //                                         item.last_message = data?.ticket.last_message;
    //                                         item.bold = data?.ticket.bold;
    //                                         setForcedUpdate(true);

    //                                     }

    //                                     return item;
    //                                 });
    //                                 return newAux.filter((item) => {
    //                                     return item && (item?.status_id !== TicketStatus.Resolved)
    //                                 });
    //                             } else {
    //                                 return newAux.filter((item) => {
    //                                     return item && (item?.status_id !== TicketStatus.Resolved)
    //                                 });
    //                             }
    //                         }
    //                     });
    //                     // setBlockBool(false);
    //                     // setCurrentStatusPage("updating SOKET" + Date.now().toString());
    //                 }
    //                 return param;
    //             });
    //             return order;
    //         })
    //     }
    // }

    const onSocketDeletetList = async (ticket_ids: any) => {
        setAux((prevState) => {
            if (prevState) {
                //remove o ticket caso ele esteja na lista
                let newAux = prevState.filter((item: { id: string }) => {
                    return item && !ticket_ids.includes(item.id);
                })
                // atualiza total de tickets
                // dispatch(setUpdateButtonNumberOfTickets(true));
                // adiciona o ticket no começo da listagem
                setcurrentFilterDatas([...newAux]);

                return [...newAux];
            } else {
                return [];
            }
        });
    }

    /*************************** updated ticket is not filtered and not listed ******************************/

    /*useEffect(() => {
        if (nextTicket === null && aux.length >= 2) {
            setNextTicket(currentFilterDatas[1]);
        }
        nextTicketAfterUpdate();
    }, [currentFilterDatas && currentFilterDatas.length]);*/


    /*const nextTicketAfterUpdate = () => {
        let notIncludedTicket = true;
        if (currentFilterDatas !== undefined && currentTicket !== null) {
            currentFilterDatas.forEach((element: { id: Object; }) => {
                if (element.id === currentTicket.id)
                    notIncludedTicket = false;
            });
        }
        if (currentFilterDatas !== undefined && currentTicket !== null && notIncludedTicket/* !currentFilterDatas.includes(currentTicket) ) {
            if (currentFilterDatas.length >= 2 && nextTicket !== null) {
                setCurrentTicket(nextTicket);
                getTicket({ id: nextTicket.id }, {}).finally(() => {
                    defaultInputs();
                });

                if (props.resetCloseCreateTicketState)
                    props.resetCloseCreateTicketState();
            } else if (currentFilterDatas.length === 1) {
                setCurrentTicket(currentFilterDatas[0]);
                getTicket({ id: currentFilterDatas[0].id }, {}).finally(() => {
                    defaultInputs();
                });

                if (props.resetCloseCreateTicketState)
                    props.resetCloseCreateTicketState();
            } else {
                props.homeTicketsResetReturn();
            }
        } else if (currentTicketIsUpdated && (currentFilterDatas === undefined || currentFilterDatas.length === 0) && currentTicket !== null) {
            props.homeTicketsResetReturn();
            // empty list and ticket defined is inconsistent
        } else if ((currentFilterDatas === undefined || currentFilterDatas.length === 0) && (currentTicket /* && currentTicket.status_id === TicketStatus.Resolvido  )) {
            props.homeTicketsResetReturn();
        }

        if (currentTicketIsUpdated)
            setCurrentTicketIsUpdated(false);
    }
    */
    /*************************** updated ticket is not filtered and not listed ******************************/

    // get tickets to all, agent tickets, finalized or deleted
    const getAllTickets = async (params: { channel?: string; bot?: string; employee?: string; status?: string; date?: string; } & { limit: number; page: number; }, signal: AbortSignal) => {
        const headers = UserService.getHeaders()

        // assuming that all tickets are initially
        let urlGetTickets = "/ticket";

        let filterParams = params;
        // finalized or deleted tickets
        if (props.editTicket === false) {
            if (props.currentViewFilter === TicketFilterType.Finalized || props.currentViewFilter === TicketFilterType.Deleted) {
                urlGetTickets += "/historic";
                if (props.currentViewFilter === TicketFilterType.Deleted) {
                    filterParams = Object.assign({ deleted: true }, filterParams);
                }
            }
        }

        await AppRequesterConst.Get(
            urlGetTickets, { headers, params: filterParams, signal },
            (response: Object) => { },
            (data: GetAllTicketsInterface | TicketsHistoryInterface) => {
                if (data.status === 200 && data.data.tickets.length > 0) {
                    setAux((state) => {
                        if ((currentParams["employee"] !== "NA" && currentParams["status"] !== "daddbd66-1dd2-4008-94c9-7fcb9b1a5800") || updateNoAtendece) {
                            setcurrentFilterDatas(data.data.tickets);
                            setUpdateNoAtendece(false)
                            return data.data.tickets;
                        } else {
                            if(state === undefined) {
                                return listValidator([], data.data.tickets);
                            } else {
                                return listValidator(state, data.data.tickets);
                            }
                        }
                    });
                } else if (aux.length === 0) {
                    setcurrentFilterDatas(undefined);
                }

            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const blockDropdownChatComponent = (channel_id) => {
        switch (channel_id) {
            case Channels.Telefonia:
                setDefaultValue(t('home_tickets.messages_component.internal_note'))
                setDisabledDropdown(true)
                break;
            case Channels.Email:
                setDefaultValue(t('home_tickets.messages_component.email'))
                setDisabledDropdown(false)
                break;
            case Channels.Facebook:
                setDefaultValue(t('home_tickets.messages_component.facebook'))
                setDisabledDropdown(false)
                break;
            case Channels.WhatsApp:
                setDefaultValue(t('home_tickets.messages_component.whatsapp'))
                setDisabledDropdown(false)
                break;
            case Channels.Instagram:
                setDefaultValue(t('home_tickets.messages_component.instagram'))
                setDisabledDropdown(false)
                break;
            case Channels.Webchat:
                setDefaultValue(t('home_tickets.messages_component.webchat'))
                setDisabledDropdown(false)
            default:
                break;
        }
    }

    const getTicket = async (params: { id: string; }, signal: any) => {
        const headers = UserService.getHeaders()
        const options = Object.keys(signal).length !== 0 ? { headers, signal } : { headers };
        await AppRequesterConst.Get(
            '/ticket/' + params?.id, options,
            (response: Object) => { },
            (data: TicketsHistoryInterface | GetAllTicketsInterface) => {

                if (data.status === 200) {
                    if (data?.data?.tickets[0]?.who_is_viewing !== "" && data?.data?.tickets[0]?.who_is_viewing !== undefined && data?.data?.tickets[0]?.who_is_viewing !== null && isAgent && data?.data?.tickets[0]?.who_is_viewing?.trim() !== values.user.name.trim()) {
                        dispatch(setShowConfirmationModal({
                            visibility: true, text: {
                                "title": t("home_tickets.list_tickets.action_popover.agent_in_service"), "body": t("home_tickets.list_tickets.action_popover.the_ticket"), "id": `#${data?.data?.tickets[0]?.ticket_reference_id}`, "middleText": t("home_tickets.list_tickets.action_popover.he_is_being_accompanied_by_the_agent"), "warning": `${data?.data?.tickets[0]?.who_is_viewing}`, "end": t("home_tickets.list_tickets.action_popover.and_it_cannot_be_edited_at_the_moment"), "buttonConfirmationText": t("home_tickets.view_ticket.confirmation.blocked_ticket.confirm"), functionConfirmation: () => {
                                    ticket.setTicketId(null)
                                    ticket.setTicketReferenceId(null)
                                    ticket.setTicketWhoIsViewing(null)
                                }
                            }
                        }))
                    }
                                                                                                            // Caso o mesmo agente estiver com o olhinho no ticket, ele deve poder entrar novamente
                    if (data?.data?.tickets[0]?.who_is_viewing === null || (isAdmin || isOwner || isMaster) || ticket.getClickListTickets()) {
                    ticket.setClickListTickets(false)
                    // restaurando informações de backup; setando flag de load para false, somente recarrega o backup uma unica vez, na qual o componente é iniciado
                    if (props.ticketPreviewFormData && localStorage?.getItem('edit_ticket_data')?.length > 0 && values.getBlockTicketBackup === false) {
                        setTicketDataBackup(props.ticketPreviewFormData);
                        localStorage.setItem('edit_ticket_data', null);
                        dispatch(setBlockLoadTicketBackup(true));
                    } else {
                        setTicketDataBackup(null);
                    }

                        selectCurrentTicket(data.data.tickets[0]);
                    // if (data.data.tickets[0].id !== (currentTicket && currentTicket.id)) {
                    //     setSelected_ccEmails((data.data.tickets[0] && data.data.tickets[0].cc_list) ? data.data.tickets[0].cc_list : null);
                    //     setSelected_ccoEmails((data.data.tickets[0] && data.data.tickets[0].cco_list) ? data.data.tickets[0].cco_list : null);
                        // }

                    // essa verificação é feita na requisição do ticket/is-allowed-message no message component
                    /* if (data.data.tickets[0].consumer_last_message == null && data.data.tickets[0].channel_id === Channels.WhatsApp) {
                        block_ticket.setReadOnlyEditorComponent(true)
                    } else {
                        block_ticket.setReadOnlyEditorComponent(false)
                    } */


                    if (data.data.tickets[0].who_is_viewing !== null && data.data.tickets[0].who_is_viewing !== `${values.user.name} ${(values.user.lastname ? values.user.lastname : "")}`) {
                        setGoToTicket(true)
                        setForcedUpdate(true);
                        if (currentTicket.id !== currentClickId) {
                            setAux(aux => {
                                let newAux = [];
                                if (aux) {
                                    newAux = aux.map((item) => {
                                        if (currentTicket && item && (item.id === currentTicket.id)) {
                                            item.who_is_viewing = null;
                                        }

                                        return item;
                                    });
                                    return newAux;
                                }
                                return aux;
                            })
                        }
                    } else if (data.data.tickets[0].who_is_viewing === null) {
                        setGoToTicket(false)
                        setAux(aux => {
                            let newAux = [];
                            if (aux) {
                                // setForcedUpdate(true);
                                newAux = aux.map((item) => {
                                    if (currentTicket && item && (item.id === currentTicket.id)) {
                                        if (item.who_is_viewing === `${values.user.name} ${(values.user.lastname ? values.user.lastname : "")}`)
                                            item.who_is_viewing = null;
                                    }

                                    if (item && (item.id === data.data.tickets[0].id)) {
                                        item.who_is_viewing = `${values.user.name} ${(values.user.lastname ? values.user.lastname : "")}`
                                    }
                                    return item;
                                });
                                return newAux;
                            }
                            return aux;
                        })
                    }

                    setAudio(null)
                    setAudioBlob(null)

                    setCurrentStatus(status => {
                        if (status !== TicketStatus.Resolved)
                            setDisableSaveButton(false);

                        return status
                    })

                    if (currentFilterDatas?.length > 0) {
                        for (let i = 0; i < currentFilterDatas.length; i++) {
                            if (currentFilterDatas[i].id === params.id) {
                                currentFilterDatas[i].bold = false;
                                break;
                            }
                        }
                    }

                    setCustomFieldsGet(data?.data?.tickets[0]?.custom_fields);
                    // initializeTextareaStates(customFieldsGet);
                }
                }
            },
            (error: Object) => { },
            navigate, dispatch, setIsLoading, { values: values }
        );

        getAllCustomFields();
    }
    
    const updateCCandCCO = async () => {

        const emailCC = email.getEmailCC()
        const emailCCO = email.getEmailCCO()

        let JsonSend = {
            "id": currentTicket.id,
            "cc": emailCC ? emailCC : [],
            "cco": emailCCO ? emailCCO : []
        }

        const headers = UserService.getHeaders()

        await AppRequesterConst.Put(
            '/ticket/cc/cco', JsonSend, { headers },
            (response: Object) => {
                return response
            },
            (data: { data: { message: string } }) => {
                dispatch(setShowAlertFeedback({ message: data.data.message, visibility: true, signalIcon: true }))
                // email.setEmailCC([])
                // email.setEmailCCO([])
            },
            (error: { response: { status: number; data: { message: string } } }) => {
                if (error.response.status === 400) {
                    dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }))
                } else {
                    dispatch(setShowAlertFeedback({ message: t("errors.defaultErrorMessage"), visibility: true, signalIcon: false }))
                }
            }, navigate, dispatch, setIsLoading
        )

        await AppRequesterConst.Get(
            '/ticket/' + currentTicket?.id.toString(), { headers },
            (response: Object) => { },
            (data: GetAllTicketsInterface) => {
                if (data.status === 200 && data.data.tickets) {
                    setCurrentTicket(data.data.tickets[0])
                }
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, {
            values: values,
            finallyFunction: () => {
                setDisableSaveButton(false)
                if (currentStatus === TicketStatus.Resolved) {
                    setDisableSaveButton(true)
                }
            }
        })
    }

    // requisição dos agentes
    const getAgents = async (controller: any) => {
        const signal = controller.signal;

        const headers = UserService.getHeaders()

        const agents = AppRequesterConst.Get(
            '/agent', { headers, signal },
            (response: Object) => { },
            (data: UserInterface) => {
                let agentsIdsInDepartment = selectedDepartments.length > 0 && selectedDepartments[0].agents.length > 0? selectedDepartments[0].agents.map((agent) => { return agent.user_id }): [];

                let auxObj2 = [];
                if (data.status === 200 && data.data.employees) {
                    let users = [agentNotItem];
                    data.data.employees.forEach((item) => {
                        if (agentsIdsInDepartment.includes(item.user_id) || selectedDepartments.length === 0) {
                            users.push({ ...item, label: item?.name, value: item?.user_id, key: item?.user_id, hide: false });
                        }
                        
                        auxObj2.push({ name: item?.name + (item?.lastname ? (" " + item?.lastname) : ""), id: item?.user_id });
                    });
                    
                    setAgentsOptions(users);
                }
    
                setFollowers(auxObj2);
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );

        return agents;
    } 

    const getInformations = async (controller: any) => {
        const signal = controller.signal;

        const headers = UserService.getHeaders()

        const getSector = AppRequesterConst.Get(
            '/sector/actives/', { headers, params: { companyid: values.company.id }, signal },
            (response: Object) => { },
            (data: SectorActiveInterface) => {
                if (data.data.sectors) {
                    setAllDepartments(data.data.sectors);
                }
            }, (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        )

        const getUsers = getAgents(controller);

        const getConsumers = AppRequesterConst.Get(
            '/consumer', { headers, signal },
            (response: Object) => { },
            (data: /* ConsumerInterface */UserInterface) => {
                /* setConsumers(data.data.users);
                setInitialConsumers(data.data.users); */
                if (data.status === 200 && data.data.consumers) {
                    let users = [consumerCreateItem];
                    data.data.consumers.forEach((item) => {
                        users.push({ ...item, label: item?.name, value: item?.user_id, key: item?.user_id });
                    });
                    // setAgentsOptions((agents) => {
                    //     setConsumersOptions([...users, ...agents]);
                    //     return agents;
                    // });
                    setConsumersOptions(users);
                }
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );

        const getTags = AppRequesterConst.Get(
            '/tag?active=true&limit=1000', { headers, signal },
            (response: Object) => { },
            (data: { data: { tags: { name: string, id: string }[] }, status: number }) => {
                let auxObj = [];
                if (data.status === 200) {
                    data.data.tags.forEach((element) => {
                        auxObj.push({ name: element.name, id: element.id });
                    });
                }

                setTags(auxObj);
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );

        return Promise.all([getSector, getUsers, getConsumers, getTags]);
    }

    const defaultInputs = async () => {
        // backup é setado dentro de getTicket, caso não seja necessário mais usalo ele será null
        setTicketDataBackup((backup) => {
            setCurrentTicket((ticket: { requester_user: { user_id: string; name: string; }; }) => {
                setConsumersOptions((consumers) => {
                    if (backup?.requester_user_id) {
                        let finded = consumers.find((item) => item?.value === backup?.requester_user_id);

                        if (finded) {
                            /* setSelectedConsumers([finded]);
                            setCurrentRequester_user(finded); */
                            addRequesterNotInOptions(Object.assign(finded, { user_id: finded.value }));
                        } else {
                            let requesterNotInList = backup?.requester_user;
                            addRequesterNotInOptions(requesterNotInList);
                        }
                    } else if (ticket && ticket?.requester_user && consumers !== undefined) {
                        let finded = consumers?.find((item) => item?.value === ticket?.requester_user?.user_id);
                        if (finded) {
                            let requesterNotInList
                            if (ticket !== null && ticket?.requester_user !== null && finded.value === ticket?.requester_user?.user_id) {
                                requesterNotInList = Object.assign(ticket.requester_user, { value: finded.value, label: ticket.requester_user.name, key: ticket.requester_user.user_id });
                                // selectRequester([finded]);
                                setSelectedConsumers([finded]);
                                setCurrentRequester_user(finded);
                                // addRequesterNotInOptions(requesterNotInList);
                            }
                        } else {
                            let requesterNotInList = Object.assign(ticket?.requester_user, { value: ticket?.requester_user?.user_id, label: ticket?.requester_user?.name, key: ticket?.requester_user?.user_id });

                            // addRequesterNotInOptions(requesterNotInList);
                            setSelectedConsumers([requesterNotInList]);
                            setCurrentRequester_user(requesterNotInList);

                            /* selectRequester([requesterNotInList]);
                            setCurrentRequester_user(requesterNotInList); */
                        }
                    } else if (ticket && ticket.requester_user === null) {
                        // selectRequester([]);
                        setSelectedConsumers([]);
                        setCurrentRequester_user(null);
                    }
                    return consumers;
                });
                return ticket;
            });

            setAllDepartments((state: { name: string; id: string; }[]) => {
                if (state !== null) {
                    if (state) {
                        let departments = [];
                        state.forEach((department: { name: string; id: string; agents: any[]; }) => {
                            departments.push({ label: department.name, value: department.id, agents: department.agents });
                        });

                        // add current department when inactive
                        setCurrentTicket((ticket: { sector: { active: boolean, name: string, id: string, agents: any[] } }) => {
                            if (ticket) {
                                let department = ticket.sector;
                                if (department && !department.active)
                                    departments.unshift({ label: department.name, value: department.id, agents: department.agents });
                            }
                            return ticket;
                        });

                        setDepartmentsOptions(departments);
                    }
                }
            });

            setCurrentTicket((ticket: { assigned_employee: { user_id: React.SetStateAction<string>; name: string; lastname: string; }; followers: any[]; tags: React.SetStateAction<any[]>; subject: React.SetStateAction<string>; sector: { id: string; name: string; }; requester_user: any }) => {
                setCurrentTags([]);
                setCurrentFollowers([]);
                if (ticket !== null) {
                    // atribuido
                    if (backup?.assigned_employee_user_id) {
                        setAgentsOptions((agents) => {
                            let agent = agents.filter(ag => ag.value == backup.assigned_employee_user_id);

                            if (agent) {
                                selectAssigned(agent);
                                setCurrentAssigned_employee_user_id(backup.assigned_employee_user_id);
                            } else if (ticket.assigned_employee) {
                                let assignedNotInList = backup.assigned_employee;
                                selectAssigned([assignedNotInList]);
                                setCurrentAssigned_employee_user_id(backup.assigned_employee_user_id);
                            }

                            return agents;
                        });
                    } else if (ticket.assigned_employee) {
                        setAgentsOptions((agents) => {
                            let findTicketAgent = agents.find((item) => item.value === ticket.assigned_employee.user_id);
                            if (agents && findTicketAgent) {
                                agents.forEach((agent) => {
                                    if (ticket.assigned_employee !== null && agent.value === ticket.assigned_employee.user_id) {
                                        selectAssigned([agent]);
                                        setCurrentAssigned_employee_user_id(ticket.assigned_employee.user_id);
                                    }
                                });
                            } else {
                                let assignedNotInList = Object.assign(ticket.assigned_employee, { value: ticket.assigned_employee.user_id, label: ticket.assigned_employee.name + (ticket.assigned_employee.lastname ? (" " + ticket.assigned_employee.lastname) : "") });
                                selectAssigned([assignedNotInList]);
                                setCurrentAssigned_employee_user_id(ticket.assigned_employee.user_id);
                            }
                            return agents;
                        });
                    } else if (ticket && ticket.assigned_employee === null) {
                        setSelectedAgents([]);
                        setCurrentAssigned_employee_user_id("");
                    }

                    /* if (ticket.requester_user) {
                        setConsumersOptions((consumers) => {
                            // if (ticket && ticket.requester_user && consumers !== undefined) {
                            let finded = consumers.find((item) => item.value === ticket.requester_user.user_id);
                            if (finded) {
                                consumers.forEach((element) => {
                                    if (ticket !== null && ticket.requester_user !== null && element.value === ticket.requester_user.user_id) {
                                        // setSelectedConsumers([element]);
                                        homeTicketsViewTicketControllerConst.selectRequester([element], true);
                                        setCurrentRequester_user(element);
                                    }
                                });
                            } else {
                                let requesterNotInList = Object.assign(ticket.requester_user, { value: ticket.requester_user.user_id, label: ticket.requester_user.name, key: ticket.requester_user.user_id });

                                addRequesterNotInOptions(requesterNotInList);

                                //selectRequester([requesterNotInList]);
                                //setCurrentRequester_user(requesterNotInList); 
                            }
                            // } 
                            return consumers;
                        });
                    } else if (ticket && ticket.requester_user === null) {
                        // selectRequester([]);
                        setSelectedConsumers([]);
                        setCurrentRequester_user(null);
                    } */

                    // seguidores
                    if (backup?.followers) {
                        setCurrentFollowers(backup.followers);
                    } else if (ticket.followers != null && ticket.followers[0] != null) {
                        let auxObj = [];
                        ticket.followers.forEach((element) => {
                            auxObj.push({ name: element.name + (element.lastname ? (" " + element.lastname) : ""), id: element.user_id });
                        });
                        setCurrentFollowers(auxObj);
                    } else {
                        setCurrentFollowers([]);
                    }

                    // tags
                    if (backup?.tags) {
                        setCurrentTags(backup.tags);
                    } else if (ticket.tags && ticket.tags.length > 0) {
                        setCurrentTags(ticket.tags);
                    } else {
                        setCurrentTags([]);
                    }

                    // assunto do ticket
                    if (backup?.subject) {
                        setCurrentSubject(backup.subject);
                    } else {
                        if (ticket.subject != null) {
                            setCurrentSubject(ticket.subject);
                        }
                    }

                    if (backup?.sector_id) {
                        setDepartmentsOptions(deps => {
                            let findedDepartment = deps.find((item) => item.value === backup.sector_id/*  && item.label === ticket.sector.name */);

                            if (findedDepartment) {
                                setSelectedDepartments([findedDepartment]);
                            }

                            return deps;
                        })
                    } else if (ticket.sector != null) {
                        setDepartmentsOptions(state2 => {
                            let findedDepartment = state2.find((item) => item.value === ticket.sector.id && item.label === ticket.sector.name);

                            if (findedDepartment) {
                                setSelectedDepartments(state2.filter(elem => elem.label === ticket.sector.name && elem.value === ticket.sector.id));
                            } else {
                                //let requestedDepartment = null;
                                // const headers = {
                                //     "Content-Type": "application/json; charset=utf-8",
                                //     "Authorization": "Bearer " + values.token.value
                                // };

                                //const headers = UserService.getHeaders()

                                /*const dept = AppRequesterConst.Get(
                                    '/sector/' + ticket.sector.id, { headers },
                                    (response: Object) => { },
                                    (data: SectorInterface) => {
                                        if (data.status === 200) {
                                            requestedDepartment = data.data[0];
                                            if (requestedDepartment) {
                                                setSelectedDepartments([Object.assign(requestedDepartment, { value: requestedDepartment.sector_id, label: requestedDepartment.sector_name })]);
                                            }
                                        }
                                    },
                                    (error: Object) => { }, navigate, dispatch, setIsLoading
                                )*/
                            }

                            return state2;
                        })

                    } else {
                        setSelectedDepartments([]);
                    }
                }
                return ticket;
            });

            // selecionando status
            constsApi.status.forEach((element) => {
                if (backup?.status_id) {
                    if (backup.status_id === element.id){
                        let label = '';
                        if (element.name === 'Aberto') {
                            label = t("ticket_status.open");
                        } else if (element.name === 'Em espera') {
                            label = t("ticket_status.on_hold");
                        } else if (element.name === 'Pendente') {
                            label = t("ticket_status.pending");
                        } else if (element.name === 'Resolvido') {
                            label = t("ticket_status.resolved");
                        } else if (element.name === 'Novo') {
                            label = t("ticket_status.new");
                        } else if (element.name === 'Abandonado') {
                            label = t("ticket_status.abandoned");
                        } 
                        selectStatus([{ value: element?.id, label: label, key: element?.id }]);
                    }
                } else {
                    setCurrentTicket((state: { status_id: string; }) => {
                        if (state !== null) {
                            if (element?.id === state?.status_id) {
                                /* document.getElementById("select_status").innerHTML = "<span style='align-items:center; display:flex;'>" + element.name + "</span>";
                                document.getElementById("select_status").className = "dropdownButtonTicketsActive dropdown-toggle btn btn-primary"; */
                                let label = '';
                                if (element.name === 'Aberto') {
                                    label = t("ticket_status.open");
                                } else if (element.name === 'Em espera') {
                                    label = t("ticket_status.on_hold");
                                } else if (element.name === 'Pendente') {
                                    label = t("ticket_status.pending");
                                } else if (element.name === 'Resolvido') {
                                    label = t("ticket_status.resolved");
                                } else if (element.name === 'Novo') {
                                    label = t("ticket_status.new");
                                } else if (element.name === 'Abandonado') {
                                    label = t("ticket_status.abandoned");
                                } 
                                selectStatus([{ value: element?.id, label: label, key: element?.id }]);
                                // setCurrentStatus(element.name);
                            }
                        }
                        return state;
                    });
                }
            });

            return backup;
        });
    }

    const updateTicket = async () => {
        setDisableSaveButton(true);

        // verificando se existe algum valor com true no object de errors
        const indexHasError = Object.values(customFieldIdErrors).indexOf(true);

        if (!currentSubject) {
            dispatch(setShowAlertFeedback({ visibility: true, message: t("home_tickets.home_create_ticket.alert_feedback.ticket_without_subject"), signalIcon: false }));
            setDisableSaveButton(false);
        } else if (indexHasError > -1) {
            dispatch(setShowAlertFeedback({ visibility: true, message: t("home_tickets.custom_fields_for_forms.save_ticket_without_required_field"), signalIcon: false }));
            setDisableSaveButton(false);
        } else {
            /******set next ticket id********/
            if (aux?.length >= 2) {
                let indexNextTicket: number;
                aux.forEach((ticketAux, index) => {
                    if (currentTicket.id === ticketAux.id) {
                        indexNextTicket = index + 1;
                    }
                })
                // current ticket == last ticket
                if (indexNextTicket === aux.length && aux.length >= 2) {
                    // next ticket == back ticket
                    indexNextTicket = aux.length - 2;
                }
                if (indexNextTicket >= 0 && indexNextTicket < aux.length) {
                    setNextTicket(aux[indexNextTicket]);
                } else {
                    setNextTicket(0);
                }
            } else {
                setNextTicket(null);
            }
            setCurrentTicketIsUpdated(true);
            /******set next ticket id********/

            let datasConverted = { status: '' };

            constsApi.status.forEach((element) => {
                if (element.id === currentStatus) {
                    datasConverted.status = element.id;
                }
            });

            const JsonSend = {
                "id": currentTicket.id,
                //"subject": currentTicket.subject,
                "subject": currentSubject,
            };

            /* if (currentRequester_user !== '' && currentRequester_user !== undefined && currentRequester_user !== null && currentRequester_user.user_id === undefined && currentRequester_user.email) {
                JsonSend['consumer_name'] = currentRequester_user.name;
                JsonSend['consumer_email'] = currentRequester_user.email;
            } else  */
            if (currentRequester_user !== '' && currentRequester_user !== undefined && currentRequester_user !== null) {
                JsonSend['requester_user_id'] = currentRequester_user.user_id;//.id;
            }

            if (currentAssigned_employee_user_id !== 'NA' && currentAssigned_employee_user_id !== '' && currentAssigned_employee_user_id !== undefined && currentAssigned_employee_user_id !== null) {
                JsonSend['assigned_employee_user_id'] = currentAssigned_employee_user_id;
            }

            if (currentFollowers.length !== 0) {
                if (currentFollowers[0] !== null) {
                    let auxArray = [];
                    currentFollowers.forEach((element) => {
                        auxArray.push(element.id);
                    });
                    JsonSend['followers_id'] = auxArray;
                }
            }

            if (datasConverted.status !== '') {
                JsonSend['status_id'] = datasConverted.status;
            }


            if (currentTags.length !== 0) {
                if (currentTags[0] !== undefined) {
                    let auxArray = [];
                    currentTags.forEach((element) => {
                        auxArray.push(element.id);
                    });
                    JsonSend['tags'] = auxArray;
                }
            }

            if (selectedDepartments.length > 0) {
                JsonSend['sector_id'] = selectedDepartments[0].value;
            }

            if (customFieldsIds.length !== 0) {
                JsonSend["custom_fields"] = []
                customFieldsIds.map((customFieldId) => {
                    JsonSend["custom_fields"].push({ custom_field_id: customFieldId, value: textareaStates[customFieldId] ? [textareaStates[customFieldId]] : [] })
                })
            }

            const headers = UserService.getHeaders()

            await AppRequesterConst.Put(
                '/ticket', JsonSend, { headers },
                (response: Object) => { },
                (data: GetAllTicketsInterface) => {
                    dispatch(setShowAlertFeedback({ message: t("home_tickets.view_ticket.alert_feedback.success"), visibility: true, signalIcon: true }));
                    
                    if (props.currentViewFilter === TicketFilterType.Finalized || props.currentViewFilter === TicketFilterType.Deleted) {
                        navigate(constsRouters.routers.tickets.path)
                    }
                    
                    if (props.resetCloseCreateTicketState) {
                        props.resetCloseCreateTicketState();
                    }
                },
                (error: { response: { status: number, data: { message: string, code_cxpress: number } } }) => {
                    if (error.response !== undefined) {
                        if (error.response.status === 400) {
                            if(error.response.data.code_cxpress) {
                                if(error.response.data.code_cxpress === 703) {
                                    dispatch(setShowAlertFeedback({ message: t("home_tickets.view_ticket.error400.code703"), visibility: true, signalIcon: false }));
                                } else if(error.response.data.code_cxpress === 726) {
                                    dispatch(setShowAlertFeedback({ message: t("home_tickets.view_ticket.error400.code726"), visibility: true, signalIcon: false }));
                                } else if(error.response.data.code_cxpress === 739) {
                                    dispatch(setShowAlertFeedback({ message: t("home_tickets.view_ticket.error400.code739"), visibility: true, signalIcon: false }));
                                }
                            } else {
                                dispatch(setShowAlertFeedback({ message: t("errors.defaultErrorMessage"), visibility: true, signalIcon: false }));
                            }
                        }
                        if (error.response.status === 401) {

                        }
                        if (error.response.status === 403) {

                        }
                        if (error.response.status === 422) {
                            dispatch(setShowAlertFeedback({ message: t("home_tickets.view_ticket.alert_feedback.without_subject"), visibility: true, signalIcon: false }));
                        }
                        if (error.response.status === 500) {
                            dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }));
                        }
                    }
                },
                navigate, dispatch, setIsLoading,
                () => {
                    // setAux([]);

                    if (document.getElementById("listOfTickets") !== null) {
                        document.getElementById("listOfTickets").scrollTop = 0;
                    }

                    SetCurrentPage((state) => {
                        setCurrentStatusPage("updating" + Date.now().toString());
                        return 1;
                    });

                    if (JsonSend['status_id'] === TicketStatus.Resolved) {
                        // dispatch(setUpdateButtonNumberOfTickets(true));
                    }
                },
            );

            await AppRequesterConst.Get(
                '/ticket/' + (values.internal.getNextTicketId !== '' ? values.internal?.getNextTicketId : currentTicket?.id.toString()), { headers },
                (response: Object) => { },
                (data) => {
                    if (data.status === 200 && data.data.tickets) {
                        if (data.data.tickets[0].assigned_employee) {
                            let assignedNotInList = Object.assign(data.data.tickets[0].assigned_employee, { value: data.data.tickets[0].assigned_employee.user_id, label: data.data.tickets[0].assigned_employee.name + (data.data.tickets[0].assigned_employee.lastname ? (" " + data.data.tickets[0].assigned_employee.lastname) : "") });
                            selectAssigned([assignedNotInList]);
                        }
                        setCurrentTicket(data.data.tickets[0]);
                        dispatch(setNextTicketId(''));
                    }
                },
                (error: Object) => { },
                navigate, dispatch, setIsLoading,
                {
                    values: values,
                    finallyFunction: () => {
                        setDisableSaveButton(false);
                        if (currentStatus === TicketStatus.Resolved)
                            setDisableSaveButton(true);
                    }
                }
            );

            // close update ticket status on finalized tickets view
            if (currentStatus !== TicketStatus.Resolved && props.currentViewFilter && props.currentViewFilter === TicketFilterType.Finalized) {
                props.saveFinalizedClick();
            }

            setShowContent(false)
        }
    }

    const deleteSelectedTicket = async () => {
        const headers = UserService.getHeaders()

        const JsonSend = {
            "id": currentTicket?.id,
        };

        try {
            await AppRequesterConst.Delete(
                '/ticket/' + JsonSend['id'], { headers },
                (response: Object) => { },
                (data: GetAllTicketsInterface) => {
                    dispatch(setShowAlertFeedback({ message: t("home_tickets.view_ticket.alert_feedback.success_deleted"), visibility: true, signalIcon: true }));

                    if (props.resetCloseCreateTicketState)
                        props.resetCloseCreateTicketState();
                },
                (error: { response: { status: number; data: { message: any[]; code_cxpress: number } } }) => {
                    if (error.response.status === 403 && error.response.data.code_cxpress === 726) {
                        dispatch(setShowAlertFeedback({ message: t("home_tickets.list_tickets.alert_feedback.master_delete_ticket"), visibility: true, signalIcon: false }));
                    }
                    throw new Error('Error deleting ticket');
                },
                navigate, dispatch, setIsLoading,
                () => {
                    if (aux.length > 1) {
                        setAux([]);
                        SetCurrentPage(1);
                        setCurrentStatusPage("deleting" + Date.now().toString());
                    }
                },
            );

            if (aux.length >= 2) {
                let indexNextTicket: number;
                aux.forEach((ticketAux, index) => {
                    if (currentTicket.id === ticketAux.id) {
                        indexNextTicket = index + 1;
                    }
                })
                if (indexNextTicket === aux.length && aux.length >= 2) {
                    indexNextTicket = aux.length - 2;
                }

                if (indexNextTicket >= 0 && indexNextTicket < aux.length) {
                    setNextTicket(aux[indexNextTicket]);
                } else {
                    setNextTicket(0);
                }

                setCurrentTicket(aux[indexNextTicket]);

                getTicket({ id: aux[indexNextTicket].id }, {}).finally(() => {
                    defaultInputs();
                });
            } else {
                props.homeTicketsResetReturn();
            }
        } catch (error) {
            console.error('Error deleting ticket:', error);
        }
    }

    const showEditConsumerButton = async (requesterId: string) => {
        const headers = UserService.getHeaders()

        await AppRequesterConst.Get(
            '/consumer/' + requesterId, { headers },
            (response: Object) => { },
            (data: ConsumerInterface) => {
                if (data.status === 200 && data.data.consumers != null) {
                    setCurrentConsumersData(data.data.consumers[0]);
                } else {
                    setCurrentConsumersData(null);
                }
                dispatch(setShowEditConsumersModal(true));
            },
            (error: Object) => { },
            navigate, dispatch, setIsLoading,
            { values: values }
        );
    }

    const addRequesterNotInOptions = async (selected: { user_id: string; value?: string; key?: string; name?: string }) => {
        const headers = UserService.getHeaders();

        let user: { name: string; lastname: string; user_id: string; key?: string; id?: string; confirmation_code?: string; };
        await AppRequesterConst.Get(
            '/consumer/' + selected?.user_id, { headers },
            (response: Object) => { },
            (data: UserInterface) => {
                if (data.status === 200 && data.data.consumers != null) {
                    user = data.data.consumers[0];
                    let requester = Object.assign(user, { value: user?.user_id, label: user?.name, key: user?.user_id });

                    selectRequester([requester], true);
                    setCurrentRequester_user(requester);
                } else if (selected !== null && selected !== undefined) { // consumidor não foi encontrado mas continua dentro do ticket, então deve ser selecionado e escondido o botão de editar
                    let requester = Object.assign(selected, { value: selected.user_id, label: selected.name, key: selected.user_id });
                    setSelectedConsumers([requester]);
                    setCurrentRequester_user(requester);
                    Utils.changeStyleElementById('editConsumerButtonId', 'display', 'none');
                }

            },
            (error: Object) => { },
            navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const multiselectFilterDepartments = async (options: Option[], filter: Object) => {
        const headers = UserService.getHeaders()

        const params = {
            "search": filter,
            "companyid": values.company.id
        }

        if (!filter) {
            return options;
        } else {
            let departments = [];
            await AppRequesterConst.Get(
                '/sector/actives/', { headers, params: params },
                (response: Object) => { },
                (data: SectorActiveInterface) => {
                    if (data.status === 200 && data.data.sectors) {
                        data.data.sectors.forEach((item: { name: string; id: string; }) => {
                            departments.push({ ...item, label: item.name, value: item.id });
                        });
                    }
                }, (error: Object) => { }, navigate, dispatch, () => { }, { values: values }
            );
            return departments;
        }
    }

    const multiselectFilterAgents = async (options: Option[], filter: Object) => {
        const headers = UserService.getHeaders()

        const params = {
            "search": filter,
        }

        if (!filter) {
            return options;
        } else {
            let agents = [agentNotItem];
            await AppRequesterConst.Get(
                '/agent', { headers, params },
                (response: Object) => { },
                (data: UserInterface) => {
                    // user ids dos agentes do setor selecionado
                    let agentsIdsInDepartment = selectedDepartments.length > 0 && selectedDepartments[0].agents.length > 0? selectedDepartments[0].agents.map((agent) => { return agent.user_id }): [];

                    if (((data.status === 200 && (values.user.profile_id === constsApi.profile[0].id)) || (values.user.profile_id === constsApi.profile[2].id) || (values.user.profile_id === constsApi.profile[3].id))) {
                        if (data.data.employees) {
                            data.data.employees.forEach((item: any) => {
                                if (agentsIdsInDepartment.includes(item.user_id) || selectedDepartments.length === 0)
                                    agents.push({ ...item, label: item.name, value: item.user_id });
                            });
                        }
                    } else {
                        if (data.data.employees) {
                            data.data.employees.filter((elem: { user_id: string; }) => elem.user_id === values.user.id).forEach((item: any) => {
                                if (agentsIdsInDepartment.includes(item.user_id) || selectedDepartments.length === 0)
                                    agents.push({ ...item, label: item.name, value: item.user_id });
                            });
                        }
                    }
                },
                (error: Object) => { }, navigate, dispatch, () => { }, { values: values }
            );

            return agents;
        }
    }

    const selectFollowers = (value) => {
        setCurrentFollowers(value);
    }

    const selectTags = (value) => {
        setCurrentTags(value);
    }

    const multiselectFilterUsers = async (options: Option[], filter: Object) => {
        const headers = UserService.getHeaders()

        const params = {
            "search": filter,
        }

        if (!filter) {
            return options;
        } else {
            let users = [consumerCreateItem];
            await AppRequesterConst.Get(
                '/consumer', { headers, params: params },
                (response: Object) => { },
                (data: UserInterface) => {
                    if (data.status === 200 && data.data.consumers) {
                        data.data.consumers.forEach((item: any) => {
                            users.push({ ...item, label: item.name, value: item.user_id });
                        });
                    }
                },
                (error: Object) => { }, navigate, dispatch, null, { values: values }
            );

            return users;
        }
    }

    const getLastUpdatedDate = (currentTicket) => {
        let date = new Date();

        if (currentTicket !== null) {
            if (currentTicket.deleted_at !== null) {
                date = new Date(currentTicket.deleted_at);
            } else if (currentTicket.ended_at !== null) {
                date = new Date(currentTicket.ended_at);
            } else if (currentTicket?.updated_level2_at !== null) {
                date = new Date(currentTicket?.updated_level2_at);
            } else if (currentTicket.updated_at !== null) {
                date = new Date(currentTicket.updated_at);
            } else {
                date = new Date(currentTicket.created_at);
            }
        }

        setCurrentLastUpdatedDate(date);
    }

    const onSearchFollowers = (value: string) => {
        const headers = UserService.getHeaders();
        const signal = abortConst.signal;

        let params = { search: value };

        if (value.length === 0)
            params.search = null;

        const getUsers = AppRequesterConst.Get(
            '/agent', { headers, params: params, signal },
            (response: Object) => { },
            (data: AgentInterface) => {
                let searchedFollowers = [];
                if (data.status === 200 && data.data.employees) {
                    data.data.employees.forEach((item) => {
                        searchedFollowers.push({ name: item?.name + (item?.lastname ? (" " + item?.lastname) : ""), id: item?.user_id, key: item?.email });
                    });
                }

                setFollowers(searchedFollowers);
            },
            (error) => { console.log("Error", error.response); }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const toggleContent = () => {
        setShowContent(prevState => !prevState)
        const controller = new AbortController()
        getTicket({ id: currentTicket?.id }, controller.signal)
        getAllCustomFields()
    };

    const initializeTextareaStates = (customFields) => {
        const initialState = {};

        customFields.forEach((customField) => {
            customFieldsGet?.map((customFieldGet) => {
                if (customFieldGet.custom_field_id === customField.id) {
                    initialState[customField.id] = customFieldGet.value[0]

                    // verificando se os campos obrigatórios estão preenchidos inicialmente
                    setCustomFieldIdErrors((errors) => {
                        if (customFieldGet.value[0]?.length > 0)
                            errors[customField.id] = false;
                        else if (customField.required)
                            errors[customField.id] = true;
                        else
                            errors[customField.id] = false;
                        return errors;
                    });
                }
            })

            // como não existe nenhum valor então cada campo fica com erro
            if (customFieldsGet?.length === 0) {
                setCustomFieldIdErrors((errors) => {
                    errors[customField.id] = customField.required;
                    return errors;
                });
            }
        });
        setTextareaStates(initialState);
    };

    const getAllCustomFields = async () => {
        const headers = UserService.getHeaders()

        await AppRequesterConst.Get(
            `/custom-fields`, { headers: headers },
            (response: Object) => { },
            (data: any) => {
                if (data?.status === 200 && data) {
                    let array = [];
                    let errors = {};
                    for (let index = 0; index < data.data?.custom_fields?.length; index++) {
                        if (data?.data?.custom_fields[index]?.entity_type_id === entityId) {
                            array.push({ name: data.data.custom_fields[index]?.name, type: data.data.custom_fields[index]?.custom_field_type_id, id: data.data.custom_fields[index]?.id, placeholder: data.data.custom_fields[index]?.placeholder, required: data.data.custom_fields[index]?.required });
                            errors[data?.data?.custom_fields[index].id] = false;
                        }
                    }

                    setCustomFields(array);
                    // array com os booleanos para erro
                    setCustomFieldIdErrors(errors);
                    setCustomFieldsIds((fieldIds) => {
                        fieldIds = array.map((field) => {
                            return field.id;
                        });
                        return fieldIds;
                    });
                }
            },
            (error: { response: { status: number } }) => {
                if (error?.response?.status === 400) {

                }
                if (error?.response?.status === 401) {

                }
            }, navigate, dispatch, setIsLoading, { values: values }
        )
    }

    const changeCustomField = (e, customField) => {
        if (customField.type === "2982a738-4de3-440a-8ed1-cc1fdb51bc9b" || customField.type === "d31ebd9e-af9f-4559-b04a-729439218a6f") {
            setTextareaStates((prevState) => ({
                ...prevState,
                [customField.id]: e.target.value,
            }));

            // ids dos custom fields sendo coletados
            /* if (!customFieldsIds.includes(customField.id)) {
                setCustomFieldsIds((prevCustomFieldId) => [...prevCustomFieldId, customField.id]);
            } */

            // validação de erros para campos customizados
            setCustomFieldIdErrors((errors) => {
                if (e.target.value?.length > 0)
                    errors[customField.id] = false;
                else if (customField.required)
                    errors[customField.id] = true;
                else 
                    errors[customField.id] = false;
                return errors;
            });
        }
    }

    if (props.onLoadingViewTicket) {
        props.onLoadingViewTicket()
    }

    const resetOverlayIdOnScroll = () => {
        if (overlayId !== null) {
            setOverlayId(null)
        }
    }

    const getAllTicketsToPagination = (params: { channel?: string; bot?: string; employee?: string; status?: string; date?: string; } & { limit: number; page: number; }, forcedUpdate: boolean, controller: AbortController) => {
        const signal = controller.signal;

        if (props.getList === undefined) {
            getAllTickets(params, signal).finally(() => {
                setIsLoading(false);
                if (currentTicket === null) {
                    if (props.currentTicket === null) {
                        if(ticket.getTicketId) {
                            setAux((state) => {
                                getTicket({ id: ticket.getTicketId() }, signal).finally(() => {
                                    defaultInputs();
                                });
    
                                return state;
                            })
                        }else {
                            setAux((state) => {
                                getTicket({ id: state[0]?.id }, signal).finally(() => {
                                    defaultInputs();
                                });
    
                                return state;
                            })
                        }
                    } else {
                        // esse trecho poderia ocasionar em um erro na requisição e não ser executado devidamente no primeiro if. Já no else não executaria porque esta passando object para um parameto que seria uma string
                        if (props?.currentTicket?.id) {
                            getTicket({ id: props.currentTicket.id.split("*ID*")[0].split("*who_is_viewing*")[0] }, signal).finally(() => {
                                defaultInputs();
                            });
                        } else {
                            getTicket({ id: props.currentTicket }, signal).finally(() => {
                                defaultInputs();
                            });
                        }
                    }
                    // if (!forcedUpdate)
                    //     setForcedUpdate(true);
                } else {
                    if (forcedUpdate)
                        setForcedUpdate(false);
                }
                // dispatch(setUpdateButtonNumberOfTickets(true));
            });
        } else {
            props.getList(params, setcurrentFilterDatas, aux, setAux).finally(() => {
                setIsLoading(false);
                if (currentTicket === null) {
                    if (props.currentTicket === null) {
                        setAux((state) => {
                            getTicket({ id: state[0].id }, signal).finally(() => {
                                defaultInputs();
                            });

                            return state;
                        })

                    } else {
                        if (props.currentTicket.id) {
                            getTicket({ id: props.currentTicket.id.split("*ID*")[0].split("*who_is_viewing*")[0] }, signal).finally(() => {
                                defaultInputs();
                            });
                        } else if (props.currentTicket) {
                            getTicket({ id: props.currentTicket }, signal).finally(() => {
                                defaultInputs();
                            });
                        }
                    }

                }
            });
        }
    }

    const listValidator = (state: any[], tickets: Array<TicketInterface>) => {
        let aux2 = [];

        for (let i = 0; i < tickets.length; i++) {
            let validador = true;
            for (let j = 0; j < state.length; j++) {
                if ((state[j].id === tickets[i].id)) {
                    validador = false;
                }
            }

            if (validador) {
                aux2.push(tickets[i]);
            }
        }

        let concatArrays = state.concat(aux2);
        setcurrentFilterDatas(concatArrays);
        return concatArrays;
    }

    const onClickButtonAccept = () => {
        // procurando agente na lista de agentes do setor
        let agentInDepartment = selectedDepartments?.length > 0 && selectedDepartments[0]?.agents?.length > 0? selectedDepartments[0].agents.find((agent) => agent.user_id === userId): null;

        // se o agente aceito não estiver no setor, então o setor é removido
        if (!agentInDepartment) {
            setSelectedDepartments([]);
        }

        setSelectedDepartments((dep) => {
            setAgentsOptions((agents) => {
                let findedAgent = agents.find((agent) => agent.value === userId);

                if (findedAgent) {
                    setSelectedAgents([Object.assign(findedAgent, { force_select: true })]);
                    setCurrentAssigned_employee_user_id(findedAgent.value);
                } else { // listagem de agentes estava sem o agente usuário
                    let requestedAgent = null;

                    const headers = UserService.getHeaders()
    
                    const agent = AppRequesterConst.Get(
                        '/agent/' + values.user.id, { headers },
                        (response: Object) => { },
                        (data: AgentInterface) => {
                            if (data.status === 200) {
                                requestedAgent = data.data.employees[0];
                                if (requestedAgent) {
                                    setSelectedAgents([Object.assign(requestedAgent, { force_select: true, value: requestedAgent.user_id, label: requestedAgent.name + (requestedAgent.lastname ? (" " + requestedAgent.lastname) : "") })]);
                                    setCurrentAssigned_employee_user_id(requestedAgent.user_id);
                                }
                            }
                        },
                        (error: Object) => { }, navigate, dispatch, setIsLoading, { values }
                    )
                }
    
                return agents;
            }); 

            return dep;
        });

        if (props.setFilledEditTicket) {
            props?.setFilledEditTicket(true);
        }
    }
    
    const onClickButtonEdit = () => {
        showEditConsumerButton(currentRequester_user?.user_id);
    }

    const selectCurrentTicket = (itemTicket: ItemTicketInterface) => {
        let oldTicket = currentTicket;
        let newTicket = itemTicket;

        if (oldTicket && newTicket && oldTicket.id !== newTicket.id) {
            dispatch(setCleanMessageEditor(true));
        }

        // setCurrentTicketInfo(newTicket);
        setCurrentTicket(newTicket);
        blockDropdownChatComponent(newTicket?.channel_id)

        defaultInputs();

        if (props.resetCloseCreateTicketState) {
            props.resetCloseCreateTicketState();
        }
    }

    const isbot = () => {
        if (currentTicket) {
            if (currentTicket.is_bot) {
                return <>{ t("home_tickets.home_create_ticket.bot_card.yes") }</>;
            } else {
                return <>{ t("home_tickets.home_create_ticket.bot_card.no") }</>;
            }
        }

        return <></>;
    }

    const confirmationDeleteTicket = () => {
        if (block_ticket.editButtonsView == false) {
            dispatch(setShowConfirmationModal({
                visibility: true,
                text: {
                    "title": t("home_tickets.view_ticket.confirmation.delete_ticket.title"),
                    "body": t("home_tickets.view_ticket.confirmation.delete_ticket.body"),
                    "id": t("home_tickets.view_ticket.confirmation.delete_ticket.id", { ticket_reference_id: currentTicket.ticket_reference_id }),
                    "buttonReturnText": t("home_tickets.view_ticket.confirmation.delete_ticket.buttonReturnText"),
                    "buttonConfirmationText": t("home_tickets.view_ticket.confirmation.delete_ticket.buttonConfirmText"),
                },
                functionConfirmation: deleteSelectedTicket
            }));
        } else {
            dispatch(setShowConfirmationModal({
                visibility: true,
                text: { "title": "Agente em atendimento", "body": "O ticket ", "id": `#${currentTicket.ticket_reference_id}`, "middleText": " está sendo acompanhado pelo agente", "warning": `${currentTicket.who_is_viewing}`, "end": " e não pode ser excluído no momento.", "buttonConfirmationText": "Ok"},
                functionConfirmation: () => { }
            }))
        }
    }

    const callbackModalCustomers = (userIdCustomer: string) => {
        getInformations({}).finally(async () => {
            /* setConsumersOptions((state) => {
                state.map((element) => {
                    console.log(element.value);
                    if (element.value === userIdCustomer) {
                        selectRequester([element], true);
                    }
                });
                return state;
            }); */

            // addItemNotInOptions(value[value.length - 1], agentsOptions, setAgentsOptions);
            /* setSelectedConsumers((selecteds) => {
                selectRequester(selecteds);
                return selectedAgents;
            }); */

            const headers = UserService.getHeaders()

            await AppRequesterConst.Get(
                '/consumer/' + userIdCustomer, { headers },
                (response: Object) => { },
                (data: UserInterface) => {
                    if (data.status === 200 && data?.data?.consumers !== null) {
                        let user = data.data.consumers[0];
                        let requester = Object.assign(user, { value: user.user_id, label: user.name, key: user.user_id });
                        selectRequester([requester], true);
                    } else {
                        setCurrentRequester_user(null);
                        setSelectedConsumers([]);
                    }
                },
                (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
            );
        });

        setAux([]);
        if (document.getElementById("listOfTickets") !== null) {
            document.getElementById("listOfTickets").scrollTop = 0;
        }

        SetCurrentPage(() => {
            setCurrentStatusPage("updating" + Date.now().toString());
            return 1;
        });
    }


    const selectRequester = async (value: string | any[], editOrCreate?: boolean) => {
        if (value.length > 0) {
            setCurrentRequester_user(value[value?.length - 1]);

            if (editOrCreate) {
                setSelectedConsumers([value[value.length - 1]]);
            }
            setIsLoading(true);
            const headers = UserService.getHeaders()

            await AppRequesterConst.Get(
                '/user/' + value[value.length - 1]?.user_id, { headers },
                (response: Object) => { },
                (data: UserInterface) => {
                    if (data.status === 200 && data?.data?.users !== null) {
                        let user = Object.assign(value[value.length - 1], { channels: data.data.users[0]?.channels?.length > 0 ? data.data.users[0].channels : [] });

                        setCurrentRequester_user(user);
                        if (editOrCreate)
                            setSelectedConsumers([user]);
                    } else {
                        setCurrentRequester_user(null);
                        if (editOrCreate)
                            setSelectedConsumers([]);
                    }
                },
                (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
            );

            if (value[value.length - 1].profile_id === null || value[value.length - 1].profile_id === undefined) {
                Utils.changeStyleElementById('editConsumerButtonId', 'display', 'inline');
            } else {
                Utils.changeStyleElementById('editConsumerButtonId', 'display', 'none');
            }
        } else {
            // setSelectedConsumers([]);
            setCurrentRequester_user(null);
        }
    }

    const filteringTickets = (e, auxObj: {}, action: string) => {
        SetCurrentPage(1);
        setAux([]);

        auxObj = {};

        if (action === "clean") {
            SetCurrentFilterSearch("");
        } else if (currentFilterSearch !== null && currentFilterSearch !== "") {
            auxObj["search"] = currentFilterSearch;
        }
        setCurrentStatusPage("init_searching");
        /* if (currentStatusPage === "searching") {
            setCurrentStatusPage("");
        } */

        SetCurrentParams(auxObj);
    }

    /*  const convertHtml = (last_message: string, ticket_reference_id: string, bold: boolean) => {
         var divContent = document.createElement("span");
 
         if (last_message.includes("<p>")) {
             divContent.innerHTML = last_message !== undefined ? last_message.replaceAll("<br>", "").replaceAll("<p>", "<span>").replaceAll("</p>", "</span>") : "<span></span>";
         }
         else {
             divContent.innerHTML = last_message !== undefined ? "<span>" + last_message.replaceAll("<br>", "").replaceAll("<p>", "<span>").replaceAll("</p>", "</span>") + "</span>" : "<span></span>";
         }
 
         if (divContent.children[0] !== undefined)
             (divContent.children[0] as HTMLElement).style.margin = '0';
 
 
         if (document.getElementById(ticket_reference_id) != null) {
 
             document.getElementById(ticket_reference_id).innerHTML = '';
             document.getElementById(ticket_reference_id).appendChild(divContent);
         }
 
         return (
             <span id={ticket_reference_id} style={{ padding: '0px', fontWeight: bold ? 'bolder' : '' }}>
 
             </span>
         );
     }
 
     const changeToText = (html: string) => {
         if (html !== undefined) {
             let blocksFromHTML = convertFromHTML(html);
             let newEditorState = ContentState.createFromBlockArray(
                 blocksFromHTML.contentBlocks,
                 blocksFromHTML.entityMap,
             );
             let stateHtml = EditorState.createWithContent(newEditorState)
             let text = convertToRaw(stateHtml.getCurrentContent()).blocks.map((item: { text: string; }, index: any) => {
                 return item.text
             })
             return text.join('\n')
         } else {
             return "";
         }
     } */

    const limiterChangeTextArea = (value: string) => {
        let limit = 200;
        if (value.length > limit) {
            document.getElementById("textAreaAssunto").innerHTML = value.substring(0, limit - 1);
            setCurrentSubject(value.substring(0, limit - 1));
            dispatch(setShowAlertFeedback({ visibility: true, message: t("home_tickets.alert_feedback.limit_chars.body"), signalIcon: false }));
        } else {
            setCurrentSubject(value);
        }
        if (props.setFilledEditTicket) {
            props?.setFilledEditTicket(true);
        }
    }

    const addItemNotInOptions = (selected: { value: string, key: string }, list, setList: Function) => {
        setList((state: Array<Object>) => {
            if (state && !state.find((item: { value: string }) => item?.value === selected?.value) && selected?.key !== "NA") {
                state.splice(1, 0, selected);
            }
            return state;
        });
    }

    const selectStatus = (value: string | any[]) => {
        if (value.length > 0) {
            setSelectedStatus([value[value.length - 1]]);
            setCurrentStatus(value[value.length - 1].value);

        } else {
            setSelectedStatus([]);
            setCurrentStatus(TicketStatus.New);
        }
    }

    const selectAssigned = (value: string | any[]) => {
        if (value.length > 0) {
            // not assigned option selected
            if (value[value.length - 1].value === "NA") {
                setSelectedAgents([]);
                setCurrentAssigned_employee_user_id("");
            } else {
                setSelectedAgents([value[value.length - 1]]);
                setCurrentAssigned_employee_user_id(value[value.length - 1].value);
                addItemNotInOptions(value[value.length - 1], agentsOptions, setAgentsOptions);
            }
        } else {
            setSelectedAgents([]);
            setCurrentAssigned_employee_user_id("");
        }
    }

    const onSelectRequester = (value: string | any[]) => {
        selectRequester(value);
        if (props.setFilledEditTicket) {
            props?.setFilledEditTicket(true);
        }
    }

    const onSelectDropdownAssigned = (value: string | any[]) => {
        selectAssigned(value);
        if (props.setFilledEditTicket) {
            props?.setFilledEditTicket(true);
        }
    }

    const onSelectDepartment = (value: string | any[]) => {
        if (props.setFilledEditTicket) {
            props?.setFilledEditTicket(true);
        }
    }

    const onSelectStatus = (value: string | any[]) => {
        selectStatus(value);
        if (props.setFilledEditTicket) {
            props?.setFilledEditTicket(true);
        }
    }

    return (
        <ViewTicket
            values={values}
            callbackModalCustomers={callbackModalCustomers}
            setCurrentConsumer={setCurrentConsumer}
            similarConsumers={similarConsumers}
            setSimilarConsumers={setSimilarConsumers}
            currentConsumersData={currentConsumersData}
            currentConsumer={currentConsumer}
            t={t}
            filters={props.filters}
            filteringTickets={filteringTickets}
            SetCurrentFilterSearch={SetCurrentFilterSearch}
            currentFilterSearch={currentFilterSearch}
            setAux={setAux}
            SetCurrentPage={SetCurrentPage}
            setForcedUpdate={setForcedUpdate}
            resetOverlayIdOnScroll={resetOverlayIdOnScroll}
            currentFilterDatas={currentFilterDatas}
            updateListfunction={updateListfunction}
            currentHasMoreInformation={currentHasMoreInformation}
            getTicket={getTicket}
            currentTicket={currentTicket}
            // onlyRead={onlyRead}
            // blockBool={blockBool}
            currentRequester_user={currentRequester_user}
            setCurrentStatusPage={setCurrentStatusPage}
            defaultInputs={defaultInputs}
            disableEditTicket={block_ticket.disableEditTicket}
            // disableSubmitTicket={disableSubmitTicket}
            confirmationDeleteTicket={confirmationDeleteTicket}
            updateCCandCCO={updateCCandCCO}
            onClickButtonEdit={onClickButtonEdit}
            consumersOptions={consumersOptions}
            setConsumersOptions={setConsumersOptions}
            selectedConsumers={selectedConsumers}
            setSelectedConsumers={setSelectedConsumers}
            multiselectFilterUsers={multiselectFilterUsers}
            customCreateRequesterButtonClick={customCreateRequesterButtonClick}
            onSelectRequester={onSelectRequester}
            departmentsOptions={departmentsOptions}
            setDepartmentsOptions={setDepartmentsOptions}
            selectedDepartments={selectedDepartments}
            setSelectedDepartments={setSelectedDepartments}
            multiselectFilterDepartments={multiselectFilterDepartments}
            onSelectDepartment={onSelectDepartment}
            onClickButtonAccept={onClickButtonAccept}
            agentsOptions={agentsOptions}
            setAgentsOptions={setAgentsOptions}
            selectedAgents={selectedAgents}
            setSelectedAgents={setSelectedAgents}
            multiselectFilterAgents={multiselectFilterAgents}
            onSelectDropdownAssigned={onSelectDropdownAssigned}
            onSearchFollowers={onSearchFollowers}
            abortConst={abortConst}
            followers={followers}
            currentFollowers={currentFollowers}
            selectFollowers={selectFollowers}
            statusOptions={statusOptions}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            onSelectStatus={onSelectStatus}
            tags={tags}
            currentTags={currentTags}
            selectTags={selectTags}
            currentSubject={currentSubject}
            limiterChangeTextArea={limiterChangeTextArea}
            toggleContent={toggleContent}
            showContent={showContent}
            customFields={customFields}
            textareaStates={textareaStates}
            changeCustomField={changeCustomField}
            disableSaveButton={disableSaveButton}
            updateTicket={updateTicket}
            setCurrentTicket={setCurrentTicket}
            isbot={isbot}
            currentLastUpdatedDate={currentLastUpdatedDate}
            setOpenModalPhone={props.setOpenModalPhone}
            setPhoneNumber={props.setPhoneNumber}
            audioChunks={audioChunks}
            setAudioChunks={setAudioChunks}
            audio={audio}
            setAudio={setAudio}
            audioBlob={audioBlob}
            setAudioBlob={setAudioBlob}
            userId={userId}
            addRequesterNotInOptions={addRequesterNotInOptions}
            blockDropdownChatComponent={blockDropdownChatComponent}
            setDefaultValue={setDefaultValue}
            setDisabledDropdown={setDisabledDropdown}
            defaultValue={defaultValue}
            disabledDropdown={disabledDropDown}
            editTicket={props.editTicket}
            taskUpdate={props?.taskUpdate}
            getShowAcquirePlatformComponent={values.internal.getShowAcquirePlatformComponent}
            isHistoryHome={props.isHistoryHome} 
            customFieldIdErrors={customFieldIdErrors}    
            renderMessagesChat={renderMessagesChat}
            setRenderMessagesChat={setRenderMessagesChat}
            block_ticket={block_ticket}
            dispatch={dispatch}
            message={useSelector(getShowMessageSend)}
            setCurrentClickId={setCurrentClickId}
            currentClickId={currentClickId}
            setShowConfirmationModal={setShowConfirmationModal}
            updateNoAtendece={updateNoAtendece}
            setUpdateNoAtendece={setUpdateNoAtendece}
            currentParams={currentParams}
        />
    )
}

export default ViewTicketController;