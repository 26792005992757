import React, { FC } from "react";
import { IModalEditTask } from "./indexModel";
import { Button, Col, Container, Form, FormControl, InputGroup, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import allStyles from "./styles";
import { Formik } from "formik";
import InputMask from 'react-input-mask';
import UserService from "../../../../services/user-service";
import { setShowAlertFeedback } from "../../../../store/internal";
import SelectComponentController from "../../components/selectComponent/indexController";

const ModalEditTask: FC<IModalEditTask> = (props) => {

    const handleSelectChange = (selectedOptions) => {
        props.setSelectedAssigned(selectedOptions);
        props.setErrorSelect(selectedOptions?.length === 0);
    };

    return (
        <Modal
            show={props?.modalShow}
            onHide={() => props?.clearFields()}
            aria-labelledby=""
            size="lg"
            centered={true}
        >
            <Modal.Header
                closeButton
                style={{ padding: '25px 25px 10px 25px' }}
            >
                {props?.isNotEditable === true ?
                    <Modal.Title style={allStyles.textBlack}>
                        {props.t('home_tasks.modal_task.info_data')}
                    </Modal.Title>
                    :
                    <Modal.Title style={allStyles.textBlack}>
                        {props.t('home_tasks.modal_task.edit_data')}
                    </Modal.Title>
                }
            </Modal.Header>
            <Modal.Body
                style={{ padding: '10px 20px 20px 25px' }}
            >
                <Container>
                    <Formik
                        initialValues={{ 
                            requester: props?.selectedRequester, 
                            status: props?.selectedStatus, 
                            assigned: props?.selectedAssigned, 
                            followers: props?.selectedFollowers, 
                            tags: props?.selectedTags, 
                            description: props?.description, 
                            date: props?.dataFormatada, 
                            hour: props?.horaFormatada,
                            notification_alert_time: props?.notificationAlertTime,
                            observation: props?.observation,
                        }}
                        validate={values => {
                            let errors = {};

                            props?.setDate(values?.date);
                            props?.setHour(values?.hour);
                            props?.setNotificationAlertTime(values.notification_alert_time)

                            if (!values.description) {
                                errors["description"] = props.t('home_tasks.modal_task.validate.fill_field');
                                props?.setShowFeedbackDescription(true);
                            }

                            
                            var today = new Date().toLocaleString().substr(0, 10)
                            if (values.date !== props.dataFormatada) {
                                props.setAtrasado(false)
                            }
                            if (!values.date) {
                                errors["date"] = props.t('home_tasks.modal_task.validate.fill_field');
                                props?.setShowFeedbackDate(true);
                            }
                            else if (props?.convertDate(values.date).toString() === "Invalid Date") {
                                errors["date"] = props.t('home_tasks.modal_task.validate.invalid_date');
                                props?.setShowFeedbackDate(true);
                            }
                            else if (props?.convertDate(values.date).getTime() < props?.convertDate(today).getTime()) {
                                errors["date"] = props.t('home_tasks.modal_task.validate.invalid_date');
                                props?.setShowFeedbackDate(true);
                            }
                            else {
                                props?.setShowFeedbackDate(false);
                            }

                            if (!values.hour) {
                                errors["hour"] = props.t('home_tasks.modal_task.validate.fill_field');
                                props?.setShowFeedbackHour(true);
                            }
                            else if (props?.convertHour(values.hour).toString() === "Invalid Date" || parseInt(values.hour.split(":")[0]) > 23 || parseInt(values.hour.split(":")[1]) > 59) {
                                errors["hour"] = props.t('home_tasks.modal_task.validate.invalid_hour');
                                props?.setShowFeedbackHour(true);
                            }
                            else {
                                props?.setShowFeedbackHour(false);
                            }

                            return errors;
                        }}
                        onSubmit={async values => {
                            const headers = UserService.getHeaders()
                            let JsonSend = {
                                "id": props?.dataEdit?.id,
                                "tags_id": [],
                                "followers_id": []
                            }

                            if (props?.selectedAssigned[0]?.value !== props?.dataEdit?.assigned_employee_id)
                                JsonSend["assigned_employee_id"] = props.selectedAssigned[0].value

                            if (props?.selectedStatus[0]?.value !== props?.dataEdit?.status_task_id && props?.selectedStatus[0]?.value !== undefined)
                                JsonSend["status_task_id"] = props.selectedStatus[0].value
                            else if (props?.selectedStatus[0]?.value === undefined) {
                                props?.dispatch(setShowAlertFeedback({ message: props.t('home_tasks.modal_task.submit.select_status'), visibility: true, signalIcon: false }));
                                return;
                            }

                            if (values.date && values.hour) {
                                let fullDate = values.date.split('/')
                                let day = fullDate[0]
                                let month = fullDate[1]
                                let year = fullDate[2]

                                let formatedDate = `${year}-${month}-${day} ${values.hour}:00`
                                let inputDate = Math.floor(new Date(formatedDate).getTime() / 1000)

                                JsonSend["date_limit"] = inputDate
                            }

                            if (values.description !== '' && values.description !== props?.dataEdit?.description)
                                JsonSend["description"] = values.description

                            if (props?.selectedFollowers.length !== 0) {
                                JsonSend["followers_id"] = props?.selectedFollowers.map((follower) => {
                                    return follower.value
                                })
                            }

                            if (props?.selectedTags.length !== 0) {
                                JsonSend["tags_id"] = props?.selectedTags.map((tag) => {
                                    return tag.value
                                })
                            }

                            if (props.alertRegister && props.selectdPeriod.length !== 0) {
                                let timePeriod = props.selectdPeriod.map((period) => { return period.value })

                                JsonSend["should_notificate"] = props.alertRegister
                                JsonSend["notification_alert_time"] = props.notificationAlertTime.toString()
                                JsonSend["notification_alert_type"] = timePeriod.shift()
                            } else if (props.alertRegister && props.selectdPeriod.length === 0) {
                                return props.dispatch(setShowAlertFeedback({ message: props.t('home_tasks.modal_task.submit.period_value_error'), visibility: true, signalIcon: false }));
                            } else {
                                JsonSend["should_notificate"] = props.alertRegister
                                JsonSend["notification_alert_time"] = undefined
                                JsonSend["notification_alert_type"] = undefined
                            }

                            if (values.observation !== '' && values.observation !== null) {
                                JsonSend["observation"] = values.observation
                            } else {
                                JsonSend["observation"] = ''
                            }

                            await props.AppRequesterConst.Put(
                                '/task', JsonSend, { headers },
                                (response: Object) => {
                                    return response;
                                },
                                (data) => {
                                    props?.dispatch(setShowAlertFeedback({ message: props.t('home_tasks.modal_task.submit.updated_task'), visibility: true, signalIcon: true }));
                                    props?.clearFields(true);
                                },
                                (error: { response: { status: number, data: { code_cxpress: number, message: string } } }) => {
                                    if (error.response.status === 400) {
                                        props?.dispatch(setShowAlertFeedback({ message: props.t('home_tasks.modal_task.submit.unexpected_error'), visibility: true, signalIcon: false }));
                                    } 
                                    else if (error.response.status === 422) {
                                        props?.dispatch(setShowAlertFeedback({ message: props.t('home_tasks.modal_task.submit.invalid_date'), visibility: true, signalIcon: false }));
                                    }
                                    else{
                                        if(error.response.data.code_cxpress === 3602){
                                            props?.dispatch(setShowAlertFeedback({ message: props.t('home_tasks.modal_task.submit.invalid_date'), visibility: true, signalIcon: false }));
                                        }
                                        else{
                                            props?.dispatch(setShowAlertFeedback({ message: props.t('home_tasks.modal_task.submit.unexpected_error'), visibility: true, signalIcon: false }));
                                        }
                                    }
                                }, props?.navigate, props?.dispatch, props?.setIsLoading
                            );
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => (
                            <Form onSubmit={handleSubmit} noValidate validated={false}>
                                <Row
                                    style={allStyles.textFontGlay}
                                    className=" d-flex justify-content-center"
                                >
                                    <Col md={6} className="ps-0">
                                        <Row style={{ marginLeft: '0vw' }}>
                                            <Col className="ps-0">
                                                <Form.Label htmlFor="basic-url">{props.t('home_tasks.modal_task.requester_form.label')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                                <InputGroup>
                                                    <FormControl
                                                        className="form-control-Default-text-area"
                                                        size="lg"
                                                        type="text"
                                                        id="requester"
                                                        name="requester"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={props?.dataEdit?.requester_employee_name}
                                                        disabled={true}
                                                    ></FormControl>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={6} className="">
                                        <Row style={{ marginLeft: '0vw' }}>
                                            <Col className="ps-0">
                                                <Form.Label htmlFor="basic-url">{props.t('home_tasks.modal_task.assigned_form.label')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                                <SelectComponentController
                                                    className={!props.errorSelect ? "multiselectForm mb-3" : "multiselectFormError"}
                                                    options={props?.agentsOptions ? props.agentsOptions : []}
                                                    setOptions={props?.setAgentsOptions ? props.setAgentsOptions : () => { }}
                                                    selecteds={props?.selectedAssigned ? props.selectedAssigned : []}
                                                    setSelecteds={handleSelectChange}
                                                    singleSelect={true}
                                                    isFormNoFilter={true}
                                                    title={props.t('home_tasks.modal_task.assigned_form.title')}
                                                    overrideStrings={
                                                        {
                                                            "allItemsAreSelected": props.t('home_tasks.modal_task.assigned_form.override_strings.allItemsAreSelected'),
                                                            "clearSearch": props.t('home_tasks.modal_task.assigned_form.override_strings.clearSearch'),
                                                            "clearSelected": props.t('home_tasks.modal_task.assigned_form.override_strings.clearSelected'),
                                                            "noOptions": props.t('home_tasks.modal_task.assigned_form.override_strings.noOptions'),
                                                            "search": props.t('home_tasks.modal_task.assigned_form.override_strings.search'),
                                                            "selectAll": props.t('home_tasks.modal_task.assigned_form.override_strings.selectAll'),
                                                            "selectAllFiltered": props.t('home_tasks.modal_task.assigned_form.override_strings.selectAllFiltered'),
                                                            "selectSomeItems": props.t('home_tasks.modal_task.assigned_form.override_strings.selectSomeItems'),
                                                            "create": props.t('home_tasks.modal_task.assigned_form.override_strings.create'),
                                                        }
                                                    }
                                                    hasCreateItem={false}
                                                    hasNullOption={true}
                                                    /* hasNullOption={true}
                                                    nullOptionLabel={ t("home_tickets.home_create_ticket.null_option") } */
                                                    /* onSelect={(value) => homeTicketsViewTicketControllerConst.onSelectDropdownAssigned(value)}
                                                    disabled={disableEditTicket} */
                                                    hasAllOption={false}
                                                    disabled={props.isNotEditable}
                                                />
                                                {props.errorSelect && <p style={{ padding: '0', color: '#dc3545' , fontSize: ".875em" }}>{props.t('home_tasks.modal_task.validate.fill_field')}</p>}

                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row
                                    style={allStyles.textFontGlay}
                                    className=" d-flex justify-content-center"
                                >
                                    <Col md={6} className="ps-0">
                                        <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                                            <Col sm={true}>
                                                <Form.Label htmlFor="basic-url">{props.t("home_tickets.home_create_ticket.followers_field_label")}</Form.Label>
                                                <SelectComponentController
                                                    options={props?.agentsOptions ? props.agentsOptions : []}
                                                    setOptions={props?.setAgentsOptions ? props.setAgentsOptions : () => { }}
                                                    selecteds={props?.selectedFollowers ? props.selectedFollowers : []}
                                                    setSelecteds={props?.setSelectedFollowers ? props.setSelectedFollowers : () => { }}
                                                    singleSelect={false}
                                                    isFormNoFilter={true}
                                                    title={props.t("home_tickets.home_create_ticket.followers_field_label")}
                                                    overrideStrings={
                                                        {
                                                            "allItemsAreSelected": props.t("home_tickets.home_create_ticket.select_component.all_items_are_selected", { items_name: props.t("home_tickets.home_create_ticket.assigneds_field_label") }),
                                                            "clearSearch": props.t("home_tickets.home_create_ticket.select_component.clear_search"),
                                                            "clearSelected": props.t("home_tickets.home_create_ticket.select_component.clear_selected", { items_name: props.t("home_tickets.home_create_ticket.assigneds_field_label") }),
                                                            "noOptions": props.t("home_tickets.home_create_ticket.select_component.no_options", { items_name: props.t("home_tickets.home_create_ticket.assigneds_field_label") }),
                                                            "search": props.t("home_tickets.home_create_ticket.select_component.search", { items_name: props.t("home_tickets.home_create_ticket.assigneds_field_label") }),
                                                            "selectAll": props.t("home_tickets.home_create_ticket.select_component.select_all", { items_name: props.t("home_tickets.home_create_ticket.assigneds_field_label") }),
                                                            "selectAllFiltered": props.t("home_tickets.home_create_ticket.select_component.select_all_filtered", { items_name: props.t("home_tickets.home_create_ticket.assigneds_field_label") }),
                                                            "selectSomeItems": props.t("home_tickets.home_create_ticket.followers_field_label"),
                                                            "create": props.t("home_tickets.home_create_ticket.select_component.create"),
                                                        }
                                                    }
                                                    hasCreateItem={false}
                                                    hasNullOption={false}
                                                    hasAllOption={false}
                                                    disabled={props.isNotEditable}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={6} className="">
                                        <Row style={{ marginLeft: '0vw' }}>
                                            <Col className="ps-0">
                                                <Form.Label htmlFor="basic-url">{props.t("home_tickets.home_create_ticket.status_field_label")}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>

                                                <SelectComponentController
                                                    options={props?.statusOptions ? props.statusOptions : []}
                                                    setOptions={props?.setStatusOptions ? props.setStatusOptions : () => { }}
                                                    selecteds={props?.atrasado ? [{ label: props.t('home_tasks.modal_task.status_form.delayed'), value: props.t('home_tasks.modal_task.status_form.delayed') }] : props?.selectedStatus ? props.selectedStatus : []}
                                                    setSelecteds={props?.setSelectedStatus}
                                                    singleSelect={true}
                                                    isFormNoFilter={true}
                                                    title={props.t('home_tasks.modal_task.status_form.title')}
                                                    overrideStrings={
                                                        {
                                                            "allItemsAreSelected": props.t('home_tasks.modal_task.status_form.override_strings.allItemsAreSelected'),
                                                            "clearSearch": props.t('home_tasks.modal_task.status_form.override_strings.clearSearch'),
                                                            "clearSelected": props.t('home_tasks.modal_task.status_form.override_strings.clearSelected'),
                                                            "noOptions": props.t('home_tasks.modal_task.status_form.override_strings.noOptions'),
                                                            "search": props.t('home_tasks.modal_task.status_form.override_strings.search'),
                                                            "selectAll": props.t('home_tasks.modal_task.status_form.override_strings.selectAll'),
                                                            "selectAllFiltered": props.t('home_tasks.modal_task.status_form.override_strings.selectAllFiltered'),
                                                            "selectSomeItems": props.t('home_tasks.modal_task.status_form.override_strings.selectSomeItems'),
                                                            "create": props.t('home_tasks.modal_task.status_form.override_strings.create'),
                                                        }
                                                    }
                                                    hasCreateItem={false}
                                                    hasNullOption={false}
                                                    // onSelect={(value) => homeTicketsViewTicketControllerConst.onSelectStatus(value)}
                                                    disableSearch={true}
                                                    // disabled={disableEditTicket}
                                                    hasAllOption={false}
                                                    className={(props.isNotEditable || props?.atrasado) ? "multiselectForm disabled" : ''}
                                                    disabled={!props?.atrasado ? props.isNotEditable : true}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row
                                    style={allStyles.textFontGlay}
                                    className=" d-flex justify-content-center"
                                >
                                    <Col className="ps-0">
                                        <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                                            <Col sm={true}>
                                                <Form.Label htmlFor="basic-url">{props.t("home_tickets.home_create_ticket.tags_field_label")}</Form.Label>
                                                <SelectComponentController
                                                    options={props?.tags ? props.tags : []}
                                                    setOptions={props?.setTags}
                                                    selecteds={props?.selectedTags ? props.selectedTags : []}
                                                    setSelecteds={props?.setSelectedTags}
                                                    singleSelect={false}
                                                    isFormNoFilter={true}
                                                    title={props.t("home_tickets.home_create_ticket.tags_field_label")}
                                                    overrideStrings={
                                                        {
                                                            "allItemsAreSelected": props.t("home_tickets.home_create_ticket.select_component.all_items_are_selected", { items_name: props.t("home_tickets.home_create_ticket.tags_field_label") }),
                                                            "clearSearch": props.t("home_tickets.home_create_ticket.select_component.clear_search"),
                                                            "clearSelected": props.t("home_tickets.home_create_ticket.select_component.clear_selected", { items_name: props.t("home_tickets.home_create_ticket.tags_field_label") }),
                                                            "noOptions": props.t("home_tickets.home_create_ticket.select_component.no_options", { items_name: props.t("home_tickets.home_create_ticket.tags_field_label") }),
                                                            "search": props.t("home_tickets.home_create_ticket.select_component.search", { items_name: props.t("home_tickets.home_create_ticket.tags_field_label") }),
                                                            "selectAll": props.t("home_tickets.home_create_ticket.select_component.select_all", { items_name: props.t("home_tickets.home_create_ticket.tags_field_label") }),
                                                            "selectAllFiltered": props.t("home_tickets.home_create_ticket.select_component.select_all_filtered", { items_name: props.t("home_tickets.home_create_ticket.tags_field_label") }),
                                                            "selectSomeItems": props.t("home_tickets.home_create_ticket.tags_field_label"),
                                                            "create": props.t("home_tickets.home_create_ticket.select_component.create"),
                                                        }
                                                    }
                                                    hasCreateItem={false}
                                                    hasNullOption={false}
                                                    hasAllOption={false}
                                                    disabled={props.isNotEditable}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row
                                    className="d-flex justify-content-center"
                                    style={allStyles.textFontGlay}
                                >
                                    <Col md={12} className="ps-0" style={allStyles.textFontGlay}>
                                        <Form.Label htmlFor="description">{props.t('home_tasks.modal_task.description_form.label')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                        <InputGroup>
                                            <FormControl
                                                className="form-control-Default-text-area"
                                                style={{ height: '10vh' }}
                                                // isInvalid={showfeedback}
                                                as="textarea"
                                                rows={10}
                                                placeholder={props.t('home_tasks.modal_task.description_form.placeholder')}
                                                aria-label={props.t('home_tasks.modal_task.description_form.placeholder')}
                                                aria-describedby={props.t('home_tasks.modal_task.description_form.placeholder')}
                                                size="lg"
                                                type="text"
                                                id="description"
                                                name="description"
                                                maxLength={200}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.description}
                                                disabled={props.isNotEditable}
                                                isInvalid={props.showFeedbackDescription}
                                        ></FormControl>
                                        <Form.Control.Feedback
                                            type="invalid"
                                            id="feedbackdescription"
                                        >
                                            {errors.description}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                                <Col className="ps-0" style={allStyles.textFontGlay}>
                                    <Form.Label htmlFor="basic-url">{props.t('home_tasks.modal_task.limit_date.label')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                    <InputMask
                                        mask={"99/99/9999"}
                                        maskChar={"_"}
                                        value={values.date}
                                        onChange={handleChange}
                                    >
                                        {(inputProps: any) => (
                                            <InputGroup>
                                                <FormControl
                                                    isInvalid={props?.showFeedbackDate}
                                                    className="form-control-Default"
                                                    placeholder={props.t('home_tasks.modal_task.limit_date.placeholder')}
                                                    aria-label={props.t('home_tasks.modal_task.limit_date.placeholder')}
                                                    aria-describedby={props.t('home_tasks.modal_task.limit_date.placeholder')}
                                                    type="text"
                                                    size="lg"
                                                    id="date"
                                                    name="date"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    disabled={props.isNotEditable}
                                                />
                                                <Form.Control.Feedback
                                                    type="invalid"
                                                    id="feedbackdate"
                                                >
                                                    {errors.date}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        )}
                                    </InputMask>
                                </Col>
                                <Col className="ps-0" style={allStyles.textFontGlay}>
                                    <Form.Label htmlFor="basic-url">{props.t('home_tasks.modal_task.limit_hour.label')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                    <InputMask
                                        mask="99:99"
                                        maskChar="_"
                                        value={values.hour}
                                        onChange={handleChange}
                                    >
                                        {(inputProps: any) => (
                                            <InputGroup>
                                                <FormControl
                                                    className="form-control-Default"
                                                    placeholder="HH:MM"
                                                    aria-label="HH:MM"
                                                    aria-describedby="HH:MM"
                                                    type="text"
                                                    size="lg"
                                                    id="hour"
                                                    name="hour"
                                                    isInvalid={props?.showFeedbackHour}
                                                    onBlur={handleBlur}
                                                    disabled={props.isNotEditable}
                                                ></FormControl>
                                                <Form.Control.Feedback
                                                    type="invalid"
                                                    id="feedbackHour"
                                                >
                                                    {errors.hour}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        )}
                                    </InputMask>
                                </Col>
                            </Row>
                            <p></p>
                            <Row className="d-flex justify-content-center" style={allStyles.textFontGlay}>
                                <Col style={{ display: "flex" }} className="ps-0">
                                    <Form.Group>
                                        <Form.Check style={{ paddingRight: "0px", marginRight: "3px" }} type='checkbox' label={props.t('home_tasks.modal_task.notify_config.check_label')} checked={props.alertRegister} onChange={() => props.setAlertRegister(!props.alertRegister)} inline></Form.Check>
                                    </Form.Group>
                                    <OverlayTrigger
                                        key='top'
                                        placement='top'
                                        overlay={
                                            <Tooltip id='tootip'>
                                                {props.t('home_tasks.modal_task.notify_config.check_tooltip')}
                                            </Tooltip>
                                        }
                                    >
                                        <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 28 28" className="me-3" style={{ marginTop: '-0.1rem' }}>
                                            <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                            <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        </svg>
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                            <p></p>
                                {props.alertRegister ? 
                                    <>
                                        <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center mt-3">
                                            <Col className="ps-0">
                                                <Form.Label>{props.t('home_tasks.modal_task.notify_config.interval_period_label')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                                <SelectComponentController
                                                    options={props.allPeriod}
                                                    setOptions={props.setAllPeriod}
                                                    selecteds={props.selectdPeriod}
                                                    setSelecteds={props.setSelectedPeriod}
                                                    singleSelect={true}
                                                    isFormNoFilter={true}
                                                    title={props.t('home_tasks.modal_task.notify_config.interval_period_select.title')}
                                                    disableSearch={true}
                                                    overrideStrings={{
                                                        "allitemsAreSelected": props.selectdPeriod[0] ? props.selectdPeriod[0].label && props.selectdPeriod[0]?.value : "",
                                                        "clearSearch": props.t('home_tasks.modal_task.notify_config.interval_period_select.clearSearch'),
                                                        "clearSelected": props.t('home_tasks.modal_task.notify_config.interval_period_select.clearSelected'),
                                                        "noOptions": props.t('home_tasks.modal_task.notify_config.interval_period_select.noOptions'),
                                                        "search": props.t('home_tasks.modal_task.notify_config.interval_period_select.search'),
                                                        "selectAll": props.t('home_tasks.modal_task.notify_config.interval_period_select.selectAll'),
                                                        "selectAllFiltered": props.t('home_tasks.modal_task.notify_config.interval_period_select.selectAllFiltered'),
                                                        "selectSomeItems": props.t('home_tasks.modal_task.notify_config.interval_period_select.selectSomeItems'),
                                                        "create": props.t('home_tasks.modal_task.notify_config.interval_period_select.selectSomeItems'),
                                                    }}
                                                    hasCreateItem={false}
                                                    hasNullOption={false}
                                                    hasAllOption={false}
                                                />
                                            </Col>
                                        </Row>
                                        <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                                            <Col className="ps-0">
                                                <Form.Label>{props.t('home_tasks.modal_task.notify_config.interval_value_label')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                                <InputGroup>
                                                    <FormControl
                                                        // isInvalid={feedbackNotificationAlertTime}
                                                        className='form-control-Default'
                                                        placeholder={props.t('home_tasks.modal_task.notify_config.interval_value_placeholder')}
                                                        aria-label={props.t('home_tasks.modal_task.notify_config.interval_value_placeholder')}
                                                        aria-describedby={props.t('home_tasks.modal_task.notify_config.interval_value_placeholder')}
                                                        type='number'
                                                        maxLength={3}
                                                        size='lg'
                                                        id='notification_alert_time'
                                                        name='notification_alert_time'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.notification_alert_time}
                                                    />
                                                    <Form.Control.Feedback type='invalid' id='feedbackVariableNumber'>
                                                        {errors.notification_alert_time}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                    </>
                                    :
                                    <></>
                                }
                                <Row
                                    className="d-flex justify-content-center"
                                    style={allStyles.textFontGlay}
                                >
                                    <Col md={12} className="ps-0" style={allStyles.textFontGlay}>
                                        <Form.Label htmlFor="description">{props.t('home_tasks.modal_task.observation_form.label')}</Form.Label>
                                        <InputGroup>
                                            <FormControl
                                                className="form-control-Default-text-area"
                                                style={{ height: '10vh' }}
                                                as="textarea"
                                                rows={10}
                                                placeholder={props.t('home_tasks.modal_task.observation_form.placeholder')}
                                                aria-label={props.t('home_tasks.modal_task.observation_form.placeholder')}
                                                aria-describedby={props.t('home_tasks.modal_task.observation_form.placeholder')}
                                                size="lg"
                                                type="text"
                                                id="observation"
                                                name="observation"
                                                maxLength={2000}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.observation}
                                                disabled={props.isNotEditable}
                                            ></FormControl>
                                        </InputGroup>
                                    </Col>
                                </Row>
                            <Row
                                style={allStyles.textFontGlay}
                                className="d-flex justify-content-center mt-4"
                            >
                                    {props.isNotEditable === false ?
                                        <>
                                            <Col md={6} className="d-flex justify-content-start ps-0">
                                                <Button
                                                    size="lg"
                                                    className="buttonWhite"
                                                    style={{ width: "50%" }}
                                                    onClick={() => props?.clearFields()}
                                                >
                                                    {props.t('home_tasks.modal_task.cancel')}
                                                </Button>
                                            </Col>
                                            <Col md={6} className="d-flex justify-content-end ps-0">
                                                <Button
                                                    type="submit"
                                                    size="lg"
                                                    className="buttonBlue"
                                                    style={{ width: "50%" }}
                                                >
                                                    {props.t('home_tasks.modal_task.save')}
                                                </Button>
                                            </Col>
                                        </>
                                        :
                                        <>
                                            <Col xs={12} className="d-flex justify-content-start ps-0">
                                                <Button
                                                    size="lg"
                                                    className="buttonWhite"
                                                    style={{ width: "100%" }}
                                                    onClick={() => props?.clearFields()}
                                                >
                                                    {props.t('home_tasks.modal_task.back')}
                                                </Button>
                                            </Col>
                                        </>
                                    }
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

export default ModalEditTask;