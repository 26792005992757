import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, InputGroup, FormControl, Col, Row, ListGroup, OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';
import { Formik } from 'formik';
import allStyles from './styles';
import { setShowAlertFeedback } from '../../../../store/internal';

import UserService from '../../../../services/user-service';
import { t } from 'i18next';
import Loading from '../../../../components/loading';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ListName } from '../../../../core/enums/order-sort-by';
import { TicketStatus } from '../../../../core/enums/ticket-status';
import Utils from '../../../../core/shared/utils';
import InputMask from 'react-input-mask';
import SelectComponentController from '../selectComponent/indexController';


export interface optionsType {
    value: string,
    label: string,
}

const ModalListTasks = ({
    show,
    zerarCampos,
    data,
    selectedType,
    AppRequesterConst,
    dispatch,
    callBackModalCustomField,
    navigate,
    setIsLoading,
    validated,
    setDescription,
    showFeedbackDescription,
    setShowFeedbackDescription,
    type,
    setType,
    setSelectedType,
    isLoading,
    updateListfunction,
    setCurrentTicketId,
    currentTicketId,
    showModal,
    showEdit,
    alterarVisibilidadeEdit,
    alterarVisibilidadeDelete,
    multiselectFilterAgents,
    onSelectAssigned,
    setDate,
    setHour,
    setShowfeedbackDate,
    setShowfeedbackHour,
    converterData,
    converterHora,
    showfeedbackDate,
    showfeedbackHour,
    alterarVisibilidadeAdd,
    showButton,
    showAdd,
    showDelete,
    tasks,
    taskId,
    setTaskId,
    allAgents,
    setAllAgents,
    allTags,
    setAllTags,
    dataEdit,
    selectedAssigned,
    setSelectedAssigned,
    selectedRequester,
    setSelectedRequester,
    selectedTags,
    setSelectedTags,
    selectedFollowers,
    setSelectedFollowers,
    deleteTask,
    requesterId,
    date,
    setAlertRegister,
    alertRegister,
    allPeriod,
    setAllPeriod,
    selectdPeriod,
    setSelectedPeriod,
    onSelectPeriod,
    showFeedbackNotificationAlertTime,
    feedbackNotificationAlertTime,
    selectOnPeriod,
    setNotificationAlertTime,
    notificationAlertTime,
    getStatusElement,
    t,
    setObservation,
    errorSelect,
    setErrorSelect
}) => {

    let status = '';
    
    const handleSelectChange = (selectedOptions) => {
        setSelectedAssigned(selectedOptions);
        setErrorSelect(selectedOptions?.length === 0);
    };

    let dataFormatada, horaFormatada;
    const dateStr = dataEdit?.date_limit;

    if (dateStr) {
        const [datePart, timePart] = dateStr.split(' ');
        const [ano, mes, dia] = datePart.split('-');
        const [hora, minuto] = timePart.split(':');

        dataFormatada = `${dia.padStart(2, '0')}/${mes.padStart(2, '0')}/${ano}`;
        horaFormatada = `${hora.padStart(2, '0')}:${minuto.padStart(2, '0')}`;
    }

    setDate("");
    const popover = (
        <Popover id={currentTicketId} style={{ backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }}>
            <Popover.Body className="nopadding">
                <Button onClick={alterarVisibilidadeEdit} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t('home_tasks.list_of_tasks.edit_task')}</Button>
                <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                <Button onClick={alterarVisibilidadeDelete} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t('home_tasks.list_of_tasks.delete_task')}</Button>
            </Popover.Body>
        </Popover>
    );

    return (
        <Modal size={'lg'} show={show} onHide={zerarCampos} centered>
            <Modal.Header closeButton style={{ padding: '25px 25px 10px 25px' }}>
                <Modal.Title style={allStyles.textBlack}>
                    {showModal &&
                        <span>{t('home_tasks.modal_task.title')}</span>
                    }
                    {showDelete &&
                        <span>{t('home_tasks.modal_task.delete_data')}</span>
                    }
                    {showAdd &&
                        <span>{t('home_tasks.modal_task.add_data')}</span>
                    }
                    {showEdit &&
                        <span>{t('home_tasks.modal_task.edit_data')}</span>
                    }

                </Modal.Title>
                {showButton &&
                    <OverlayTrigger
                        key='top'
                        placement='top'
                        overlay={
                            <Tooltip id='tooltip' className='tooltip-arrow-Top'>
                                {t('home_tasks.modal_task.add_data')}
                            </Tooltip>
                        }
                    >

                        <Button className="mx-3 nopadding buttonTicketsSec" onClick={alterarVisibilidadeAdd}>
                            <svg id="Grupo_11079" data-name="Grupo 11079" xmlns="http://www.w3.org/2000/svg" width="50%" viewBox="0 0 24 24">
                                <path id="Caminho_9572" data-name="Caminho 9572" d="M0,0H24V24H0Z" fill="none" />
                                <line id="Linha_147" data-name="Linha 147" y2="14" transform="translate(12 5)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                <line id="Linha_148" data-name="Linha 148" x2="14" transform="translate(5 12)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                            </svg>
                        </Button>
                    </OverlayTrigger>
                }
            </Modal.Header>
            <Modal.Body style={{ padding: '10px 25px 25px 25px' }}>
                <Loading visibility={isLoading}></Loading>

                {showDelete &&

                    <Form>
                        <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                            <Col sm={true}>
                                <Form.Label htmlFor="basic-url">{t('home_tasks.modal_task.delete_data')}?</Form.Label>
                                <Row className="d-flex justify-content-center mt-4">

                                    <Col xs={6} className="d-flex justify-content-start">
                                        <Button size='lg' className="buttonWhite" style={{ width: '56%' }} onClick={zerarCampos}>{t('home_tasks.modal_task.cancel')}</Button>
                                    </Col>
                                    <Col xs={6} className="d-flex justify-content-end">
                                        <Button size='lg' className="buttonBlue" style={{ width: '56%' }} onClick={() => deleteTask(taskId)}>{t('home_tasks.modal_task.save')}</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                }
                {showAdd &&
                    <Formik
                    initialValues={{ 
                        requester: selectedRequester, 
                        status: selectedType,
                        assigned: selectedAssigned, 
                        followers: selectedFollowers, 
                        tags: '', 
                        description: '', 
                        date: '', 
                        hour: '00:00', 
                        notification_alert_time: '',
                        observation: ''
                    }}
                    validate={values => {
                            let errors = {};
                            setDate(values?.date);
                            setHour(values?.hour);
                            setNotificationAlertTime(values?.notification_alert_time)
                            setObservation(values?.observation)

                            if(selectedAssigned?.length === 0){
                                setErrorSelect(true)
                            }

                            var today = new Date().toLocaleString().substr(0, 10)
                            if (values.date === "" || values.date === "__/__/____") {
                                values.date = date
                            }
                            if (!values.date) {
                                errors["date"] = t('home_tasks.modal_task.validate.fill_field');
                                setShowfeedbackDate(true);
                            }
                            else if (converterData(values.date).toString() === "Invalid Date") {
                                errors["date"] = t('home_tasks.modal_task.validate.invalid_date');
                                setShowfeedbackDate(true);
                            }
                            else if (converterData(values.date).getTime() < converterData(today).getTime()) {
                                errors["date"] = t('home_tasks.modal_task.validate.invalid_date');
                                setShowfeedbackDate(true);
                            }
                            else {
                                setShowfeedbackDate(false);
                            }

                            if (!values.hour) {
                                errors["hour"] = t('home_tasks.modal_task.validate.fill_field');
                                setShowfeedbackHour(true);
                            }
                            else if (converterHora(values.hour).toString() === "Invalid Date" || parseInt(values.hour.split(":")[0]) > 23 || parseInt(values.hour.split(":")[1]) > 59) {
                                errors["hour"] = t('home_tasks.modal_task.validate.invalid_hour');
                                setShowfeedbackHour(true);
                            }
                            else {
                                setShowfeedbackHour(false);
                            }

                            if (!values.description) {
                                errors["description"] = t('home_tasks.modal_task.validate.fill_field');
                                setShowFeedbackDescription(true);
                            } else {
                                setShowFeedbackDescription(false);
                            }

                            if(alertRegister){                                
                                if (!values.notification_alert_time) {
                                    errors["notification_alert_time"] = t('home_tasks.modal_task.validate.fill_field');
                                    showFeedbackNotificationAlertTime(true)
                                } else {
                                    showFeedbackNotificationAlertTime(false);
                                }
                            }

                            return errors;
                        }}
                        onSubmit={async values => {
                            const headers = UserService.getHeaders()
                            let JsonSend = {
                                "ticket_id": data,
                                "requester_employee_id": requesterId,
                                "assigned_employee_id": "",
                                // "date_limit": "",
                                "description": ""
                            }

                            if (selectedAssigned)
                                JsonSend["assigned_employee_id"] = selectedAssigned[0].value

                            if (selectedFollowers) {
                                let followers = []
                                selectedFollowers.map((follower) => {
                                    followers.push(follower.value)
                                })
                                JsonSend["followers_id"] = followers
                            }

                            if (selectedTags) {
                                let tags = []
                                selectedTags.map((tag) => {
                                    tags.push(tag.value)
                                })
                                JsonSend["tags_id"] = tags
                            }

                            if (values.date && values.hour) {
                                let fullDate = values.date.split('/')
                                let day = fullDate[0]
                                let month = fullDate[1]
                                let year = fullDate[2]

                                let formatedDate = `${year}-${month}-${day} ${values.hour}:00`
                                let inputDate = Math.floor(new Date(formatedDate).getTime() / 1000)

                                JsonSend["date_limit"] = inputDate
                            }

                            if (alertRegister && selectdPeriod.length !== 0) {
                                JsonSend["should_notificate"] = alertRegister;
                                JsonSend["notification_alert_time"] = notificationAlertTime.toString();
                                JsonSend["notification_alert_type"] = selectOnPeriod[0].value;
                            } else if (alertRegister && selectdPeriod.length === 0) {
                                return dispatch(setShowAlertFeedback({ message: t('home_tasks.modal_task.submit.period_value_error'), visibility: true, signalIcon: false }));
                            } else {
                                JsonSend["should_notificate"] = alertRegister
                                JsonSend["notification_alert_time"] = undefined
                                JsonSend["notification_alert_type"] = undefined
                            }

                            if (values.description !== '') {
                                JsonSend["description"] = values.description
                            }

                            if (values.observation !== '') {
                                JsonSend["observation"] = values.observation
                            }

                            await AppRequesterConst.Post(
                                '/task', JsonSend, { headers },
                                (response: Object) => {
                                    return response;
                                },
                                (data: { data: { user_id: string; }; }) => {
                                    dispatch(setShowAlertFeedback({ message: t('home_tasks.modal_task.submit.added_task'), visibility: true, signalIcon: true }));
                                    callBackModalCustomField()
                                    zerarCampos();
                                },
                                (error) => {
                                    if (error.response.status === 400) {
                                        dispatch(setShowAlertFeedback({ message: t('home_tasks.modal_task.submit.unexpected_error'), visibility: true, signalIcon: false }));
                                    } else if (error.response.status === 422) {
                                        if (error.response.data.error?.includes("description")) {
                                            dispatch(setShowAlertFeedback({ message: t('home_tasks.modal_task.submit.description_empty_error'), visibility: true, signalIcon: false }));
                                        } else if (error.response.data.code_cxpress === 3602) {
                                            dispatch(setShowAlertFeedback({ message: t('home_tasks.modal_task.submit.notification_date_expired'), visibility: true, signalIcon: false }));
                                        } else {
                                            dispatch(setShowAlertFeedback({ message: t('home_tasks.modal_task.submit.numeric_value_error'), visibility: true, signalIcon: false }));
                                        }
                                    } else if (error.response.status === 500 && error.response.data.code_cxpress === 3602){
                                        dispatch(setShowAlertFeedback({ message: t('home_tasks.modal_task.submit.notification_date_expired'), visibility: true, signalIcon: false }));
                                    }

                                }, navigate, dispatch, setIsLoading
                            );

                        }
                        }
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => (
                            <Form onSubmit={handleSubmit} noValidate validated={validated}>
                                <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                                    <Col sm={true}>
                                        <Form.Label htmlFor="basic-url">{t('home_tasks.modal_task.assigned_form.label')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                        <SelectComponentController
                                            className={!errorSelect ? "multiselectForm mb-3" : "multiselectFormError"}
                                            options={allAgents}
                                            setOptions={setAllAgents}
                                            selecteds={selectedAssigned}
                                            setSelecteds={handleSelectChange}
                                            singleSelect={true}
                                            title={t('home_tasks.modal_task.assigned_form.title')}
                                            overrideStrings={{
                                                "clearSearch": t('home_tasks.modal_task.assigned_form.override_strings.clearSearch'),
                                                "noOptions": t('home_tasks.modal_task.assigned_form.override_strings.noOptions'),
                                                "search": t('home_tasks.modal_task.assigned_form.override_strings.search'),
                                                "selectSomeItems": t('home_tasks.modal_task.assigned_form.override_strings.selectSomeItems'),
                                                "create": t('home_tasks.modal_task.assigned_form.override_strings.create'),
                                            }}
                                            hasCreateItem={false}
                                            hasNullOption={false}
                                            // filtering={false}
                                            hasAllOption={false} 
                                            isFormNoFilter={false}
                                            />
                                            {errorSelect && <p style={{ padding: '0', color: '#dc3545' , fontSize: ".875em" }}> {t('home_tasks.modal_task.validate.fill_field')} </p>}
                                    </Col>
                                </Row>
                                <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                                    <Col sm={true}>
                                        <Form.Label htmlFor="basic-url">{t("home_tickets.home_create_ticket.followers_field_label")}</Form.Label>
                                        <SelectComponentController
                                            options={allAgents}
                                            setOptions={setAllAgents}
                                            selecteds={selectedFollowers}
                                            setSelecteds={setSelectedFollowers}
                                            singleSelect={false}
                                            isFormNoFilter={true}
                                            title={t("home_tickets.home_create_ticket.followers_field_label")}
                                            overrideStrings={
                                                {
                                                    "allItemsAreSelected": t("home_tickets.home_create_ticket.select_component.all_items_are_selected", { items_name: t("home_tickets.home_create_ticket.assigneds_field_label") }),
                                                    "clearSearch": t("home_tickets.home_create_ticket.select_component.clear_search"),
                                                    "clearSelected": t("home_tickets.home_create_ticket.select_component.clear_selected", { items_name: t("home_tickets.home_create_ticket.assigneds_field_label") }),
                                                    "noOptions": t("home_tickets.home_create_ticket.select_component.no_options", { items_name: t("home_tickets.home_create_ticket.assigneds_field_label") }),
                                                    "search": t("home_tickets.home_create_ticket.select_component.search", { items_name: t("home_tickets.home_create_ticket.assigneds_field_label") }),
                                                    "selectAll": t("home_tickets.home_create_ticket.select_component.select_all", { items_name: t("home_tickets.home_create_ticket.assigneds_field_label") }),
                                                    "selectAllFiltered": t("home_tickets.home_create_ticket.select_component.select_all_filtered", { items_name: t("home_tickets.home_create_ticket.assigneds_field_label") }),
                                                    "selectSomeItems": t("home_tickets.home_create_ticket.followers_field_label"),
                                                    "create": t("home_tickets.home_create_ticket.select_component.create"),
                                                }
                                            }
                                            hasCreateItem={false}
                                            hasNullOption={false}
                                            onSelect={(value) => onSelectAssigned(value)}
                                            hasAllOption={false}
                                        />
                                    </Col>
                                </Row>
                                <p></p>
                                <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                                    <Col sm={true}>
                                        <Form.Label htmlFor="basic-url">{t("home_tickets.home_create_ticket.tags_field_label")}</Form.Label>
                                        <SelectComponentController
                                            options={allTags}
                                            setOptions={setAllTags}
                                            selecteds={selectedTags}
                                            setSelecteds={setSelectedTags}
                                            singleSelect={false}
                                            isFormNoFilter={true}
                                            title={t("home_tickets.home_create_ticket.tags_field_label")}
                                            overrideStrings={
                                                {
                                                    "allItemsAreSelected": t("home_tickets.home_create_ticket.select_component.all_items_are_selected", { items_name: t("home_tickets.home_create_ticket.tags_field_label") }),
                                                    "clearSearch": t("home_tickets.home_create_ticket.select_component.clear_search"),
                                                    "clearSelected": t("home_tickets.home_create_ticket.select_component.clear_selected", { items_name: t("home_tickets.home_create_ticket.tags_field_label") }),
                                                    "noOptions": t("home_tickets.home_create_ticket.select_component.no_options", { items_name: t("home_tickets.home_create_ticket.tags_field_label") }),
                                                    "search": t("home_tickets.home_create_ticket.select_component.search", { items_name: t("home_tickets.home_create_ticket.tags_field_label") }),
                                                    "selectAll": t("home_tickets.home_create_ticket.select_component.select_all", { items_name: t("home_tickets.home_create_ticket.tags_field_label") }),
                                                    "selectAllFiltered": t("home_tickets.home_create_ticket.select_component.select_all_filtered", { items_name: t("home_tickets.home_create_ticket.tags_field_label") }),
                                                    "selectSomeItems": t("home_tickets.home_create_ticket.tags_field_label"),
                                                    "create": t("home_tickets.home_create_ticket.select_component.create"),
                                                }
                                            }
                                            hasCreateItem={false}
                                            hasNullOption={false}
                                            onSelect={(value) => onSelectAssigned(value)}
                                            hasAllOption={false}
                                        />
                                    </Col>
                                </Row>
                                <p></p>
                                <Row className="d-flex justify-content-center">
                                    <Col sm={true} style={allStyles.textFontGlay} >
                                        <Form.Label htmlFor="basic-url">{t('home_tasks.modal_task.description_form.label')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                        <InputGroup>
                                            <FormControl
                                                className="form-control-Default-text-area"
                                                style={{ height: '10vh' }}
                                                isInvalid={showFeedbackDescription}
                                                as="textarea"
                                                rows={10}
                                                placeholder={t('home_tasks.modal_task.description_form.placeholder')}
                                                aria-label={t('home_tasks.modal_task.description_form.placeholder')}
                                                aria-describedby={t('home_tasks.modal_task.description_form.placeholder')}
                                                size="lg"
                                                type="text"
                                                id="description"
                                                name="description"
                                                maxLength={200}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            ></FormControl>
                                            <Form.Control.Feedback
                                                type="invalid"
                                                id="feedbackdescription"
                                            >
                                                {errors.description}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <p></p>
                                <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                                    <Col sm={true}>
                                        <Form.Label htmlFor="basic-url">{t('home_tasks.modal_task.limit_date.label')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                        <InputMask
                                            mask={"99/99/9999"}
                                            maskChar={"_"}
                                            value={values.date}
                                            onChange={handleChange}
                                        >
                                            {(inputProps: any) => (
                                                <InputGroup>
                                                    <FormControl
                                                        isInvalid={showfeedbackDate}
                                                        className="form-control-Default"
                                                        placeholder={t('home_tasks.modal_task.limit_date.placeholder')}
                                                        aria-label={t('home_tasks.modal_task.limit_date.placeholder')}
                                                        aria-describedby={t('home_tasks.modal_task.limit_date.placeholder')}
                                                        type="text"
                                                        size="lg"
                                                        id="date"
                                                        name="date"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    <Form.Control.Feedback
                                                        type="invalid"
                                                        id="feedbackdate"
                                                    >
                                                        {errors.date}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            )}
                                        </InputMask>
                                    </Col>
                                    <Col sm={true}>
                                        <Form.Label htmlFor="basic-url">{t('home_tasks.modal_task.limit_hour.label')}</Form.Label>
                                        <InputMask
                                            mask="99:99"
                                            maskChar="_"
                                            value={values.hour}
                                            onChange={handleChange}
                                        >
                                            {(inputProps: any) => (
                                                <InputGroup>
                                                    <FormControl
                                                        className="form-control-Default"
                                                        placeholder="HH:MM"
                                                        aria-label="HH:MM"
                                                        aria-describedby="HH:MM"
                                                        type="text"
                                                        size="lg"
                                                        id="hour"
                                                        name="hour"
                                                        isInvalid={showfeedbackHour}
                                                        onBlur={handleBlur}
                                                    ></FormControl>
                                                    <Form.Control.Feedback
                                                        type="invalid"
                                                        id="feedbackHour"
                                                    >
                                                        {errors.hour}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            )}
                                        </InputMask>
                                    </Col>
                                </Row>
                                <p></p>
                                <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                                    <Col style={{ display: "flex" }}>
                                        <Form.Group>
                                            <Form.Check style={{ paddingRight: "0px", marginRight: "3px" }} type='checkbox' label={t('home_tasks.modal_task.notify_config.check_label')} checked={alertRegister} onChange={() => setAlertRegister(!alertRegister)} inline/>
                                        </Form.Group>
                                        <OverlayTrigger
                                            key='top'
                                            placement='top'
                                            overlay={
                                                <Tooltip id='tooltip'>
                                                    {t('home_tasks.modal_task.notify_config.check_tooltip')}
                                                </Tooltip>
                                            }
                                        >
                                            <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 28 28" className="me-3" style={{ marginTop: '-0.1rem' }}>
                                                <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                                <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            </svg>
                                        </OverlayTrigger>
                                    </Col>
                                </Row>
                                <p></p>
                                {alertRegister ?
                                    <>
                                        <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center mt-3">
                                            <Col>
                                                <Form.Label>{t('home_tasks.modal_task.notify_config.interval_period_label')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                                <SelectComponentController
                                                    options={allPeriod}
                                                    setOptions={setAllPeriod}
                                                    selecteds={selectdPeriod}
                                                    setSelecteds={setSelectedPeriod}
                                                    singleSelect={true}
                                                    isFormNoFilter={true}
                                                    title={t('home_tasks.modal_task.notify_config.interval_period_select.title')}
                                                    disableSearch={true}
                                                    overrideStrings={{
                                                        "allitemsAreSelected": selectdPeriod[0] ? selectdPeriod[0].label : "",
                                                        "clearSearch": t('home_tasks.modal_task.notify_config.interval_period_select.clearSearch'),
                                                        "clearSelected": t('home_tasks.modal_task.notify_config.interval_period_select.clearSelected'),
                                                        "noOptions": t('home_tasks.modal_task.notify_config.interval_period_select.noOptions'),
                                                        "search": t('home_tasks.modal_task.notify_config.interval_period_select.search'),
                                                        "selectAll": t('home_tasks.modal_task.notify_config.interval_period_select.selectAll'),
                                                        "selectAllFiltered": t('home_tasks.modal_task.notify_config.interval_period_select.selectAllFiltered'),
                                                        "selectSomeItems": t('home_tasks.modal_task.notify_config.interval_period_select.selectSomeItems'),
                                                        "create": t('home_tasks.modal_task.notify_config.interval_period_select.selectSomeItems'),
                                                    }}
                                                    hasCreateItem={false}
                                                    hasNullOption={false}
                                                    onSelect={(value) => onSelectPeriod(value)}
                                                    hasAllOption={false}
                                                />
                                            </Col>
                                        </Row>
                                        <p></p>
                                        <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                                            <Col>
                                                <Form.Label>{t('home_tasks.modal_task.notify_config.interval_value_label')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                                <InputGroup>
                                                    <FormControl
                                                        isInvalid={feedbackNotificationAlertTime}
                                                        className='form-control-Default'
                                                        placeholder={t('home_tasks.modal_task.notify_config.interval_value_placeholder')}
                                                        aria-label={t('home_tasks.modal_task.notify_config.interval_value_placeholder')}
                                                        aria-describedby={t('home_tasks.modal_task.notify_config.interval_value_placeholder')}
                                                        type='number'
                                                        size='lg'
                                                        id='notification_alert_time'
                                                        name='notification_alert_time'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    <Form.Control.Feedback type='invalid' id='feedbackVariableNumber'>
                                                        {errors.notification_alert_time}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                    </>
                                    :
                                    <></>
                                }
                                <Row
                                    className="d-flex justify-content-center"
                                    style={allStyles.textFontGlay}
                                >
                                    <Col>
                                        <Form.Label htmlFor="description">{t('home_tasks.modal_task.observation_form.label')}</Form.Label>
                                        <InputGroup>
                                            <FormControl
                                                className="form-control-Default-text-area"
                                                style={{ height: '10vh' }}
                                                as="textarea"
                                                rows={10}
                                                placeholder={t('home_tasks.modal_task.observation_form.placeholder')}
                                                aria-label={t('home_tasks.modal_task.observation_form.placeholder')}
                                                aria-describedby={t('home_tasks.modal_task.observation_form.placeholder')}
                                                size="lg"
                                                type="text"
                                                id="observation"
                                                name="observation"
                                                maxLength={2000}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.observation}
                                            ></FormControl>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row className="d-flex justify-content-center mt-4">
                                    <Col xs={6} className="d-flex justify-content-start">
                                        <Button size='lg' className="buttonWhite" style={{ width: '56%' }} onClick={zerarCampos} >{t('home_tasks.modal_task.cancel')}</Button>
                                    </Col>
                                    <Col xs={6} className="d-flex justify-content-end">
                                        <Button size='lg' type='submit' className="buttonBlue" style={{ width: '56%' }}>{t('home_tasks.modal_task.save')}</Button>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>

                }

                {showEdit &&
                    <Formik
                        enableReinitialize={true}
                        initialValues={{ 
                            requester: selectedRequester, 
                            tags: selectedTags, 
                            description: dataEdit?.description, 
                            date: dataFormatada, 
                            hour: horaFormatada,
                            notification_alert_time: dataEdit.notification_alert_time,
                            observation: dataEdit?.observation ? dataEdit.observation : ''
                        }}
                        validate={values => {
                            let errors = {};

                            setDate(values.date);
                            setHour(values.hour);
                            setNotificationAlertTime(values.notification_alert_time)
                            setObservation(values.observation)

                            var today = new Date().toLocaleString().substr(0, 10)

                            if (!values.date) {
                                errors["date"] = t('home_tasks.modal_task.validate.fill_field');
                                setShowfeedbackDate(true);
                            }
                            else if (converterData(values.date).toString() === "Invalid Date") {
                                errors["date"] = t('home_tasks.modal_task.validate.invalid_date');
                                setShowfeedbackDate(true);
                            }
                            else if (converterData(values.date).getTime() < converterData(today).getTime()) {
                                errors["date"] = t('home_tasks.modal_task.validate.invalid_date');
                                setShowfeedbackDate(true);
                            }
                            else {
                                setShowfeedbackDate(false);
                            }

                            if (!values.notification_alert_time) {
                                errors["notification_alert_time"] = t('home_tasks.modal_task.validate.fill_field');
                                showFeedbackNotificationAlertTime(true)
                            } else {
                                showFeedbackNotificationAlertTime(false);
                            }

                            if (!values.hour) {
                                errors["hour"] = t('home_tasks.modal_task.validate.fill_field');
                                setShowfeedbackHour(true);
                            }
                            else if (converterHora(values.hour).toString() === "Invalid Date" || parseInt(values.hour.split(":")[0]) > 23 || parseInt(values.hour.split(":")[1]) > 59) {
                                errors["hour"] = t('home_tasks.modal_task.validate.invalid_hour');
                                setShowfeedbackHour(true);
                            }
                            else {
                                setShowfeedbackHour(false);
                            }

                            if (!values.description) {
                                errors["description"] = t('home_tasks.modal_task.validate.fill_field');
                                setShowFeedbackDescription(true);
                            } else {
                                setShowFeedbackDescription(false);
                            }

                            return errors;
                        }}
                        onSubmit={async values => {


                            const headers = UserService.getHeaders()
                            let JsonSend = {
                                "id": dataEdit.id,
                                "tags_id": [],
                                "followers_id": []
                            }

                            if (selectedAssigned[0].value !== dataEdit.assigned_employee_id)
                                JsonSend["assigned_employee_id"] = selectedAssigned[0].value

                            if (selectedType[0].value !== dataEdit?.status_task_id) {
                                JsonSend["status_task_id"] = selectedType[0].value
                            }
                            // if (selectedType[0].value !== dataEdit?.status_task_id) {
                            //     if (selectedType[0].value === TicketStatus.Atrasado) {
                            //         var today = new Date()
                            //         var date = new Date(dataEdit?.date_limit)

                            //         if (today > date) {

                            //             JsonSend["status_task_id"] = selectedType[0].value
                            //         }
                            //         else {
                            //             dispatch(setShowAlertFeedback({ message: 'Não é possível alterar o status para Atrasado. A data ainda está válida.', visibility: true, signalIcon: false }));
                            //             return
                            //         }
                            //     } else
                            //         JsonSend["status_task_id"] = selectedType[0].value
                            // }


                            if (values.date && values.hour) {
                                let fullDate = values.date.split('/')
                                let day = fullDate[0]
                                let month = fullDate[1]
                                let year = fullDate[2]

                                let formatedDate = `${year}-${month}-${day} ${values.hour}:00`
                                let inputDate = Math.floor(new Date(formatedDate).getTime() / 1000)
                                
                                JsonSend["date_limit"] = inputDate 
                            }

                            if (values.description !== '' && values.description !== dataEdit.description)
                                JsonSend["description"] = values.description

                            if (selectedFollowers.length !== 0) {
                                JsonSend["followers_id"] = selectedFollowers.map((follower) => {
                                    return follower.value
                                })
                            }

                            if (selectedTags.length !== 0) {
                                JsonSend["tags_id"] = selectedTags.map((tag) => {
                                    return tag.value
                                })
                            }

                            if (alertRegister && selectdPeriod.length !== 0) {
                                let timePeriod = selectdPeriod.map((period) => { return period.value })

                                JsonSend["should_notificate"] = alertRegister
                                JsonSend["notification_alert_time"] = notificationAlertTime.toString()
                                JsonSend["notification_alert_type"] = timePeriod.shift()
                            } else if (alertRegister && selectdPeriod.length === 0) {
                                return dispatch(setShowAlertFeedback({ message: t('home_tasks.modal_task.submit.period_value_error'), visibility: true, signalIcon: false }));
                            } else {
                                JsonSend["should_notificate"] = alertRegister
                                JsonSend["notification_alert_time"] = undefined
                                JsonSend["notification_alert_type"] = undefined
                            }

                            if (values.observation !== '') {
                                JsonSend["observation"] = values.observation
                            } else {
                                JsonSend["observation"] = ''
                            }

                            await AppRequesterConst.Put(
                                '/task', JsonSend, { headers },
                                (response: Object) => {
                                    return response;
                                },
                                (data) => {
                                    dispatch(setShowAlertFeedback({ message: t('home_tasks.modal_task.submit.updated_task'), visibility: true, signalIcon: true }));
                                    callBackModalCustomField()
                                    zerarCampos();
                                },
                                (error: { response: { status: number, data: { message: string, code_cxpress: number } } }) => {
                                    if (error.response.status === 400) {
                                        dispatch(setShowAlertFeedback({ message: t('home_tasks.modal_task.submit.unexpected_error'), visibility: true, signalIcon: false }));
                                    } else if (error.response.status === 422) {
                                        dispatch(setShowAlertFeedback({ message: t('home_tasks.modal_task.submit.datetime_exceeded_error'), visibility: true, signalIcon: false }));
                                    } else if (error.response.status === 500 && error.response.data.code_cxpress === 3602) {
                                        dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }));
                                    }
                                }, navigate, dispatch, setIsLoading
                            );
                        }
                        }
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => (
                            <Form onSubmit={handleSubmit} noValidate validated={validated}>
                                <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                                    <Col sm={true}>
                                        <Form.Label htmlFor="basic-url">{t('home_tasks.modal_task.requester_form.label')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                        <InputGroup>
                                            <FormControl
                                                isInvalid={showfeedbackDate}
                                                className="form-control-Default"
                                                type="text"
                                                size="lg"
                                                id="date"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                disabled={true}
                                                value={dataEdit.requester_employee_name}
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <p></p>
                                <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                                    <Col sm={true}>
                                        <Form.Label htmlFor="basic-url">{t('home_tasks.modal_task.assigned_form.label')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                        <SelectComponentController
                                            className={"multiselectForm mb-3"}
                                            options={allAgents}
                                            setOptions={setAllAgents}
                                            selecteds={selectedAssigned}
                                            setSelecteds={setSelectedAssigned}
                                            singleSelect={true}
                                            title={"Selecione o agente"}
                                            overrideStrings={{
                                                "clearSearch": t('home_tasks.modal_task.assigned_form.override_strings.clearSearch'),
                                                "noOptions": t('home_tasks.modal_task.assigned_form.override_strings.noOptions'),
                                                "search": t('home_tasks.modal_task.assigned_form.override_strings.search'),
                                                "selectSomeItems": t('home_tasks.modal_task.assigned_form.override_strings.selectSomeItems'),
                                                "create": t('home_tasks.modal_task.assigned_form.override_strings.create'),
                                            }}
                                            hasCreateItem={false}
                                            hasNullOption={false}
                                            // filtering={false}
                                            hasAllOption={false} isFormNoFilter={false} />

                                    </Col>
                                </Row>
                                <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                                    <Col sm={true}>
                                        <Form.Label htmlFor="basic-url">Status<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                        <SelectComponentController
                                            className={"multiselectForm mb-3"}
                                            options={type}
                                            setOptions={setType}
                                            selecteds={selectedType}
                                            setSelecteds={setSelectedType}
                                            singleSelect={true}
                                            title={t('home_tasks.modal_task.status_form.title')}
                                            overrideStrings={{
                                                "allItemsAreSelected": selectedType[0] ? selectedType[0]?.label && selectedType[0]?.value : "",
                                                "clearSearch": t('home_tasks.modal_task.status_form.override_strings.clearSearch'),
                                                "noOptions": t('home_tasks.modal_task.status_form.override_strings.noOptions'),
                                                "search": t('home_tasks.modal_task.status_form.override_strings.search'),
                                                "selectSomeItems": t('home_tasks.modal_task.status_form.override_strings.selectSomeItems'),
                                                "create": t('home_tasks.modal_task.status_form.override_strings.create'),
                                            }}
                                            hasCreateItem={false}
                                            hasNullOption={false}
                                            // filtering={false}
                                            hasAllOption={false} isFormNoFilter={false} />

                                    </Col>
                                </Row>
                                <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                                    <Col sm={true}>
                                        <Form.Label htmlFor="basic-url">{t("home_tickets.home_create_ticket.followers_field_label")}</Form.Label>
                                        <SelectComponentController
                                            options={allAgents}
                                            setOptions={setAllAgents}
                                            selecteds={selectedFollowers}
                                            setSelecteds={setSelectedFollowers}
                                            singleSelect={false}
                                            isFormNoFilter={true}
                                            title={t("home_tickets.home_create_ticket.followers_field_label")}
                                            overrideStrings={
                                                {
                                                    "allItemsAreSelected": t("home_tickets.home_create_ticket.select_component.all_items_are_selected", { items_name: t("home_tickets.home_create_ticket.followers_field_label") }),
                                                    "clearSearch": t("home_tickets.home_create_ticket.select_component.clear_search"),
                                                    "clearSelected": t("home_tickets.home_create_ticket.select_component.clear_selected", { items_name: t("home_tickets.home_create_ticket.followers_field_label") }),
                                                    "noOptions": t("home_tickets.home_create_ticket.select_component.no_options", { items_name: t("home_tickets.home_create_ticket.followers_field_label") }),
                                                    "search": t("home_tickets.home_create_ticket.select_component.search", { items_name: t("home_tickets.home_create_ticket.followers_field_label") }),
                                                    "selectAll": t("home_tickets.home_create_ticket.select_component.select_all", { items_name: t("home_tickets.home_create_ticket.followers_field_label") }),
                                                    "selectAllFiltered": t("home_tickets.home_create_ticket.select_component.select_all_filtered", { items_name: t("home_tickets.home_create_ticket.followers_field_label") }),
                                                    "selectSomeItems": t("home_tickets.home_create_ticket.followers_field_label"),
                                                    "create": t("home_tickets.home_create_ticket.select_component.create"),
                                                }
                                            }
                                            hasCreateItem={false}
                                            hasNullOption={false}
                                            onSelect={(value) => onSelectAssigned(value)}
                                            hasAllOption={false}
                                        />
                                    </Col>
                                </Row>
                                <p></p>
                                <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                                    <Col sm={true}>
                                        <Form.Label htmlFor="basic-url">{t("home_tickets.home_create_ticket.tags_field_label")}</Form.Label>
                                        <SelectComponentController
                                            options={allTags}
                                            setOptions={setAllTags}
                                            selecteds={selectedTags}
                                            setSelecteds={setSelectedTags}
                                            singleSelect={false}
                                            isFormNoFilter={true}
                                            title={t("home_tickets.home_create_ticket.tags_field_label")}
                                            overrideStrings={
                                                {
                                                    "allItemsAreSelected": t("home_tickets.home_create_ticket.select_component.all_items_are_selected", { items_name: t("home_tickets.home_create_ticket.tags_field_label") }),
                                                    "clearSearch": t("home_tickets.home_create_ticket.select_component.clear_search"),
                                                    "clearSelected": t("home_tickets.home_create_ticket.select_component.clear_selected", { items_name: t("home_tickets.home_create_ticket.tags_field_label") }),
                                                    "noOptions": t("home_tickets.home_create_ticket.select_component.no_options", { items_name: t("home_tickets.home_create_ticket.tags_field_label") }),
                                                    "search": t("home_tickets.home_create_ticket.select_component.search", { items_name: t("home_tickets.home_create_ticket.tags_field_label") }),
                                                    "selectAll": t("home_tickets.home_create_ticket.select_component.select_all", { items_name: t("home_tickets.home_create_ticket.tags_field_label") }),
                                                    "selectAllFiltered": t("home_tickets.home_create_ticket.select_component.select_all_filtered", { items_name: t("home_tickets.home_create_ticket.tags_field_label") }),
                                                    "selectSomeItems": t("home_tickets.home_create_ticket.tags_field_label"),
                                                    "create": t("home_tickets.home_create_ticket.select_component.create"),
                                                }
                                            }
                                            hasCreateItem={false}
                                            hasNullOption={false}
                                            onSelect={(value) => onSelectAssigned(value)}
                                            hasAllOption={false}
                                        />
                                    </Col>
                                </Row>
                                <p></p>
                                <Row className="d-flex justify-content-center">
                                    <Col sm={true} style={allStyles.textFontGlay} >
                                        <Form.Label htmlFor="basic-url">{t('home_tasks.modal_task.description_form.label')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                        <InputGroup>
                                            <FormControl
                                                className="form-control-Default-text-area"
                                                style={{ height: '10vh' }}
                                                isInvalid={showFeedbackDescription}
                                                as="textarea"
                                                rows={10}
                                                placeholder={t('home_tasks.modal_task.description_form.placeholder')}
                                                aria-label={t('home_tasks.modal_task.description_form.placeholder')}
                                                aria-describedby={t('home_tasks.modal_task.description_form.placeholder')}
                                                size="lg"
                                                type="text"
                                                id="description"
                                                name="description"
                                                maxLength={200}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.description}
                                            ></FormControl>
                                            <Form.Control.Feedback
                                                type="invalid"
                                                id="feedbackdescription"
                                            >
                                                {errors.description}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <p></p>
                                <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                                    <Col sm={true}>
                                        <Form.Label htmlFor="basic-url">{t('home_tasks.modal_task.limit_date.label')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                        <InputMask
                                            mask={"99/99/9999"}
                                            maskChar={"_"}
                                            value={values.date}
                                            onChange={handleChange}
                                        >
                                            {(inputProps: any) => (
                                                <InputGroup>
                                                    <FormControl
                                                        isInvalid={showfeedbackDate}
                                                        className="form-control-Default"
                                                        placeholder={t('home_tasks.modal_task.limit_date.placeholder')}
                                                        aria-label={t('home_tasks.modal_task.limit_date.placeholder')}
                                                        aria-describedby={t('home_tasks.modal_task.limit_date.placeholder')}
                                                        type="text"
                                                        size="lg"
                                                        id="date"
                                                        name="date"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    <Form.Control.Feedback
                                                        type="invalid"
                                                        id="feedbackdate"
                                                    >
                                                        {errors.date}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            )}
                                        </InputMask>
                                    </Col>
                                    <Col sm={true}>
                                        <Form.Label htmlFor="basic-url">{t('home_tasks.modal_task.limit_hour.label')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                        <InputMask
                                            mask="99:99"
                                            maskChar="_"
                                            value={values.hour}
                                            onChange={handleChange}
                                        >
                                            {(inputProps: any) => (
                                                <InputGroup>
                                                    <FormControl
                                                        className="form-control-Default"
                                                        placeholder="HH:MM"
                                                        aria-label="HH:MM"
                                                        aria-describedby="HH:MM"
                                                        type="text"
                                                        size="lg"
                                                        id="hour"
                                                        name="hour"
                                                        isInvalid={showfeedbackHour}
                                                        onBlur={handleBlur}
                                                    ></FormControl>
                                                    <Form.Control.Feedback
                                                        type="invalid"
                                                        id="feedbackHour"
                                                    >
                                                        {errors.hour}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            )}
                                        </InputMask>
                                    </Col>
                                </Row>
                                <p></p>
                                <Row style={allStyles.textFontGlay} className=' d-flex justify-content-center'>
                                    <Col style={{ display: "flex" }}>
                                        <Form.Group>
                                            <Form.Check style={{ paddingRight: "0px", marginRight: "3px" }} type='checkbox' label={t('home_tasks.modal_task.notify_config.check_label')} checked={alertRegister} onChange={() => setAlertRegister(!alertRegister)} inline></Form.Check>
                                        </Form.Group>
                                        <OverlayTrigger
                                            key='top'
                                            placement='top'
                                            overlay={
                                                <Tooltip id='tootip'>
                                                    {t('home_tasks.modal_task.notify_config.check_tooltip')}
                                                </Tooltip>
                                            }
                                        >
                                            <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 28 28" className="me-3" style={{ marginTop: '-0.1rem' }}>
                                                <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                                <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            </svg>
                                        </OverlayTrigger>
                                    </Col>
                                </Row>
                                <p></p>
                                {alertRegister ?
                                    <>
                                        <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                                            <Col>
                                                <Form.Label>{t('home_tasks.modal_task.notify_config.interval_period_label')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                                <SelectComponentController
                                                    options={allPeriod}
                                                    setOptions={setAllPeriod}
                                                    selecteds={selectdPeriod}
                                                    setSelecteds={setSelectedPeriod}
                                                    singleSelect={true}
                                                    isFormNoFilter={true}
                                                    title={t('home_tasks.modal_task.notify_config.interval_period_select.title')}
                                                    disableSearch={true}
                                                    overrideStrings={{
                                                        "allitemsAreSelected": selectdPeriod[0] ? selectdPeriod[0].label && selectdPeriod[0]?.value : "",
                                                        "clearSearch": t('home_tasks.modal_task.notify_config.interval_period_select.clearSearch'),
                                                        "clearSelected": t('home_tasks.modal_task.notify_config.interval_period_select.clearSelected'),
                                                        "noOptions": t('home_tasks.modal_task.notify_config.interval_period_select.noOptions'),
                                                        "search": t('home_tasks.modal_task.notify_config.interval_period_select.search'),
                                                        "selectAll": t('home_tasks.modal_task.notify_config.interval_period_select.selectAll'),
                                                        "selectAllFiltered": t('home_tasks.modal_task.notify_config.interval_period_select.selectAllFiltered'),
                                                        "selectSomeItems": t('home_tasks.modal_task.notify_config.interval_period_select.selectSomeItems'),
                                                        "create": t('home_tasks.modal_task.notify_config.interval_period_select.selectSomeItems'),
                                                    }}
                                                    hasCreateItem={false}
                                                    hasNullOption={false}
                                                    hasAllOption={false}
                                                />
                                            </Col>
                                        </Row>
                                        <p></p>
                                        <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                                            <Col>
                                                <Form.Label>{t('home_tasks.modal_task.notify_config.interval_value_label')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                                <InputGroup>
                                                    <FormControl
                                                        isInvalid={feedbackNotificationAlertTime}
                                                        className='form-control-Default'
                                                        placeholder={t('home_tasks.modal_task.notify_config.interval_value_placeholder')}
                                                        aria-label={t('home_tasks.modal_task.notify_config.interval_value_placeholder')}
                                                        aria-describedby={t('home_tasks.modal_task.notify_config.interval_value_placeholder')}
                                                        type='number'
                                                        maxLength={3}
                                                        size='lg'
                                                        id='notification_alert_time'
                                                        name='notification_alert_time'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.notification_alert_time}
                                                    />
                                                    <Form.Control.Feedback type='invalid' id='feedbackVariableNumber'>
                                                        {errors.notification_alert_time}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                    </>
                                : <></>}
                                <Row
                                    className="d-flex justify-content-center"
                                    style={allStyles.textFontGlay}
                                >
                                    <Col>
                                        <Form.Label htmlFor="description">{t('home_tasks.modal_task.observation_form.label')}</Form.Label>
                                        <InputGroup>
                                            <FormControl
                                                className="form-control-Default-text-area"
                                                style={{ height: '10vh' }}
                                                as="textarea"
                                                rows={10}
                                                placeholder={t('home_tasks.modal_task.observation_form.placeholder')}
                                                aria-label={t('home_tasks.modal_task.observation_form.placeholder')}
                                                aria-describedby={t('home_tasks.modal_task.observation_form.placeholder')}
                                                size="lg"
                                                type="text"
                                                id="observation"
                                                name="observation"
                                                maxLength={2000}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.observation}
                                            ></FormControl>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row className="d-flex justify-content-center mt-4">
                                    <Col xs={6} className="d-flex justify-content-start">
                                        <Button size='lg' className="buttonWhite" style={{ width: '56%' }} onClick={zerarCampos} >{t('home_tasks.modal_task.cancel')}</Button>
                                    </Col>
                                    <Col xs={6} className="d-flex justify-content-end">
                                        <Button size='lg' type='submit' className="buttonBlue" style={{ width: '56%' }}>{t('home_tasks.modal_task.save')}</Button>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                }

                {showModal &&
                    <div style={{ overflow: 'auto' }}>
                        <ListGroup variant="flux" className="TicketsRow">
                            {
                                tasks?.length > 0 &&
                                <ListGroup.Item className="TicketsRowCollums mb-3" style={{ border: 'none' }} id="headerList" key="headerList">
                                    <Row name="listOfTicketsHead">
                                        <Col md={2} className="nopadding">
                                            {t('home_tasks.list_of_tasks.limit_date')}
                                        </Col>
                                        <Col md={3} className="nopadding">
                                            {t('home_tasks.list_of_tasks.assigned')}
                                        </Col>
                                        <Col md={2} className="nopadding">
                                            {t('home_tasks.list_of_tasks.status')}
                                        </Col>
                                        <Col md className="nopadding">
                                            {t('home_tasks.list_of_tasks.description')}
                                        </Col>
                                        <Col md={2} className='d-flex justify-content-end'>
                                            {t("home_tasks.list_of_tasks.actions")}
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            }

                            <div id="listOfTickets" style={{
                                overflow: 'auto', height: '48vh', display: 'flex',
                                flexDirection: 'column',
                            }}>
                                <InfiniteScroll
                                    dataLength={20}
                                    next={updateListfunction}
                                    hasMore={true}
                                    loader={<h4> </h4>}
                                    scrollableTarget={ListName.ListOfTickets}
                                    onScroll={() => Utils.hidePopoverOnScroll(ListName.ListOfTickets)}

                                >

                                    {
                                        tasks?.length === 0 &&

                                        <div className='align-self-center' style={{ overflow: 'hidden' }}>
                                            <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '17vh' }}>
                                                    <Col md={2} className="d-flex justify-content-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-checkup-list" width="7vw" height='7vh' viewBox="0 0 24 24" stroke-width="2.0" stroke="#4a69bd" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                            <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
                                                            <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
                                                            <path d="M9 14h.01" />
                                                            <path d="M9 17h.01" />
                                                            <path d="M12 16l1 1l3 -3" />
                                                        </svg>
                                                </Col>
                                            </Row>
                                            <Row className='d-flex justify-content-center align-content-center mt-2' style={{ marginLeft: '8%' }}>
                                                <Col md={8}>
                                                    <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>{t("home_tasks.list_of_tasks.list_modal_empty")}<Button className="buttonAsText3 nopadding mb-1" onClick={alterarVisibilidadeAdd}>{t("home_tasks.list_of_tasks.list_add_task")}</Button></p>
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                    {
                                        tasks?.length > 0 &&
                                        <ListGroup variant="flush" className="TicketsRow">


                                            {
                                                tasks.map((item, index) => {

                                                    let date: Date | null = null;
                                                    let hour: string = null;
                                                    let newDate: Date | null = null

                                                    var dateLimit = item.date_limit;

                                                    var dateObj = new Date(dateLimit);

                                                    var dia = dateObj.getUTCDate();
                                                    var mes = dateObj.getUTCMonth() + 1;
                                                    var ano = dateObj.getUTCFullYear();

                                                    // Formatando a data no formato "dd/mm/aaaa"
                                                    var dataFormatada = dia.toString().padStart(2, '0') + '/' + mes.toString().padStart(2, '0') + '/' + ano;

                                                    date = newDate;
                                                    // hour = `${newDate.getHours() < 10 ? `0${newDate.getHours()}` : newDate.getHours()}:${newDate.getMinutes() < 10 ? `0${newDate.getMinutes()}` : newDate.getMinutes()}:${newDate.getSeconds() < 10 ? `0${newDate.getSeconds()}` : newDate.getSeconds()}`;

                                                    return (
                                                        <ListGroup.Item style={{ color: '#707070' }} id={item.id} key={item.id}>
                                                            <Row className='TicketsRowHeight'>
                                                                <Col md={2} className={'align-self-center nopadding'} style={{ cursor: 'default' }}>
                                                                    <OverlayTrigger key='top' placement='top' overlay={<Tooltip id='tooltip'>{dataFormatada}</Tooltip>}>
                                                                        <span>{dataFormatada}</span>
                                                                    </OverlayTrigger>
                                                                </Col>

                                                                <Col md={3} className={'align-self-center nopadding'} style={{ cursor: 'default' }}>
                                                                    <div className='oneLineClampEllipsis me-3'>
                                                                        <OverlayTrigger
                                                                            key='top'
                                                                            placement='top'
                                                                            overlay={
                                                                                <Tooltip id='tooltip'>
                                                                                    {item.assigned_employee_name}
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <div style={{
                                                                                display: 'flex',
                                                                                justifyContent: 'flex-start',
                                                                                alignItems: 'center',

                                                                            }}>
                                                                                <span style={{
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                    whiteSpace: 'nowrap',
                                                                                }}>
                                                                                    {item.assigned_employee_name}
                                                                                </span>
                                                                            </div>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                </Col>

                                                                <Col md={2} className='align-self-center nopadding' style={{ cursor: 'default' }} >
                                                                    <Col md={0}>
                                                                        {getStatusElement(item.status_task_id)}
                                                                    </Col>
                                                                </Col>
                                                                <Col md className={'align-self-center nopadding'} style={{ cursor: 'default' }}>
                                                                    <div className='oneLineClampEllipsis'>
                                                                        <OverlayTrigger
                                                                            key='top'
                                                                            placement='top'
                                                                            overlay={
                                                                                <Tooltip id='tooltip'>
                                                                                    {item.description}
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <span>
                                                                                {item.description}
                                                                            </span>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                </Col>

                                                                <Col md={2} className='d-flex justify-content-end ps-0' style={{  }}>
                                                                    <OverlayTrigger rootClose={true} trigger={['click']} placement="left" overlay={popover} /* show={ !scrolling && ((item.ticket_reference_id + "*ID*" + item.id) === currentTicketId) } */ >
                                                                        <Button id={item.id + "*ID*" + item.id} onClick={() => setTaskId(item.id)} className='nopadding d-flex justify-content-start align-self-center' style={{ marginTop: '', backgroundColor: 'transparent', border: 'none', width: "24px" }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="90%" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#6A6A6A" fill="#6A6A6A" strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                <circle cx="12" cy="12" r="1" />
                                                                                <circle cx="12" cy="19" r="1" />
                                                                                <circle cx="12" cy="5" r="1" />
                                                                            </svg>
                                                                        </Button>
                                                                    </OverlayTrigger>
                                                                </Col>
                                                            </Row>
                                                        </ListGroup.Item>
                                                    )
                                                })


                                            }
                                        </ListGroup>
                                    }

                                </InfiniteScroll>

                            </div>
                        </ListGroup>


                    </div>
                }
            </Modal.Body >
        </Modal >

    );

}


export default ModalListTasks;