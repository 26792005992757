import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { Button, Col, OverlayTrigger, Row, Tooltip, Spinner } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Editor, { createEditorStateWithText } from "@draft-js-plugins/editor";
import { EditorState, ContentState, convertFromHTML , RichUtils, Modifier, CompositeDecorator } from "draft-js";
import createToolbarPlugin from '@draft-js-plugins/static-toolbar';
import createEmojiPlugin, { defaultTheme } from '@draft-js-plugins/emoji';
import createLinkPlugin from '@draft-js-plugins/anchor';
import { stateToHTML } from 'draft-js-export-html';
import editorStyles from "./styles.module.css";
import linkStyles from './linkStyles.module.css' 
import "./toolbarStyles.css";
import "@draft-js-plugins/emoji/lib/plugin.css";
import allStyles from "./styles";
import { ErrorCode } from "react-dropzone";

import {
  setShowAlertFeedback,
} from '../../../../store/internal'

import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  UnorderedListButton,
  OrderedListButton,
  BlockquoteButton,
} from '@draft-js-plugins/buttons';
import { getCleanMessageEditor, setCleanMessageEditor } from "../../../../store/internal";
import { useAppContext } from "../../../../providers";
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import { getToken } from '../../../../store/token';
import { Channels } from "../../../../core/enums/channels";
import { ChatEditorInterface } from "./indexModel";
import { TokenInterface } from '../../../../services/requestsInterfacesModel';
import { verifyCode } from "../../../../services/codeCxpressInterface";
import { ChatEditorController } from "./indexController";
import { useTranslation } from "react-i18next";
import { TicketContext } from "../../../../core/context/ticket-context";
import { BlockTicketContext } from "../../../../core/context/block-ticket-context";

defaultTheme.emojiSelectPopover = defaultTheme.emojiSelectPopover + " emojiSelectPopoverTop";

const AppRequesterConst = new AppRequesterController();

const ChatEditor: FC<ChatEditorInterface> = (props) => {
  const [currentUser, setCurrentUser] = useState(null);
  const {t} = useTranslation();
  const [/*isLoading*/, setIsLoading] = useState(false)
  const findLinkEntities = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'LINK'
      );
    }, callback);
  };
  
  const Link = (props) => {
    const { url } = props.contentState.getEntity(props.entityKey).getData();
    return (
      <a href={url} style={{ color: 'blue' }}>
        {props.children}
      </a>
    );
  };  
  const decorator = new CompositeDecorator([{ strategy: findLinkEntities,component: Link, }]);
  const [editorState, setEditorState] = useState(props.initialHtmlMarkup ? htmlToDraftEditorState(props.initialHtmlMarkup) : EditorState.createEmpty(decorator));//useState(createEditorStateWithText(""));
  const [showDropZoneVisibility, setShowDropZoneVisibility] = useState(false);
  const [currentTicket] = useState(props.currentTicket)
  const [colorPicker, setColorPicker] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ticket = useContext(TicketContext)
  const block_ticket = useContext(BlockTicketContext)

  const values: TokenInterface = {
    token: {
      value: useSelector(getToken)
    }
  }

  const [files, setFiles] = useState([]);
  const [fileIds, setFileIds] = useState([]);
  const [state, setState] = useState("");
  const [fileLoading, setFileLoading] = useState([])
  const [maxSize, setMaxSize] = useState(0)
  const [urls, setUrls] = useState([])

  const [permission, setPermission] = useState(false)
  const mediaRecorder = useRef(null)
  const [recordingStatus, setRecordingsStatus] = useState("inactive")
  const [stream, setStream] = useState(null)
  const [disabled, setDisabled] = useState(false)
  // const [audioChunks, setAudioChunks] = useState([])
  // const [audio, setAudio] = useState(null)
  // const [audioBlob, setAudioBlob] = useState(null)
  const [fileTest, setFileTest] = useState<Array<any>>()

  const [time, setTime] = useState(0)
  const [imgUrl, setImgUrl] = useState(null);
  const [imgUrlList, setImgUrlList] = useState([]);
  const [running, setRunning] = useState(false);

  const combinedList = imgUrlList.map((url, index) => ({
    url,
    file: files[index]
  }));

  const mimeType = "audio/mpeg"

  const parentRef = React.createRef<HTMLDivElement>();
  const editorRef = React.createRef<Editor>();

  const cleanMessageEditor = useSelector(getCleanMessageEditor);

  const { setHtmlMessageContent, setTemplateMessageId, currentMessageChannel } = useAppContext();

  const previewImg = async (img) => {
    const file = img[0];
    if (file) { 
      const reader = new FileReader();
      reader.onload = function(this, img) {
        setImgUrlList((imgUrlList) => {
          const newImgUrlList = [...imgUrlList];
          if (!newImgUrlList.includes(this.result)) {
            newImgUrlList.push(this.result);
            // setImgUrlList(newImgUrlList);
          }
          // setImgUrl(img.target.result);
          return newImgUrlList;
        });
      }
      reader.readAsDataURL(file);
    }
  }

  const pasteImg = async (e)=> {
    try {
      e.preventDefault();
      let clipboardItems = (e.clipboardData || e.originalEvent.clipboardData).items
      let items = [].slice.call(clipboardItems).filter((item) => item.type.indexOf('image') !== -1)
      if (items.length === 0) {
        return
      }
      
      let acceptingFiles = [];
      for (let item of items) {  
        let blob;
        blob = item.getAsFile();
        blob = Object.assign(blob, { path: blob.name });
        // preview da imagem
        previewImg(blob);
        acceptingFiles.push(blob);
      }

      // fazendo upload das imagens
      setFiles([...files, ...acceptingFiles]);
      onDropAcceptedFiles(acceptingFiles);
    } catch(err) {
        console.log(err);
    }
  }

  const removeImg = (imgToRemove) => {
    const index = imgUrlList.indexOf(imgToRemove);
    
    if (index !== -1) {
        const newImgUrlList = [...imgUrlList];
        newImgUrlList.splice(index, 1);
        setImgUrlList(newImgUrlList);
    }
  }

  function getFileNameFromUrl(url) {
    if (url !== null) {
      const segments = url.split('/');
      return segments.pop();
    }
  }

  const sendAudio = async (audio) => {
    const formData = new FormData()
    formData.append("file", audio)
    formData.append("channel_id", currentMessageChannel)
    formData.append("audio_whatsapp", "true")

    fileLoading[files.length] = true
    setFileLoading(fileLoading)

    const headers = {
      "Content-Type": "multipart/form-data; charset=utf-8",
      "Authorization": "Bearer " + values.token.value
    }

    await AppRequesterConst.Post(
      '/chat/upload/attachment', formData, { headers },
      (response: Object) => {
        return response;
      },
      (data: { data: { files: { id: string; name: string; }[] }; }) => {
        fileLoading[files.length] = false;
        files.push(audio)
        setFileId(data.data.files);

        dispatch(setShowAlertFeedback({ message: t('home_tickets.chat_editor.upload_success'), visibility: true, signalIcon: true }));
      },
      (error: {response: { status: number; data: { message: any []; code_cxpress: number }}}) => {
        if (error.response?.data?.message && error.response.data.message[0]) {
          dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }))
        } else {
          dispatch(setShowAlertFeedback({ message: t('error.TicketErrorMessage'), visibility: true, signalIcon: false }));
        }
      }, navigate, dispatch, setIsLoading,
    )
  }

  const toggleColor = (toggledColor) => {
    const selection = editorState.getSelection();

    const nextContentState = Object.keys(ChatEditorControllerConst.getColorStyleMap())
      .reduce((contentState, color) => {
        return Modifier.removeInlineStyle(contentState, selection, color)
      }, editorState.getCurrentContent());

    let nextEditorState = EditorState.push(
      editorState,
      nextContentState,
      'change-inline-style'
    );

    const currentStyle = editorState.getCurrentInlineStyle();
    // Unset style override for current color.
    if (selection.isCollapsed()) {
      nextEditorState = currentStyle.reduce((state, color) => {
        return RichUtils.toggleInlineStyle(state, color);
      }, nextEditorState);
    }
    // If the color is being toggled on, apply it.
    if (!currentStyle.has(toggledColor)) {
      nextEditorState = RichUtils.toggleInlineStyle(
        nextEditorState,
        toggledColor
      );
    }

    setEditorState(nextEditorState);
  }

  const ChatEditorControllerConst = new ChatEditorController(setEditorState, props, stateToHTML, parentRef, setHtmlMessageContent, htmlToDraftEditorState, setTemplateMessageId, showDropZoneVisibility, setShowDropZoneVisibility,
    files, setMaxSize, maxSize, fileIds, fileLoading, setFiles, setState, state, setFileIds, setFileLoading, editorState, dispatch, setShowAlertFeedback, editorRef, props.setAudio, props.setAudioBlob, setImgUrl, setPermission, setStream, setRecordingsStatus,
    setRunning, mimeType, props.setAudioChunks, stream, mediaRecorder, setTime, props.audioChunks, sendAudio, props.audio, props.audioBlob, setColorPicker, disabled, setDisabled, toggleColor, t, setImgUrlList, setUrls, props.currentRequester_user, props.templateParamsGeneralType)

  const colorStyleMap = ChatEditorControllerConst.getColorStyleMap();

  const onDropAcceptedFiles = (dropedFiles) => {
    try {
      let dropedFilesLength = dropedFiles.map((file) => file.size);

      // verificando tamanho total para requisição, mensagem de erro esta no useffect do acceptedFiles
      if (maxSize + dropedFilesLength.reduce((sumSize, size) => { return size + sumSize; }, 0) <= 15728640) {          
        // let promise = [new Promise()]
        let dropedPreviewImgs = dropedFiles;
        dropedPreviewImgs.reduce((promise, file) => { 
          if ((file.type)?.startsWith("image")) {
            if (promise[0]) {
              promise[0].finally(() => {
                promise[0] = previewImg([file]);
              });
            } else {
              promise[0] = previewImg([file]);
            }
          } else {
            imgUrlList.push("doc");
          }

          return promise;
        }, []);

        let jsonSend = new FormData();
        dropedFiles.forEach(element => {
          jsonSend.append("files", element)
        });
        jsonSend.append("channel_id", currentMessageChannel.toString());
        jsonSend.append("audio_whatsapp", "false")

        // adicionando loading nos arquivos recentes
        const fileLoadingLength = fileLoading.length;
        for (let index = fileLoadingLength; index < fileLoadingLength + dropedFiles.length;  index++) {
          fileLoading[index] = true;
        }
        setFileLoading(fileLoading);

        const headers = {
          "Content-Type": "multipart/form-data; boundary=---011000010111000001101001",
          "Authorization": "Bearer " + values.token.value
        };

        AppRequesterConst.Post(
          '/chat/upload/attachment', jsonSend, { headers },
          (response: Object) => {
            return response;
          },
          (data: { data: { files: { id: string; name: string; }[] }; }) => {
            setFileId(data.data.files);
            setMaxSize(maxSize + dropedFilesLength.reduce((sumSize, size) => { return size + sumSize; }, 0));
            dispatch(setShowAlertFeedback({ message: t('home_tickets.chat_editor.upload_success'), visibility: true, signalIcon: true }));
          },
          (error: {response: {status: number; data: { message: any []; code_cxpress: number}}}) => {
            if (error.response?.data?.message && error.response.data.message[0]) {
              dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
            } else {
              dispatch(setShowAlertFeedback({ message: t('error.TicketErrorMessage'), visibility: true, signalIcon: false }))
            }
          }, navigate, dispatch, setIsLoading, () => {
            // removendo loading dos arquivos recentes
            for (let index = 0; index < fileLoading.length; index++) {
              fileLoading[index] = false;
            }
            setFileLoading(fileLoading);
          }
        );
      }
    } catch (error) {
      // console.log(error)
    }
  }

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: true,
    minSize: 1,
    maxSize: 15728640,
    onDrop: onDropAcceptedFiles,
    onDropRejected: (reject) => {
      // if (reject[0].errors[0].code === ErrorCode.FileTooLarge) {
      //   dispatch(setShowAlertFeedback({ visibility: true, message: t('home_tickets.chat_editor.file_length'), signalIcon: false }));
      // } else if (reject[0].errors[0].code === ErrorCode.FileTooSmall) {
      //   dispatch(setShowAlertFeedback({ visibility: true, message: t('home_tickets.chat_editor.empty_files'), signalIcon: false }));
      // } else if (reject[0].errors[0].code === ErrorCode.FileInvalidType) {
      //   if (currentMessageChannel === Channels.Instagram) {
      //     dispatch(setShowAlertFeedback({ visibility: true, message: t('home_tickets.chat_editor.image_files_only'), signalIcon: false }));
      //   } else if (currentMessageChannel === Channels.WhatsApp) {
      //     dispatch(setShowAlertFeedback({ visibility: true, message: t('home_tickets.chat_editor.media_files_only'), signalIcon: false }));
      //   } else if (currentMessageChannel === Channels.Webchat) {
      //     dispatch(setShowAlertFeedback({ visibility: true, message: t('home_tickets.chat_editor.document_files_only'), signalIcon: false }));
      //   } else {
      //     dispatch(setShowAlertFeedback({ visibility: true, message: t('home_tickets.chat_editor.files_not_supported'), signalIcon: false }));
      //   }
      // }
    },
    // reject specifics files types
    validator: (file) => {
      if (file.type === "" || file.type === "application/octet-stream" || file.type === "application/x-msdownload" || file.type === "vnd.microsoft.portable-executable") {
        return { message: t('home_tickets.chat_editor.unknown_file'), code: ErrorCode.FileInvalidType };
      }
      return null;
    },
    accept:
      props.messageTriggerModal/*  && (props.messageTriggerChannel === Channels.Email || props.messageTriggerChannel === Channels.WhatsApp) */? 
        {
          // imagens que foram colocadas especificamente para imagens
          /* 'image/gif': ['.gif'],
          'image/vnd.microsoft.icon': ['.ico'],
          'image/jpeg': ['.jpeg', '.jpg'],
          'image/png': ['.png'],
          'image/bmp': ['.bmp'],
          'image/tiff': ['.tif', '.tiff'], */

          'video/mpeg': ['.mpeg'],
          'video/mp4': ['.mp4'],
          'audio/mpeg': ['.mpeg', '.mp3'],
          'audio/aac': ['.aac'],
          'audio/amr': ['.amr'],
          'image/jpeg': ['.jpeg', '.jpg'],
          'image/png': ['.png'],
          'application/pdf': ['.pdf'],
          'application/msword': ['.doc'],
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
          'application/vnd.ms-powerpoint': ['.ppt'],
          'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
          'application/vnd.ms-excel': ['.xls'],
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        }:
      currentMessageChannel === Channels.Instagram ?
        {
          'image/gif': ['.gif'],
          'image/jpeg': ['.jpeg'],
          'image/png': ['.png'],
          'video/mp4': ['.mp4'],
        }:
      currentMessageChannel === Channels.Email?
        {
          'application/msword': ['.doc'],
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
          'application/vnd.ms-excel': ['.xls'],
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
          'application/vnd.ms-powerpoint': ['.ppt'],
          'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
          'application/vnd.ms-xpsdocument': ['.xps'],
          'image/vnd.microsoft.icon': ['.ico'],
          'application/oxps': ['.oxps'],
          'application/pdf': ['.pdf', '.ai'],
          'image/vnd.adobe.photoshop': ['.psd'],
          'application/postscript': ['.eps', '.ai', '.ps'],
          'application/eps': ['.eps', '.ai', '.ps'],
          'application/x-eps': ['.eps', '.ps'],
          'image/eps': ['.eps', '.ps'],
          'image/x-eps': ['.eps', '.ps'],
          'image/svg+xml': ['.svg'],
          'font/ttf': ['.ttf'],
          'application/zip': ['.zip'],
          'application/vnd.rar': ['.rar'],
          'application/x-tar': ['.tar'],
          'application/gzip': ['.gzip', '.gz'],
          'audio/mpeg': ['.mpeg', '.mp3'],
          'audio/wav': ['.wav'],
          'application/ogg': ['.ogg'],
          'image/jpeg': ['.jpeg', '.jpg'],
          'image/png': ['.png'],
          'image/gif': ['.gif'],
          'image/bmp': ['.bmp'],
          'image/tiff': ['.tif', '.tiff'],
          'video/webm': ['.webm'],
          'video/mp4': ['.mp4'],
          'video/3gpp': ['.3gpp', '.3gp'],
          'video/quicktime': ['.mov'],
          'video/x-msvideo': ['.avi'],
          'video/x-ms-wmv': ['.wmv'],
          'text/plain': ['.txt'],
          'text/csv': ['.csv'],
          'text/calendar': ['.ics'],
          'audio/midi': ['.mid', '.midi'],
          'audio/x-midi': ['.mid', '.midi'],
          'video/mpeg': ['.mpeg'],
          'application/vnd.oasis.opendocument.presentation': ['.odp'],
          'application/vnd.oasis.opendocument.spreadsheet': ['.ods'],
          'application/vnd.oasis.opendocument.text': ['.odt'],
          'font/otf': ['.otf'],
          'application/rtf': ['.rtf'],
          'application/x-7z-compressed': ['.7z']
        }:
      currentMessageChannel === Channels.WhatsApp?
        {
          'video/mp4': ['.mp4'],
          'video/mpeg': ['.mpeg'],
          'audio/mpeg': ['.mpeg', '.mp3'],
          'audio/aac': ['.aac'],
          'audio/amr': ['.amr'],
          'image/jpeg': ['.jpeg', '.jpg'],
          'image/png': ['.png'],
          'application/pdf': ['.pdf'],
          'application/msword': ['.doc'],
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
          'application/vnd.ms-excel': ['.xls'],
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
          'application/vnd.ms-powerpoint': ['.ppt'],
          'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
        }:
      currentMessageChannel === Channels.Facebook?
        {
          'image/jpeg': ['.jpeg', '.jpg'],
          'image/png': ['.png'],
          'image/tiff': ['.tif', '.tiff'],
          'image/gif': ['.gif'],
          'audio/x-matroska': [".mka"],
          'audio/x-mod': ['.mod'],
          'audio/mpeg': ['.mpeg', '.mp3'],
          'audio/wav': ['.wav'],
          'audio/x-flac': ['.flac'],
          'audio/ogg': ['.ogg'],
          'audio/aac': ['.aac'],
          'audio/x-ms-wma': ['.wma'],
          'audio/3gpp2': ['.3g2'],
          'audio/3gpp': ['.3gp'],
          'video/3gpp2': ['.3g2'],
          'video/3gpp': ['.3gp', '.3gpp'],
          'video/x-ms-asf': ['.asf'],
          'video/x-msvideo': ['.avi'],
          'video/divx': ['.divx'],
          'video/x-dv': ['.dv'],
          'video/x-f4v': ['f4v'],
          'video/x-flv': [".flv"],
          'video/mp2t': ['.m2ts', '.mts', '.ts'],
          'video/x-m4v': ['.m4v'],
          'video/x-matroska': [".mkv"],
          'video/quicktime': ['.mov', '.qt'],
          'video/mp4': ['.mp4'],
          'video/mpeg': ['.mpe', '.mpeg', '.mpg', '.tod'],
          'video/x-nsv': ['.nsv', '.NSV'],
          'video/nsv': ['.nsv', '.NSV'],
          'video/ogg': ['.ogv'],
          'video/MP2T': ['MP2T'],
          'video/x-ms-vob': ['.vob'],
          'video/x-ms-wmv': ['.wmv'],
          'application/x-pds': ['.dat'],
          'application/vnd.ms-asf': ['.asf'],
          'application/ogg': ['.ogg', '.ogm'],
          'application/msword': ['.doc'],
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
          'application/vnd.ms-excel': ['.xls'],
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
          'application/vnd.ms-powerpoint': ['.ppt'],
          'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
          'application/rtf': ['.rtf'],
          'text/plain': ['.txt'],
        }:
      currentMessageChannel === Channels.Webchat?
        {
          'application/pdf': ['.pdf'],
          'text/plain': ['.txt'],
          'image/jpeg': ['.jpeg', '.jpg'],
          'image/png': ['.png'],
        }
        : {}
  });

  const [{ Toolbar, draftPlugins, EmojiSelect, linkPlugin }] = useState(() => {
    let positionSuggestions = ({ state, props }) => { //state e props não são usados aqui
      return {};
    };
    let emojiPlugin = createEmojiPlugin({
      positionSuggestions: positionSuggestions,
      useNativeArt: true,
    });
    let linkPlugin = createLinkPlugin({
      placeholder: t('home_tickets.chat_editor.hiperlink_placeholder'),
      theme: {input: linkStyles.input, inputInvalid: linkStyles.inputInvalid, link: linkStyles.link}
    });
    let staticToolbarPlugin = createToolbarPlugin();
    let draftPlugins = [staticToolbarPlugin, emojiPlugin, linkPlugin];
    let { EmojiSelect, /*EmojiSuggestions*/ } = emojiPlugin;
    let { Toolbar } = staticToolbarPlugin;

    return { Toolbar, draftPlugins, EmojiSelect, linkPlugin };
  });

  useEffect(() => {

    let allFiles = files.concat(acceptedFiles);

    allFiles = [...new Map(allFiles.map(file => [file.name, file])).values()];

    allFiles.map(file => {
      const returnedTarget = Object.assign(file, { isLoading: true });
      file = returnedTarget;
    });

    let dropedFilesLength = allFiles.map((file) => file.size);
    if (maxSize + dropedFilesLength.reduce((sumSize, size) => { return size + sumSize; }, 0) > 15728640) {
      // remove esses novos arquivos com tamanho extrapolando
      allFiles = allFiles.filter((item) => !(acceptedFiles.map((file) => file.name).includes(item.path)));
      dispatch(setShowAlertFeedback({ visibility: true, message: t('home_tickets.chat_editor.all_files_length'), signalIcon: false }));
    } 

    setFiles(allFiles);

    if (allFiles.length > 0) {
      if(props.messageTriggerModal !== true)
        props?.setChangeChatHeight(true)
      if (props.cleanFiles) {
        props.afterMessageSent();
        setFiles([]);
        setFileIds([]);
        setFileLoading([]);
        setMaxSize(0);
      }
    } else {
      if(props.messageTriggerModal !== true)
        props?.setChangeChatHeight(false);
    }

    setShowDropZoneVisibility(false);
    acceptedFiles.forEach((file, i) => { acceptedFiles.pop() });

    if (cleanMessageEditor) {
      setEditorState(createEditorStateWithText(""));
      ChatEditorControllerConst.cleanAllFiles();
      dispatch(setCleanMessageEditor(false));
      props.setQuickMessage(null)
    }

  }, [acceptedFiles, cleanMessageEditor, props.templateMessage]);

  // useEffect(() => {
  //   urlToFileObject(imgUrl)
  // }, [imgUrl])
  useEffect(() => {
    if (props.templateMessage) {
      ChatEditorControllerConst.templateMessage(props.templateMessage, props.currentTicket);
      if (props.templateMessage.image_url) {
        setImgUrl(props.templateMessage.image_url);
      }
      if (props.messageTriggerModal === true) {
        props.callbackGetStringHtml(parentRef.current, stateToHTML(editorState.getCurrentContent(),{ defaultBlockTag: 'span' }));
      }
    }
    else if(props.quickMessage) {
      ChatEditorControllerConst.quickMessage(props.quickMessage, props.currentTicket);
      if (props.messageTriggerModal === true) {
        props.callbackGetStringHtml(parentRef.current, stateToHTML(editorState.getCurrentContent()));
      }
    }
    else {
      if(currentUser === null || currentUser?.id !== props?.currentRequester_user?.id) {
        setEditorState(createEditorStateWithText(""));
        setCurrentUser(props.currentRequester_user)
      }
    }
  }, [props.templateMessage, props.quickMessage, props.currentRequester_user]);

  useEffect(() => {
    props.callbackGetStringHtml(parentRef.current, stateToHTML(editorState.getCurrentContent(), { defaultBlockTag: 'span' }));

  }, [stateToHTML(editorState.getCurrentContent())]);

  // useEffect(() => { /* verificando se a quickMessage ainda é a selecionada, senão, não enviar o id dela mais */
  //   if(stateToHTML(editorState.getCurrentContent()).replace(/<\/?[^>]+(>|$)/g, "").replace(/\s+/g, ' ').trim() !== props?.quickMessage?.message){
  //     props.setQuickMessage(null)
  //     setImgUrl(null)
  //   }
  // }, [stateToHTML(editorState.getCurrentContent())])



  useEffect(() => {
    setEditorState(props.initialHtmlMarkup ? htmlToDraftEditorState(props.initialHtmlMarkup) : EditorState.createEmpty())
  }, []);


  // useEffect(() => {
  //   if (editorRef.current !== null) {
  //     editorRef.current.setReadOnly(block_ticket.isAllowedMessage);
  //   }
  // }, [block_ticket.isAllowedMessage]);

  useEffect(() => {
    let ids = []
    if (props.onDropFiles) {
      props.onDropFiles(
        fileIds.map((item) => {
          ids = fileIds.map(item => item?.id);
          return item.id
        })
      );
    }

    // files ids para o pai, parecido com a callback onDropFiles
    if (props?.setFilesIds) {
      props?.setFilesIds(ids)
    }

    // para disparo ativo
    if(fileIds.length > 0 && props.messageTriggerModal !== true) {
      props?.setChangeChatHeight(true);
    } else if (fileIds.length === 0 && props.messageTriggerModal !== true) {
      props?.setChangeChatHeight(false);
    }
  }, [state, fileIds.length])

  // Timer from audio
  useEffect(() => {
    let interval;

    if (running) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 10);
      }, 10);
    } else if (!running) {
      clearInterval(interval)
    }
    return () => clearInterval(interval)

  }, [running])

  // bloqueando adição de arquivos pelo quantidade se for pelo canal de whatsapp
  useEffect(() => {
    if (files?.length >= 1 && props.messageChannel === Channels.WhatsApp) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [files, fileIds]);

  // const onChange = (editorState: { getCurrentContent: () => { (): Function; new(): Function; hasText: { (): Function; new(): Function; }; }; }) => {
  //   setEditorState(editorState);

  //   /*if (props.isAllowedMessage === false) {
  //     setHtmlMessageContent(null);
  //   }*/

  //   if (props.messageTriggerModal === true) {
  //     let html = stateToHTML(editorState.getCurrentContent());
  //     props.callbackGetStringHtml(parentRef.current, html);
  //   }


  //   // envio usando o botao de salvar ticket
  //   if (editorState.getCurrentContent().hasText()) {
  //     let html = stateToHTML(editorState.getCurrentContent());
  //     setHtmlMessageContent(html);
  //   } else {
  //     setHtmlMessageContent(null);
  //   }
  // };

  // const templateMessage = (templateMessage: { message: React.SetStateAction<string>; id: React.SetStateAction<string>; }) => {
  //   setEditorState(htmlToDraftEditorState(templateMessage.message));
  //   setTemplateMessageId(templateMessage.id)
  //   setHtmlMessageContent(templateMessage.message)
  //   props.callbackGetStringHtml(parentRef.current, stateToHTML(htmlToDraftEditorState(templateMessage.message).getCurrentContent()));
  // }

  // const onOfDropZone = (e: Object) => {
  //   if (showDropZoneVisibility) {
  //     setShowDropZoneVisibility(false)
  //   } else {
  //     setShowDropZoneVisibility(true)
  //   }
  // }

  // const deleteFile = (fileName: string) => {
  //   let newFiles = files.filter((item) => item.path !== fileName);
  //   let sizeDeletedFile = files.filter((item) => item.path === fileName);
  //   setMaxSize(maxSize - sizeDeletedFile[0].size)
  //   fileIds.forEach((item) => {
  //     if (item.name == fileName) {
  //       fileIds.splice(fileIds.indexOf(item), 1)
  //       fileLoading.splice(fileIds.indexOf(item), 1)
  //     }
  //   });
  //   setFiles(newFiles);
  // }

  // const setFileId = (id: string, name: string) => {
  //   let hasName = false
  //   fileIds.forEach((item) => {

  //     if (item.name == name) {

  //       item.id = id
  //       hasName = true
  //       fileLoading.splice(fileIds.indexOf(item), 1)

  //       setState("Editado")
  //       if (state == "Editado") {
  //         setState("")
  //       }

  //     }

  //   });
  //   if (hasName == false) {
  //     fileIds.push({ id: id, name: name });
  //   }
  // }

  // const cleanAllFiles = () => {
  //   props.afterMessageSent();
  //   setFiles([]);
  //   setFileIds([]);
  //   setFileLoading([]);
  //   setMaxSize(0);
  // }

  const handleDeleteFile = (fileName, file) => {
    ChatEditorControllerConst.deleteFile(fileName);
    removeImg(file);
  }

  // atualiza o fileIds
  const setFileId = (files: { id: string; name: string; } []) => {
    files.map((file) => {
      setFileIds((currentFileIds) => {
        // '_' é o separador do timestamp, mas pode estar no nome original do arquivo
        let fileNameSlices = file.name.split("_");
        // como o timestamp precisa ser removido as duas últimas partes serão desconsideradas
        let firstSlice = fileNameSlices.slice(0, fileNameSlices.length - 2).join("_");
        // a ultima parte contem a extensão do arquivo que precisa estar no nome
        let name = firstSlice += "." + fileNameSlices[fileNameSlices.length - 1].split(".")[1];
        // let name = file.name;

        let id = file.id;
        let hasName = false;

        currentFileIds.map((fileId) => {
          if (fileId.name === name) {
            fileLoading[fileIds.indexOf(fileId)] = false;
            hasName = true;

            setState("Editado")
            if (state == "Editado") {
              setState("")
            }
          }
        });

        return [...currentFileIds, ...(hasName === false? [{ id: id, name: name }]: []) ];
      });

      // this.fileIds.forEach((item) => {
      //   if (item.name == name) {
  
      //     item.id = id
      //     hasName = true
      //     this.fileLoading.splice(this.fileIds.indexOf(item), 1)
  
      //     this.setState("Editado")
      //     if (this.state == "Editado") {
      //       this.setState("")
      //     }
      //   }
      // });

      // if (hasName == false) {
      //   this.fileIds.push({ id: id, name: name });
      // }
    });
  }

  const showFiles = files.map((file, index) => (
    <span key={index}
      style={{
        background: '#E8E8E8 0% 0% no-repeat padding-box',
        borderRadius: '3px',
        opacity: '1',
        marginRight: '0.5vw',
        marginBottom: '1vh',
        whiteSpace: 'pre', display: 'inline-block'
      }}>
      {(file.type)?.startsWith("image") ?
        <></>
        :
        <>
          <svg id="Grupo_11419" data-name="Grupo 11419" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path id="Caminho_9725" data-name="Caminho 9725" d="M0,0H24V24H0Z" fill="none" />
            <path id="Caminho_9726" data-name="Caminho 9726" d="M14,3V7a1,1,0,0,0,1,1h4" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            <path id="Caminho_9727" data-name="Caminho 9727" d="M17,21H7a2,2,0,0,1-2-2V5A2,2,0,0,1,7,3h7l5,5V19A2,2,0,0,1,17,21Z" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            <line id="Linha_391" data-name="Linha 391" y2="6" transform="translate(12 11)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            <path id="Caminho_9728" data-name="Caminho 9728" d="M9,14l3-3,3,3" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
          </svg>
          <span
            style={{
              textAlign: 'left',
              font: 'var(--px10_13-font-Roboto)',
              letterSpacing: '0px',
              color: '#1E1E1E',
              opacity: '1',
            }}
            className='oneLineClampEllipsisNoDisplay'
          >
            {file.name}
          </span>
          {fileLoading[index] === false ? file.isLoading = false : ""}
          {!fileLoading[index] ?
            <svg name={file.name} onClick={() => handleDeleteFile(file.name, 'doc') /* ChatEditorControllerConst.deleteFile(file.name) */} style={{ cursor: 'pointer', marginRight: '3px', marginLeft: '3px' }} id="Grupo_11428" data-name="Grupo 11428" xmlns="http://www.w3.org/2000/svg" width="14.438" height="14.438" viewBox="0 0 14.438 14.438">
              <path id="Caminho_9729" data-name="Caminho 9729" d="M0,0H14.438V14.438H0Z" fill="none" />
              <line id="Linha_392" data-name="Linha 392" x1="7" y2="7" transform="translate(4 3.438)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
              <line id="Linha_393" data-name="Linha 393" x2="7" y2="7" transform="translate(4 3.438)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            </svg>
            :
            <Spinner animation="border" role="status" style={{ marginRight: '3px', marginLeft: '3px', width: "14.438px", height: "14.438px" }} >
            </Spinner>
          }
        </>
      }
    </span>
  ));

  // const buttonAttachment = (stateVisibility: boolean) => {
  //   if (stateVisibility) {
  //     return (
  //       <div className="bi09khh">
  //         <button
  //           className="buttonAttachmentFocus"
  //           onClick={(e) => ChatEditorControllerConst.onOfDropZone(e)}
  //         >
  //           <svg id="Grupo_11416" data-name="Grupo 11416" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  //             <path id="Caminho_9714" data-name="Caminho 9714" d="M0,0H24V24H0Z" fill="none" />
  //             <path id="Caminho_9715" data-name="Caminho 9715" d="M15,7,8.5,13.5a2.121,2.121,0,0,0,3,3L18,10a4.243,4.243,0,1,0-6-6L5.5,10.5a6.364,6.364,0,0,0,9,9L21,13" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
  //           </svg>
  //         </button>
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div className="bi09khh">
  //         <button
  //           className="buttonAttachmentBlur"
  //           onClick={(e) => ChatEditorControllerConst.onOfDropZone(e)}
  //         >
  //           <svg id="Grupo_11416" data-name="Grupo 11416" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  //             <path id="Caminho_9714" data-name="Caminho 9714" d="M0,0H24V24H0Z" fill="none" />
  //             <path id="Caminho_9715" data-name="Caminho 9715" d="M15,7,8.5,13.5a2.121,2.121,0,0,0,3,3L18,10a4.243,4.243,0,1,0-6-6L5.5,10.5a6.364,6.364,0,0,0,9,9L21,13" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
  //           </svg>
  //         </button>
  //       </div>
  //     );
  //   }
  // }

  // const exportStateToStringHtml = () => {
  //   let isLoadingFiles = false;
  //   files.map(file => {
  //     if (file.isLoading === true) {
  //       isLoadingFiles = true;
  //     }
  //   });
  //   if (!isLoadingFiles) {
  //     if (editorState.getCurrentContent().hasText()) {
  //       props.callbackGetStringHtml(parentRef.current, stateToHTML(editorState.getCurrentContent()));
  //       props.sendMessage();
  //       ChatEditorControllerConst.cleanAllFiles();
  //     } else {
  //       if (files.length > 0) {
  //         props.callbackGetStringHtml(parentRef.current, '<p>Segue anexo!</p>');
  //         props.sendMessage();
  //         ChatEditorControllerConst.cleanAllFiles();
  //       } else {
  //         dispatch(setShowAlertFeedback({ visibility: true, message: "Você só pode enviar mensagens vazias se elas tiverem anexo!", signalIcon: false }));
  //       }
  //     }
  //   } else {
  //     dispatch(setShowAlertFeedback({ visibility: true, message: "Não enviado. Existem anexos não carregados", signalIcon: false }));
  //   }

  // }

  // const getMicrophonePermission = async () => {
  //   if("MediaRecorder" in window) {
  //     try {
  //       const streamData = await navigator.mediaDevices.getUserMedia({
  //         audio: true,
  //         video: false
  //       });
  //       setPermission(true)
  //       setStream(streamData)
  //     } catch (err) {
  //       alert(err.message)
  //     }
  //   } else {
  //     alert ("The MediaRecorder API is not supported in your browser.")
  //   }
  // }

  // const startRecording = async () => {
  //   setRecordingsStatus("recording")
  //   setRunning(true)
  //   // create new Media recorder instance using the stream
  //   const media = new MediaRecorder(stream);
  //   // set the MediaRecorder instance to the mediaRecorder ref
  //   mediaRecorder.current = media
  //   // invokes the start method to start the recording process
  //   mediaRecorder.current.start();

  //   let localAudioChunks = [];
  //   mediaRecorder.current.ondataavailable = (event) => {
  //     if (typeof event.data === "undefined") return;
  //     if (event.data.size === 0) return;
  //     localAudioChunks.push(event.data)
  //   }
  //   setAudioChunks(localAudioChunks)
  // }

  // const stopRecording = () => {
  //   setRecordingsStatus("inactive")
  //   setRunning(false)
  //   setTime(0)
  //   // stops the recording instance
  //   mediaRecorder.current.stop();
  //   mediaRecorder.current.onstop = () => {
  //     // creates a blob file from the audiochunks data
  //     const audioBlob = new Blob(audioChunks, { type: mimeType });
  //     // creates a playable URL from the blob file.
  //     const audioUrl = URL.createObjectURL(audioBlob);
  //     const returnedTarget = Object.assign(audioBlob, { isLoading: true, name: "audio.mp3", path: "audio.mp3"});
  //     let file = new File([returnedTarget], returnedTarget.name, {type: returnedTarget.type })
  //     sendAudio(Object.assign(file, { path: returnedTarget.name }))
  //     setAudio(audioUrl)
  //   }
  // }

  const clearAll = () => {
    props.setAudio(null)
    props.setAudioBlob(null)
  }


  function htmlToDraftEditorState(htmlMarkup: string) {
    let sanitizedHtmlMarkup = htmlMarkup.replace(/\n/g, '<br />');
    let blocksFromHTML = convertFromHTML(sanitizedHtmlMarkup);
    let newEditorState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );
    return EditorState.createWithContent(newEditorState, decorator);
  }

  const focusEditor = () => {
    editorRef.current.focus();
  }

  return (
    <div className="nopadding"
      style={{
        backgroundColor: props.blockColor === true ? '#E9ECEF' : 'transparent',
        border: props.messageTriggerModal === true ? '1px solid #ced4da' : 'none',
        width: '100%',
        height: '100%',
        // height: props.messageTriggerModal === true ? `200px` : `none`,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: props.messageTriggerModal === true ? '4px' : 'none',
      }}>
      <div className="py-1 nopadding"
        style={allStyles.textFilesZone}
        onClick={() => ChatEditorControllerConst.focusEditor()}
      >
        <div style={allStyles.changeEditorDropzone} name="editorOrDropfile">
          {showDropZoneVisibility ?
            <div {...getRootProps({ className: 'dropzone' })}
              className={props.messageTriggerModal ? "mt-2 mx-3" : ""}
              style={allStyles.dragDropzone}>
              <input {...getInputProps({})} />
              <div style={{ font: 'var(--px15_19_Bold-font-Roboto) !important' }}>{t('home_tickets.chat_editor.drag_file')}</div>
            </div>
            :
            <>
              <div id={props.id} ref={parentRef} className={editorStyles.editor} style={{ minHeight: props.isAllowedMessage === true && '3vh' }} onPaste={(e) => pasteImg(e)}>
                <Editor
                  readOnly={props.messageTriggerModal ? 
                    ((props.viewTriggerModal && props.messageChannel === Channels.Email) || ((props.isAllowedMessage || !props.isAllowedMessage) && props.messageChannel !== Channels.Email) === false ? false : true)
                    : block_ticket.readOnlyEditorComponent}
                  editorState={editorState}
                  onChange={(editorState) => { ChatEditorControllerConst.onChange(editorState) }}
                  plugins={draftPlugins}
                  customStyleMap={colorStyleMap}
                  ref={editorRef}
                  keyBindingFn={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      ChatEditorControllerConst.exportStateToStringHtml(editorState, props.messageChannel);
                      setTimeout(() => {
                        // ADDED LOGIC TO RESET BUTTON FOCUS
                        document.getElementById("sendButtonMessage").focus();
                        document.getElementById("sendButtonMessage").blur();
                      }, 50)
                    }
                  }}
                />
              </div>
            </>
          }
        </div>
        {showFiles.length > 0 &&
          <div style={allStyles.showAllFiles} name="showFilesName" className="ps-2 py-1 pt-2">
            <div style={{ display: 'block' }}>
              {showFiles}
            </div>
          </div>
        }
      </div>

      <div>
        {props.audio
          ?
          <div style={{ display: "flex", alignItems: "center" }}>
            <audio style={{ paddingBottom: "10px", paddingLeft: "10px" }} src={props.audio} controls></audio>
            <div style={{ paddingBottom: "10px", paddingLeft: "5px" }}>
              <button onClick={() => clearAll()} style={{ border: "none", backgroundColor: "transparent" }}>
                <svg
                  viewBox="0 0 512 512"
                  fill="currentColor"
                  height="1em"
                  width="1em"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    strokeMiterlimit={10}
                    strokeWidth={32}
                    d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
                  />
                  <path
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={32}
                    d="M320 320L192 192M192 320l128-128"
                  />
                </svg>
              </button>
            </div>
          </div>
          :
          null
        }

        {/* os anexos do QuickMessage */}
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginLeft: '10px', marginBottom: '5px' }}>
        {
          imgUrl && imgUrl.match(/\.(jpeg|png|jpg|gif|tif|tiff|ico|bmp|psd|svg)$/) ?
            <div style={{ display: 'block', /* backgroundColor: '#e8e8e8', */ /* padding: '4px', */ paddingBottom: '8px', height: '110px', width: '110px', marginRight: '5px' }}>
              <div style={{ display: 'block', backgroundColor: '#e8e8e8', padding: '4px', paddingBottom: '20px', height: '110px', width: '110px', position: 'relative' }} className="d-flex flex-column">
                <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', backgroundColor: '#f8f9fa', flex: '1 0 auto', height: '100%', width: '100%' }}>

                  <img style={{ maxHeight: '100%', maxWidth: '100%' }} src={imgUrl} id="imageQuick"></img>

                </div>
                
                <div style={{ textAlign: 'left', font: 'var(--px10_13-font-Roboto)', letterSpacing: '0px', color: '#1E1E1E', opacity: '1', padding: '5px',  }}>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                           {getFileNameFromUrl(imgUrl)}
                        </Tooltip>
                      }
                    >
                      <p style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '100%'
                      }}>
                        {getFileNameFromUrl(imgUrl)}
                      </p>
                    </OverlayTrigger>
                    </div>
                {/* se for apagado a image, não mandar a quickMessage, já que foi alterado */}
                {
                  props.templateMessage.image_url ?
                  undefined
                  :
                  <button onClick={() => {setImgUrl(null); props.setQuickMessage(null)}} style={{ border: "none", backgroundColor: "transparent", position: 'absolute', top: '-6px', right: '-15px' }}>
                    <svg
                      viewBox="0 0 512 512"
                      fill="currentColor"
                      height="1em"
                      width="1em"
                    >
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeMiterlimit={10}
                        strokeWidth={32}
                        d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
                      />
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={32}
                        d="M320 320L192 192M192 320l128-128"
                      />
                    </svg>
                  </button>
                }
              </div>
            </div>
            : 
            imgUrl &&
            // <div style={{ display: 'block', /* backgroundColor: '#e8e8e8', */ padding: '4px', paddingBottom: '8px' }}>
            //   <span
            //     style={{
            //       background: '#E8E8E8 0% 0% no-repeat padding-box',
            //       borderRadius: '3px',
            //       opacity: '1',
            //       marginRight: '0.5vw',
            //       marginBottom: '1vh',
            //       whiteSpace: 'pre', display: 'inline-block'
            //     }}>
            //     <svg id="Grupo_11419" data-name="Grupo 11419" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            //       <path id="Caminho_9725" data-name="Caminho 9725" d="M0,0H24V24H0Z" fill="none" />
            //       <path id="Caminho_9726" data-name="Caminho 9726" d="M14,3V7a1,1,0,0,0,1,1h4" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            //       <path id="Caminho_9727" data-name="Caminho 9727" d="M17,21H7a2,2,0,0,1-2-2V5A2,2,0,0,1,7,3h7l5,5V19A2,2,0,0,1,17,21Z" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            //       <line id="Linha_391" data-name="Linha 391" y2="6" transform="translate(12 11)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            //       <path id="Caminho_9728" data-name="Caminho 9728" d="M9,14l3-3,3,3" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            //     </svg>
            //     <span
            //       style={{
            //         textAlign: 'left',
            //         font: 'var(--px10_13-font-Roboto)',
            //         letterSpacing: '0px',
            //         color: '#1E1E1E',
            //         opacity: '1',
            //       }}
            //       className='oneLineClampEllipsisNoDisplay'
            //     >
            //       {getFileNameFromUrl(imgUrl)}
            //     </span>
            //     <svg name={getFileNameFromUrl(imgUrl)} onClick={() => { setImgUrl(null); props.setQuickMessage(null) } /* ChatEditorControllerConst.deleteFile(file.name) */} style={{ cursor: 'pointer', marginRight: '3px', marginLeft: '3px' }} id="Grupo_11428" data-name="Grupo 11428" xmlns="http://www.w3.org/2000/svg" width="14.438" height="14.438" viewBox="0 0 14.438 14.438">
            //       <path id="Caminho_9729" data-name="Caminho 9729" d="M0,0H14.438V14.438H0Z" fill="none" />
            //       <line id="Linha_392" data-name="Linha 392" x1="7" y2="7" transform="translate(4 3.438)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            //       <line id="Linha_393" data-name="Linha 393" x2="7" y2="7" transform="translate(4 3.438)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            //     </svg>
            //   </span>
            // </div>

            <div style={{ display: 'flex', flexShrink: 0, gap: '10px', marginLeft: '4px', marginBottom: '5px',  width: '100%' }}>
                  <div style={{ background: '#E8E8E8', padding: '5px', fontSize: 'small', height: '2em' }}>
                    <a target='_blank'href={imgUrl} rel="noreferrer">{imgUrl}</a>
                    <svg name={getFileNameFromUrl(imgUrl)} onClick={() => { setImgUrl(null); props.setQuickMessage(null) } /* ChatEditorControllerConst.deleteFile(file.name) */} style={{ cursor: 'pointer', marginRight: '3px', marginLeft: '3px' }} id="Grupo_11428" data-name="Grupo 11428" xmlns="http://www.w3.org/2000/svg" width="14.438" height="14.438" viewBox="0 0 14.438 14.438">
                      <path id="Caminho_9729" data-name="Caminho 9729" d="M0,0H14.438V14.438H0Z" fill="none" />
                      <line id="Linha_392" data-name="Linha 392" x1="7" y2="7" transform="translate(4 3.438)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                      <line id="Linha_393" data-name="Linha 393" x2="7" y2="7" transform="translate(4 3.438)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    </svg>
                  </div>
            </div>
        }
          {
            combinedList.map((item, index) => (
              item.url !== 'doc' &&
              <div key={index} style={{ display: 'block', backgroundColor: '#e8e8e8', padding: '4px', paddingBottom: '20px', height: '110px', width: '110px', position: 'relative' }} className="d-flex flex-column">
                <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', backgroundColor: '#f8f9fa', flex: '1 0 auto', height: '100%', width: '100%' }}>
                  <img style={{ maxHeight: '100%', maxWidth: '100%' }} src={item.url} />
                </div>
                <div style={{ textAlign: 'left', font: 'var(--px10_13-font-Roboto)', letterSpacing: '0px', color: '#1E1E1E', opacity: '1', padding: '5px',  }}>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                           {item?.file?.name}
                        </Tooltip>
                      }
                    >
                      <p style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '100%'
                      }}>
                        {item?.file?.name}
                      </p>
                    </OverlayTrigger>
                  {fileLoading[index] === false ? item.file.isLoading = false : ""}
                  {!fileLoading[index] ?
                    <button onClick={() => handleDeleteFile(item?.file?.name, item.url)} style={{ border: "none", backgroundColor: "transparent", position: 'absolute', top: '-6px', right: '-15px' }}>
                      <svg
                        viewBox="0 0 512 512"
                        fill="currentColor"
                        height="1.5em"
                        width="1.5em"
                      >
                        <path
                          fill="none"
                          stroke="currentColor"
                          strokeMiterlimit={10}
                          strokeWidth={32}
                          d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
                        />
                        <path
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={32}
                          d="M320 320L192 192M192 320l128-128"
                        />
                      </svg>
                    </button>
                    :
                    <Spinner animation="border" role="status" style={{ width: "14.438px", height: "14.438px", position: 'absolute', top: '-6px', right: '-15px' }} >
                    </Spinner>
                  }
                </div>
              </div>
            ))
          }
        </div>
      </div>

      <Row
        style={{
          width: '100%',
          //height: '18%',
          marginLeft: '0px'

        }}
        className="toolbar">
        <Col className="nopadding d-flex align-items-center">
          <div className="nopadding">
            {block_ticket.isAllowedMessage || (!block_ticket.isAllowedMessage && props.messageChannel === Channels.Email)?
              <Toolbar>
                {
                  (externalProps) => (
                    <div className="d-flex">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            {t('home_tickets.chat_editor.bold')}
                          </Tooltip>
                        }
                        rootClose
                      >
                        <div className="nopadding" style={{ width: '36px' }}>
                          <BoldButton {...externalProps} />
                        </div>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            {t('home_tickets.chat_editor.italic')}
                          </Tooltip>
                        }
                        rootClose
                      >
                        <div className="nopadding" style={{ width: '36px' }}>
                          <ItalicButton {...externalProps} />
                        </div>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            {t('home_tickets.chat_editor.underline')}
                          </Tooltip>
                        }
                        rootClose
                      >
                        <div className="nopadding" style={{ width: '36px' }}>
                          <UnderlineButton {...externalProps} />
                        </div>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            {t('home_tickets.chat_editor.list')}
                          </Tooltip>
                        }
                        rootClose
                      >
                        <div className="nopadding" style={{ width: '36px' }}>
                          <UnorderedListButton {...externalProps} />
                        </div>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            {t('home_tickets.chat_editor.sorted_list')}
                          </Tooltip>
                        }
                      >
                        <div className="nopadding" style={{ width: '36px' }}>
                          <OrderedListButton {...externalProps} />
                        </div>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            {t('home_tickets.chat_editor.hiperlink')}
                          </Tooltip>
                        }
                      >
                        <div className="nopadding" style={{ width: '36px' }}>
                          <linkPlugin.LinkButton {...externalProps}/>
                        </div>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            {t('home_tickets.chat_editor.paragraph')}
                          </Tooltip>
                        }
                        rootClose
                      >

                        <div className="nopadding" style={{ width: '36px' }}>
                          <BlockquoteButton {...externalProps} />
                        </div>
                      </OverlayTrigger>
                      {/* <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip>
                            {t('home_tickets.chat_editor.text_color')}
                          </Tooltip>
                        }
                        rootClose
                      >

                        <div className="nopadding" style={{ width: '36px' }}>
                        {ChatEditorControllerConst.colorPicker(colorPicker)}
                        </div>
                      </OverlayTrigger> */}
                      {/* { props.messageTriggerModal === true(props.messageTriggerModal === true && props.viewTriggerModal == false) && (props.messageTriggerChannel === Channels.Email || props.messageTriggerChannel === Channels.WhatsApp)?
                        <></>:
                        <>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                Adicionar imagem
                              </Tooltip>
                            }
                            rootClose
                          >
                            {ChatEditorControllerConst.buttonAttachmentImage(showDropZoneVisibility)}
                          </OverlayTrigger>
                        </>
                      } */}
                      {props.messageTriggerModal === true || props.audio != null || recordingStatus === "recording" ?
                        <></>
                        :
                        <>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                {t('home_tickets.chat_editor.add_attachment')}
                              </Tooltip>
                            }
                            rootClose
                          >
                            {ChatEditorControllerConst.buttonAttachment(showDropZoneVisibility)}
                          </OverlayTrigger>
                        </>
                      }
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            {t('home_tickets.chat_editor.emoji')}
                          </Tooltip>
                        }
                        rootClose
                      >
                        <div className="nopadding" style={{ width: '36px' }}>
                          <EmojiSelect />
                        </div>
                      </OverlayTrigger>
                      {props.messageTriggerModal === true
                        ?
                        <></>
                        :
                        <>
                          {props?.currentTicket !== null && props?.currentTicket?.channel_id === Channels.WhatsApp
                            ?
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip>
                                  {!permission && recordingStatus === "inactive" ? t("home_tickets.messages_component.allow_microphone") : ''}
                                  {permission && recordingStatus === "inactive" ? t("home_tickets.messages_component.record_audio_message") : ''}
                                  {recordingStatus === "recording" ? 'Parar gravação' : ''}
                                </Tooltip>
                              }
                              rootClose
                            >
                              <div className="nopadding" style={{ width: '36px', display: "flex", alignItems: "center" }}>
                                <div className="bi09khh">
                                  {!permission ? (
                                    <button className="buttonAttachmentFocus" onClick={() => ChatEditorControllerConst.getMicrophonePermission()}>
                                      <svg
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        height="2em"
                                        width="2em"
                                      >
                                        <path d="M17.3 11c0 3-2.54 5.1-5.3 5.1S6.7 14 6.7 11H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c3.28-.49 6-3.31 6-6.72m-8.2-6.1c0-.66.54-1.2 1.2-1.2.66 0 1.2.54 1.2 1.2l-.01 6.2c0 .66-.53 1.2-1.19 1.2-.66 0-1.2-.54-1.2-1.2M12 14a3 3 0 003-3V5a3 3 0 00-3-3 3 3 0 00-3 3v6a3 3 0 003 3z" />
                                      </svg>
                                    </button>
                                  ) : null}
                                  {permission && recordingStatus === "inactive" ? (
                                    <div>
                                      <button className="buttonAttachmentFocus" onClick={() => ChatEditorControllerConst.startRecording()}>
                                        <svg
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          height="2em"
                                          width="2em"
                                        >
                                          <path d="M7 6v12l10-6z" />
                                        </svg>
                                      </button>
                                    </div>
                                  ) : null}
                                  {recordingStatus === "recording" ? (
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                      <button className="buttonAttachmentFocus" onClick={() => ChatEditorControllerConst.stopRecording()}>
                                        <svg
                                          viewBox="0 0 384 512"
                                          fill="currentColor"
                                          height="1em"
                                          width="1em"
                                          {...props}
                                        >
                                          <path d="M0 128c0-35.3 28.7-64 64-64h256c35.3 0 64 28.7 64 64v256c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z" />
                                        </svg>
                                      </button>
                                      {/* <input {...getInputProps({})} /> */}
                                      <div style={{ paddingLeft: "5px" }}>
                                        <span>{("0" + Math.floor((time / 60000) % 60)).slice(-2)}:</span>
                                        <span>{("0" + Math.floor((time / 1000) % 60)).slice(-2)}</span>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </OverlayTrigger>
                            :
                            <></>
                          }
                        </>
                      }
                    </div>
                  )
                }
              </Toolbar> :
              /* (props.messageTriggerModal === true && props.viewTriggerModal === false) && (props.messageTriggerChannel === Channels.Email || props.messageTriggerChannel === Channels.WhatsApp) ?
                <Toolbar>
                  {
                    (externalProps) => (
                      <div className="d-flex mb-1">
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip>
                              Adicionar imagem
                            </Tooltip>
                          }
                          rootClose
                        >
                          {ChatEditorControllerConst.buttonAttachmentImage(showDropZoneVisibility)}
                        </OverlayTrigger>
                      </div>
                    )
                  }
                </Toolbar>: */
              <></>
            }
          </div>
        </Col>
        {(props.messageTriggerModal === true) ?
          <></>
          :
          <Col className="nopadding d-flex justify-content-end" >
            <Button id="sendButtonMessage" type="submit" onClick={() => ChatEditorControllerConst.exportStateToStringHtml(editorState, props.messageChannel)} className={editorStyles.buttonBlueSendMessage} style={{ width: '95px', marginRight: '12px' }} disabled={props.showSubjectError != null ? props.showSubjectError : false}>{t('home_tickets.chat_editor.send')}</Button>
          </Col>
        }
      </Row>
    </div>
  );
}

export default ChatEditor;
