import { useState, useEffect, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux'

import { getToken } from '../../../../store/token';
import { getIdUser, getNameUser, getProfileId } from '../../../../store/user';
import { getIdCompany } from '../../../../store/company';

import {
  setShowAlertFeedback,
  setShowCreateDepartmentModal,
} from '../../../../store/internal'
import { IModalRegisterDepartmentsController } from './indexModel';
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import { useNavigate } from 'react-router-dom';
import { TokenInterface } from '../../../../services/requestsInterfacesModel';

import "@yaireo/tagify/dist/tagify.css"
import { DataAgentInterface, DataCompanyEmailAvailableInterface } from '../../../../services/requestsInterfacesModel';

import UserService from '../../../../services/user-service';
import { useTranslation } from 'react-i18next';
import ModalRegisterDepartments from '.';
import { verifyCode } from '../../../../services/codeCxpressInterface';
import { Channels } from '../../../../core/enums/channels';

const AppRequesterConst = new AppRequesterController();

const ModalRegisterDepartmentsController = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [validated] = useState(false);

  const [department_name, setDepartment_name] = useState("");

  const [department_isActive, setDepartment_isActive] = useState(true);

  const [showfeedbackName, setShowfeedbackName] = useState(false);
  const [selected_agents, setSelected_agents] = useState([]);
  const [selected_emails, setSelected_emails] = useState([]);
  const [selected_keyWords, setSelected_keyWords] = useState([]);

  const [/*sector_id*/, setSector_id] = useState(null as string || null);

  const [options_agents, setOptions_agents] = useState([]);

  const [options_emails, setOptions_emails] = useState([]);

  const [modalShow, setModalShow] = useState(props.visibility);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [channelKeys, setChannelKeys] = useState([]);
  const [selectedChannelKeys, setSelectedChannelKeys] = useState([]);
  const [whatsappKeys, setWhatsappKeys] = useState([]);
  const [facebookKeys, setFacebookKeys] = useState([]);
  const [instagramKeys, setInstagramKeys] = useState([]);

  const values: TokenInterface = {
    company: {
      id: useSelector(getIdCompany)
    },
    user: {
      id: useSelector(getIdUser),
      name: useSelector(getNameUser),
      profile_id: useSelector(getProfileId)
    },
    token: {
      value: useSelector(getToken)
    },
    internal: {
    }
  };

  // concatenando keys
  useEffect(() => {
    setChannelKeys([...whatsappKeys, ...facebookKeys, ...instagramKeys]);
  }, [whatsappKeys, facebookKeys, instagramKeys]);

  useEffect(() => {
    setModalShow(props.visibility);
    if (props.visibility === true) {
      Promise.allSettled([
        getCompanyEmail(),
        getAgents(),
        getChannelKeys()
      ])
    }
  }, [props.visibility])

  const getCompanyEmail = async () => {

    const headers = UserService.getHeaders();

    await AppRequesterConst.Get(
      '/company/email/available', { headers },
      (response: Object) => {
        return response;
      },
      (data: DataCompanyEmailAvailableInterface) => {
        if (data.status === 200) {
          setOptions_emails(data.data.emails.length > 0 ? data.data.emails.map((item) => ({ label: item.email, value: item.id })) : []);
        }
      },
      (error: Object) => {
      }, navigate, dispatch, setIsLoading, { values: values }
    );
  }

  const getAgents = async () => {
    const headers = UserService.getHeaders();
    await AppRequesterConst.Get(
      '/agent', { headers },
      (response: Object) => {
        return response;
      },
      (data: DataAgentInterface) => {
        if (data.status === 200) {
          setOptions_agents(data.data.employees.length > 0 ? data.data.employees.map((item) => ({ label: `${item.name} ${item.lastname ? item.lastname : ""}`, value: item.user_id })) : []);
        }
      },
      (error: Object) => {
      }, navigate, dispatch, setIsLoading, { values: values }
    );
  }

  const Hide = () => {
    setSelected_agents([]);
    setSelected_emails([]);
    setSelected_keyWords([]);
    setShowfeedbackName(false);
    dispatch(setShowCreateDepartmentModal(false));
    setModalShow(false);
    setDepartment_isActive(true)
    setSelectedChannelKeys([]);
  }

  const onChangeTags = (e: { detail: { tagify: { getCleanValue: () => any[]; }; }; }) => {
    setSelected_keyWords(e.detail.tagify.getCleanValue().map((tag) => {
      return tag.value;
    }));
  }

  const validate = (values) => {
    let errors = {};
    setDepartment_name(values.name);

    if (!values.name) {
      errors["name"] = t('home_departments.modal_register_departments.validate.empty_field');
      setShowfeedbackName(true);
    } else {
      setShowfeedbackName(false);
    }

    return errors;
  }

  const onSubmit = async () => {
    let JsonSend_sector = {
      "name": department_name,
      "active": department_isActive,
    }

    if (selected_emails && selected_emails.length > 0) {
      JsonSend_sector["emails_id"] = selected_emails.map(({ value }) => value);
    }

    let whatsapp_keys = [];
    let facebook_keys = [];
    let instagram_keys = [];
    if (selectedChannelKeys?.length > 0) {
      selectedChannelKeys.map((item) => {
        if (item.key_type === "instagram") {
          instagram_keys.push(item.value);
        } else if (item.key_type === "facebook") {
          facebook_keys.push(item.value);
        } else {
          whatsapp_keys.push(item.value);
        }
      });
    }
    JsonSend_sector['instagram_keys_id'] = instagram_keys;
    JsonSend_sector['facebook_keys_id'] = facebook_keys;
    JsonSend_sector['whatsapp_keys_id'] = whatsapp_keys;

    if (selected_agents && selected_agents.length > 0) {
      JsonSend_sector["employees_user_ids"] = selected_agents.map(({ value }) => value);
    }
    if (selected_keyWords && selected_keyWords.length > 0) {
      JsonSend_sector["key_words"] = selected_keyWords;
    }

    const headers = {
      "Content-Type": "application/json; charset=utf-8",
      "Authorization": "Bearer " + values.token.value
    };

    await AppRequesterConst.Post(
      '/sector', JsonSend_sector, { headers },
      (response: Object) => {
        return response;
      },
      (data: { data: { sector: { name: string; id: string; }; }; }) => {
        dispatch(setShowAlertFeedback({ message: `${t('home_departments.modal_register_departments.create_success_a')} ${data.data.sector.name} ${t('home_departments.modal_register_departments.create_success_b')}`, visibility: true, signalIcon: true }));
        props.callback(data.data.sector.id);
        setSector_id(data.data.sector.id);
        Hide();
      },
      (error: { response: { status: number; data: { message: string, code_cxpress: number; } } }) => {
        if (error.response.status == 400) {
          if (error.response.data.code_cxpress === 2708) {
            dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
          } else if (error.response.data.code_cxpress === 2711) {
            dispatch(setShowAlertFeedback({ message: t('errors.SectorNumberRegistered'), visibility: true, signalIcon: false }));
          } else if (error.response.data.code_cxpress === 2709) {
            dispatch(setShowAlertFeedback({ message: t('home_departments.modal_register_departments.existent_department') + department_name + ".", visibility: true, signalIcon: false }));
          } else if (error.response.data.code_cxpress === 2714) {
            dispatch(setShowAlertFeedback({ message: t('home_departments.modal_register_departments.existent_key'), visibility: true, signalIcon: false }));
          } else if (error.response.data.code_cxpress === 2715) {
            dispatch(setShowAlertFeedback({ message: t('home_departments.modal_register_departments.inactive_key'), visibility: true, signalIcon: false }));
          } else {
            dispatch(setShowAlertFeedback({ message: t('errors.defaultErrorMessage'), visibility: true, signalIcon: false }));
          }
        } else {
          dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }))
        }
        // dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }));
      }, navigate, dispatch, setIsLoading,
      () => { },
      {
        error500: (data: { name: string; }) => {
          dispatch(setShowAlertFeedback({ message: t('home_departments.modal_register_departments.existent_department') + data.name + ".", visibility: true, signalIcon: false }));
        }
      }
    );
  }

  const getChannelKeys = async () => {
    const headers = UserService.getHeaders();

    await AppRequesterConst.Get(
      'channel-whatsapp/whatsapp-numbers', { headers },
      (response: Object) => {
        return response;
      },
      (data) => {
        if (data.status === 200) {
          setWhatsappKeys(data.data.whatsapp_numbers.map((item) => {
            let label = (`${item.subaccount_name} - ${item.phone_number}`)

            return {
              label: label,
              value: item.id,
              channel_icon: Channels.WhatsApp,
              key_type: "whatsapp",
            }
          }));
        }
      },
      (error: Object) => {
      }, navigate, dispatch, setIsLoading, { values: values }
    );

    // listagem de instagram
    await AppRequesterConst.Get(
      'channel-instagram/list-pages-by-user', { headers },
      (response: Object) => {
        return response;
      },
      (data) => {
        if (data.status === 200) {
          setInstagramKeys(data.data.keys.filter((item) => item.isActive).map((item) => {
            return {
              label: item.pageName,
              value: item.id,
              key_type: "instagram",
              channel_icon: Channels.Instagram,
            }
          }));
        }
      },
      (error: Object) => {
      }, navigate, dispatch, setIsLoading, { values: values }
    );

    // listagem de facebook
    await AppRequesterConst.Get(
      'channel-facebook/list-pages-by-user', { headers },
      (response: Object) => {
        return response;
      },
      (data) => {
        if (data.status === 200) {
          setFacebookKeys(
            data.data.keys.filter((item) => item.isActive).map((item) => (
              {
                label: item.pageName,
                value: item.id,
                key_type: "facebook",
                channel_icon: Channels.Facebook,
              }
            ))
          );
        }
      },
      (error: Object) => {
      }, navigate, dispatch, setIsLoading, { values: values }
    );
  }

  return <ModalRegisterDepartments
    modalShow={modalShow}
    Hide={Hide}
    values={values}
    options_agents={options_agents}
    setOptions_agents={setOptions_agents}
    selected_agents={selected_agents}
    setSelected_agents={setSelected_agents}
    options_emails={options_emails}
    setOptions_emails={setOptions_emails}
    selected_emails={selected_emails}
    setSelected_emails={setSelected_emails}
    selected_keyWords={selected_keyWords}
    onChangeTags={onChangeTags}
    setDepartment_name={setDepartment_name}
    setDepartment_isActive={setDepartment_isActive}
    department_isActive={department_isActive}
    department_name={department_name}
    setSector_id={setSector_id}
    navigate={navigate}
    dispatch={dispatch}
    isLoading={isLoading}
    setIsLoading={setIsLoading}
    t={t}
    validated={validated}
    setShowfeedbackName={setShowfeedbackName}
    showfeedbackName={showfeedbackName}
    AppRequesterConst={AppRequesterConst}
    validate={validate}
    onSubmit={onSubmit}
    channelKeys={channelKeys}
    setChannelKeys={setChannelKeys}
    selectedChannelKeys={selectedChannelKeys}
    setSelectedChannelKeys={setSelectedChannelKeys}
  />
}

export default ModalRegisterDepartmentsController;