import { Button, Col, Container, FormControl, InputGroup, ListGroup, Modal, OverlayTrigger, Row, Tooltip, Form } from "react-bootstrap"
import TagsComponent from "../tagsComponent"
import "./styles.scss";
// import allStyles from "./styles";

import { t } from "i18next"
import FilterOptionComponentController from "../filterOptionComponent/indexController";
import SelectComponentController from "../selectComponent/indexController";
import { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import { IconCalendarEvent } from "@tabler/icons-react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { setShowAlertFeedback } from "../../../../store/internal";
import { useNavigate } from "react-router-dom";
import Loading from "../../../../components/loading";


const DateInput = forwardRef<any, any>((props, ref) => (
    <div className="ticket_export-date-input" ref={ref} onClick={props.onClick} aria-labelledby="Data">
        <input type='text' placeholder={t("summary_dashboard.register_summary_modal.placeholder_date")} onChange={props.onChange} value={props.value} />
        <IconCalendarEvent />
    </div>
));

const ModalRegisterSummary = ({
    modalShow,
    Hide,
    channelOptions,
    setChannelOptions,
    selectedChannel,
    setSelectedChannel,
    tagsOptions,
    setTagsOptions,
    selectedTags,
    setSelectedTags,
    startDate,
    endDate,
    onChangeDate,
    calculateMaxDate,
    isDateSelectable,
    token,
    AppRequesterConst,
    setIsLoading,
    isValidDate,
    enableRegister,
    updateListfunction,
    isLoading,
    setSummaryTitle,
    summaryTitle,
    setShowfeedbackName,
    showfeedbackName
}) => {

    const dispatch = useDispatch()
    const navigate = useNavigate();

    return (
        <Modal className="modal-register-summary" show={modalShow} onHide={Hide} centered size="sm">
            <Loading visibility={isLoading} customStyle={{ position: 'absolute' }} shapeStyle={{ margin: '-60px' }} ></Loading>
            {
                enableRegister === true ?
                <>
                        <Modal.Header closeButton >
                            <Modal.Title className="textBlack">
                                {t("summary_dashboard.register_summary_modal.title")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Formik
                                initialValues={{ title: '', canal: '', tags: '', date: '' }}
                                validate={values => {
                                    let errors = {};
                                    setSummaryTitle(values.title);
                      
                                    if (!values.title) {
                                      errors["title"] = t('home_departments.modal_register_departments.validate.empty_field');
                                      setShowfeedbackName(true);
                                    } else {
                                      setShowfeedbackName(false);
                                    }

                                    return errors;
                                  }}
                                onSubmit={async () => {
                                    let JsonSend = {
                                        "title": summaryTitle,                                        
                                        "start_date": startDate,
                                        "end_date": endDate
                                    }

                                    if (selectedChannel.length !== 0) {
                                        JsonSend['channel'] = selectedChannel.map(channel => channel.value).join(',');
                                    }

                                    if(selectedTags.length !== 0){

                                        JsonSend['tags'] = selectedTags.map(item => item.value).join(','); /* lista de tags como unica string separados por virgula */
                                    }

                                    const headers = {
                                        "Content-Type": "application/json; charset=utf-8",
                                        "Authorization": "Bearer " + token
                                    };

                                    await AppRequesterConst.Post(
                                        '/summary', JsonSend, { headers },
                                        (response: Object) => {
                                            return response;
                                        },
                                        (data: any) => {
                                            setIsLoading(true)
                                            dispatch(setShowAlertFeedback({ message: t("summary_dashboard.register_summary_modal.message_success"), visibility: true, signalIcon: true }));
                                            updateListfunction()
                                            Hide()
                                        },
                                        (error: { response: { status: number; data: { message: { message: string, path: []; }, code_cxpress: number; }; }; }) => {
                                            if (error.response !== undefined) {
                                                if (error.response.status === 400) {
                                                    if (error.response.data.code_cxpress === 5701) {
                                                        dispatch(setShowAlertFeedback({ message: t("summary_dashboard.register_summary_modal.message_not_enough_messages"), visibility: true, signalIcon: false }));
                                                    } else if (error.response.data.code_cxpress === 5704) {
                                                        dispatch(setShowAlertFeedback({ message: t("summary_dashboard.register_summary_modal.message_date_lenght"), visibility: true, signalIcon: false }));
                                                    } else if (error.response.data.code_cxpress === 5706) {
                                                        dispatch(setShowAlertFeedback({ message: t("summary_dashboard.register_summary_modal.message_summary_already_exists"), visibility: true, signalIcon: false }));
                                                    }
                                                } else {
                                                    dispatch(setShowAlertFeedback({ message: t("errors.defaultErrorMessage"), visibility: true, signalIcon: false }));
                                                }
                                            }
                                        }, navigate, dispatch, setIsLoading
                                    )

                                }}>
                                {({
                                    values,
                                    setValues,
                                    errors,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit
                                }) => (
                                <Form onSubmit={handleSubmit} noValidate>
                                    <Row className="textFontGray">
                                        <Col sm={true}>
                                            <Form.Label htmlFor="title">{t('summary_dashboard.register_summary_modal.modal_title')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                            <InputGroup>
                                                <FormControl className="form-control-Default"
                                                    isInvalid={showfeedbackName}
                                                    placeholder={t('summary_dashboard.register_summary_modal.place_holder')}
                                                    aria-label={t('summary_dashboard.register_summary_modal.place_holder')}
                                                    aria-describedby={t('summary_dashboard.register_summary_modal.place_holder')}
                                                    size='lg'
                                                    type='title'
                                                    id="title"
                                                    name="title"
                                                    autoComplete='given-name'
                                                    maxLength={50}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.title}
                                                >
                                                </FormControl>
                                                <Form.Control.Feedback type="invalid" id="feedbackname">
                                                    {errors.title}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3 textFontGray">
                                        <Col sm={true} style={{width: "40%"}}>
                                            <Form.Label htmlFor="channels">{t('summary_dashboard.register_summary_modal.channel')}</Form.Label>
                                            <SelectComponentController
                                                className="selectButton"
                                                options={channelOptions}
                                                setOptions={setChannelOptions}
                                                selecteds={selectedChannel}
                                                setSelecteds={setSelectedChannel}
                                                singleSelect={false}
                                                isFormNoFilter={false}
                                                title="Canal"
                                                overrideStrings={{
                                                    allItemsAreSelected: t('filter_option.allItemsAreSelectedCanal'),
                                                    clearSearch: t('filter_option.clearSearchCanal'),
                                                    clearSelected: t('filter_option.clearSelectedCanal'),
                                                    noOptions: t('filter_option.noOptionsCanal'),
                                                    search: t('filter_option.searchCanal'),
                                                    selectAll: t('filter_option.selectAllCanal'),
                                                    selectAllFiltered: t('filter_option.selectAllFilteredCanal'),
                                                    selectSomeItems: t('filter_option.canal'),
                                                    create: t('filter_option.create'),
                                                }}
                                                hasCreateItem={false}
                                                hasNullOption={false}
                                                hasAllOption
                                                isChannel
                                            />
                                        </Col>
                                        <Col sm={true} style={{width: "40%"}}>
                                            <Form.Label htmlFor="tags">{t('summary_dashboard.register_summary_modal.tags')}</Form.Label>
                                            <SelectComponentController
                                                className="selectButton"
                                                options={tagsOptions}
                                                setOptions={setTagsOptions}
                                                selecteds={selectedTags}
                                                setSelecteds={setSelectedTags}
                                                singleSelect={false}
                                                isFormNoFilter={false}
                                                title="Tags"
                                                overrideStrings={{
                                                    allItemsAreSelected: t("filter_option.allItemsAreSelectedTag"),
                                                    clearSearch: t("filter_option.clearSearchTag"),
                                                    clearSelected: t("filter_option.clearSelectedTag"),
                                                    noOptions: t("filter_option.noOptionsTag"),
                                                    search: t("filter_option.searchTag"),
                                                    selectAll: t("filter_option.selectAllTag"),
                                                    selectAllFiltered: t("filter_option.selectAllFilteredTag"),
                                                    selectSomeItems: t("filter_option.tags"),
                                                    create: t("filter_option.create"),
                                                }}
                                                hasCreateItem={false}
                                                hasNullOption={false}
                                                hasAllOption
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mt-3 textFontGray">
                                        <Col sm={true}>
                                            <Form.Label htmlFor="periodDate">{t('summary_dashboard.register_summary_modal.date_range')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                                <OverlayTrigger
                                                    key='top'
                                                    placement='top'
                                                    overlay={
                                                        <Tooltip id='tooltip'>
                                                            {t("summary_dashboard.register_summary_modal.message_range_info")}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <svg
                                                        id="informação"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        className="me-3"
                                                        style={{ marginTop: '-0.1rem', marginLeft: '0.5rem' }}
                                                    >
                                                        <path id="Caminho_10010" d="M0,0H24V24H0Z" fill="none" />
                                                        <circle id="Elipse_1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <line id="Linha_604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <path id="Caminho_10011" d="M11,12h1v4h1" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                    </svg>
                                                </OverlayTrigger>
                                                <Row className="textFontGray" style={{width: '100%', marginLeft: 0.9}}>
                                                    <DatePicker
                                                        dateFormat={t("summary_dashboard.register_summary_modal.date_format")} /* alterar para estilo do idioma */
                                                        placeholderText={t("summary_dashboard.register_summary_modal.placeholder_date")}
                                                        selectsRange
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        onChange={onChangeDate}
                                                        maxDate={calculateMaxDate(startDate)}
                                                        filterDate={isDateSelectable}
                                                        allowSameDay={false}
                                                        customInput={<DateInput />}
                                                        onCalendarClose={() => {
                                                            if (!endDate) /* se não tiver a data final, zerar a data de inicio */
                                                                onChangeDate([null, null])
                                                        }}
                                                        
                                                    />
                                                </Row>
                                        </Col>
                                    </Row>

                                    <Row className="d-flex justify-content-center mt-4">
                                        <Col xs={6} className="d-flex justify-content-start">
                                            <Button size='lg' className="buttonWhite" style={{ width: '80%' }} onClick={Hide} >{t('summary_dashboard.register_summary_modal.button_cancel')}</Button>
                                        </Col>
                                        <Col xs={6} className="d-flex justify-content-end">
                                            <Button size='lg' type='submit' className="buttonBlue" style={{ width: '80%' }} disabled={!isValidDate} >{t('summary_dashboard.register_summary_modal.button_create')}</Button>
                                        </Col>
                                    </Row>
                                </Form>
                                ) }
                            </Formik>

                        </Modal.Body>
                        
                    </>
                    :
                    /* CASO A EMPRESA NÃO POSSA CRIAR NOVOS SUMÁRIOS */
                    <>
                        <Modal.Body>
                            {t("summary_dashboard.register_summary_modal.warning_no_register_summary")}
                        </Modal.Body>

                        <div className="d-flex justify-content-end">
                            <Button id="filterButton" className="buttonBlue m-3 " style={{ width: "30%" }} onClick={() => Hide()} /* disabled={!isValidDate} */>
                                {t('summary_dashboard.register_summary_modal.button_close')}
                            </Button>
                        </div>
                    </>
            }
        </Modal>
    )
}

export default ModalRegisterSummary
