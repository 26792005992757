import React from 'react';
import { Modal, Button, Form, InputGroup, FormControl, Col, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import styles from './styles';
import UserService from '../../../../services/user-service';
import { setShowAlertFeedback } from '../../../../store/internal';
import { TokenInterface } from '../../../../services/requestsInterfacesModel';
import { useSelector } from 'react-redux';
import { getToken } from '../../../../store/token';


const ModalRegisterFacebookPage = ({
    show,
    handleClose,
    cnpj,
    clearFields,
    validated,
    showfeedbackName,
    showfeedbackId,
    showfeedbackToken,
    showfeedbackPostback,
    showfeedbackBotUrl,
    showfeedbackApiUrl,
    showfeedbackIdNlp,
    bot,
    setBot,
    setShowfeedbackName,
    setShowfeedbackId,
    setShowfeedbackToken,
    setShowfeedbackIdNlp,
    setShowfeedbackPostback,
    setShowfeedbackBotUrl,
    setShowfeedbackApiUrl,
    pageName,
    pageId,
    pageToken,
    pagePostback,
    pageBotUrl,
    pageApiUrl,
    pageIdNlp,
    setPageName,
    setPageId,
    setPageToken,
    setPagePostback,
    setPageBotUrl,
    setPageApiUrl,
    setPageIdNlp,
    AppRequesterConst,
    dispatch,
    navigate,
    isLoading,
    setIsLoading,
    cancelButton,
    getFacebookById
}) => {

    const values: TokenInterface = {
        token: {
            value: useSelector(getToken)
        },
    };

    return (
        <Modal show={show} onHide={cancelButton} centered>
            <Modal.Header closeButton>
                <Modal.Title style={{ marginBottom: '-2vh' }}>Adicionar nova página</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{ name: '', id: '', token: '', postbackurl: '', boturl: '', apiurl: '', botnlpid: '' }}
                    validate={(values) => {
                        let errors = {};
                        if (!values.name) {
                            errors['name'] = 'Preencha o nome da página';
                            setShowfeedbackName(true);
                        } else {
                            setShowfeedbackName(false);
                        }

                        if (!values.id) {
                            errors['id'] = 'Preencha o ID da página';
                            setShowfeedbackId(true);
                        } else if (!/^\d+$/.test(values.id)) {
                            errors['id'] = 'O ID deve ser um número';
                            setShowfeedbackId(true);
                        } else {
                            setShowfeedbackId(false);
                        }

                        if (!values.token) {
                            errors['token'] = 'Preencha o token da página';
                            setShowfeedbackToken(true);
                        } else {
                            setShowfeedbackToken(false);
                        }

                        if (!values.postbackurl && bot) {
                            errors['postbackurl'] = 'Preencha a URL de PostBack da página';
                            setShowfeedbackPostback(true);
                        } else {
                            setShowfeedbackPostback(false);
                        }

                        if (!values.boturl && bot) {
                            errors['boturl'] = 'Preencha a URL do bot da página';
                            setShowfeedbackBotUrl(true);
                        } else {
                            setShowfeedbackBotUrl(false);
                        }

                        if (!values.apiurl) {
                            errors['apiurl'] = 'Preencha a URL da Api da página';
                            setShowfeedbackApiUrl(true);
                        } else {
                            setShowfeedbackApiUrl(false);
                        }

                        if (values.botnlpid && !/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i.test(values.botnlpid) && bot) {
                            errors['botNlp'] = 'Preencha com um ID válido'
                            setShowfeedbackIdNlp(true)
                        } else {
                            setShowfeedbackIdNlp(false)
                        }

                        setPageName(values?.name);
                        setPageId(values?.id);
                        setPageToken(values?.token);
                        setPagePostback(values?.postbackurl);
                        setPageBotUrl(values?.boturl);
                        setPageApiUrl(values?.apiurl);
                        setPageIdNlp(values?.botnlpid);

                        return errors;
                    }}
                    onSubmit={async () => {
                        let JsonSend = {
                            cnpj: cnpj,
                            has_bot: false
                        }

                        if (pageName !== '')
                            JsonSend['page_name'] = pageName;
                        if (pageId !== '')
                            JsonSend['page_id'] = pageId;
                        if (pageToken !== '')
                            JsonSend['access_token'] = pageToken;
                        if (pageApiUrl !== '')
                            JsonSend['api_url'] = pageApiUrl;
                        if (bot){
                            JsonSend['has_bot'] = true;
                            if (pagePostback !== '')
                                JsonSend['post_back_url'] = pagePostback;
                            if (pageBotUrl !== '')
                                JsonSend['bot_url'] = pageBotUrl;
                            if (pageIdNlp !== '')
                                JsonSend['nlp_id'] = pageIdNlp;
                        }

                        const headers = {
                            "Content-Type": "application/json; charset=utf-8",
                            "Authorization": "Bearer " + values.token.value
                        };
                        const router = '/channel-facebook/save-facebook-keys'
                        const config = { headers }

                        await AppRequesterConst.Post(
                            router, JsonSend, config,
                            (response: Object) => { },
                            (data: { status: number }) => {
                                if (data.status === 200) {
                                    dispatch(setShowAlertFeedback({ message: "Página cadastrada com sucesso.", visibility: true, signalIcon: true }))
                                    clearFields()
                                    handleClose()
                                    getFacebookById()
                                }
                            },
                            (error: { response: { status: number, data: { message: { path: [] }, code_cxpress: number } } }) => {
                                if (error.response.status === 409) {
                                    dispatch(setShowAlertFeedback({ message: "Esta página já está cadastrada.", visibility: true, signalIcon: false }))
                                } else if (error?.response?.status === 422) {
                                    if (error?.response?.data?.message[0]?.path[0] === 'nlp_id') {
                                        dispatch(setShowAlertFeedback({ message: 'ID do Bot Nlp é inválido.', visibility: true, signalIcon: false }))
                                    }
                                } else if (error?.response?.status === 400) {
                                    if (error?.response?.data?.code_cxpress === 1613) {
                                        dispatch(setShowAlertFeedback({ message: 'Não foi possível encontrar um Bot NLP com o ID fornecido', visibility: true, signalIcon: false }))
                                    } else if (error?.response?.data?.code_cxpress === 1614) {
                                        dispatch(setShowAlertFeedback({ message: 'ID do Bot Nlp não pertence a empresa informada.', visibility: true, signalIcon: false }))
                                    } else if (error?.response?.data?.code_cxpress === 1615) {
                                        dispatch(setShowAlertFeedback({ message: 'ID do Bot Nlp não pertence ao canal informado.', visibility: true, signalIcon: false }))
                                    } else if (error?.response?.data?.code_cxpress === 1616) {
                                        dispatch(setShowAlertFeedback({ message: 'Este Bot NLP ainda não foi treinado.', visibility: true, signalIcon: false }))
                                    } else if (error?.response?.data?.code_cxpress === 1617) {
                                        dispatch(setShowAlertFeedback({ message: 'ID do Bot Nlp já está associado à uma página.', visibility: true, signalIcon: false }))
                                    } else if (error?.response?.data?.code_cxpress === 317) {
                                        dispatch(setShowAlertFeedback({ message: 'O canal Facebook não está habilitado para integrações na empresa.', visibility: true, signalIcon: false }))
                                    } else {
                                        dispatch(setShowAlertFeedback({ message: 'ID do Bot Nlp é inválido.', visibility: true, signalIcon: false }))
                                    }
                                } else {
                                    dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }))
                                }
                            }, navigate, dispatch, setIsLoading, {}
                        )
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    }) => (
                        <Form onSubmit={handleSubmit} noValidate validated={validated}>
                            <Row style={styles.textFontGlay} className=" d-flex justify-content-left">
                                <Col md={6}>
                                    <Form.Label htmlFor="name">
                                        Nome da página
                                        <span
                                            style={{
                                                font: "normal bold normal calc(0.2em + 0.7vw) Montserrat",
                                                color: "black",
                                            }}
                                        >
                                            *
                                        </span>
                                    </Form.Label>
                                    <InputGroup>
                                        <FormControl
                                            className="form-control-Default"
                                            isInvalid={showfeedbackName}
                                            placeholder="Informe o nome da página"
                                            aria-label="Informe o nome da página"
                                            aria-describedby="Informe o nome da página"
                                            size="lg"
                                            type="name"
                                            id="name"
                                            name="name"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        ></FormControl>
                                        <Form.Control.Feedback type="invalid" id="feedbackname">
                                            {errors['name']}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                                <Col md={6}>
                                    <Form.Label htmlFor="id">
                                        ID da página
                                        <span
                                            style={{
                                                font: "normal bold normal calc(0.2em + 0.7vw) Montserrat",
                                                color: "black",
                                            }}
                                        >
                                            *
                                        </span>
                                    </Form.Label>
                                    <InputGroup>
                                        <FormControl
                                            className="form-control-Default"
                                            isInvalid={showfeedbackId}
                                            placeholder="Informe o ID da página"
                                            aria-label="Informe o ID da página"
                                            aria-describedby="Informe o ID da página"
                                            size="lg"
                                            type="id"
                                            id="id"
                                            name="id"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        ></FormControl>
                                        <Form.Control.Feedback type="invalid" id="feedbackid">
                                            {errors['id']}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row style={styles.textFontGlay} className=" d-flex justify-content-left">
                                <Col md={12}>
                                    <Form.Label htmlFor="token">
                                        Token de acesso
                                        <span
                                            style={{
                                                font: "normal bold normal calc(0.2em + 0.7vw) Montserrat",
                                                color: "black",
                                            }}
                                        >
                                            *
                                        </span>
                                    </Form.Label>
                                    <InputGroup>
                                        <FormControl
                                            className="form-control-Default"
                                            isInvalid={showfeedbackToken}
                                            placeholder="Informe o token da página"
                                            aria-label="Informe o token da página"
                                            aria-describedby="Informe o token da página"
                                            size="lg"
                                            type="token"
                                            id="token"
                                            name="token"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        ></FormControl>
                                        <Form.Control.Feedback type="invalid" id="feedbacktoken">
                                            {errors['token']}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row className="d-flex justify-content-center mt-3">
                                <Col sm={true} style={styles.textFontGlay}>
                                    <Form.Label htmlFor="basic-url">Possui bot?
                                        <span
                                            style={{
                                                font: "normal bold normal calc(0.2em + 0.7vw) Montserrat",
                                                color: "black",
                                            }}
                                        >
                                            *
                                        </span> &nbsp; &nbsp;
                                    </Form.Label>
                                    <Form.Check name="radioIsAdministrator" label={'Não'} type={'radio'} checked={!bot} onChange={() => { setBot(false) }} inline></Form.Check>
                                    <Form.Check name="radioIsAdministrator" label={'Sim'} type={'radio'} checked={bot} onChange={() => { setBot(true) }} inline></Form.Check>
                                </Col>
                            </Row>
                            {bot && (<>
                                <Row style={styles.textFontGlay} className=" d-flex justify-content-left">
                                    <Col md={12}>
                                        <Form.Label htmlFor="postbackurl">
                                            URL de PostBack
                                        </Form.Label>
                                        <InputGroup>
                                            <FormControl
                                                className="form-control-Default"
                                                isInvalid={showfeedbackPostback}
                                                placeholder="Informe o URL de PostBack da página"
                                                aria-label="Informe o URL de PostBack da página"
                                                aria-describedby="Informe o URL de PostBack da página"
                                                size="lg"
                                                type="postbackurl"
                                                id="postbackurl"
                                                name="postbackurl"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            ></FormControl>
                                            <Form.Control.Feedback type="invalid" id="feedbacktoken">
                                                {errors['postbackurl']}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row style={styles.textFontGlay} className=" d-flex justify-content-left">
                                    <Col md={12}>
                                        <Form.Label htmlFor="boturl">
                                            URL do bot
                                        </Form.Label>
                                        <InputGroup>
                                            <FormControl
                                                className="form-control-Default"
                                                isInvalid={showfeedbackBotUrl}
                                                placeholder="Informe o URL do bot da página"
                                                aria-label="Informe o URL do bot da página"
                                                aria-describedby="Informe o URL do bot da página"
                                                size="lg"
                                                type="boturl"
                                                id="boturl"
                                                name="boturl"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            ></FormControl>
                                            <Form.Control.Feedback type="invalid" id="feedbacktoken">
                                                {errors['boturl']}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row style={styles.textFontGlay} className=" d-flex justify-content-left">
                                    <Col md={12} mb={2}>
                                        <Form.Label htmlFor="botnlpid">
                                            ID do Bot NLP
                                        </Form.Label>
                                        <InputGroup>
                                            <FormControl
                                                className="form-control-Default"
                                                isInvalid={showfeedbackIdNlp}
                                                placeholder="Informe o ID do Bot NLP da página"
                                                aria-label="Informe o ID do Bot NLP da página"
                                                aria-describedby="Informe o ID do Bot NLP da página"
                                                size="lg"
                                                type="botnlpid"
                                                id="botnlpid"
                                                name="botnlpid"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            ></FormControl>
                                            <Form.Control.Feedback type="invalid" id="feedbacktoken">
                                                {errors['botNlp']}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </>)}
                            <Row style={styles.textFontGlay} className=" d-flex justify-content-left">
                                <Col md={12}>
                                    <Form.Label htmlFor="apiurl">
                                        URL da API
                                    </Form.Label>
                                    <InputGroup>
                                        <FormControl
                                            className="form-control-Default"
                                            isInvalid={showfeedbackApiUrl}
                                            placeholder="Informe o URL da API da página"
                                            aria-label="Informe o URL da API da página"
                                            aria-describedby="Informe o URL da API da página"
                                            size="lg"
                                            type="apiurl"
                                            id="apiurl"
                                            name="apiurl"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        ></FormControl>
                                        <Form.Control.Feedback type="invalid" id="feedbacktoken">
                                            {errors['apiurl']}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row className="d-flex justify-content-center mt-4">
                                <Col xs={6} className="d-flex justify-content-start">
                                    <Button size='lg' className="buttonWhite" style={{ width: '56%' }} onClick={cancelButton} >Cancelar</Button>
                                </Col>
                                <Col xs={6} className="d-flex justify-content-end">
                                    <Button size='lg' type='submit' className="buttonBlue" style={{ width: '56%' }}>Salvar</Button>
                                </Col>
                            </Row>

                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
};

export default ModalRegisterFacebookPage;