import { useEffect, useState } from "react";
import SidebarPanel from ".";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { TokenInterface } from "../../../../services/requestsInterfacesModel";
import { getToken, setToken } from "../../../../store/token";
import { getForceLogout, getOpenSidebar, getShowConfirmationModal, getSidebarPage, setForceLogout, setOpenSidebar, setShowConfirmationModal } from "../../../../store/internal";
import constsRouters from "../../../../routes/constsRouter";
import { setExtension, setPassword, setOwner_id } from "../../../../store/callcenter";
import { setIdCompany, setNameCompany, setSubdomainCompany } from "../../../../store/company";
import { setIdEmployee } from "../../../../store/employee";
import { setIdUser, setNameUser, setUserLastName, setProfileId } from "../../../../store/user";
import { IconUsers, IconUserShield, IconShieldLock } from "@tabler/icons-react";
import { getUserControlPanelPermissions, setUserControlPanelAuthMethodDefault, setUserControlPanelEmail, setUserControlPanelHasAppAuth, setUserControlPanelId, setUserControlPanelPermissions } from "../../../../store/user_controlpanel";
import { setToken as setTokenControlPanel } from "../../../../store/control_panel";
import constsApi from "../../../../services/constsApi";
import { t } from "i18next";

const SidebarPanelController = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [currentPage, setCurrentPage] = useState('clients');
  const [isMaster, setIsMaster] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);

  const values: TokenInterface = {
    token: {
      value: useSelector(getToken)
    },
    internal: {
      getOpenSidebar: useSelector(getOpenSidebar),
      getSidebarPage: useSelector(getSidebarPage),
      getForceLogout: useSelector(getForceLogout),
      getShowConfirmationModal: useSelector(getShowConfirmationModal)
    },
    internal_controlpanel: {
      permissions: useSelector(getUserControlPanelPermissions)
    }
  };

  useEffect(() => {
    switch (location.pathname) {
      case '/clients':
        setCurrentPage('clients');
        navigate(pages['clients'].route);
        break;
      case '/accounts':
        if (isMaster) {
          setCurrentPage('accounts');
          navigate(pages['accounts'].route);
        } else {
          setCurrentPage('clients');
          navigate(pages['clients'].route);
        }
        break;
      case '/security':
        if (hasPermission) {
          setCurrentPage('security');
          navigate(pages['security'].route);
          break;
        } else {
          setCurrentPage('clients');
          navigate(pages['clients'].route);
          break;
        }
      default:
        setCurrentPage('clients');
        navigate(pages['clients'].route);
        break;
    }
  }, []);

  useEffect(() => {
    if (values.internal.getForceLogout) {
      dispatch(setForceLogout(false));
      logoutConfirm();
    }
  }, [values.internal.getForceLogout, dispatch]);

  useEffect(() => {
    const permissions = values.internal_controlpanel?.permissions || [];
    setIsMaster(false);
    setHasPermission(false);
    if (permissions) {
      if (permissions.length > 0) {
        setHasPermission(true);
        permissions.forEach(permission => {
          if (permission.id === constsApi.permissions_controlpanel.master) {
            setIsMaster(true);
          }
        });
      }
    }
  }, [values.internal_controlpanel?.permissions]);

  useEffect(() => {
    document.title = `Painel Cxpress | ${pages[currentPage].name}`;
  }, [currentPage]);

  useEffect(() => {
    if (values.internal.getShowConfirmationModal.visibility) {
        dispatch(setShowConfirmationModal({ visibility: false, text: { title: '', body: '', id: '' }, functionConfirmation: () => {} }));
    }
}, []);

  const logoutConfirm = () => {
    dispatch(setShowConfirmationModal({
      visibility: true,
      text: { title: t('components.sidebar.modals.expired_session_title'), body: t('components.sidebar.modals.expired_session_body'), buttonConfirmationText: 'OK', closeButton: false },
      funtionConfirmation: logout,
    }));
  }

  const pages = { // Novas páginas devem ser adicionadas aqui, na ordem em que devem aparecer na sidebar
    clients: {
      id: 'clients',
      name: 'Clientes',
      route: constsRouters.routers.controlPanelclients.path,
      icon: <IconUsers className="menu-icon" stroke={1.5} />,
      show: true
    },
    accounts: {
      id: 'accounts',
      name: 'Contas de CS',
      route: constsRouters.routers.controlPanelAccounts.path,
      icon: <IconUserShield className="menu-icon" stroke={1.5} />,
      show: isMaster
    },
    security: {
      id: 'security',
      name: 'Segurança',
      route: constsRouters.routers.controlPanelSecurity.path,
      icon: <IconShieldLock className="menu-icon" stroke={1.5} />,
      show: !isMaster && hasPermission
    }
  };

  const getPages = () => {
    return Object.keys(pages).map(key => ({
      id: pages[key].id,
      name: pages[key].name,
      icon: pages[key].icon,
      show: pages[key].show
    }));
  }

  const showHideSidebar = (): void => {
    if (values.internal.getOpenSidebar) {
      dispatch(setOpenSidebar(false));
    } else {
      dispatch(setOpenSidebar(true));
    }
  }

  const menuAction = (menu: string): void => {
    if (menu !== 'logout') {
      setCurrentPage(menu);
      navigate(pages[menu].route);
    } else {
      logout();
    }
  }

  const logout = () => {
    localStorage.setItem('hasLogin', null);
    dispatch(setToken(null));
    dispatch(setIdCompany(null));
    dispatch(setNameCompany(null));
    dispatch(setSubdomainCompany(null));
    dispatch(setIdEmployee(null));
    dispatch(setIdUser(null));
    dispatch(setNameUser(null));
    dispatch(setUserLastName(null));
    dispatch(setProfileId(null));
    dispatch(setExtension(null));
    dispatch(setPassword(null));
    dispatch(setOwner_id(null));

    dispatch(setTokenControlPanel(null));
    dispatch(setUserControlPanelId(null));
    dispatch(setUserControlPanelEmail(null));
    dispatch(setUserControlPanelAuthMethodDefault(null));
    dispatch(setUserControlPanelHasAppAuth(null));
    dispatch(setUserControlPanelPermissions(null));
    dispatch(setForceLogout(false));

    navigate("/");
  }

  return (
    <SidebarPanel
      currentPage={currentPage}
      menuAction={menuAction}
      showHideSidebar={showHideSidebar}
      sidebarOpened={values.internal.getOpenSidebar}
      confirmationModal={values.internal.getShowConfirmationModal}
      getPages={getPages}
    />
  );
}

export default SidebarPanelController;
