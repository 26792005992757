import React, { FC, useEffect } from 'react';

import allStyles from './styles';

import {
  Modal,
  Row,
  Container,
  Col,
  Button,
  Form,
  InputGroup,
  FormControl,
  Spinner
} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Formik } from 'formik';
import InputMask from 'react-input-mask';
import constsApi from '../../../../services/constsApi';

import {
  setShowAlertFeedback,
} from '../../../../store/internal';
import { ModalViewMessageTriggerInterface } from './indexModel';
import Loading from '../../../../components/loading'

import ChatEditor from '../chatEditor';

import { verifyCode } from '../../../../services/codeCxpressInterface';
import { useTranslation } from 'react-i18next';
import AttachmentsInMessageController from '../../homeMessageTrigger/components/attachmentsInMessage/indexController';
import { Channels } from '../../../../core/enums/channels';
import Utils from '../../../../core/shared/utils';
import SelectComponentController from '../selectComponent/indexController';


const ModalViewMessageTrigger: FC<ModalViewMessageTriggerInterface> = (props) => {
  
  const handleSelectChangeType = (selectedOptions) => {
    props.setSelected_type(selectedOptions);
    props.setErrorSelectType(selectedOptions?.length === 0);
  };
  
  const handleSelectChangeChannel = (selectedOptions) => {
    props.setSelected_channel(selectedOptions);
    props.setErrorSelectChannel(selectedOptions?.length === 0);
  };
  
  const handleSelectChangeTemplate = (selectedOptions) => {
    props.setSelected_template(selectedOptions);
    props.setErrorSelectWhatsappTemplate(selectedOptions?.length === 0);
  };

  const {t} = useTranslation();
  
  const showFiles = props.files.map((file, index) => (
    <span key={index}
      style={{
        background: '#E8E8E8 0% 0% no-repeat padding-box',
        borderRadius: '3px',
        opacity: '1',
        marginRight: '0.5vw',
        marginBottom: '1vh',
        whiteSpace: 'pre', display: 'inline-block'
      }}>
      <svg id="Grupo_11419" data-name="Grupo 11419" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path id="Caminho_9725" data-name="Caminho 9725" d="M0,0H24V24H0Z" fill="none" />
        <path id="Caminho_9726" data-name="Caminho 9726" d="M14,3V7a1,1,0,0,0,1,1h4" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        <path id="Caminho_9727" data-name="Caminho 9727" d="M17,21H7a2,2,0,0,1-2-2V5A2,2,0,0,1,7,3h7l5,5V19A2,2,0,0,1,17,21Z" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        <line id="Linha_391" data-name="Linha 391" y2="6" transform="translate(12 11)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        <path id="Caminho_9728" data-name="Caminho 9728" d="M9,14l3-3,3,3" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      </svg>
      <span
        style={{
          textAlign: 'left',
          font: 'var(--px10_13-font-Roboto)',
          letterSpacing: '0px',
          color: '#1E1E1E',
          opacity: '1',
        }}
      >
        {file.name}
      </span>
      {file.isLoading ?
        <svg name={file.name} onClick={() => props.viewTriggerModal ? {} : props.deleteFile(file.name)} style={{ cursor: 'pointer', marginRight: '3px', marginLeft: '3px' }} id="Grupo_11428" data-name="Grupo 11428" xmlns="http://www.w3.org/2000/svg" width="14.438" height="14.438" viewBox="0 0 14.438 14.438">
          <path id="Caminho_9729" data-name="Caminho 9729" d="M0,0H14.438V14.438H0Z" fill="none" />
          <line id="Linha_392" data-name="Linha 392" x1="7" y2="7" transform="translate(4 3.438)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
          <line id="Linha_393" data-name="Linha 393" x2="7" y2="7" transform="translate(4 3.438)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        </svg>
        :
        <Spinner animation="border" role="status" style={{ marginRight: '3px', marginLeft: '3px', width: "14.438px", height: "14.438px" }} >
        </Spinner>
      }
    </span>
  ));
  
  const fileName = () => {
    return (
      <span key={"0"}
        style={{
          background: '#E8E8E8 0% 0% no-repeat padding-box',
          borderRadius: '3px',
          opacity: '1',
          marginRight: '0.5vw',
          marginBottom: '1vh',
          whiteSpace: 'pre', display: 'inline-block'
        }}>
        <a href={props.data.file_url}>
          <svg id="Grupo_11419" data-name="Grupo 11419" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path id="Caminho_9725" data-name="Caminho 9725" d="M0,0H24V24H0Z" fill="none" />
            <path id="Caminho_9726" data-name="Caminho 9726" d="M14,3V7a1,1,0,0,0,1,1h4" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            <path id="Caminho_9727" data-name="Caminho 9727" d="M17,21H7a2,2,0,0,1-2-2V5A2,2,0,0,1,7,3h7l5,5V19A2,2,0,0,1,17,21Z" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            <line id="Linha_391" data-name="Linha 391" y2="6" transform="translate(12 11)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            <path id="Caminho_9728" data-name="Caminho 9728" d="M9,14l3-3,3,3" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
          </svg>
          <span
            style={{
              textAlign: 'left',
              font: 'var(--px10_13-font-Roboto)',
              letterSpacing: '0px',
              color: '#1E1E1E',
              opacity: '1',
            }}
          >
            {props.data.file_name}
          </span>
        </a>
        <svg name={props.data.file_name} onClick={() => props.viewTriggerModal ? {} :props.deleteFile(props.data.file_name)} style={{ cursor: 'pointer', marginRight: '3px', marginLeft: '3px' }} id="Grupo_11428" data-name="Grupo 11428" xmlns="http://www.w3.org/2000/svg" width="14.438" height="14.438" viewBox="0 0 14.438 14.438">
          <path id="Caminho_9729" data-name="Caminho 9729" d="M0,0H14.438V14.438H0Z" fill="none" />
          <line id="Linha_392" data-name="Linha 392" x1="7" y2="7" transform="translate(4 3.438)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
          <line id="Linha_393" data-name="Linha 393" x2="7" y2="7" transform="translate(4 3.438)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        </svg>

      </span>)
  }

  return (
    <Modal show={props.modalShow} onHide={()=> props.hide()} aria-labelledby="" size="sm" centered={true} >
      <Modal.Header closeButton style={{ marginLeft: "6%", marginTop: "1%", marginBottom: "-1%" }}>
        <Modal.Title style={allStyles.textBlack}>
          {t('home_message_trigger.modal_view_message_trigger.message_trigger_information')}
        </Modal.Title>
      </Modal.Header>
      <Loading visibility={props.isLoading}></Loading>
      <Modal.Body style={{ marginLeft: "4%", marginRight: "4%", marginBottom: "2%" }}>
        <Container>
          <Formik
            initialValues={{
              date: Utils.formatedDate(new Date(props.scheduledDate)),
              hour: Utils.formatTime((props.scheduledDate)?.toString())
            }}
            validate={(values: { date: string; hour: string; }) => {
              let errors = {};

              if(props.selected_type.length === 0){
                props.setErrorSelectType(true);
              }

              if(props.selected_channel.length === 0){
                props.setErrorSelectChannel(true);
              }

              if(!props.file_name && props.files.length === 0) {
                props.setErrorSelectRecipients(true);
              }

              if(props.selected_template.length === 0) {
                props.setErrorSelectWhatsappTemplate(true);
              }

              props.setDate(values.date)
              props.setHour(values.hour)
              
              var hoje = new Date().toLocaleString().substr(0, 10)

              if (!values.date) {
                errors["date"] = t("home_message_trigger.modal_register_message_trigger.fill_in_field");
                props.setShowfeedbackDate(true);
              }
              else if (props.converterData(values.date).toString() === "Invalid Date") {
                errors["date"] = t("home_message_trigger.modal_register_message_trigger.invalid_date");
                props.setShowfeedbackDate(true);
              }
              else if (props.converterData(values.date).getTime() < props.converterData(hoje).getTime()) {
                errors["date"] = t("home_message_trigger.modal_register_message_trigger.invalid_date");
                props.setShowfeedbackDate(true);
              }
              else {
                props.setShowfeedbackDate(false);
              }

              if (!values.hour) {
                errors["hour"] = t("home_message_trigger.modal_register_message_trigger.fill_in_field");
                props.setShowfeedbackHour(true);
              }
              else if (props.converterHora(values.hour).toString() === "Invalid Date" || parseInt(values.hour.split(":")[0]) > 23 || parseInt(values.hour.split(":")[1]) > 59) {
                errors["hour"] = t("home_message_trigger.modal_register_message_trigger.invalid_hour");
                props.setShowfeedbackHour(true);
              }
              else {
                props.setShowfeedbackHour(false);
              }

              if (!props.messageContentHtml) {
                // dispatch(setShowAlertFeedback({ message: "Campo mensagem não preenchido", visibility: true, signalIcon: false }));
                errors["message"] = t("home_message_trigger.modal_register_message_trigger.fill_in_field");
                props.setShowfeedbackMessage(true);
              }
              else {
                props.setShowfeedbackMessage(false);
              }

              return errors;
            }}

            onSubmit={async () => {
              let JsonSend_sector = {};

              switch(props.selected_channel[0].value){
                case "3f3fa530-fd3e-4018-ab98-4d9c3cd5406a":
                  JsonSend_sector = {
                    "date_scheduled": props.dataToTimestamp(props.date, props.hour),
                    "message": props.hasEmail ? (props.messageContentHtml === "<p><br></p>" ? null : props.changeToText(props.messageContentHtml)) : (props.messageContentHtml === "<p><br></p>" ? null : props.messageContentHtml),
                    "messages_sending_type_id": props.selected_type[0].value,
                    "chanel_id": props.selected_channel[0].value,
                    "id": props.data.id,
                    "email_id": props.selected_email[0] ? props.selected_email[0].value : props.email_principal[0].value
                  }
                  break;
                case "33be81d8-88ac-4e08-bb4d-cf69fd23267f":
                  JsonSend_sector = {
                    "date_scheduled": props.dataToTimestamp(props.date, props.hour),
                    "message": props.hasWhatsapp ? (props.messageContentHtml === "<p><br></p>" ? null : props.changeToText(props.messageContentHtml)) : (props.messageContentHtml === "<p><br></p>" ? null : props.messageContentHtml),
                    "messages_sending_type_id": props.selected_type[0].value,
                    "chanel_id": props.selected_channel[0].value,
                    "id": props.data.id,
                    "whatsapp_keys_id": props.whatsAppKeysId?.whatsapp_keys_id,
                    "template_message_id": props.selected_template[0]?.id
                  }
                  if (props.selected_template[0]?.is_bot_nlp === true) {
                    if (props.selected_atendimento[0]?.value === "chatbot") {
                      JsonSend_sector["response_message_level"] = 1
                    } else {
                      JsonSend_sector["response_message_level"] = 2
                    }
                  }

                  if (props.data?.is_active_triage === true)
                    JsonSend_sector["has_triage"] = props.triageConfirmation
                  else
                    JsonSend_sector["has_triage"] = false
                  break;
              }

              JsonSend_sector["attachment_ids"] = props.tempAttachmentIds;

              if(props.file_id){
                JsonSend_sector["file_id"] = props.file_id;
              }

              const headers = {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": "Bearer " + props.token
              };

              await props.AppRequesterConst.Put(
                '/message-sending', JsonSend_sector, { headers },
                (response: Object) => {
                  return response;
                },
                (data: { data: { message: string, messageSending: {id: string, chanel_id: string, employee_id: string, message_sending_status_id: string, message_sending_type_id: string}; }; }) => {
                  props.dispatch(setShowAlertFeedback({ message: t("home_message_trigger.modal_view_message_trigger.sucess_put"), visibility: true, signalIcon: true }));
                  props.callback(data.data.messageSending);
                  props.hide();
                },
                (error: { response: { status: number; data: { message: any[], code_cxpress: number; }; }; }) => {
                  props.dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                }, props.navigate, props.dispatch, props.setIsLoading,
                () => { },
                {
                  error422: () => {
                    if (props.selected_channel && props.selected_channel[0]?.value === Channels.WhatsApp && props.tempAttachmentIds?.length > 1)
                      props.dispatch(setShowAlertFeedback({ message: t("home_message_trigger.modal_view_message_trigger.one_file"), visibility: true, signalIcon: false }));
                    else 
                      props.dispatch(setShowAlertFeedback({ message: t("home_message_trigger.modal_view_message_trigger.field_not_selected"), visibility: true, signalIcon: false }));
                  }
                }
              );
              if (props.file_id) {
                JsonSend_sector["file_id"] = props.file_id
              }

              JsonSend_sector["attachment_ids"] = props.tempAttachmentIds;

            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,

            }) => (
              <Form onSubmit={handleSubmit} noValidate validated={props.validated}>

                <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                  <Col sm={true} >
                    <Form.Label htmlFor="basic-url">{t("home_message_trigger.modal_view_message_trigger.type")}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                    {/* <pre>{JSON.stringify(selected_departments)}</pre> */}
                    <SelectComponentController
                      className={props.viewTriggerModal === true ? "mb-3 disabled" : !props.errorSelectType ? "mb-3" : "multiselectFormError"}
                      options={props.options_types && props.options_types.length > 0? props.options_types: []}
                      setOptions={props.setOptions_types}
                      selecteds={props.selected_type}
                      setSelecteds={handleSelectChangeType}
                      singleSelect={true}
                      isFormNoFilter={true}
                      title={t("home_message_trigger.modal_register_message_trigger.trigger_type")}
                      overrideStrings={
                        {
                          "allItemsAreSelected": props.selected_type[0] ? props.selected_type[0].label : "",
                          "clearSearch": t("home_message_trigger.filter_option_type.clearSearch"),
                          "clearSelected": t("home_message_trigger.filter_option_type.clearSelected"),
                          "noOptions": t("home_message_trigger.filter_option_type.noOptions"),
                          "search": t("home_message_trigger.modal_register_message_trigger.search_type"),
                          "selectAll": t("home_message_trigger.filter_option_type.selectAll"),
                          "selectAllFiltered": t("home_message_trigger.filter_option_type.selectAllFiltered"),
                          "selectSomeItems": t("home_message_trigger.modal_register_message_trigger.trigger_type"),
                          "create": t("home_message_trigger.filter_option_type.create"),
                        }
                      }
                      hasCreateItem={false}
                      hasNullOption={false}
                      hasAllOption={false}
                      disabled={props.viewTriggerModal}
                    />
                  </Col>
                  {props.errorSelectType && <p style={{ color: '#dc3545' , fontSize: ".875em" }}>{t('home_tasks.modal_task.validate.fill_field')}</p>}
                </Row>

                <Row
                  style={allStyles.textFontGlay}
                  className="mb-3 d-flex justify-content-center"
                >
                  <Col md={6}>
                    <Form.Label htmlFor="basic-url">{t("home_message_trigger.modal_view_message_trigger.date_trigger")}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                    <InputMask
                      mask={"99/99/9999"}
                      maskChar={"_"}
                      value={props.date}
                      onChange={handleChange}
                    >
                      {(inputProps: any) => (
                        <InputGroup>
                          <FormControl
                            isInvalid={props.showfeedbackDate}
                            className="form-control-Default"
                            placeholder="DD/MM/AAAA"
                            aria-label="DD/MM/AAAA"
                            aria-describedby="DD/MM/AAAA"
                            type="text"
                            size="lg"
                            id="date"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={props.viewTriggerModal}
                          />
                          <Form.Control.Feedback
                            type="invalid"
                            id="feedbackdate"
                          >
                            {errors.date}
                          </Form.Control.Feedback>
                        </InputGroup>
                      )}
                    </InputMask>
                  </Col>
                  <Col md={6}>
                    <Form.Label htmlFor="basic-url">{t("home_message_trigger.modal_view_message_trigger.trigger_hour")}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                    <InputMask
                      mask="99:99"
                      maskChar="_"
                      value={props.hour}
                      onChange={handleChange}
                    >
                      {(inputProps: any) => (
                        <InputGroup>
                          <FormControl
                            className="form-control-Default"
                            placeholder="HH:MM"
                            aria-label="HH:MM"
                            aria-describedby="HH:MM"
                            type="text"
                            size="lg"
                            id="hour"
                            name="hour"
                            isInvalid={props.showfeedbackHour}
                            onBlur={handleBlur}
                            disabled={props.viewTriggerModal}
                          ></FormControl>
                          <Form.Control.Feedback
                            type="invalid"
                            id="feedbackHour"
                          >
                            {errors.hour}
                          </Form.Control.Feedback>
                        </InputGroup>
                      )}
                    </InputMask>
                  </Col>
                </Row>

                <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                  <Col sm={true}>
                    <Form.Label htmlFor="basic-url">{t("home_message_trigger.modal_view_message_trigger.channel")}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                    {/* <pre>{JSON.stringify(selected_departments)}</pre> */}
                    <SelectComponentController
                      className={props.viewTriggerModal === true ? "mb-3 disabled" : !props.errorSelectChannel ? "mb-3" : "multiselectFormError"}
                      options={props.options_channel && props.options_channel? props.options_channel: []}
                      setOptions={props.setOptions_channel}
                      selecteds={props.selected_channel}
                      setSelecteds={handleSelectChangeChannel}
                      singleSelect={true}
                      isFormNoFilter={true}
                      title={t("home_message_trigger.modal_register_message_trigger.select_trigger_channel")}
                      overrideStrings={
                        {
                          "allItemsAreSelected": props.selected_channel[0] ? props.selected_channel[0].label && props.selected_channel[0].value : "",
                          // "clearSearch": "Limpar pesquisa",
                          // "clearSelected": "Limpar canais selecionados",
                          "noOptions": t("home_message_trigger.modal_register_message_trigger.noOptions"),
                          "search": t("home_message_trigger.modal_register_message_trigger.search_channel"),
                          // "selectAll": "Selecionar todos os canais",
                          // "selectAllFiltered": "Selecionar todos os canais (Filtrado)",
                          "selectSomeItems": t("home_message_trigger.modal_view_message_trigger.selectd_channel"),
                          // "create": "Criar",
                        }
                      }
                      hasCreateItem={false}
                      hasNullOption={false}
                      hasAllOption={false}
                      disabled={props.viewTriggerModal}
                      onSelect={(value) => props.selectChannel(value)}
                      isChannel
                    />
                    {props.errorSelectChannel && <p style={{ color: '#dc3545' , fontSize: ".875em" }}>{t('home_tasks.modal_task.validate.fill_field')}</p>}
                  </Col>
                </Row>

                <Row style={allStyles.textFontGlay} className="mb-3 d-flex justify-content-center">
                  <Col sm={true}>
                    <Form.Label htmlFor="basic-url">{t("home_message_trigger.modal_view_message_trigger.recipients")}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span> <span style={{ font: "normal normal normal calc(0.05em + 0.7vw) Montserrat" }}>({t("home_message_trigger.modal_view_message_trigger.csv_name")}{props.selected_channel[0] ? props.selected_channel[0].label : t("home_message_trigger.modal_register_message_trigger.channel")}")</span></Form.Label>
                    <div style={allStyles.changeEditorDropzone}>
                      <div {...props.getRootProps({ className: 'dropzone' })}
                        style={allStyles.dragDropzone}>
                        <input {...props.getInputProps({})} />
                        {props.file_name ?
                          <div name="showFilesName" className="ps-2 py-1 pt-2">
                            <div style={{ display: 'block' }}>
                              {fileName()}
                            </div>
                          </div>
                          :
                          props.files.length > 0 ?
                            <div name="showFilesName" className="ps-2 py-1 pt-2">
                              {props.setErrorSelectRecipients(false)}
                              <div style={{ display: 'block' }}>
                                {showFiles}
                              </div>
                            </div>
                            :
                            <>
                            {props.setErrorSelectRecipients(true)}
                            <div>{props.selected_channel[0] ? t("home_message_trigger.modal_register_message_trigger.click_and_drag") : t("home_message_trigger.modal_register_message_trigger.select_channel")}</div>
                            </>
                        }
                      </div>

                    </div>
                      {props.errorSelectRecipients && <p style={{ color: '#dc3545' , fontSize: ".875em" }}> {t('home_tasks.modal_task.validate.fill_field')} </p>}

                  </Col>
                </Row>
                {props.hasEmail === true ? 
                  <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                    <Col sm={true}>
                      <Form.Label htmlFor="basic-url">{t('home_message_trigger.modal_view_message_trigger.set_email')}</Form.Label>
                      <SelectComponentController
                        disabled={props.disabled}
                        className={props.disabled ? "multiselectForm mb-3 disabled" : "multiselectForm mb-3"}
                        options={props.options_email && props.options_email.length > 0? props.options_email: []}
                        setOptions={props.setOptions_email}
                        selecteds={props.selected_email}
                        setSelecteds={props.setSelected_email}
                        singleSelect={true}
                        isFormNoFilter={true}
                        title={t("home_message_trigger.modal_register_message_trigger.select_email")}
                        overrideStrings={
                          {
                            "allItemsAreSelected": props.selected_template[0] ? props.selected_template[0].label && props.selected_template[0].id: "",
                            "clearSearch": t("home_message_trigger.filter_option_email.clearSearch"),
                            "clearSelected": t("home_message_trigger.filter_option_email.clearSelected"),
                            "noOptions": t("home_message_trigger.filter_option_email.noOptions"),
                            "search": t("home_message_trigger.filter_option_email.search"),
                            "selectAll": t("home_message_trigger.filter_option_email.selectAll"),
                            "selectAllFiltered": t("home_message_trigger.filter_option_email.selectAllFiltered"),
                            "selectSomeItems": `${props.email_principal[0]?.label} (E-mail principal)`,
                            "create": t("home_message_trigger.filter_option_email.create"),
                          }
                        }
                        hasCreateItem={false}
                        hasNullOption={false}
                        hasAllOption={false}
                      />
                    </Col>
                  </Row>
                  : 
                  <></>  
                }

                {props.hasWhatsapp === true ?
                  <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                    <Col sm={true}>
                      <Form.Label htmlFor="basic-url">{t('home_message_trigger.modal_view_message_trigger.template_whatsapp')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                      <SelectComponentController
                        disabled={props.disabled}
                        className={props.disabled ? "multiselectForm mb-3 disabled" : !props.errorSelectWhatsappTemplate ? "mb-3 multiselectFormViewTrigger" : "multiselectFormViewTrigger multiselectFormError"}
                        options={props.options_templates && props.options_templates.length > 0? props.options_templates: []}
                        setOptions={props.setOptions_templates}
                        selecteds={props.selected_template}
                        setSelecteds={handleSelectChangeTemplate}
                        singleSelect={true}
                        isFormNoFilter={true}
                        title={t("home_message_trigger.modal_register_message_trigger.select_template_whatsapp")}
                        overrideStrings={
                          {
                            "allItemsAreSelected": props.selected_template[0] ? props.selected_template[0].label && props.selected_template[0].whatsapp_keys_id  && props.selected_template[0].id && props.selected_template[0].title_and_number: "",
                            // "clearSearch": "Limpar pesquisa",
                            // "clearSelected": "Limpar canais selecionados",
                            "noOptions": t("home_message_trigger.modal_register_message_trigger.noOptions"),
                            "search": t("home_message_trigger.modal_register_message_trigger.search_whatsapp"),
                            // "selectAll": "Selecionar todas as templates",
                            // "selectAllFiltered": "Selecionar todas as templates (Filtrado)",
                            "selectSomeItems": t("home_message_trigger.modal_register_message_trigger.selecte_template"),
                            // "create": "Criar",
                          }
                        }
                        hasCreateItem={false}
                        hasNullOption={false}
                        hasAllOption={false}
                        onSelect={(value) => props.selectTemplate(value)}
                      />
                      {props.errorSelectWhatsappTemplate && <p style={{ color: '#dc3545' , fontSize: ".875em" }}> {t('home_tasks.modal_task.validate.fill_field')} </p>}

                    </Col>
                  </Row>
                  :
                  <></>
                }

                <Row style={allStyles.textFontGlay} className="mb-3 d-flex justify-content-center">
                  <Col sm={true}>
                    <Form.Label htmlFor="basic-url">{t("home_message_trigger.modal_view_message_trigger.message")}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                    <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: '1'}}
                      >
                      {props.messageContentHtml &&
                      <ChatEditor
                        messageChannel={props.selected_channel[0]?.value}
                        viewTriggerModal={true} 
                        messageTriggerModal={true}
                        hideButtonSend={true}
                        callbackGetStringHtml={props.callbackGetStringHtml}
                        initialHtmlMarkup={props.messageContentHtml}
                        isAllowedMessage={props.hasWhatsapp ? props.hasWhatsapp : props.viewTriggerModal}
                        blockColor={props.hasWhatsapp ? props.hasWhatsapp : props.viewTriggerModal}
                        cleanMessageEditor={props.cleanMessageEditor}
                        templateMessage={props.selected_template && props.selected_template[0]? props.selected_template[0]: null}>
                      </ChatEditor>
                      }
                    </div>
                  </Col>
                </Row>

                {props.hasWhatsapp === true && props.selected_template[0]?.is_bot_nlp === true && props.selected_template[0]?.bot_enable === true ?
                  <>
                    <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                      <Col sm={true}>
                        <Form.Label htmlFor="basic-url">{t("home_message_trigger.modal_register_message_trigger.service_after_trigger")}</Form.Label>
                        <SelectComponentController
                          className={"mb-3"}
                          options={props.options_atendimento}
                          setOptions={props.setOptions_atendimento}
                          selecteds={props.selected_atendimento}
                          setSelecteds={props.setSelected_atendimento}
                          singleSelect={true}
                          isFormNoFilter={true}
                          title={t("home_message_trigger.modal_register_message_trigger.title_service_after_trigger")}
                          overrideStrings={
                            {
                              "allItemsAreSelected": props.selected_atendimento[0] ? props.selected_atendimento[0].label && props.selected_atendimento[0].value : "",
                              // "clearSearch": "Limpar pesquisa",
                              // "clearSelected": "Limpar selecionado",
                              "noOptions": t("home_message_trigger.modal_register_message_trigger.noOptions"),
                              "search": t("home_message_trigger.modal_register_message_trigger.search_service"),
                              // "selectAllFiltered": "Selecionar todos os canais (Filtrado)",
                              "selectSomeItems": t("home_message_trigger.modal_register_message_trigger.select_service"),
                              // "create": "Criar",
                            }
                          }
                          hasCreateItem={false}
                          hasNullOption={false}
                          hasAllOption={false}
                          disableSearch={true}
                        />
                      </Col>
                    </Row>
                    {props.data?.is_active_triage === true ?
                      <>
                        <Row style={allStyles.textFontGlay}>
                          <Col className='d-flex  align-self-center'>
                            <Form.Label htmlFor="basic-url">{t("home_message_trigger.modal_register_message_trigger.triage_check")}</Form.Label>
                            <div className="switch-input" style={{ marginLeft: '0.3rem' }}>
                              <label className="switch">
                                <input
                                  disabled={props.isLoading}
                                  type="checkbox"
                                  checked={props.triageConfirmation}
                                  onChange={props.enableTriageConfirmation}
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </>
                      :
                      <>
                      </>
                    }
                  </>
                  :
                  <></>
                }

                { (props.selected_template && props.selected_template[0]?.is_attachment_allowed || (props.selected_channel && props.selected_channel[0]?.value === Channels.Email) ) && 
                  <Row style={allStyles.textFontGlay} className="mb-3 d-flex justify-content-center">
                    <AttachmentsInMessageController attachmentType={props.selected_template && props.selected_template[0]?.attachment_type} modalVisibility={props.visibility} selectedChannelId={props.selected_channel[0]?.value} setAttachmentsFilesIds={props.attachmentIdsCallback} previewFiles={props.data?.attachments || props.data?.message_sending?.attachments || []} disableClick={props.viewTriggerModal} setDisableButtonSave={props.setDisableButtonSave}/>
                  </Row>
                }
                
                <Row className="d-flex justify-content-center mt-4">

                  {props.viewTriggerModal === true ?
                    <Col xs={12} className="d-flex justify-content-start">
                      <Button size='lg' className="buttonWhite" style={{ width: '100%' }} onClick={()=>props.hide()} >{t("home_message_trigger.modal_view_message_trigger.back")}</Button>
                    </Col>
                    :
                    <>
                      <Col xs={6} className="d-flex justify-content-start">
                        <Button size='lg' className="buttonWhite" style={{ width: '56%' }} onClick={()=>props.hide()} >{t("home_message_trigger.modal_view_message_trigger.cancel")}</Button>
                      </Col>
                      <Col xs={6} className="d-flex justify-content-end">
                        <Button disabled={props.disableButtonSave} size='lg' type='submit' className="buttonBlue" style={{ width: '56%' }}>{t("home_message_trigger.modal_view_message_trigger.save")}</Button>
                      </Col>
                    </>
                  }

                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      </Modal.Body >
    </Modal >
  );
}

export default ModalViewMessageTrigger;
