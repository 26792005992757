import { useSelector } from 'react-redux'

import { Container, Row, Col, Button, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';

import {
    getShowEditTagsModal,
    getShowCallNotificationBar,
    getShowAcquirePlatformComponent,
} from '../../../../../store/internal'

import Loading from '../../../../../components/loading/index'
import CallNotificationBar from '../../../components/callNotificationBar';
import ButtonSearchCleanInput from '../../../components/buttonSearchCleanInputComponent';
import AcquirePlatformNotificationBar from '../../../components/acquirePlatformNotificationBar';
import ButtonStatusAgent from '../../../components/buttonStatusAgent';
import FilterOptionComponentController from '../../../components/filterOptionComponent/indexController';
import ModalEditTagsController from '../../../components/modalEditTags/indexController';
import ButtonListNotificationController from '../../../../../components/buttonListNotification/indexController';
import ViewTicketController from '../../../homeTickets/viewTicket/indexController';
import ListOfTicketsController from '../../../homeTickets/listOfTickets/indexController';
import ListOfConsumersController from '../../../homeConsumers/listOfConsumers/indexController';


declare module 'react' {
    interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
        name?: string;
    }
}

const ViewTags = ({
    isLoading,
    token,
    callbackModalTags,
    SetCurrentFilterSearch,
    currentFilterSearch,
    updateListfunction,
    updateListAfterDelete,
    getShowEditTicketsModal,
    currentTag,
    listOfTagsPage,
    filteringTickets,
    showEditTagModal,
    currentState,
    currentParams,
    currentTicket,
    getAllTickets,
    setCurrentState,
    cleanList,
    user,
    currentTags,
    callbackOrderByTickets,
    showCreateTicket,
    setCurrentTicketFunction,
    resetingFilters,
    setResetingFilters,
    yoursTickets,
    setYoursTickets,
    id,
    t,
    tagConsumer,
    auxConsumer,
    callbackModalCustomers,
    currentHasMoreInformation,
    showCreateConsumer,
    setCurrentConsumerFunction,
    callbackOrderByConsumers
}) => {
    return (
        <>
            <Container fluid className="nopadding" style={{ overflowX: 'hidden' }}>
                <Loading visibility={isLoading}></Loading>
                <ModalEditTagsController token={token} visibility={useSelector(getShowEditTagsModal)} callback={() => callbackModalTags()} data={currentTag} tagConsumer={tagConsumer}></ModalEditTagsController>
                <div className="wrapper">
                    {/* <SidebarController currentPage={SidebarButtonPage.Configurations} subPage={SidebarButtonPage.SubTags}></SidebarController> */}
                    <div className="content ps-0 pt-0" >
                        <CallNotificationBar visibility={useSelector(getShowCallNotificationBar)}></CallNotificationBar>
                        <AcquirePlatformNotificationBar visibility={useSelector(getShowAcquirePlatformComponent).visibility} limitDays={useSelector(getShowAcquirePlatformComponent).limitDays} />
                        {
                            getShowEditTicketsModal === true ?
                                <>
                                    <Row className="nopadding ps-3 mt-3">
                                        <Col md={5} style={{ marginRight: '-8vw' }}>
                                            <Row style={{ marginBottom: '1.5vh' }}>
                                                <Col className="d-flex justify-content-start align-content-start">
                                                    <div style={{ font: 'var(--px20_24_Bold-font)' }}>{t('home_tags.view_tag_title')}</div>
                                                </Col>
                                            </Row>
                                            <Row style={{ marginBottom: '1vh' }}>
                                                <Col className="d-flex" style={{ marginTop: "0vh", marginLeft: '0.5vw' }} >
                                                    <div className="nopadding" style={{ font: 'var(--px16_19-font)', color: '#1E1E1E', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => listOfTagsPage()}>
                                                        <span>
                                                            <svg id="Grupo_11161" data-name="Grupo 11161" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 2 24 24">
                                                                <path id="Caminho_9641" data-name="Caminho 9641" d="M0,0H24V24H0Z" fill="none" />
                                                                <path id="Caminho_9642" data-name="Caminho 9642" d="M9,13,5,9,9,5M5,9H16a4,4,0,0,1,0,8H15" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                            </svg>
                                                        </span>
                                                        {t('home_tags.view_tag_return_list')}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="d-flex justify-content-end">
                                            <ButtonSearchCleanInput inputPlaceholder={"pesquisar ticket"} filteringTickets={(e, auxObj, action) => filteringTickets(e, auxObj, action)} setCurrentFilterSearch={SetCurrentFilterSearch} currentFilterSearch={currentFilterSearch} ></ButtonSearchCleanInput>
                                            <ButtonListNotificationController />
                                            <ButtonStatusAgent/>
                                        </Col>
                                        <Row style={{ margin: '1vh 0vh 0vh 0vh' }}>
                                            <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat' }}>
                                                {currentTag != null && currentTag.name}
                                                <OverlayTrigger
                                                    key='top'
                                                    placement='top'
                                                    overlay={
                                                        <Tooltip id='tooltip' className='tooltip-arrow-Top'>
                                                            {t('home_tags.view_tag_data_tag')}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <Button className="mx-3 nopadding buttonTicketsSec" onClick={() => showEditTagModal()}>
                                                        <svg id="Grupo_11248" data-name="Grupo 11248" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                            <path id="Caminho_9655" data-name="Caminho 9655" d="M0,0H24V24H0Z" fill="none" />
                                                            <path id="Caminho_9656" data-name="Caminho 9656" d="M9,7H6A2,2,0,0,0,4,9v9a2,2,0,0,0,2,2h9a2,2,0,0,0,2-2V15" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                            <path id="Caminho_9657" data-name="Caminho 9657" d="M9,15h3l8.5-8.5a2.121,2.121,0,0,0-3-3L9,12v3" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                            <line id="Linha_183" data-name="Linha 183" x2="3" y2="3" transform="translate(16 5)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        </svg>
                                                    </Button>

                                                </OverlayTrigger>
                                            </p>
                                        </Row>
                                    </Row>
                                    <ViewTicketController currentTicket={currentTicket} filters={currentParams} getList={getAllTickets} currentViewFilter={'all'} setCurrentParams={() => {}}></ViewTicketController>
                                </>
                                :
                                <>
                                    <Row className="nopadding mt-3 ps-3">
                                        <Col md={5} style={{ marginRight: '-8vw' }}>
                                            <Row>
                                                <Col className="d-flex justify-content-start align-self-start">
                                                    <div style={{ font: 'var(--px20_24_Bold-font)' }}>{t('home_tags.view_tag_title')}</div>
                                                </Col>
                                            </Row>
                                            <Row className='mt-2'>
                                                <div style={{ font: 'var(--px15_19-font)', color: '#707070' }}>{t('home_tags.view_tag_subtitle')}</div>
                                            </Row>
                                        </Col>
                                        <Col className="d-flex justify-content-end">
                                            <ButtonSearchCleanInput inputPlaceholder={tagConsumer ? "pesquisar consumidor" :"pesquisar ticket"} filteringTickets={(e, auxObj, action) => filteringTickets(e, auxObj, action)} setCurrentFilterSearch={SetCurrentFilterSearch} currentFilterSearch={currentFilterSearch} ></ButtonSearchCleanInput>
                                            <ButtonListNotificationController />
                                            <ButtonStatusAgent/>
                                        </Col>
                                    </Row>
                                    <Row className="ps-4" style={{ marginTop: '1.5vh' }}>
                                        <Col className="d-flex" style={{ marginTop: "0vh", marginLeft: '0.5vw' }} >
                                            <p style={{ font: 'var(--px15_19-font)', color: '#1E1E1E', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => listOfTagsPage()}>
                                                <span>
                                                    <svg id="Grupo_11161" data-name="Grupo 11161" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 2 24 24">
                                                        <path id="Caminho_9641" data-name="Caminho 9641" d="M0,0H24V24H0Z" fill="none" />
                                                        <path id="Caminho_9642" data-name="Caminho 9642" d="M9,13,5,9,9,5M5,9H16a4,4,0,0,1,0,8H15" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                    </svg>
                                                </span>
                                                {t('home_tags.view_tag_return_list')}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="ps-3" id="buttonEditTag" style={{ margin: '1vh 0vw 0px 0vw' }}>
                                        <Col md style={{ marginLeft: '1vw' }}>
                                            <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat' }}>
                                                {currentTag != null && currentTag.name}
                                                <OverlayTrigger
                                                    key='top'
                                                    placement='top'
                                                    overlay={
                                                        <Tooltip id='tooltip' className='tooltip-arrow-Top'>
                                                            {t('home_tags.view_tag_data_tag')}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <Button className="mx-3 nopadding buttonTicketsSec" onClick={() => showEditTagModal()}>
                                                        <svg id="Grupo_11248" data-name="Grupo 11248" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                            <path id="Caminho_9655" data-name="Caminho 9655" d="M0,0H24V24H0Z" fill="none" />
                                                            <path id="Caminho_9656" data-name="Caminho 9656" d="M9,7H6A2,2,0,0,0,4,9v9a2,2,0,0,0,2,2h9a2,2,0,0,0,2-2V15" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                            <path id="Caminho_9657" data-name="Caminho 9657" d="M9,15h3l8.5-8.5a2.121,2.121,0,0,0-3-3L9,12v3" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                            <line id="Linha_183" data-name="Linha 183" x2="3" y2="3" transform="translate(16 5)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        </svg>
                                                    </Button>

                                                </OverlayTrigger>
                                            </p>
                                        </Col>
                                    </Row>
                                    <div className='FilterTicketsRow ps-3' style={{ margin: '2vh 0.5vw 0px', padding: '0' }}>
                                        <ListGroup variant="flush" >
                                            <ListGroup.Item style={{ backgroundColor: 'rgba(207, 227, 255,0.25)', padding: '0.6%' }}>
                                                <FilterOptionComponentController
                                                    optionNames={tagConsumer ? ['Consumer'] :["Canal", "Bot", "Atribuido", "Status", "Setor", "Data"]}
                                                    currentState={currentState}
                                                    setCurrentState={setCurrentState}
                                                    values_user={user}
                                                    values_internal_getShowEditTicketsModal={getShowEditTicketsModal}
                                                    values_internal_getShowCreateTicketsModal={false}
                                                    filteringTickets={(e, auxObj, action) => filteringTickets(e, auxObj, action)}
                                                    cleanList={() => cleanList()}
                                                    values_token_value={token}
                                                    values_company_id={id}
                                                    resetingFilters={resetingFilters}
                                                    setResetingFilters={setResetingFilters}
                                                    yoursTickets={yoursTickets}
                                                    setYoursTickets={setYoursTickets}
                                                    screen={tagConsumer ? 'view_consumer_tags' : 'view_tags'}
                                                />
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </div>
                                    {tagConsumer ?
                                        <ListOfConsumersController datas={auxConsumer} callbackModalCustomers={callbackModalCustomers} updateListAfterDelete={updateListAfterDelete} updateListfunction={updateListfunction} HasMoreInformation={currentHasMoreInformation} showCreateConsumer={showCreateConsumer} setCurrentConsumerFunction={(value) => setCurrentConsumerFunction(value)} callbackOrderByConsumers={(column, order) => callbackOrderByConsumers(column, order)} tagConsumer={tagConsumer} />
                                        :
                                        <ListOfTicketsController datas={currentTags} updateListAfterDelete={() => updateListAfterDelete()} updateListfunction={() => updateListfunction()} showCreateTicket={() => showCreateTicket()} setCurrentTicketFunction={(value) => setCurrentTicketFunction(value)} assignedEmployeeActiveColumn={true} requesterUserActiveColumn={true} actionsActiveColumn={true} checkboxes={true} callbackOrderByTickets={(column, order) => callbackOrderByTickets(column, order)}></ListOfTicketsController>
                                    }
                                </>
                        }
                    </div>
                </div>
            </Container >
        </>
    );
}

export default ViewTags;
