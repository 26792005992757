import { FC } from 'react';
import { useSelector } from 'react-redux'
import { Col, Row, ListGroup, Button, OverlayTrigger, Tooltip, InputGroup, FormControl, Collapse, Form } from 'react-bootstrap';
import allStyles from './styles';
import {
    getShowRegisterCustomersModal,
    getShowEditConsumersModal,
    getShowModalConsumersSimilarData,
    setShowMessageSend,
} from '../../../../store/internal'
import TagsComponent from '../../components/tagsComponent/index'
import InfiniteScroll from 'react-infinite-scroll-component';
import ModalRegisterConsumersController from '../../components/modalRegisterConsumers/indexController';
import ModalEditConsumersController from '../../components/modalEditConsumers/indexController';
import Utils from '../../../../core/shared/utils';
import { TicketStatus } from '../../../../core/enums/ticket-status';
import { Channels } from '../../../../core/enums/channels';
import ButtonSearchCleanInput from '../../components/buttonSearchCleanInputComponent';
import { ViewTicketInterface } from './indexModel';
import { TicketFilterType } from '../../../../core/enums/ticket-filter-type';
import ChannelLabel from '../../components/channelLabel';
import ModalConsumersSimilarDataController from '../../homeConsumers/unificationConsumers/modalConsumersSimilarData/indexController';
import MessagesComponentController from '../../components/messagesComponent/indexController';
import SelectComponentController from '../../components/selectComponent/indexController';
import { IconInfoCircle } from '@tabler/icons-react';

const ViewTicket: FC<ViewTicketInterface> = (props) => {
    return (
        <>
            <ModalRegisterConsumersController token={props.values.token.value} visibility={useSelector(getShowRegisterCustomersModal)} callback={(userIdCustomer) => props.callbackModalCustomers(userIdCustomer)} setCurrentConsumer={props.setCurrentConsumer} setSimilarConsumers={props.setSimilarConsumers} />
            <ModalEditConsumersController token={props.values.token.value} visibility={useSelector(getShowEditConsumersModal)} callback={(userIdCustomer) => props.callbackModalCustomers(userIdCustomer)} data={props.currentConsumersData} setCurrentConsumer={props.setCurrentConsumer} setSimilarConsumers={props.setSimilarConsumers} addRequesterNotInOptions={props.addRequesterNotInOptions} />
            <ModalConsumersSimilarDataController originRedirectPage='edit_ticket' visibility={useSelector(getShowModalConsumersSimilarData)} similarConsumers={props.similarConsumers} currentConsumer={props.currentConsumer} />
            {/*Filtros*/}

            {props?.taskUpdate ?
                <Row className="nopadding ps-3">
                    <Col className="d-flex" style={{ marginTop: "1vh", marginBottom: "1vh", marginLeft: '0.5vh' }}>
                        <p className="nopadding" style={{ font: 'var(--px16_19-font)', color: '#1E1E1E', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => window?.location?.reload()}>
                            <span>
                                <svg id="Grupo_11161" data-name="Grupo 11161" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 2 24 24">
                                    <path id="Caminho_9641" data-name="Caminho 9641" d="M0,0H24V24H0Z" fill="none" />
                                    <path id="Caminho_9642" data-name="Caminho 9642" d="M9,13,5,9,9,5M5,9H16a4,4,0,0,1,0,8H15" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                </svg>
                            </span>
                            { props.t("home_tickets.list_tickets.return_to_list") }
                        </p>
                    </Col>
                </Row> :
                <></>
            }

            <Row className="ps-3">
                {/*Coluna listagem de tickets*/}
                <Row className="align-items-center mx-2" style={{ marginBottom: '1vh', width: '80%' }}>
                    <Col>
                        <Row name="listOfTicketsHead">
                            <Col md={1}
                                className=""
                                style={{
                                    textAlign: 'left',
                                    font: 'var(--px15_19_Bold-font)',
                                    letterSpacing: '0px',
                                    color: '#0C2461',
                                    opacity: '1',
                                    width: '120px',
                                    paddingRight: '0',
                                }}>
                                {props.t("home_tickets.view_ticket.list_tickets.filter_label")}
                            </Col>
                            {
                                props.filters &&
                                    props.filters.channel === undefined
                                    && props.filters.bot === undefined
                                    && props.filters.employee === undefined
                                    && props.filters.status === undefined
                                    && props.filters.date === undefined ?
                                    <Col md={1} className="me-2 d-flex align-items-center" style={allStyles.labelBlue}>{props.t("home_tickets.view_ticket.list_tickets.labels.all")}</Col>
                                    :
                                    <>
                                        {
                                            props.filters.channel !== undefined &&
                                            <Col md={1} className="me-2 d-flex align-items-center" style={allStyles.labelBlue}>{props.t("home_tickets.view_ticket.list_tickets.labels.channel")}</Col>
                                        }
                                        {
                                            props.filters.bot !== undefined &&
                                            <Col md={1} className="me-2 d-flex align-items-center" style={allStyles.labelBlue}>{props.t("home_tickets.view_ticket.list_tickets.labels.bot")}</Col>
                                        }
                                        {
                                            props.filters.employee !== undefined &&
                                            <Col md={2} className="me-2 d-flex align-items-center" style={allStyles.labelBlue2}>{props.t("home_tickets.view_ticket.list_tickets.labels.assigned")}</Col>
                                        }
                                        {
                                            props.filters.status !== undefined &&
                                            <Col md={1} className="me-2 d-flex align-items-center" style={allStyles.labelBlue}>{props.t("home_tickets.view_ticket.list_tickets.labels.status")}</Col>
                                        }
                                        {
                                            props.filters.date !== undefined &&
                                            <Col md={1} className="me-2 d-flex align-items-center" style={allStyles.labelBlue}>{props.t("home_tickets.view_ticket.list_tickets.labels.date")}</Col>
                                        }
                                    </>
                            }
                        </Row>
                    </Col>
                </Row>
                
                <Col className="me-1 mx-2" md={5} style={{ maxWidth: '24vw' }}>
                    <Row>
                        <ButtonSearchCleanInput inputPlaceholder={props.t("home_tickets.button_search_placeholder")} filteringTickets={(e, auxObj, action) => props.filteringTickets(e, auxObj, action)} setCurrentFilterSearch={props.SetCurrentFilterSearch} currentFilterSearch={props.currentFilterSearch} ></ButtonSearchCleanInput>
                    </Row>
                    <Row style={{ display: 'flex', justifyContent: 'center' }}>
                        <Col md={2}>
                            <Button style={{ backgroundColor: 'transparent', border: 'none', width: '100%' }} onClick={() => {
                                props.setAux([]);
                                props.SetCurrentPage(1);
                                props.setForcedUpdate(true);
                            }}> <svg id="Grupo_12899" data-name="Grupo 12899" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <path id="Caminho_10314" data-name="Caminho 10314" d="M0,0H24V24H0Z" fill="none" />
                                    <path id="Caminho_10315" data-name="Caminho 10315" d="M20,11A8.1,8.1,0,0,0,4.5,9M4,5V9H8" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    <path id="Caminho_10316" data-name="Caminho 10316" d="M4,13a8.1,8.1,0,0,0,15.5,2m.5,4V15H16" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                </svg></Button>
                        </Col>
                    </Row>
                    {/*listagem de tickets*/}
                    <Row className="">
                        <div id="listOfTickets" style={{ overflowY: 'auto', height: props.isHistoryHome? '58vh' : '67vh' }} onScroll={() => props.resetOverlayIdOnScroll()} >
                            <InfiniteScroll
                                dataLength={props.currentFilterDatas ? props.currentFilterDatas.length : 10}
                                next={props.updateListfunction}
                                hasMore={props.currentHasMoreInformation}
                                loader={<h4> </h4>}
                                scrollableTarget={"listOfTickets"}
                            >
                                {

                                    props.currentFilterDatas ?
                                        <ListGroup variant="flush" >
                                            {props.currentFilterDatas.map((item, index: number) => {
                                                return (
                                                    <ListGroup.Item className="" id={"itemTicketList" + (index + 1)}
                                                        onClick={() => {
                                                            if(item?.processing === false || item?.processing === undefined) {
                                                                props.getTicket({ id: item.id }, {});
                                                                props.setForcedUpdate(true);
    
                                                                setTimeout(() => {
                                                                    props.setForcedUpdate(true);
                                                                }, 800)
                                                                if ((props?.currentTicket?.id !== item?.id) && !props.updateNoAtendece) {
                                                                    if (props.currentParams["employee"] === "NA" && props.currentParams["status"] === "daddbd66-1dd2-4008-94c9-7fcb9b1a5800") {
                                                                        props.setUpdateNoAtendece(true)
                                                                    }
                                                                }
                                                                if (props.currentClickId !== item?.id) {
                                                                    props.setCurrentClickId(item?.id)
                                                                    props.block_ticket.setAdmBlocked(false)
                                                                    props.block_ticket.setDisableEditTicket(false)
                                                                }
    
                                                                if (props.message === true) {
                                                                    props.dispatch(setShowMessageSend(false))
                                                                }
                                                            } else if (item?.processing === true) {
                                                                props.dispatch(props.setShowConfirmationModal({ visibility: true, text: {"title": props.t("home_tickets.view_ticket.processing.title"), "body": props.t("home_tickets.view_ticket.processing.body"), 'id': '', "buttonConfirmationText": props.t("home_tickets.view_ticket.processing.confirmation")}, functionConfirmation: () => {}}))
                                                            }
                                                            // props.setRenderMessagesChat(false)
                                                        }}
                                                        style={{ cursor: 'pointer', minHeight: '4rem', backgroundColor: props.currentTicket && props.currentTicket.id && props.currentTicket.id === item.id && '#f0f8ff' }} key={index + 1}
                                                    >
                                                        {/*item ticket*/}
                                                        <Row style={{ color: '#0C2461' }} className="d-flex align-items-center">
                                                            <OverlayTrigger
                                                                key='top'
                                                                placement='top'
                                                                overlay={
                                                                    <Tooltip id='tooltip'>
                                                                        #{item.ticket_reference_id}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <Col md={2}
                                                                    className="d-flex align-items-center"
                                                                    style={{
                                                                        background: '#EEF3FF 0% 0% no-repeat padding-box',
                                                                        borderRadius: '4px',
                                                                        opacity: '1',
                                                                        textAlign: 'left',
                                                                        font: 'var( --px15_19_Bold-font)',
                                                                        letterSpacing: '0px',
                                                                        height: '2.2vh',
                                                                    }}>
                                                                    <p style={{ padding: '0', margin: '0vw 0vw 0vw -0.2vw' }}>#{item.ticket_reference_id}</p>
                                                                </Col>
                                                            </OverlayTrigger>
                                                            <Col md={7} style={{ width: '62%', textAlign: 'left', font: 'var( --px15_19_Bold-font)', letterSpacing: '0px', color: '#707070', opacity: '1', fontWeight: item.bold ? 'bolder' : '' }}>
                                                                {item.requester_user != null ?
                                                                    <div className='oneLineClampEllipsis'>
                                                                        <OverlayTrigger
                                                                            key='top'
                                                                            placement='top'
                                                                            overlay={
                                                                                <Tooltip id='tooltip'>
                                                                                    {(item.requester_user.name != null) ?
                                                                                        <>{item.requester_user.name} {item.requester_user.lastname ? (" " + item.requester_user.lastname) : ""}</>
                                                                                        : null
                                                                                    }
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <span style={{
                                                                                height: '35%',
                                                                            }}>
                                                                                {item.requester_user.name} {item.requester_user.lastname ? (" " + item.requester_user.lastname) : ""}
                                                                            </span>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                    :
                                                                    <>
                                                                        N/A
                                                                    </>
                                                                }
                                                            </Col>
                                                            <Col sm={2} md={2} className="" style={{ width: '8%' }}>
                                                                {item.status_id === TicketStatus.Resolved ?
                                                                    <Button style={{ backgroundColor: '#707070', border: 'none', width: '20px', height: '20px', lineHeight: '1', textIndent: '-2', padding: '0rem 0rem' }}>
                                                                        <OverlayTrigger
                                                                            key='top'
                                                                            placement='top'
                                                                            overlay={
                                                                                <Tooltip id='tooltip'>
                                                                                    {props.t("ticket_status.resolved")}
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <span className='FilterTicketsRow '>{props.t("ticket_status_symbol.resolved")}</span>
                                                                        </OverlayTrigger>
                                                                    </Button>
                                                                    :
                                                                    item.status_id === TicketStatus.Pending ?
                                                                        <Button style={{ backgroundColor: '#DE2450', border: 'none', width: '20px', height: '20px', lineHeight: '1', textIndent: '-2', padding: '0rem 0rem' }}>
                                                                            <OverlayTrigger
                                                                                key='top'
                                                                                placement='top'
                                                                                overlay={
                                                                                    <Tooltip id='tooltip'>
                                                                                        {props.t("ticket_status.pending")}
                                                                                    </Tooltip>
                                                                                }
                                                                            >
                                                                                <span className='FilterTicketsRow '>{props.t("ticket_status_symbol.pending")}</span>
                                                                            </OverlayTrigger>
                                                                        </Button>
                                                                        :
                                                                        item.status_id === TicketStatus.OnHold ?
                                                                            <Button style={{ backgroundColor: '#FBBD4E', border: 'none', width: '20px', height: '20px', lineHeight: '1', textIndent: '-2', padding: '0rem 0rem' }}>
                                                                                <OverlayTrigger
                                                                                    key='top'
                                                                                    placement='top'
                                                                                    overlay={
                                                                                        <Tooltip id='tooltip'>
                                                                                            {props.t("ticket_status.on_hold")}
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <span className='FilterTicketsRow '>{props.t("ticket_status_symbol.on_hold")}</span>
                                                                                </OverlayTrigger>
                                                                            </Button>
                                                                            :
                                                                            item.status_id === TicketStatus.Open ?
                                                                                <Button style={{ backgroundColor: '#4A69BD', border: 'none', width: '20px', height: '20px', lineHeight: '1', textIndent: '-2', padding: '0rem 0rem' }}>
                                                                                    <OverlayTrigger
                                                                                        key='top'
                                                                                        placement='top'
                                                                                        overlay={
                                                                                            <Tooltip id='tooltip'>
                                                                                                {props.t("ticket_status.open")}
                                                                                            </Tooltip>
                                                                                        }
                                                                                    >
                                                                                        <span className='FilterTicketsRow '>{props.t("ticket_status_symbol.open")}</span>
                                                                                    </OverlayTrigger>
                                                                                </Button>
                                                                                :
                                                                                item.status_id === TicketStatus.New ?
                                                                                    <Button style={{ backgroundColor: '#17a589', border: 'none', width: '20px', height: '20px', lineHeight: '1', textIndent: '-2', padding: '0rem 0rem' }}>
                                                                                        <OverlayTrigger
                                                                                            key='top'
                                                                                            placement='top'
                                                                                            overlay={
                                                                                                <Tooltip id='tooltip'>
                                                                                                    {props.t("ticket_status.new")}
                                                                                                </Tooltip>
                                                                                            }
                                                                                        >
                                                                                            <span className='FilterTicketsRow '>{props.t("ticket_status_symbol.new")}</span>
                                                                                        </OverlayTrigger>
                                                                                    </Button>
                                                                                    :
                                                                                    item.status_id === TicketStatus.Abandoned ?
                                                                                        <Button style={{ backgroundColor: '#2D3233', border: 'none', width: '20px', height: '20px', lineHeight: '1', textIndent: '-2', padding: '0rem 0rem' }}>
                                                                                            <OverlayTrigger
                                                                                                key='top'
                                                                                                placement='top'
                                                                                                overlay={
                                                                                                    <Tooltip id='tooltip'>
                                                                                                        {props.t("ticket_status.abandoned")}
                                                                                                    </Tooltip>
                                                                                                }
                                                                                            >
                                                                                                <span className='FilterTicketsRow '>{props.t("ticket_status_symbol.abandoned")}</span>
                                                                                            </OverlayTrigger>
                                                                                        </Button>
                                                                                        :
                                                                                        null
                                                                }
                                                            </Col>
                                                            <Col md={1} className='align-self-center'>
                                                                {item.who_is_viewing &&
                                                                    <OverlayTrigger
                                                                        key='top'
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id='tooltip'>
                                                                                {
                                                                                    props.t("home_tickets.list_tickets.body.who_is_viewing", { who_is_viewing: item.who_is_viewing })
                                                                                }
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye" width="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#707070" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                            <circle cx="12" cy="12" r="2" />
                                                                            <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                                                                        </svg>
                                                                    </OverlayTrigger>
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Row className="mt-1"
                                                            style={{
                                                                textAlign: 'left',
                                                                font: 'var(--px15_19-font-Roboto)',
                                                                letterSpacing: '0px',
                                                                color: '#707070',
                                                                opacity: '1',
                                                                width: '80%',
                                                            }}
                                                        >
                                                            <div className='oneLineClampEllipsis nopadding'>
                                                                <OverlayTrigger
                                                                    key='top'
                                                                    placement='top'
                                                                    overlay={
                                                                        <Tooltip id={'tooltip*ID*' + item.id}>
                                                                            {item.last_message}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span>
                                                                        {item.last_message}
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </Row>
                                                    </ListGroup.Item>
                                                )
                                            })}
                                        </ListGroup>
                                        :
                                        <div className='' style={{ overflow: 'hidden' }}>
                                            <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '5vh' }}>
                                                <Col md={3} className="d-flex justify-content-center">
                                                    <svg id="Grupo_11197" data-name="Grupo 11197" xmlns="http://www.w3.org/2000/svg" width="3vw" viewBox="0 0 24 24">
                                                        <path id="Caminho_9561" data-name="Caminho 9561" d="M0,0H24V24H0Z" fill="none" />
                                                        <line id="Linha_144" data-name="Linha 144" y2="2" transform="translate(15 5)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <line id="Linha_145" data-name="Linha 145" y2="2" transform="translate(15 11)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <line id="Linha_146" data-name="Linha 146" y2="2" transform="translate(15 17)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <path id="Caminho_9562" data-name="Caminho 9562" d="M5,5H19a2,2,0,0,1,2,2v3a2,2,0,0,0,0,4v3a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V14a2,2,0,0,0,0-4V7A2,2,0,0,1,5,5" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                    </svg>
                                                </Col>
                                            </Row>
                                            <Row className='d-flex justify-content-center align-content-center mt-2' style={{ marginLeft: '8%' }}>
                                                <Col md={7}>
                                                    <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>
                                                        {
                                                            props.t("home_tickets.list_tickets.body.empty_list")
                                                        }
                                                    </p>
                                                </Col>
                                            </Row>
                                        </div>
                                }
                            </InfiniteScroll>
                        </div>
                    </Row>
                </Col>

                <Col md className="">
                    {/*CHAT*/}
                    <MessagesComponentController setPhoneNumber={props.setPhoneNumber} setOpenModalPhone={props.setOpenModalPhone} /*onlyRead={props.onlyRead}*/ /*isAllowedMessage={props.blockBool}*/ /**O problema ta aqui */ currentRequester_user={props.currentRequester_user} goBackToTicketList={false} name={props.values.user.name + (props.values.user.lastname ? (" " + props.values.user.lastname) : "")} onPostTicket={async () => { }} showSubjectError={null} agent={props.values.user} currentTicket={props.currentTicket} token={props.values.token.value}
                        audioChunks={props.audioChunks}
                        audio={props.audio}
                        audioBlob={props.audioBlob}
                        setAudioChunks={props.setAudioChunks}
                        setAudio={props.setAudio}
                        setAudioBlob={props.setAudioBlob}
                        updateList={() => {
                            props.setAux([]);
                            props.SetCurrentPage(1);
                            props.setCurrentStatusPage("Updating: " + Date.now().toString());

                            props.getTicket({ id: props.currentTicket.id }, {}).finally(() => {
                                props.defaultInputs();
                            });
                        }}
                        hiddenChatEditor={
                            props.currentTicket && (props.currentTicket.status_id === TicketStatus.Resolved || props.currentTicket.status_id === TicketStatus.Abandoned || props.currentTicket.deleted_at !== null) ?
                                true : false
                        }
                        showHistoryButton
                        showOptionsButton={
                            !props.disableEditTicket
                        }
                        deleteTicket={props.confirmationDeleteTicket} ticketChannel={props.currentTicket ? props.currentTicket.channel_id : Channels.NotAssigned}
                        getTicket={props.getTicket}
                        updateCCandCCO={props.updateCCandCCO}
                        setTicketInfo={props.setCurrentTicket}
                        currentChannelId={props.currentTicket?.channel_id}
                        blockDropdownChatComponent={props.blockDropdownChatComponent}
                        setDefaultValue={props.setDefaultValue}
                        setDisabledDropdown={props.setDisabledDropdown}
                        defaultValue={props.defaultValue}
                        disabledDropdown={props.disabledDropdown}
                        editTicket={props.editTicket}
                        setSelectedConsumers={props.setSelectedConsumers}
                        onSelectRequester={props.onSelectRequester}
                        // editTicket={
                        //     props.currentTicket && (props.currentTicket.status_id === TicketStatus.Resolved || props.currentTicket.status_id === TicketStatus.Abandoned || props.currentTicket.deleted_at !== null) ?
                        //         false :
                        //         props.editTicket
                        // }
                        // internalObservationBlock={props.internalObservationBlock}
                        renderMessagesChat={props.renderMessagesChat}
                        isHistoryHome={props.isHistoryHome}
                    >
                    </MessagesComponentController>
                </Col>

                {/*coluna dropdowns*/}
                <Col sm={3} md={3} className='me-4'
                    style={{ 
                        maxWidth: '22vw', 
                        overflowY: 'auto', 
                        overflowX: 'hidden', 
                        height: (
                            props.isHistoryHome? 
                                // usando 88% de quando não é da tela de histórico
                                (props.getShowAcquirePlatformComponent.visibility ? '61vh' : '67vh') : 
                                (props.getShowAcquirePlatformComponent.visibility ? '70vh' : '76vh')
                        ) 
                    }}>
                    {/*DROPDOWNS*/}
                    <Row
                        className="mb-0 pb-1"
                        style={{
                            background: '#F9F9F9 0% 0% no-repeat padding-box',
                            borderRadius: '3px',
                            opacity: '1',
                        }}
                    >
                        <Col className="mt-2" style={{ font: 'var(--px15_19-font-Roboto)' }}>
                            {/*Solicitante dropdown*/}
                            <Row className="mx-1 mb-1 mt-2">
                                <Col>
                                    {props.t("home_tickets.home_create_ticket.requester_field_label")}
                                </Col>
                            </Row>
                            <Row className="mx-1 mb-2 mt-1">
                                <InputGroup>
                                    <FormControl
                                        placeholder={props.t("home_tickets.home_create_ticket.requester_field_label")}
                                        type='text'
                                        id={'requester'}
                                        name={'requester'}
                                        value={props?.selectedConsumers[0]?.label}
                                        style={{ font: 'var(--px15_19-font-Roboto)', height: '5vh' }}
                                        disabled={true}
                                    >
                                    </FormControl>
                                </InputGroup>
                            </Row>
                            <Row style={{ marginBottom: '1vh' }}>
                                <Row className="mx-1 mb-1 mt-1">
                                    <Col>
                                        {props.t("home_tickets.home_create_ticket.department_field_label")}
                                        <OverlayTrigger placement="top" overlay={<Tooltip>{props.t('home_tickets.home_create_ticket.sector_info')}</Tooltip>}>
                                            <IconInfoCircle color="#0C2461" size={22}/>
                                        </OverlayTrigger>
                                    </Col>
                                </Row>
                                <Row style={{ marginLeft: '0vw' }}>
                                    <Col>
                                        <SelectComponentController
                                            options={props.departmentsOptions}
                                            setOptions={props.setDepartmentsOptions}
                                            selecteds={props.selectedDepartments}
                                            setSelecteds={props.setSelectedDepartments}
                                            singleSelect={true}
                                            isFormNoFilter={true}
                                            title={props.t("home_tickets.home_create_ticket.select_component.requester.field_label")}
                                            overrideStrings={
                                                {
                                                    "allItemsAreSelected": props.t("home_tickets.home_create_ticket.select_component.all_items_are_selected", { items_name: props.t("home_tickets.home_create_ticket.departments_field_label") }),
                                                    "clearSearch": props.t("home_tickets.home_create_ticket.select_component.clear_search"),
                                                    "clearSelected": props.t("home_tickets.home_create_ticket.select_component.clear_selected", { items_name: props.t("home_tickets.home_create_ticket.departments_field_label") }),
                                                    "noOptions": props.t("home_tickets.home_create_ticket.select_component.no_options", { items_name: props.t("home_tickets.home_create_ticket.departments_field_label") }),
                                                    "search": props.t("home_tickets.home_create_ticket.select_component.search", { items_name: props.t("home_tickets.home_create_ticket.departments_field_label") }),
                                                    "selectAll": props.t("home_tickets.home_create_ticket.select_component.select_all", { items_name: props.t("home_tickets.home_create_ticket.departments_field_label") }),
                                                    "selectAllFiltered": props.t("home_tickets.home_create_ticket.select_component.select_all_filtered", { items_name: props.t("home_tickets.home_create_ticket.departments_field_label") }),
                                                    "selectSomeItems": props.t("home_tickets.home_create_ticket.department_field_label"),
                                                    "create": props.t("home_tickets.home_create_ticket.select_component.create"),
                                                }
                                            }
                                            hasCreateItem={false}
                                            filterOptions={props.multiselectFilterDepartments}
                                            hasNullOption={false}
                                            disabled={props.disableEditTicket}
                                            onSelect={(value) => props.onSelectDepartment(value)}
                                            hasAllOption={false}
                                        />
                                    </Col>
                                </Row>
                            </Row>
                            <Row style={{ marginBottom: '1vh' }}>
                                <Row className="mx-1 mb-1">
                                    <Col className="align-self-end" >
                                        {props.t("home_tickets.home_create_ticket.assigned_field_label")}
                                    </Col>
                                    <Col md={7} className="d-flex justify-content-end" style={{ height: '70%' }}>
                                        {(props.currentViewFilter && (props.currentViewFilter === TicketFilterType.Deleted || props.currentViewFilter === TicketFilterType.Finalized)) /* || ((props.blockBool === true)) */ ?
                                            <></> :
                                            <Button onClick={props.onClickButtonAccept} disabled={props.disableEditTicket} className="buttonAsText">{props.t("home_tickets.home_create_ticket.accept_assigned")}</Button>
                                        }
                                    </Col>
                                </Row>
                                <Row style={{ marginLeft: '0vw' }}>
                                    <Col>
                                        <SelectComponentController
                                            options={props.agentsOptions}
                                            setOptions={props.setAgentsOptions}
                                            selecteds={props.selectedAgents}
                                            setSelecteds={props.setSelectedAgents}
                                            singleSelect={true}
                                            isFormNoFilter={true}
                                            title={props.t("home_tickets.home_create_ticket.assigned_field_label")}
                                            overrideStrings={
                                                {
                                                    "allItemsAreSelected": props.t("home_tickets.home_create_ticket.select_component.all_items_are_selected", { items_name: props.t("home_tickets.home_create_ticket.assigneds_field_label") }),
                                                    "clearSearch": props.t("home_tickets.home_create_ticket.select_component.clear_search"),
                                                    "clearSelected": props.t("home_tickets.home_create_ticket.select_component.clear_selected", { items_name: props.t("home_tickets.home_create_ticket.assigneds_field_label") }),
                                                    "noOptions": props.t("home_tickets.home_create_ticket.select_component.no_options", { items_name: props.t("home_tickets.home_create_ticket.assigneds_field_label") }),
                                                    "search": props.t("home_tickets.home_create_ticket.select_component.search", { items_name: props.t("home_tickets.home_create_ticket.assigneds_field_label") }),
                                                    "selectAll": props.t("home_tickets.home_create_ticket.select_component.select_all", { items_name: props.t("home_tickets.home_create_ticket.assigneds_field_label") }),
                                                    "selectAllFiltered": props.t("home_tickets.home_create_ticket.select_component.select_all_filtered", { items_name: props.t("home_tickets.home_create_ticket.assigneds_field_label") }),
                                                    "selectSomeItems": props.t("home_tickets.home_create_ticket.assigned_field_label"),
                                                    "create": props.t("home_tickets.home_create_ticket.select_component.create"),
                                                }
                                            }
                                            hasCreateItem={false}
                                            filterOptions={props.multiselectFilterAgents}
                                            hasNullOption={true}
                                            nullOptionLabel={props.t("home_tickets.home_create_ticket.null_option")}
                                            onSelect={(value) => props.onSelectDropdownAssigned(value)}
                                            disabled={props.disableEditTicket}
                                            hasAllOption={false}
                                        />
                                    </Col>
                                </Row>
                            </Row>

                            <Row style={{ marginBottom: '1vh' }}>
                                <Row className="mx-1 mb-1 mt-1">
                                    <Col>
                                        {props.t("home_tickets.home_create_ticket.followers_field_label")}
                                    </Col>
                                </Row>
                                <Row style={{ marginLeft: '0vw' }}>
                                    <Col>
                                        <TagsComponent
                                            disabled={
                                                props.disableEditTicket
                                            }
                                            onSearch={props.onSearchFollowers}
                                            abortConst={props.abortConst}
                                            tags={props.followers} onSelect={() => { props.setFilledEditTicket && props.setFilledEditTicket(true); }} currentTags={props.currentFollowers} setCurrentTags={props.selectFollowers}></TagsComponent>
                                    </Col>
                                </Row>
                            </Row>
                            <Row style={{ marginBottom: '1vh' }}>
                                <Row className="mx-1 mb-1 mt-1">
                                    <Col>
                                        {props.t("home_tickets.home_create_ticket.status_field_label")}
                                    </Col>
                                </Row>
                                <Row style={{ marginLeft: '0vw' }}>
                                    <Col>
                                        <SelectComponentController
                                            options={props.statusOptions}
                                            setOptions={() => { }}
                                            selecteds={props.selectedStatus}
                                            setSelecteds={props.setSelectedStatus}
                                            singleSelect={true}
                                            isFormNoFilter={true}
                                            title={props.t("home_tickets.home_create_ticket.status_field_label")}
                                            overrideStrings={
                                                {
                                                    "allItemsAreSelected": props.t("home_tickets.home_create_ticket.select_component.all_items_are_selected", { items_name: props.t("home_tickets.home_create_ticket.status_field_label_lower") }),
                                                    "clearSearch": props.t("home_tickets.home_create_ticket.select_component.clear_search"),
                                                    "clearSelected": props.t("home_tickets.home_create_ticket.select_component.clear_selected", { items_name: props.t("home_tickets.home_create_ticket.status_field_label_lower") }),
                                                    "noOptions": props.t("home_tickets.home_create_ticket.select_component.no_options", { items_name: props.t("home_tickets.home_create_ticket.status_field_label_lower") }),
                                                    "search": props.t("home_tickets.home_create_ticket.select_component.search", { items_name: props.t("home_tickets.home_create_ticket.status_field_label_lower") }),
                                                    "selectAll": props.t("home_tickets.home_create_ticket.select_component.select_all", { items_name: props.t("home_tickets.home_create_ticket.status_field_label_lower") }),
                                                    "selectAllFiltered": props.t("home_tickets.home_create_ticket.select_component.select_all_filtered", { items_name: props.t("home_tickets.home_create_ticket.status_field_label_lower") }),
                                                    "selectSomeItems": props.t("home_tickets.home_create_ticket.status_field_label"),
                                                    "create": props.t("home_tickets.home_create_ticket.select_component.create"),
                                                }
                                            }
                                            hasCreateItem={false}
                                            hasNullOption={false}
                                            onSelect={(value) => props.onSelectStatus(value)}
                                            disableSearch={true}
                                            disabled={props.disableEditTicket}
                                            hasAllOption={false}
                                        />
                                    </Col>
                                </Row>
                            </Row>
                            <Row style={{ marginBottom: '1vh' }}>
                                <Row className="mx-1 mb-1 mt-1">
                                    <Col>
                                        {props.t("home_tickets.home_create_ticket.tags_field_label")}
                                    </Col>
                                </Row>
                                <Row style={{ marginLeft: '0vw' }}>
                                    <Col>
                                        <TagsComponent
                                            disabled={
                                                props.disableEditTicket
                                            }
                                            tags={props.tags} currentTags={props.currentTags} onSelect={() => { props.setFilledEditTicket && props.setFilledEditTicket(true); }} setCurrentTags={props.selectTags}></TagsComponent>
                                    </Col>
                                </Row>
                            </Row>
                            <Row style={{ marginBottom: '1vh' }}>
                                <Col className="">
                                    <Row className="mx-1 mb-1 mt-1" >
                                        <div style={{ font: 'var(--px15_19_Bold-font)' }}>{props.t("home_tickets.home_create_ticket.subject_ticket")}*</div>
                                    </Row>
                                    <Row className="mx-1 mb-1 mt-1" >
                                        <Col className="d-flex justify-content-start">
                                            <textarea
                                                disabled={
                                                    props.disableEditTicket
                                                }
                                                id="textAreaAssunto"
                                                value={props.currentSubject}
                                                onChange={(e) => { props.limiterChangeTextArea(e.target.value) }}
                                                //style={{  }}
                                            ></textarea>
                                        </Col>

                                    </Row>
                                </Col>
                            </Row>
                            {/* Vir aqui mais tarde */}
                            <Row style={{ marginBottom: '1vh' }}>
                                <Col className="">
                                    <Row className="mx-1 mb-1 mt-1">
                                        <div
                                            style={{ font: 'var(--px15_19_Bold-font)', cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                                                onClick={props.toggleContent}
                                        >
                                            {props.t("home_tickets.view_ticket.custom_fields")}
                                            <span style={{ marginLeft: '5px', transition: 'transform 0.3s', color: '#4a69bd', fontSize: '15px' }}>
                                                {props.showContent ?
                                                    <svg id="Grupo_11467" data-name="Grupo 11467" xmlns="http://www.w3.org/2000/svg" width="16.383" height="16.383" viewBox="0 0 16.383 16.383">
                                                        <path id="Caminho_9741" data-name="Caminho 9741" d="M0,16.383H16.383V0H0Z" fill="none" />
                                                        <path id="Caminho_9742" data-name="Caminho 9742" d="M6,13.1,10.1,9l4.1,4.1" transform="translate(-1.904 -2.856)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                    </svg> :
                                                    <svg id="Grupo_11489" data-name="Grupo 11489" xmlns="http://www.w3.org/2000/svg" width="16.383" height="16.383" viewBox="0 0 16.383 16.383">
                                                        <path id="Caminho_9741" data-name="Caminho 9741" d="M0,0H16.383V16.383H0Z" fill="none" />
                                                        <path id="Caminho_9742" data-name="Caminho 9742" d="M6,9l4.1,4.1,4.1-4.1" transform="translate(-1.904 -2.856)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                    </svg>
                                                }
                                            </span>
                                        </div>
                                    </Row>
                                    <Collapse in={props.showContent}>
                                        <Row style={{ marginBottom: '-1vh' }}>
                                            {(props.customFields !== null && props.customFields !== undefined && props.customFields.length > 0 ? props.customFields.map((customField) => {
                                                if (customField !== undefined || customField !== null) {
                                                    switch (customField.type) {
                                                        case "d31ebd9e-af9f-4559-b04a-729439218a6f": //text
                                                            return (<>
                                                                <Row className="mx-1 mb-1 mt-2">
                                                                    <Col>
                                                                        {customField.required? '* ': ''}{customField.name}
                                                                    </Col>
                                                                </Row>
                                                                <Row className="mx-1 mb-2 mt-1">
                                                                    <InputGroup>
                                                                        <FormControl
                                                                            disabled={props.disableEditTicket}
                                                                            placeholder={customField.placeholder}
                                                                            type='text'
                                                                            id={customField.id}
                                                                            name={customField.name}
                                                                            value={props.textareaStates[customField.id]? props.textareaStates[customField.id]: ""}
                                                                            onChange={(e) => props.changeCustomField(e, customField)}
                                                                            maxLength={50}
                                                                            style={{ font: 'var(--px15_19-font-Roboto)', height: '5vh' }}
                                                                            isInvalid={props.customFieldIdErrors[customField.id]}
                                                                        >
                                                                        </FormControl>
                                                                        <Form.Control.Feedback type="invalid" id="feedbackcustomField">
                                                                            { props.t("home_tickets.custom_fields_for_forms.required_field") }
                                                                        </Form.Control.Feedback>
                                                                    </InputGroup>
                                                                </Row>
                                                            </>
                                                            )
                                                        case "2982a738-4de3-440a-8ed1-cc1fdb51bc9b": //textarea
                                                            return (<>
                                                                <Row className="mx-1 mb-1 mt-2">
                                                                    <Col>
                                                                        {customField.required? '* ': ''}{customField.name}
                                                                    </Col>
                                                                </Row>
                                                                <Row className="mx-1 mb-2 mt-1">
                                                                    <InputGroup>
                                                                        <FormControl
                                                                            placeholder={customField.placeholder}
                                                                            as="textarea" 
                                                                            type="text"
                                                                            id={customField.id}
                                                                            name={customField.name}
                                                                            value={props.textareaStates[customField.id]? props.textareaStates[customField.id]: ""}
                                                                            onChange={(e) => props.changeCustomField(e, customField)}
                                                                            maxLength={500}
                                                                            isInvalid={props.customFieldIdErrors[customField.id]}
                                                                            disabled={props.disableEditTicket}
                                                                        >
                                                                        </FormControl>
                                                                        <Form.Control.Feedback type="invalid" id="feedbackcustomField">
                                                                            { props.t("home_tickets.custom_fields_for_forms.required_field") }
                                                                        </Form.Control.Feedback>
                                                                    </InputGroup>
                                                                </Row></>)
                                                    }
                                                }
                                            }) :
                                                <Row className="mx-1 mb-1 mt-2">
                                                    <Col>
                                                       {props.t("home_tickets.home_create_ticket.no_options")}
                                                    </Col>
                                                </Row>
                                            )}
                                        </Row>
                                    </Collapse>
                                </Col>
                            </Row>
                            {
                                props.disableEditTicket ?
                                    <></>
                                    :
                                    <Row style={{ marginBottom: '1vh' }} className="mt-4">
                                        <Row style={{ marginLeft: '0vw' }}>
                                            <Col className="d-flex justify-content-center">
                                                <Button disabled={props.disableSaveButton} type='submit' onClick={props.updateTicket} className="buttonBlue">{props.t("home_tickets.home_create_ticket.save_ticket")}</Button>
                                            </Col>
                                        </Row>
                                    </Row>
                            }
                        </Col >
                    </Row >

                    <Row
                        className="d-flex justify-content-center mt-2"
                        style={{
                            background: '#F9F9F9 0% 0% no-repeat padding-box',
                            borderRadius: '3px',
                            opacity: '1',
                        }}
                    >
                        <Row className="d-flex justify-content-center mt-2">
                            <Col className="mx-2"

                                style={{
                                    height: '7.5vh',
                                    background: '#E8E8E8 0% 0% no-repeat padding-box',
                                    borderRadius: '3px',
                                    opacity: '1',
                                }}>
                                <div
                                    className="mt-2 ms-1"
                                    style={{
                                        textAlign: 'left',
                                        font: 'var(--px16_19_Bold-font)',
                                        letterSpacing: '0px',
                                        color: '#1E1E1E',
                                        opacity: '1',
                                    }}
                                >
                                    {props.t("home_tickets.list_tickets.headers.id")}
                                </div>
                                <div
                                    className="mt-2 ms-1"
                                    style={{
                                        textAlign: 'left',
                                        font: 'var(--px15_19-font-Roboto)',
                                        letterSpacing: '0px',
                                        color: '#1E1E1E',
                                        opacity: '1',
                                    }}
                                >
                                    #{props.currentTicket != null &&
                                        props.currentTicket.ticket_reference_id
                                    }
                                </div>
                            </Col>

                            {/*CARD BOT*/}
                            <Col className="mx-2"

                                style={{

                                    height: '7.5vh',
                                    background: '#E8E8E8 0% 0% no-repeat padding-box',
                                    borderRadius: '3px',
                                    opacity: '1',
                                }}>
                                <div
                                    className="mt-2 ms-1"
                                    style={{
                                        textAlign: 'left',
                                        font: 'var(--px16_19_Bold-font)',
                                        letterSpacing: '0px',
                                        color: '#1E1E1E',
                                        opacity: '1',
                                    }}
                                >
                                    {props.t("home_tickets.home_create_ticket.bot_field_label")}
                                </div>
                                <div
                                    className="mt-2 ms-1"
                                    style={{
                                        textAlign: 'left',
                                        font: 'var(--px15_19-font-Roboto)',
                                        letterSpacing: '0px',
                                        color: '#1E1E1E',
                                        opacity: '1',
                                    }}
                                >
                                    {props.isbot()}
                                </div>
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-center mt-2">
                            {/*CARD canal*/}
                            <Col className="mx-2"

                                style={{
                                    height: '7.5vh',
                                    background: '#E8E8E8 0% 0% no-repeat padding-box',
                                    borderRadius: '3px',
                                    opacity: '1',
                                }}>
                                <div
                                    className="mt-2 ms-1"
                                    style={{
                                        textAlign: 'left',
                                        font: 'var(--px16_19_Bold-font)',
                                        letterSpacing: '0px',
                                        color: '#1E1E1E',
                                        opacity: '1',
                                    }}
                                >
                                    {props.t("home_tickets.home_create_ticket.channel_field_label")}
                                </div>
                                <div
                                    className="mt-2 ms-1"
                                    style={{
                                        textAlign: 'left',
                                        font: 'var(--px15_19-font-Roboto)',
                                        letterSpacing: '0px',
                                        color: '#1E1E1E',
                                        opacity: '1',
                                    }}
                                >
                                    {props.currentTicket && ChannelLabel(props.t(Utils.getTicketChannelTranslationName(props.currentTicket.channel_id)), props.currentTicket.channel_id, { color: "rgb(30, 30, 30)" })}
                                </div>
                            </Col>

                            {/*CARD data*/}
                            <Col className="mx-2 mb-2"

                                style={{
                                    height: '7.5vh',
                                    background: '#E8E8E8 0% 0% no-repeat padding-box',
                                    borderRadius: '3px',
                                    opacity: '1',
                                }}>
                                <div
                                    className="mt-2 ms-1"
                                    style={{
                                        textAlign: 'left',
                                        font: 'var(--px16_19_Bold-font)',
                                        letterSpacing: '0px',
                                        color: '#1E1E1E',
                                        opacity: '1',
                                    }}
                                >
                                    {
                                        props.t("home_tickets.home_create_ticket.date")
                                    }
                                </div>
                                <div
                                    className="mt-2 ms-1"
                                    style={{
                                        textAlign: 'left',
                                        font: 'var(--px15_19-font-Roboto)',
                                        letterSpacing: '0px',
                                        color: '#1E1E1E',
                                        opacity: '1',
                                    }}
                                >
                                    {Utils.formatedDate(props.currentLastUpdatedDate)}
                                </div>
                            </Col>
                        </Row>
                    </Row>
                </Col >
            </Row >
        </>
    );
}

export default ViewTicket;