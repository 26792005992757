const constsRouters = {
    routers: {
        home: {
            path: '/',
            informations: "Tela principal"
        },
        register: {
            path: '/register',
            informations: "Registro"
        },
        forgoturl: {
            path: '/forgoturl',
            informations: "Esqueceu o URL"
        },
        validateemail: {
            path: '/validateemail',
            informations: "Validar email de confirmação"
        },
        newpassword: {
            path: '/newpassword',
            informations: "Criação da nova senha"
        },
        forgotpassword: {
            path: '/forgotpassword',
            informations: "Esqueceu a senha"
        },
        dashboard: {
            path: '/dashboard',
            informations: "Controle de dashboard"
        },
        tickets: {
            path: '/tickets',
            informations: "Controle de tickets"
        },
        tasks: {
            path: '/tasks',
            informations: "Tasks do ticket selecionado"
        },
        createTicket: {
            path: '/tickets/create',
            informations: "Cadastro de ticket"
        },
        viewTicket: {
            path: '/tickets/view',
            informations: "Edição e visualização do ticket"
        },
        history: {
            path: '/history',
            informations: "Histórico dos tickets"
        },
        // historyFinalized: {
        //     path: '/history/finalized',
        //     informations: "Histórico dos tickets finalizados"
        // },
        // historyDeleted: {
        //     path: '/history/deleted',
        //     informations: "Histórico dos tickets excluídos"
        // },
        viewFinalizedTicket: {
            path: '/history/finalized/view',
            informations: "Visualização dos tickets finalizados"
        },
        viewDeletedTicket: {
            path: '/history/deleted/view',
            informations: "Visualização dos tickets excluídos"
        },
        agents: {
            path: '/agents',
            informations: "Controle de agentes"
        },
        agentsView: {
            path: '/agents/view',
            informations: "View dos agentes"
        },
        consumers: {
            path: '/consumers',
            informations: "Controle de consumidores"
        },
        consumersView: {
            path: '/consumers/view',
            informations: "View dos consumidores"
        },
        consumersViewAnalysisSimilar: {
            path: '/consumers/similar',
            informations: "View para análise de consumidores similares"
        },
        configurationTags: {
            path: '/configuration/tags',
            informations: "Configurações das tags"
        },
        configurationTagsView: {
            path: '/configuration/tags/view',
            informations: "View das tags"
        },
        configurationChannels: {
            path: '/configuration/channels',
            informations: "Configurações dos canais"
        },
        configurationTickets: {
            path: '/configuration/tickets',
            informations: "Configurações dos tickets"
        },
        configurationDepartments: {
            path: '/configuration/departments',
            informations: "Configurações dos setores"
        },
        configurationCustomFields: {
            path: '/configuration/custom-fields',
            informations: "Configurações dos campos customizados"
        },
        configurationDepartmentsView: {
            path: '/configuration/departments/view',
            informations: "View dos setores"
        },
        configurationChatbot: {
            path: '/configuration/chatbot',
            informations: 'Página do chatbot'
        },
        configurationChatbotView: {
            path: '/configuration/chatbot/bot',
            informations: 'Página do bot'
        },
        configurationSecurity: {
            path: '/configuration/security',
            informations: 'Configurações de segurança'
        },
        configurationTriage: {
            path: '/configuration/triage',
            informations: 'Configurações de triagem'
        },
        chatBotIFrame: {
            path: '/chat-bot-iframe/:id',
            informations: 'Iframe do chatbot',
        },
        pageDontFind: {
            path: '/error',
            informations: "Erro 404"
        },
        endTrial: {
            path: '/end-trial',
            informations: "Período de teste encerrado"
        },
        ticketsMessageTrigger: {
            path: '/messages-trigger',
            informations: "Tela de disparo ativo de mensagem"
        },
        ticketsMessageTriggerView: {
            path: '/messages-trigger/view',
            informations: "Tela de tickets associados a um disparo ativo"
        },
        ticketsMessageTriggerReport: {
            path: '/messages-trigger/report',
            informations: "Relatório do disparo ativo"
        },
        controlPanelHome: {
            path: '/',
            information: 'Página inicial do painel de controle'
        },
        controlPanelclients: {
            path: '/clients',
            informations: "Listagem de clientes"
        },
        controlPanelclientsView: {
            path: '/clients/view',
            information: "Informações dos clientes"
        },
        controlPanelAccounts: {
            path: '/accounts',
            information: "Contas do painel"
        },
        controlPanelValidateEmail: {
            path: '/validate-control-panel',
            information: "Validar e-mail de confirmação"
        },
        controlPanelNewPassword: {
            path: '/newpassword-control-panel',
            informations: "Criação da nova senha"
        },
        controlPanelForgotPassword: {
            path: '/forgotpassword-control-panel',
            informations: "Criação da nova senha"
        },
        controlPanelSecurity: {
            path: '/security',
            informations: "Configurações de segurança do painel de CS"
        },
        dashboardChatbot: {
            path: '/dashboard-chatbot',
            informations: "Controle de dashboard do chatbot"
        },
        historyChatbot: {
            path: '/history-chatbot',
            informations: "Controle do historico do chatbot"
        },
        dashboardSummary: {
            path: '/dashboard-summary',
            informations: "Controle de dashboard do sumário"
        },
        summaryView: {
            path: '/summary/view',
            informations: "View dos sumários"
        }
        // loginControlPanel: {
        //     path: '/control-panel/login',
        //     informations: "Login no painel de controle"
        // }
    }
};


export default constsRouters;
