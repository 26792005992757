import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { DataTagIdInterface, TokenInterface } from "../../../../services/requestsInterfacesModel";
import { getIdCompany } from "../../../../store/company";
import { getIdEmployee } from "../../../../store/employee";
import { getToken } from "../../../../store/token";
import { getIdUser } from "../../../../store/user";
import { getShowConfirmationModal, getShowCreateConsumersModal, getShowEditConsumersModal, getShowEditTagsModal, getShowRegisterTagsModal, setShowAlertFeedback, setShowEditTagsModal, setShowRegisterTagsModal } from "../../../../store/internal";
import { useEffect, useState } from "react";
import BackendConstants from "../../../../core/constants/backend-constants";
import HomeTags from ".";
import UserService from "../../../../services/user-service";
import { AppRequesterController } from "../../../../services/appRequester/appRequesterController";
import { useTranslation } from "react-i18next";
import { verifyCode } from "../../../../services/codeCxpressInterface";

const HomeTagsController = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const AppRequesterConst = new AppRequesterController();

    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        },
        employee: {
            id: useSelector(getIdEmployee)
        },
        user: {
            id: useSelector(getIdUser)
        },
        token: {
            value: useSelector(getToken)
        },
        internal: {
            getShowCreateConsumersModal: useSelector(getShowCreateConsumersModal),
            getShowEditConsumersModal: useSelector(getShowEditConsumersModal),
            getShowConfirmationModal: useSelector(getShowConfirmationModal),
            getShowRegisterTagsModal: useSelector(getShowRegisterTagsModal),
            getShowEditTagsModal: useSelector(getShowEditTagsModal),
        }
    };

    const [isLoading, setIsLoading] = useState(false);
    const [currentTicketTags, setCurrentTicketTags] = useState(undefined);
    const [currentConsumerTags, setCurrentConsumerTags] = useState(undefined);
    const [/*currentTag*/, setCurrentTag] = useState([]);
    const [currentFilterSearch, SetCurrentFilterSearch] = useState(null);
    const [currentParams, SetCurrentParams] = useState({});
    const [aux, setAux] = useState([]);
    const [auxConsumer, setAuxConsumer] = useState([]);
    const [currentPage, SetCurrentPage] = useState(1);
    const [currentLimit,/*SetCurrentLimit*/] = useState(BackendConstants.limitMin);
    const [currentHasMoreInformation, SetCurrentHasMoreInformation] = useState(true);
    const [currentState, setCurrentState] = useState("");
    const [activeTab, setActiveTab] = useState("ticket");
    const [consumer, setConsumer] = useState(false);

    const controller = new AbortController();

    const updateListfunction = () => {
        SetCurrentPage(currentPage + 1);
    };

    useEffect(() => {
        if (values.internal.getShowEditTagsModal) {
            dispatch(setShowEditTagsModal(false));
        }
        if (values.internal.getShowRegisterTagsModal) {
            dispatch(setShowRegisterTagsModal(false));
        }
    }, []);

    useEffect(() => {
        if (values.token.value === null) {
        } else {
            SetCurrentParams((params) => {
                setCurrentState((state) => {
                    getAllTagsToPagination(Object.assign(currentParams, { limit: currentLimit, page: currentPage }), getAllTags);
                    return state;
                });
                return params;
            });
        }
    }, [currentPage, currentState]);

    const getAllTags = async (params: { limit: number; page: number; }) => {
        const headers = UserService.getHeaders();
        const signal = controller.signal;

        await AppRequesterConst.Get(
            '/tag', { headers, signal, params: params },
            (response: Object) => { },
            (data: DataTagIdInterface) => {
                if (data.status === 200) {
                    SetCurrentHasMoreInformation(true);
                    setAux((state) => {
                        let aux = state.concat(data.data.tags);
                        const uniqueTags = Array.from(new Set(aux.map(tag => tag.id))).map(id => aux.find(tag => tag.id === id));
                        setCurrentTicketTags(uniqueTags);
                        return uniqueTags;
                    });
                } else if (aux.length === 0) {
                    setCurrentTicketTags(undefined);
                }
            },
            (error: { response: { status: number; data: { message: any []; code_cxpress: number } } }) => { 
                if (error.response?.data?.message && error.response.data.message[0]) {
                    dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }))
                } else {
                    dispatch(setShowAlertFeedback({ message: t('errors.defaultErrorMessage'), visibility: true, signalIcon: false }));
                }
            }, navigate, dispatch, setIsLoading, { values: values }
        );
    }


    const getAllConsumerTags = async (params: { limit: number; page: number; }) => {
        const headers = UserService.getHeaders();
        const signal = controller.signal;
        await AppRequesterConst.Get(
            '/consumer-tag', { headers, signal, params: params },
            (response: Object) => { },
            (data: DataTagIdInterface) => {
                if (data.status === 200) {
                    SetCurrentHasMoreInformation(true);
                    setAuxConsumer((state) => {
                        let aux1 = state.concat(data.data.tags);
                        const uniqueTags = Array.from(new Set(aux1.map(tag => tag.id))).map(id => aux1.find(tag => tag.id === id));
                        setCurrentConsumerTags(uniqueTags);
                        return uniqueTags;
                    });

                }
            },
            (error: { response: { status: number; data: { message: any[]; code_cxpress: number } } }) => {
                if (error.response?.data?.message && error.response.data.message[0]) {
                    dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }))
                } else {
                    dispatch(setShowAlertFeedback({ message: t('errors.defaultErrorMessage'), visibility: true, signalIcon: false }));
                }
            }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const updateListAfterDelete = () => {
        setAux([]);
        setAuxConsumer([]);
        setCurrentConsumerTags([]); /* como agora usa outra variavel para diferenciar as tags, precisa limpar elas */
        setCurrentTicketTags([]);
        SetCurrentPage(1);

        if (currentState !== "deactivating") {
            setCurrentState("deactivating");
        } else {
            setCurrentState("");
        }
    };

    const search = (e: any, auxObj: Object, action: string) => {
        SetCurrentPage(1);
        setAux([]);
        setAuxConsumer([]);
        setCurrentConsumerTags([]);
        setCurrentTicketTags([]);

        let name = "";
        if (e.currentTarget) {
            name = e.currentTarget.name;
        } else if (e.target) {
            name = e.target.name;
        }

        if (name === "cleanSearchButtonName" || action === "clean") {
            SetCurrentFilterSearch("");
            auxObj = {};
            setCurrentState("searching");
            if (currentState === "searching") {
                setCurrentState("");
            }
        } else if (name === "searchButtonName" || name === "searchInputName") {
            auxObj = {};

            setCurrentState("searching");
            if (currentState === "searching") {
                setCurrentState("");
            }

            if (currentFilterSearch !== null && currentFilterSearch !== "") {
                auxObj["search"] = currentFilterSearch;
            }
        } else {
            setCurrentState("filtering");

            if (currentState === "filtering") {
                setCurrentState("");
            }
        }

        SetCurrentParams(auxObj);
    }

    // const setCurrentTagFunction = (value: any) => {
    //     setCurrentTag(value);
    // }


    const getAllTagsToPagination = (params: { limit: number; page: number; }, getAllTags: Function) => {
        getAllTags(params);
        getAllConsumerTags(params)
    }

    const showCreateTag = () => {
        dispatch(setShowRegisterTagsModal(true));
    }

    const callbackModalTags = (getAllTags: Function) => {
        SetCurrentPage(1);
        setAux([]);
        setAuxConsumer([]);
        setCurrentConsumerTags([]);
        setCurrentTicketTags([]);

        getAllTagsToPagination(Object.assign(currentParams, { limit: currentLimit, page: currentPage }), getAllTags);
    }

    const callbackOrderBy = (column: any, order: any) => {
        SetCurrentPage(1);
        setAux([]);
        setAuxConsumer([]);
        const auxObj = {};

        if (column != null) {
            auxObj["sort_by"] = column;
        }

        if (order != null) {
            auxObj["order_by"] = order;
        }
        if (currentFilterSearch !== null && currentFilterSearch !== "") {
            auxObj["search"] = currentFilterSearch;
        }
        setCurrentState(column + order);
        SetCurrentParams(auxObj);

        if (document.getElementById("listOfTags")) {
            document.getElementById("listOfTags").scrollTop = 0;
        }
    }


    return <HomeTags
        t={t}
        getAllTags={getAllTags}
        isLoading={isLoading}
        token={values.token.value}
        callbackModalTags={callbackModalTags}
        SetCurrentFilterSearch={SetCurrentFilterSearch}
        search={search}
        currentFilterSearch={currentFilterSearch}
        aux={currentTicketTags}
        showCreateTag={showCreateTag}
        updateListfunction={updateListfunction}
        currentHasMoreInformation={currentHasMoreInformation}
        updateListAfterDelete={updateListAfterDelete}
        callbackOrderBy={callbackOrderBy}
        auxConsumer={currentConsumerTags}
        getAllConsumerTags={getAllConsumerTags}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        setConsumer={setConsumer}
        consumer={consumer}
    />

}

export default HomeTagsController

