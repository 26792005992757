import { Button, Col, Container, Form, FormControl, InputGroup, Modal, Row } from "react-bootstrap";
import allStyles from "./styles";
import { Formik } from "formik";
import { FC, useEffect } from "react";
import { ICreateTagModal } from "./indexModal";
import { setShowAlertFeedback, setShowTemplateTagModal } from "../../../../../../../store/internal";
import SelectComponentController from "../../../../../../homeApp/components/selectComponent/indexController";
import './styles.css';

const CreateTagModal: FC<ICreateTagModal> = (props) => {
    return (
        <>
          <Modal.Header closeButton style={{ marginLeft: "6%", marginTop: "1%", marginBottom: "-1%" }}>
              <Modal.Title style={allStyles.textBlack}>
                {props.t('home_departments.template_message_tab.add_variable')}
              </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ marginLeft: "6%", marginRight: "4%", marginBottom: "2%" }}>
              <Container className="nopadding">
                <Formik
                    initialValues={{ name: '', label: '', example: '' }}
                    validate={values => {
                        var specialChars = /[^a-zA-Z0-9(-)(_)]/g;
                        let errors = {};

                        if (values.name.length === 0) {
                            errors['name'] = props.t('home_departments.template_message_tab.field_not_empty'); 
                            props.setShowFeedbackName(true);
                        } else if (values.name.includes(" ")) {
                            errors['name'] = props.t('home_departments.template_message_tab.not_space_in_var'); 
                            props.setShowFeedbackName(true);
                        } else if (values.name.match(specialChars)) {
                            errors['name'] = props.t('home_departments.template_message_tab.special_characters_not_accepted'); 
                            props.setShowFeedbackName(true);
                        } else if (props.tags && props.tags.find((param) => param.name === values.name)) {
                          errors['name'] = props.t('home_departments.template_message_tab.name_already_used'); 
                          props.setShowFeedbackName(true);
                        } else {
                            props.setShowFeedbackName(false);
                        }

                        if (props.createInCompany) {
                            if (values.example.length === 0) {
                                errors['example'] = props.t('home_departments.template_message_tab.field_not_empty');  
                                props.setShowFeedbackExample(true);
                            } else {
                                props.setShowFeedbackExample(false);
                            }
                        }

                        /* if (values.label.length === 0) {
                            errors['label'] = "Campo não pode estar vazio."; 
                            props.setShowFeedbackLabel(true);
                        } else {
                            props.setShowFeedbackLabel(false);
                        } */

                        return errors;
                    }}
                    onSubmit={async (values) => {
                        if (props.selectedType?.length === 0) {
                            props.dispatch(setShowAlertFeedback({ message: "Selecione o tipo da variável", visibility: true, signalIcon: false}));
                        } else {
                            props.setTags((tags) => {
                                let tag = { alias: values.name };
                                
                                if (props.selectedType?.length > 0)
                                    tag['tag'] = props.selectedType[0].value;
    
                                if (tags)
                                    tags.push(tag);
    
                                return tags;
                            });
                            if (props.createInCompany) {
                                props.setExamples([...props.examples, values.example]);
                            }
                            
                            props.dispatch(setShowTemplateTagModal(false));   
                        }
                    }}    
                >
                    {({
                        values,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,                
                    }) => (
                        <Form onSubmit={handleSubmit} noValidate validated={false}>
                            <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                                <Col sm={true}>
                                    <Form.Label htmlFor="name">{props.t('home_departments.template_message_tab.name_variable')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl className="form-control-Default"
                                            isInvalid={props.showFeedbackName}
                                            placeholder={props.t('home_departments.template_message_tab.info_name')}
                                            aria-label={props.t('home_departments.template_message_tab.info_name')}
                                            aria-describedby={props.t('home_departments.template_message_tab.info_name')}
                                            size='lg'
                                            type='name'
                                            id="name"
                                            name="name"
                                            maxLength={50}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.name}
                                        >
                                        </FormControl>
                                        <Form.Control.Feedback type="invalid" id="feedbackname">
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                                <Col sm={true}>
                                    <Form.Label htmlFor="basic-url">{props.t('home_departments.template_message_tab.type_variable')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                    <SelectComponentController
                                        className={"multiselectForm mb-3 scrollInDropContent"}
                                        options={props.optionsType}
                                        setOptions={props.setOptionsType}
                                        selecteds={props.selectedType}
                                        setSelecteds={props.setSelectedType}
                                        singleSelect={true}
                                        isFormNoFilter={true}
                                        title={props.t('home_departments.template_message_tab.select_type')}
                                        overrideStrings={
                                            {
                                            "allItemsAreSelected": "",
                                            "clearSearch": "Limpar pesquisa",
                                            "clearSelected": "Limpar tipos selecionados",
                                            "noOptions": "",
                                            "search": props.t('home_departments.template_message_tab.search_type'),
                                            "selectAll": "",
                                            "selectAllFiltered": "",
                                            "selectSomeItems": props.t('home_departments.template_message_tab.select_type'),
                                            "create": "",
                                            }
                                        }
                                        hasCreateItem={false}
                                        hasNullOption={true}
                                        // nullOptionLabel={"Sem número"}
                                        hasAllOption={false}
                                        // onSelect={selectChannel}

                                                // nullOptionLabel={"Não atribuído"}
                                                // allOptionLabel={"Todos os canais"}
                                    />
                                </Col>
                            </Row>
                            {props.createInCompany && 
                                <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                                    <Col sm={true}>
                                        <Form.Label htmlFor="name">{props.t('home_departments.template_message_tab.var_example')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                        <InputGroup className="mb-3">
                                            <FormControl className="form-control-Default"
                                                isInvalid={props.showFeedbackExample}
                                                placeholder= {props.t('home_departments.template_message_tab.info_example')}
                                                aria-label= {props.t('home_departments.template_message_tab.info_example')}
                                                aria-describedby= {props.t('home_departments.template_message_tab.info_example')}
                                                size='lg'
                                                type='example'
                                                id="example"
                                                name="example"
                                                maxLength={50}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.example}
                                            >
                                            </FormControl>
                                            <Form.Control.Feedback type="invalid" id="feedbackexample">
                                                {errors.example}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Col>
                                </Row>
                            }
                            <Row className="d-flex justify-content-center mt-4">
                              <Col xs={6} className="d-flex justify-content-start">
                                <Button size='lg' className="buttonWhite" style={{ width: '56%' }} onClick={ () => props.hideTagModal() } >{props.t('home_departments.template_message_tab.cancel')}</Button>
                              </Col>
                              <Col xs={6} className="d-flex justify-content-end">
                                <Button size='lg' type='submit' className="buttonBlue" style={{ width: '56%' }}>{props.t('home_departments.template_message_tab.save')}</Button>
                              </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
              </Container>
          </Modal.Body>
        </>
      );
}

export default CreateTagModal;