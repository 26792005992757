import { useContext, useEffect, useState } from "react";
import { IHomeTasks } from "./indexModel";
import HomeTasks from ".";
import { useDispatch, useSelector } from "react-redux";
import { TokenInterface } from "../../../services/requestsInterfacesModel";
import { getIdUser, getNameUser, getProfileId, getUserLastName } from "../../../store/user";
import { getToken } from "../../../store/token";
import { getIdCompany } from "../../../store/company";
import UserService from "../../../services/user-service";
import { AppRequesterController } from "../../../services/appRequester/appRequesterController";
import { useNavigate } from "react-router-dom";
import { getShowEditTaskModal, setShowEditTaskModal } from "../../../store/internal";
import { useTranslation } from 'react-i18next';
import BackendConstants from "../../../core/constants/backend-constants";

const AppRequesterConst = new AppRequesterController()

const HomeTasksController/* : FC<IHomeTasks> */ = () => {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate()

    const [openModalPhone, setOpenModalPhone] = useState(undefined);

    const [currentState, setCurrentState] = useState("all");
    const [currentParams, setCurrentParams] = useState({});
    const [currentFilterSearch, setCurrentFilterSearch] = useState(null);
    const [aux, setAux] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentLimit] = useState(BackendConstants.messagesTriggersLimit.typesLimit)
    const [viewFilter, setViewFilter] = useState(true);
    const [tasks, setTasks] = useState([]);

    const { t } = useTranslation();

    const values: TokenInterface = {
        user: {
            id: useSelector(getIdUser),
            name: useSelector(getNameUser),
            lastname: useSelector(getUserLastName),
            profile_id: useSelector(getProfileId)
        },
        token: {
            value: useSelector(getToken)
        },
        company: {
            id: useSelector(getIdCompany)
        },
        internal: {
            getShowEditTaskModal: useSelector(getShowEditTaskModal),
        }
    }

    useEffect(() => {
        if (values.internal.getShowEditTaskModal) {
            dispatch(setShowEditTaskModal(false));
        }
    }, []); 

    useEffect(() => {
        const controller = new AbortController()

        setCurrentState((state) => {
            setCurrentParams((param) => {
                setCurrentPage((page) => {
                    getTasks(Object.assign(param, { limit: currentLimit, page: currentPage }), controller);
                    return page;
                });
                return param
            })
            return state
        })

        return () => {
            controller.abort();
        }
    }, [currentPage, currentState])

    /* useEffect(() => {
        let controller = new AbortController();

        setCurrentPage((page) => {
            getTasks({ limit: 5, page: page }, controller);

            return page;
        });

        return () => {
            controller.abort();
        }
    }, [currentPage]); */

    const filteringTasks = (e: { currentTarget: { name: string; }; target: { name: string; }; }, auxObj: {}, action: string) => {
        setCurrentPage(1);
        setTasks([]);
        setAux([]);

        if (document.getElementById("ListOfTasks") !== null) {
            document.getElementById("ListOfTasks").scrollTop = 0;
        }

        let name = "";
        if (e.currentTarget) {
            name = e.currentTarget.name;
        } else if (e.target) {
            name = e.target.name;
        }

        if (name === "cleanSearchButtonName" || action === "clean") {
            setCurrentFilterSearch("");
            auxObj = {};
            setCurrentState("searching");
            if (currentState === "searching") {
                setCurrentState("");
            }
        } else if (name === "searchButtonName" || name === "searchInputName") {
            auxObj = {};

            setCurrentState("searching");
            if (currentState === "searching") {
                setCurrentState("");
            }

            if (currentFilterSearch !== null && currentFilterSearch !== "") {
                auxObj["search"] = currentFilterSearch;
            }
        } else {
            setCurrentState("filtering");

            if (currentState === "filtering") {
                setCurrentState("");
            }
        }
        dispatch(setShowEditTaskModal(false));

        setCurrentParams(auxObj);
    }

    const getTasks = async (params: { limit: number; page: number }, controller: AbortController) => {
        const headers = UserService.getHeaders();
        const signal = controller.signal;

        await AppRequesterConst.Get(
            `/task/`, { headers: headers, signal, params: params },
            (response: Object) => { },
            (data: any) => {
                if (data.status === 200 && data) {
                    setAux((state) => {
                        let aux = state.concat(data?.data?.tasks);
                        setAux(aux);
                        setTasks(aux)
                        return state;
                    });
                } 
                else if (aux.length === 0) {
                    setTasks([])
                }
            },
            (error: { response: { status: number } }) => {
                if (error.response.status === 400) {

                }
                if (error.response.status === 401) {

                }
            }, navigate, dispatch, setIsLoading, { values: values }

        )
    }

    const callbackModalTasks = (params, controller) => {
        setCurrentPage(1);
        setAux([]);
        //const controller = new AbortController();
        getTasks(Object.assign(params, {limit: currentLimit, page: currentPage}), controller);

    }

    const cleanList = () => {
        setCurrentParams({});
        setCurrentPage(1);
        setTasks([]);
        setAux([]);

        setCurrentState("filtering");

        if (currentState === "filtering") {
            setCurrentState("");
        }
    }

    return <HomeTasks
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        phoneNumber={""}
        openModalPhone={openModalPhone}
        setOpenModalPhone={setOpenModalPhone}
        filteringTasks={filteringTasks}
        setCurrentFilterSearch={setCurrentFilterSearch}
        currentFilterSearch={currentFilterSearch}
        currentState={currentState}
        setCurrentState={setCurrentState}
        values={values}
        cleanList={cleanList}
        tasks={tasks}
        setAux={setAux}
        getTasks={getTasks}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        t={t}
        viewFilter={viewFilter}
        setViewFilter={setViewFilter}
        currentParams={currentParams}
        callbackModalTasks={callbackModalTasks}
    />;

}

export default HomeTasksController;