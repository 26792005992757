import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import constsRouters from '../../../../../routes/constsRouter';
import { getShowEditTicketsModal, setShowEditDepartmentModal, setShowEditTicketsModal, getSortByList, getOrderByList, getShowEditDepartmentModal } from '../../../../../store/internal';
import { getToken } from '../../../../../store/token';
import { getIdUser, getNameUser, getUserLastName } from '../../../../../store/user';
import BackendConstants from '../../../../../core/constants/backend-constants';
import UserService from '../../../../../services/user-service';
import { AppRequesterController } from './../../../../../services/appRequester/appRequesterController';
import { ListName } from '../../../../../core/enums/order-sort-by';
import { DataSectorIdInterface, DataTicketInterface } from '../../../../../services/requestsInterfacesModel';
import { TokenInterface } from '../../../../../services/requestsInterfacesModel';
import ViewDepartments from '.';
import { useTranslation } from 'react-i18next';
import useLeaveTicketChat from '../../../../../hooks/useLeaveTicketChatSocket';

const AppRequesterConst = new AppRequesterController();

const ViewDepartmentsController = () => {

    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);

    const [currentFilterDatas, setCurrentFilterDatas] = useState(undefined);

    const [currentDepartment, setCurrentDepartment] = useState(undefined);
    const [currentPage, setCurrentPage] = useState(1);
    const [aux, setAux] = useState([]);
    const [currentFilterSearch, setCurrentFilterSearch] = useState(null);
    const [currentState, setCurrentState] = useState("");
    const [currentLimit, /*SetCurrentLimit*/] = useState(BackendConstants.limitMin);
    const [currentParams, setCurrentParams] = useState({});
    const [currentTicket, setCurrentTicket] = useState(null);

    const [resetingFilters, setResetingFilters] = useState(false);

    const { t } = useTranslation();

    const { onSocketLeaveTicketChat } = useLeaveTicketChat()

    const appRequesterConst = new AppRequesterController();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const values: TokenInterface = {
        user: {
            id: useSelector(getIdUser),
            name: useSelector(getNameUser),
            lastname: useSelector(getUserLastName),
        },
        token: {
            value: useSelector(getToken)
        },
        internal: {
            getShowEditTicketsModal: useSelector(getShowEditTicketsModal),
            getSortByList: useSelector(getSortByList),
            getOrderByList: useSelector(getOrderByList),
            getShowEditDepartmentModal: useSelector(getShowEditDepartmentModal),
        }
    }

    useEffect(() => {

        setCurrentDepartment((state: { sector_name: string; }) => ({
            ...state,
            sector_name: state ? state.sector_name : location.state['name'],
        }));
    }, [currentPage]);

    useEffect(() => {
        const controller = new AbortController();
        getAllTicketsToPagination(Object.assign(currentParams, { limit: currentLimit, page: currentPage }), controller);

        return () => {
            controller.abort();
        }
    }, [currentState]);

    // fechando modais abertos ao carregar a página
    useEffect(() => {
        if (values.internal.getShowEditDepartmentModal) {
            dispatch(setShowEditDepartmentModal(false));
        }
    }, []);

    const getDepartment = async (userID: string) => {

        const headers = UserService.getHeaders()

        await AppRequesterConst.Get(
            '/sector/' + userID, { headers },
            (response: Object) => {
            },
            (data: DataSectorIdInterface) => {
                if (data.status === 200) {
                    setCurrentDepartment({
                        active: data.data.sectors[0].active,
                        agents: data.data.sectors[0].agents,
                        emails: data.data.sectors[0].emails,
                        keyword: data.data.sectors[0].keyword,
                        sector_name: data.data.sectors[0].sector_name,
                        sector_id: data.data.sectors[0].sector_id,
                    });
                }
            },
            (error: Object) => {
            }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const getAllTickets = async (params: { limit: number; page: number; }, setData: Function, aux: any[], setAux: Function, controller: AbortController) => {
        if (values.internal.getSortByList.listName === ListName.ListOfTickets && values.internal.getSortByList.sort_by !== null && values.internal.getOrderByList !== null) {
            params = Object.assign(params, { sort_by: values.internal.getSortByList.sort_by, order_by: values.internal.getOrderByList });
        }

        const headers = UserService.getHeaders();
        // const signal = controller.signal;
        let config = {
            headers,
            // 'headers': {
            //     "Content-Type": "application/json; charset=utf-8",
            //     "Authorization": "Bearer " + values.token.value,
            // },
            // signal,
            'params': { ...params, ...{ sector: location.state['id'], is_all_status: true } },
        };

        await appRequesterConst.Get(
            '/ticket',
            config,
            (response: Object) => { },
            (data: DataTicketInterface) => {
                if (data.status === 200 && data.data.tickets.length > 0) {
                    setAux((state: any[]) => {
                        let aux = state?.concat(data.data.tickets);
                        setAux(aux);
                        setData(aux);
                        return state;
                    });
                } else if (aux.length === 0) {
                    setData(undefined);
                }
            },
            () => { },
            navigate,
            dispatch,
            setIsLoading,
            { values: values }
        );
    }

    const getAllTicketsToPagination = (params: { limit: number; page: number; }, controller: AbortController) => {
        getAllTickets(params, setCurrentFilterDatas, aux, setAux, controller);
    }

    const filteringTickets = (e: { currentTarget: { id: string; }; }, auxObj: Object, action: string) => {
        setCurrentPage(1);
        setAux([]);
        setCurrentFilterDatas(undefined);

        if (document.getElementById("listOfTickets") != null) {
            document.getElementById("listOfTickets").scrollTop = 0;
        }

        if (action === "clean") {
            setCurrentFilterSearch("");
            auxObj = {};
            setCurrentState("searching");
            if (currentState === "searching") {
                setCurrentState("");
            }
        } else if (e.currentTarget.id === "searchButton" || e.currentTarget.id === "SearchInput") {
            auxObj = {};
            setCurrentState("searching");

            if (currentState === "searching") {
                setCurrentState("");
            }

            if (currentFilterSearch !== null && currentFilterSearch !== "") {
                auxObj["search"] = currentFilterSearch;
            }

        } else {
            setCurrentState("filtering");

            if (currentState === "filtering") {
                setCurrentState("");
            }
        }

        dispatch(setShowEditTicketsModal(false));

        setCurrentParams(auxObj);
    }

    const cleanList = () => {
        setCurrentParams({});
        setCurrentPage(1);
        setAux([]);
        setCurrentFilterDatas(undefined);
        setResetingFilters(true);

        setCurrentState("filtering");

        if (currentState === "filtering") {
            setCurrentState("");
        }
    }

    const showCreateTicket = () => {
        setCurrentPage(1);
        setAux([]);
        setCurrentFilterDatas(undefined);
        setResetingFilters(true);
        navigate(constsRouters.routers.createTicket.path);
    }

    const setCurrentTicketFunction = (value: any) => {
        setCurrentTicket({id: value?.id.split("*ID*")[0].split("*who_is_viewing*")[0]});
    }

    const updateListfunction = () => {
        setCurrentPage(currentPage + 1);
    };

    const updateListAfterDelete = () => {
        setAux([]);
        setCurrentFilterDatas(undefined);
        setCurrentPage(1);

        if (document.getElementById("listOfTickets") != null) {
            document.getElementById("listOfTickets").scrollTop = 0;
        }

        setCurrentState("deactivating");

        if (currentState === "deactivating") {
            setCurrentState("");
        }
    };

    const goToListOfDepartmentsPage = () => {
        if (values.internal.getShowEditTicketsModal === false) {
            navigate(constsRouters.routers.configurationDepartments.path);
        } else {
            onSocketLeaveTicketChat(currentTicket?.id)
            dispatch(setShowEditTicketsModal(false));
            setCurrentPage(1);
            setAux([]);
            setCurrentParams({});
            setResetingFilters(true);

            setCurrentState("returning");
            if (currentState === "returning") {
                setCurrentState("");
            }
        }
    }

    const showEditDepartment = () => {
        setCurrentPage(1);
        setAux([]);

        getDepartment(location.state['id']).finally(() => {
            setCurrentState("editing");

            if (currentState === "editing") {
                setCurrentState("");
            }
            dispatch(setShowEditDepartmentModal(true));
        })
    }

    const callbackModalDepartment = () => {
        setCurrentPage(1);
        setAux([]);

        getDepartment(location.state['id']).finally(() => {
            setCurrentState("editing");

            if (currentState === "editing") {
                setCurrentState("");
            }
        });
    }

    const callbackOrderByTickets = (column: any, order: any) => {
        setCurrentPage(1);
        setAux([]);
        setCurrentState(column + order);
        document.getElementById(ListName.ListOfTickets).scrollTop = 0;
    }

    return (
        <ViewDepartments
        t={t}
        isLoading={isLoading}
        callbackModalDepartment={callbackModalDepartment}
        filteringTickets={filteringTickets}
        cleanList={cleanList}
        getAllTickets={getAllTickets}
        updateListAfterDelete={updateListAfterDelete}
        updateListfunction={updateListfunction}
        showCreateTicket={showCreateTicket}
        setCurrentTicketFunction={setCurrentTicketFunction}
        callbackOrderByTickets={callbackOrderByTickets}
        goToListOfDepartmentsPage={goToListOfDepartmentsPage}
        showEditDepartment={showEditDepartment}
        values={values}
        currentFilterDatas={currentFilterDatas}
        currentDepartment={currentDepartment}
        setCurrentFilterSearch={setCurrentFilterSearch}
        setCurrentState={setCurrentState}
        setResetingFilters={setResetingFilters}
        currentState={currentState}
        currentFilterSearch={currentFilterSearch}
        currentParams={currentParams}
        currentTicket={currentTicket}
        resetingFilters={resetingFilters}
        />
    )

}

export default ViewDepartmentsController;