import Routers from './routes/index';
import { useContext, useEffect, useState } from 'react';
import { SocketContext } from './core/context/socket-context';
import { EmailContext } from './core/context/cc-cco-context';
import { useSelector } from 'react-redux';
import { TokenInterface } from './services/requestsInterfacesModel';
import { getIdUser, getNameUser, getProfileId } from './store/user'
import { getToken } from './store/token';
import { getExtension, getPassword, getOwner_id } from './store/callcenter';
import UserService from './services/user-service';
import AlertPositiveNegativeController from './components/alertPositiveNegative/indexController';
import { getShowAlertFeedback } from './store/internal';
import { CallcenterProvider } from './core/context/callcenter-context';
import { StatusAgentProvider } from './core/context/status-agent-context';
import PhoneButtonController from './pages/homeApp/components/phoneCallcenterComponent/components/PhoneButton/indexController';
import AlertDisconnectSocket from './components/alertDisconnectSocket/indexController';
import { TicketContext } from './core/context/ticket-context';
import { TicketNotifyContext } from './core/context/ticket-notification-context';
import { BlockTicketProvider } from './core/context/block-ticket-context';

const App = () => {
    const socket = useContext(SocketContext);
    const email = useContext(EmailContext);
    const ticket = useContext(TicketContext);
    const ticketNot = useContext(TicketNotifyContext)
    const [connectedSocket, setConnectedSocket] = useState(socket.socket?.connected);
    const [showPhoneInsideIframe, setShowPhoneInsideIframe] = useState(false);
    const [url_iframe] = useState(window.location.href?.includes('chat-bot-iframe'));
    const [control_panel] = useState(window.location.href?.includes('controlpanel'))

    const values: TokenInterface = {
        user: {
            id: useSelector(getIdUser),
            name: useSelector(getNameUser),
            profile_id: useSelector(getProfileId)
        },
        token: {
            value: useSelector(getToken)
        },
        callcenter: {
            extension: useSelector(getExtension),
            password: useSelector(getPassword),
            owner_id: useSelector(getOwner_id),
        }
    };
    
    useEffect(() => {
        if(url_iframe !== true && control_panel !== true ){
            UserService.token = values.token.value;

            let token = values.token.value;
            if (token) {
                socket.createSocket(token);
            } else {
                setConnectedSocket(undefined)
            }
        }
    }, [values.token.value]);

    useEffect(() => {
        const element = document.getElementById('verify-iframe-parent');
        if (element) {
            if (element?.ownerDocument?.URL?.includes('chat-bot-iframe')) {
                setShowPhoneInsideIframe(false);
            } else {
                setShowPhoneInsideIframe(true);
            }
        }
    }, []);

    useEffect(() => {
        if(url_iframe !== true && control_panel !== true){
            const onConnect = () =>{
                setConnectedSocket(true)
            }
            const onDisconnect = () =>{
                setConnectedSocket(false)
            }

            socket.socket?.on('connect', onConnect)
            socket.socket?.on('disconnect', onDisconnect)

            return () => {
                socket.socket?.off('connect', onConnect)
                socket.socket?.off('disconnect', onDisconnect)
            }
        }
    },[])

    const alertFeedback = useSelector(getShowAlertFeedback);

    return (
        <BlockTicketProvider>
            <StatusAgentProvider>
                <TicketContext.Provider value={ticket}>
                    <TicketNotifyContext.Provider value={ticketNot}>
                        <EmailContext.Provider value={email}>
                            <SocketContext.Provider value={socket}>
                                <CallcenterProvider>
                                    { 
                                        alertFeedback.visibility && 
                                        <AlertPositiveNegativeController 
                                            timestamp={Date.now()}
                                            visibility={alertFeedback.visibility} 
                                            message={alertFeedback.message} 
                                            signalIcon={alertFeedback.signalIcon} 
                                            timeOut={alertFeedback.timeOut}
                                        />
                                    }
                                    { 
                                        !connectedSocket && connectedSocket !== undefined && !url_iframe &&
                                        <AlertDisconnectSocket
                                            visibility={!connectedSocket}
                                            signalIcon={alertFeedback.signalIcon} />
                                    }

                                    { showPhoneInsideIframe && values.token.value && values.callcenter?.owner_id && <PhoneButtonController /> }
                                    <div id='verify-iframe-parent' style={{display: 'none'}}></div>
                                    <Routers />
                                </CallcenterProvider>
                            </SocketContext.Provider>
                        </EmailContext.Provider>
                    </TicketNotifyContext.Provider>
                </TicketContext.Provider>
            </StatusAgentProvider>
        </BlockTicketProvider>
    );
}

export default App;
