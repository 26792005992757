import HomeViewSummary from ".";
import { useLocation, useNavigate } from "react-router-dom";
import constsRouters from "../../../../../routes/constsRouter";
import UserService from "../../../../../services/user-service";
import { AppRequesterController } from "../../../../../services/appRequester/appRequesterController";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { DataTagIdInterface, TokenInterface } from "../../../../../services/requestsInterfacesModel";
import { getIdCompany } from "../../../../../store/company";
import { getIdUser, getNameUser, getProfileId } from "../../../../../store/user";
import { getToken } from "../../../../../store/token";
import { getLanguageEmployee } from "../../../../../store/employee";
import { t } from "i18next";
import constsApi from "../../../../../services/constsApi";
import { Channels } from "../../../../../core/enums/channels";
import { IconBrandFacebook, IconBrandInstagram, IconBrandWhatsapp, IconMail, IconMessages, IconPhone } from "@tabler/icons-react";

const AppRequesterConst = new AppRequesterController();


const HomeViewSummaryController = (
    props
) => {
    const emp = useLocation();

    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        },
        user: {
            id: useSelector(getIdUser),
            name: useSelector(getNameUser),
            profile_id: useSelector(getProfileId)
        },
        token: {
            value: useSelector(getToken)
        },
        employee: {
            employee_language: useSelector(getLanguageEmployee),
        },
    };

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        getSummary(emp.state.summary_id)
    }, [])

    const [isLoading, setIsLoading] = useState(false);
    const [summary, setSummary] = useState([]);
    const [summaryTags, setSummaryTags] = useState([]);
    const [summaryChannels, setSummaryChannels] = useState([]);

    const listOfSummary = () => {
        navigate(constsRouters.routers.dashboardSummary.path)
    }

    const getSummary = async (id: string) => {
        const headers = UserService.getHeaders();

        await AppRequesterConst.Get(
            `/summary/${id}`, { headers },
            (response: Object) => { },
            (data) => {
                
                if (data.status === 200) {
                    setSummary(data.data.summaryData)
                    setSummaryTags(data.data.summaryData.tags)
                    setSummaryChannels(data.data.summaryData.channel_ids ? data.data.summaryData.channel_ids : [])
                }
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        )
    }

    const dateConvert = (date: string) => {
        const newDate = new Date(date);
        return newDate.toLocaleDateString(values.employee.employee_language) /* converter para o idioma da página */
    }

    const removePrefix = (frase: string) => { /* normalmente vem um prefixo de Insight no começo, retirando isso */
        return frase.replace(/^Insight \d+: /, '');
    };


    const iconsChannel = [ /* lista com todos os canais e icones */
        {
            id: Channels.WhatsApp,
            name: t('summary_dashboard.view_summary.channels.whatsapp'),
            icon: <IconBrandWhatsapp size={25}/>
        },
        {
            id: Channels.Email,
            name: t('summary_dashboard.view_summary.channels.email'),
            icon: <IconMail size={25}/>
        },
        {
            id: Channels.Facebook,
            name: t('summary_dashboard.view_summary.channels.facebook'),
            icon: <IconBrandFacebook size={25}/>
        },
        {
            id: Channels.Instagram,
            name: t('summary_dashboard.view_summary.channels.instagram'),
            icon: <IconBrandInstagram size={25}/>
        },        
        {
            id: Channels.Webchat,
            name: t('summary_dashboard.view_summary.channels.webchat'),
            icon: <IconMessages size={25}/>
        },
        {
            id: Channels.Telefonia,
            name: t('summary_dashboard.view_summary.channels.phone'),
            icon: <IconPhone size={25}/>
        }
    ]
    
    const getChannelsIcons = () => { /* verifica se os canais existem, e retorna a lista com os valores */
        if(summaryChannels && summaryChannels.length > 0) {
            const listChannels = iconsChannel.filter(item => summaryChannels.includes(item.id));
            return listChannels;
        }
        else {
            return iconsChannel; /* caso não tenha canais, retornar tudo */
        }
    }

    return (
        <HomeViewSummary
            listOfSummary={listOfSummary}
            summary={summary}
            dateConvert={dateConvert}
            isLoading={isLoading}
            removePrefix={removePrefix}
            getChannelsIcons={getChannelsIcons}
        />
    )
}

export default HomeViewSummaryController;
