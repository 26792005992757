import BackendConstants from "../../../../core/constants/backend-constants";
import { Channels } from "../../../../core/enums/channels";
import Utils from "../../../../core/shared/utils";
import { CreateMessageRequest, Message } from "../../../../domain/entities/messages";
import { verifyCode } from "../../../../services/codeCxpressInterface";
import constsApi from "../../../../services/constsApi";
import MessageSocketClient from "../../../../sockets/messages-socket";
import { EventParentElementInterface, MessagesComponentControllerInterface, ValuesInterface } from "./indexModel";
import MessageCardComponent from "./components/messageCardComponent/message-card-component";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppRequesterController } from "../../../../services/appRequester/appRequesterController";
import { getCleanMessageEditor, getShowAcquirePlatformComponent, getShowMessageSend, getShowSendTicketCopyModal, setCleanMessageEditor, setQuickMessageId, setShowAlertFeedback, setShowSendTicketCopyModal } from "../../../../store/internal";
import { SocketContext } from "../../../../core/context/socket-context";
import { getIdCompany } from "../../../../store/company";
import { getToken } from "../../../../store/token";
import { EmailContext } from "../../../../core/context/cc-cco-context";
import { useAppContext } from "../../../../providers";
import { useNavigate } from "react-router-dom";
import UserService from "../../../../services/user-service";
import { DataTemplateMessageWhatsappCompanyIdInterface, DataTicketIsAllowedMessageId, TokenInterface } from "../../../../services/requestsInterfacesModel";
import MessagesComponent from ".";
import { useTranslation } from 'react-i18next';
import { getProfileId } from "../../../../store/user";
import { TicketContext } from "../../../../core/context/ticket-context";
import { BlockTicketContext } from "../../../../core/context/block-ticket-context";
import useTicketUpdate from "../../../../hooks/useTicketUpdateSocket";
import useNewConsumerMessage from "../../../../hooks/useNewConsumerMessageSocket";

const MessagesComponentController: FC<MessagesComponentControllerInterface> = (props) => {
    const AppRequesterConst = new AppRequesterController();
    const cleanMessageEditor = useSelector(getCleanMessageEditor);
    const dispatch = useDispatch();
    const socket = useContext(SocketContext);
    const ticket = useContext(TicketContext)
    const block_ticket = useContext(BlockTicketContext)

    const { t } = useTranslation();

    const values: ValuesInterface = {
        company: {
            id: useSelector(getIdCompany)
        },
        token: {
            value: useSelector(getToken)
        },
        showSendTicketCopyModal: useSelector(getShowSendTicketCopyModal)
    }

    const valuesToken: TokenInterface = {
        internal: {
            getShowAcquirePlatformComponent: useSelector(getShowAcquirePlatformComponent),
        },
    }

    const [currentTicket, setCurrentTicket] = useState(null);
    const [previewTicketId, setPreviewTicketId] = useState(null);
    const [messages, setMessages] = useState([]);
    const [messagesQuick, setMessagesQuick] = useState([]);
    const [messageContentHtml, setMessageContentHtml] = useState("");
    const [messageSent, setMessageSent] = useState(false);
    const [/*name*/, setName] = useState("");
    const [currentChannel, setCurrentChannel] = useState(constsApi.channel[0].id);
    const [agent, /*setAgent*/] = useState(props.agent);
    const [pendingMessage, setPendingMessage] = useState(null as Object || null);
    const [mustScrollDown, setMustScrollDown] = useState(false);
    const [lastTicketId, setLastTicketId] = useState(null as string || null);
    const [messageSocket, setMessageSocket] = useState(() => props.currentTicket ? new MessageSocketClient(socket, props.currentTicket.id as string) : null);
    const [/*isLoading*/, setIsLoading] = useState(false);
    const [tempAttachmentIds, setTempAttachmentIds] = useState([] as string[]);
    const messagesEndRef = useRef(null)
    const [activateScrollToBottom, setActivateScrollToBottom] = useState(false);
    const [ticketModal, setTicketModal] = useState(null);
    const [internalResponse, setInternalResponse] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [page, setPage] = useState(1);
    const [lastScrollHeight, setLastScrollHeight] = useState(null as number || null);
    const [oldMessages, setOldMessages] = useState([]);
    const [/*requestMoreMessages*/, setRequestMoreMessages] = useState(false);
    const [templateMessage, setTemplateMessage] = useState(null);
    const [templateMessageList, setTemplateMessageList] = useState(null);
    const [showPopover, setShowPopover] = useState(false);
    const [showPopoverHistoryLog, setShowPopoverHistoryLog] = useState(false);
    const [isAllowedMessage, setIsAllowedMessage] = useState(true);
    const [defaultValue, setDefaultValue] = useState(t('home_tickets.messages_component.email'));
    const [isDefault, setIsDefault] = useState(true);
    const [showModalCC_CCO, setShowModalCC_CCO] = useState(false);
    const [showCustomFieldModalEdit, setShowCustomFieldModalEdit] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [aux, setAux] = useState([]);
    const [currentLimit] = useState(BackendConstants.messagesTriggersLimit.typesLimit);
    const [currentParams, setCurrentParams] = useState({});
    const [createdAtPassed24Hours, setCreatedAtPassed24Hours] = useState(false);
    const [hasConsumerMessage, setHasConsumerMessage] = useState(false);
    // const [disabledDropdown, setDisabledDropdown] = useState(false)
    const [quickMessage, setQuickMessage] = useState(null)
    const [changeChatHeight, setChangeChatHeight] = useState(false);
    const [showModalGeneralType, setShowModalGeneralType] = useState(false);
    const [generalTypesVariables, setGeneralTypesVariables] = useState([]);
    const [auxiTemplateMessage, setAuxiTemplateMessage] = useState(null);
    const [templateParamsGeneralType, setTemplateParamsGeneralType] = useState([]);

    const handleOpenCloseModalGeneralType = () => {
        setShowModalGeneralType(!showModalGeneralType);
    }

    const { ticketUpdate } = useTicketUpdate();
    const { newConsumerMessage } = useNewConsumerMessage();

    const handleShowCustomFieldModalEdit = () => setShowCustomFieldModalEdit(true)
    const handleCloseCustomFieldModalEdit = () => setShowCustomFieldModalEdit(false)

    const callBackModalCustomField = () => {
        setCurrentPage(1)
        setAux([])
        const controller = new AbortController()
    }

    useEffect(() => {
        if (props.blockDropdownChatComponent) {
            props.blockDropdownChatComponent(props?.currentTicket?.channel_id);
        }
    }, [props.currentTicket])

    // useEffect(() => {
    //     if (block_ticket.isAllowedMessage) {
    //         onSelectResponseType(t('home_tickets.messages_component.internal_note'));
    //     }
    // }, [block_ticket.isAllowedMessage])

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView();
    }

    const handleClose = () => { setShowModalCC_CCO(false) };

    const email = useContext(EmailContext)
    const emailCC = email.getEmailCC()
    const emailCCO = email.getEmailCCO()

    const {
        setCurrentMessageChannel,
        setIsMessageInternalResponse,
        attachmentFiles,
    } = useAppContext();

    const navigate = useNavigate();

    useEffect(() => {
        onSelectEditIntegrationClient(props?.currentChannelId)
        /**
         * Não apague este código
         */
        // if (props.currentRequester_user && props.currentRequester_user.channels && (props.isCreatingTicket || props.currentChannelId)) {
        //     props.currentRequester_user.channels.map((channel) => {
        //         if (channel.id === props.ticketChannel) {
        //             switch (channel.id) {
        //                 case Channels.WhatsApp:
        //                     ctrl.onSelectResponseType("Whatsapp");
        //                     break;
        //                 case Channels.Facebook:
        //                     ctrl.onSelectResponseType("Facebook");
        //                     break;
        //                 case Channels.Instagram:
        //                     ctrl.onSelectResponseType("Instagram");
        //                     break;
        //                 case Channels.Telefonia:
        //                     ctrl.onSelectResponseType("chamadaSemNumero");
        //                     break;
        //                 case Channels.Webchat:
        //                     ctrl.onSelectResponseType("Webchat");
        //                     break;
        //                 default:
        //                     ctrl.onSelectResponseType("E-mail");
        //                     break;
        //             }
        //         }
        //     });
        // }
    }, [/*props.ticketChannel,*/ props.onlyRead, /*props.currentRequester_user?.channels?.length,*/ props.currentRequester_user, props.currentChannelId]);

    useEffect(() => {
        setName(props?.name);

        if (messageSent) {
            setMessageSent(false);
        }

        if (oldMessages.length > 0) {
            addOldMessagesToChat(oldMessages);
            setPage(page + 1);
        }

        if (lastScrollHeight) {
            const chat = document.getElementById("#chat");
            let currentScrollHeight = chat.scrollHeight;

            if (currentScrollHeight !== lastScrollHeight) {
                if (chat) {
                    chat.scrollTop = currentScrollHeight - lastScrollHeight;
                }
                setLastScrollHeight(null);
            }
        }

        if (props.currentTicket !== null && (lastTicketId !== props.currentTicket.id)) {
            setIsDefault(true);
            createNewSocketOnChangeTicket();
            setPage(1);
            setLastScrollHeight(null);
        }

        if (pendingMessage) {
            sendPendingMessage();
        } else {
            if (mustScrollDown) {
                scrollToBottom();
                setMustScrollDown(false);
            }
        }

    }, [props.currentTicket && props.currentTicket.id, currentChannel, pendingMessage, oldMessages, /*messageContentHtml*/,/* messageSent*/, props.currentRequester_user && props.currentRequester_user.user_id, props.currentRequester_user?.whatsapp, props.currentRequester_user?.email, props.currentRequester_user?.phone  /* tempAttachmentIds*/, useSelector(getShowMessageSend)]);

    useEffect(() => {
        const controller = new AbortController();

        getAllTemplateMessage(props.currentTicket, controller);
        filterQuickMessage()
        dispatch(setQuickMessageId(""))
        return () => {
            controller.abort();
        }

    }, []);

    useEffect(() => {

        const controller = new AbortController();

        if (props.currentTicket !== null)
            isAllowedReturn(props.currentTicket, controller);
        /* else if (props.currentTicket === null && currentChannel === null) // habilitando mensagens para observação interna
            block_ticket.setReadOnlyEditorComponent(false) */

        return () => {
            controller.abort();
        }
    }, [props.currentTicket !== null && props.currentTicket?.id, isAllowedMessage, currentChannel]);

    const getAllMessages = async (ticketId: string, page: number, signal: AbortSignal) => {
        const headers = UserService.getHeaders();
        const config = { headers, signal };
        await AppRequesterConst.Get(
            `chat/${ticketId}/messages?page=${page}&limit=15`,
            config,
            (response: Object) => {
            },
            (data: any) => {
                if (data.status === 200 && data?.data?.chat_messages?.length > 0) {
                    setMessages((currentMessages) => {
                        if (currentMessages.length > 0 && currentMessages.length >= 15) {
                            return data.data.chat_messages.concat(currentMessages);
                        } else {
                            setActivateScrollToBottom(true);
                            scrollToBottom();
                            return data.data.chat_messages;
                        }
                    });
                }
            },
            (error: any) => { console.log(error.response) }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    useEffect(() => {
        if (props.onlyRead === true || block_ticket.admBlocked === true) {
            onSelectResponseType(t('home_tickets.messages_component.internal_note'));
        } else {
            if (messages.length && (isDefault === true)) {
                openChannelTicket();
                onSelectResponseType(props.defaultValue);
            } else if (props.currentTicket && props.currentTicket !== null && isDefault === true) {
                if (props.currentTicket.channel_id != null) {
                    defaultChannel();
                    onSelectResponseType(props.defaultValue);
                } else {
                    onSelectResponseType(t('home_tickets.messages_component.email'));
                }
            }
        }

        if (activateScrollToBottom) {
            scrollToBottom();
            setActivateScrollToBottom(false);
        }
    }, [messages, props.onlyRead, props.defaultValue, isDefault, block_ticket.admBlocked, getAllMessages]);

    // depois de trocar o ticket ou mudar a página, atualizações do ticket não precisa atualizar mensagens pois o socket e a atualização das mensagens do agente são o suficiente
    useEffect(() => {
        setShowPopoverHistoryLog(false)
        let controller = new AbortController();

        setCurrentTicket((ticket) => {
            if (ticket) {
                setMessages((messages) => {
                    // alterando ticket as mensagens estão vazias
                    if (messages?.length === 0) {
                        getAllMessages(ticket.id, 1, controller.signal);
                    } else {
                        // alterando pagina com scroll
                        setPage((page) => {
                            if (props?.currentTicket) {
                                getAllMessages(ticket.id, page, controller.signal);
                            }
                            return page;
                        });
                    }
                    return messages;
                });
                // dispatch(setCleanMessageEditor(true));
            }
            return ticket;
        });

        return () => {
            controller.abort();
        }
    }, [/* currentTicket */ previewTicketId, page]);


    // como o ticket foi alterado as mensagens são zeradas, caso fosse usado o object ticket e esse ticket tivesse alguma alteração na sua estrutura como um campo a mais o useEffect iria ser chamado sem necessidade
    useEffect(() => {
        setMessages([]);
        setTemplateMessage(null);
    }, [previewTicketId]);

    // Atualizando currentTicket e zerando listagem de mensagens
    useEffect(() => {
        setCurrentTicket(props.currentTicket);
        // id do ticket
        setPreviewTicketId(props.currentTicket?.id);
    }, [props.currentTicket]);

    const selectModalTicket = (event, data) => {
        setTicketModal(data);
        showSendTicketCopyModal(event);
    }

    const socketEvents = {
        ticket_update: (ticketUpdate) => {
            if (ticketUpdate) {
                updateStatusTicket(ticketUpdate)
            }
        },
        new_consumer_message: (newConsumerMessage) => {
            if (newConsumerMessage) {
                updateStatusTicket(newConsumerMessage.ticket)
            }
        }
    }

    const updateStatusTicket = (ticket: { id: string, status_id: string }) => {
        if (props?.currentTicket?.id === ticket?.id) {
            props.currentTicket.status_id = ticket.status_id
        }
    }

    useEffect(() => {
        socketEvents.ticket_update(ticketUpdate)
    }, [ticketUpdate])

    useEffect(() => {
        socketEvents.new_consumer_message(newConsumerMessage)
    }, [newConsumerMessage])

    const onScroll = () => {
        const chat = document.getElementById("#chat");
        const messagesLength = messages.length;
        const height = chat?.scrollTop;
        setLastScrollHeight(chat?.scrollHeight);
        if (height === 0 && messagesLength !== 0) {
            let newPage = page + 1;
            setPage(newPage);
            setIsLoading(true);
            /* messageSocket.requestMoreMessages({
                ticket_id: props.currentTicket ? props.currentTicket.id : props.ticketIdAfterCreated,
                page: newPage,
                limit: BackendConstants.messagesLimit,
            }); */
        }
    }

    const addTempMessage = async (messageContent: string) => {

        // Método para o botão enviar também criar o ticket
        if (props.onPostTicket) {
            await props.onPostTicket()
        }
    }

    const getAllTemplateMessage = async (currentTicket: { id: any; }, controller: AbortController) => {
        const signal = controller.signal;

        const headers = UserService.getHeaders();
        const router = currentTicket ? `/template-message/whatsapp/${values.company.id}?ticketId=${currentTicket.id}` : `/template-message/whatsapp/${values.company.id}`;
        await AppRequesterConst.Get(
            router,
            { headers, signal },
            (response: Object) => {
                return response;
            },
            (data: DataTemplateMessageWhatsappCompanyIdInterface) => {
                if (data.status === 200 && data?.data?.templateMessages.length > 0) {
                    setTemplateMessageList(data.data.templateMessages);
                }
            },
            (error: { response: { status: number; }; }) => {
                if (error.response !== undefined) {
                    if (error.response.status === 400) {
                        //document.getElementById("feedbackemail").innerHTML = "Usuário ou senha incorreto";
                        //setShowfeedback(true);
                    }
                    if (error.response.status === 401) {
                        //document.getElementById("feedbackpassword").innerHTML = "Usuário ou senha incorreto";
                        //setShowfeedbackPassword(true);
                    }
                }
            }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const isAllowedReturn = (currentTicket: { id: string; }, controller: AbortController) => {
        const signal = controller.signal;

        const headers = UserService.getHeaders();

        AppRequesterConst.Get(
            '/ticket/is-allowed-message/' + currentTicket.id, { headers, signal },
            (response: Object) => {
                return response;
            },
            (data: DataTicketIsAllowedMessageId) => {
                if (data.status === 200 && data?.data?.isAllowedMessage) {
                    /**
                     * Regra do bloqueio do ticket após 24 horas 
                     * sem resposta
                     */
                    const result = data.data.isAllowedMessage.filter((item) => item.channel_id === currentChannel && item.isAllowedMessage === false)

                    // no caso de ser observação interna
                    if (currentChannel === null) {
                        block_ticket.setReadOnlyEditorComponent(false);
                    } else if (result.length > 0) {
                        block_ticket.setReadOnlyEditorComponent(true)
                    }
                    
                    data.data.isAllowedMessage.map((channels) => {
                        setCurrentChannel((state) => {
                            if (state === channels.channel_id) {
                                setIsAllowedMessage(channels.isAllowedMessage);
                            }
                            return state;
                        })
                    });
                }
            },
            (error: { response: { status: number; }; }) => {
                if (error.response !== undefined) {
                    if (error.response.status === 400) {
                        //document.getElementById("feedbackemail").innerHTML = "Usuário ou senha incorreto";
                        //setShowfeedback(true);
                    }
                    if (error.response.status === 401) {
                        //document.getElementById("feedbackpassword").innerHTML = "Usuário ou senha incorreto";
                        //setShowfeedbackPassword(true);
                    }
                }
            }, navigate, dispatch, setIsLoading, { values: values }
        );
        /* Api.get('/ticket/is-allowed-message/' + currentTicket.id, { headers}).then((response) => {
            return response;
        }).then(data => {
            setResponseIsAllowed(data.data.isAllowedMessage);
            setIsLoading(false);
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 400) {
                    //document.getElementById("feedbackemail").innerHTML = "Usuário ou senha incorreto";
                    //setShowfeedback(true);
                }
                if (error.response.status === 401) {
                    //document.getElementById("feedbackpassword").innerHTML = "Usuário ou senha incorreto";
                    //setShowfeedbackPassword(true);
                }
                setIsLoading(false);
            }
        }); */
    }

    const updateListfunction = () => {
        setAux((prevState) => {
            if (prevState.length > (49 * currentPage)) {
                setCurrentPage(currentPage + 1);
                return prevState;
            } else {
                return prevState;
            }
        });
    };
    const filterQuickMessage = async () => {
        const headers = UserService.getHeaders()
        const router = `/template-message/quick-message/`

        await AppRequesterConst.Get(
            router,
            { headers },
            (response: Object) => {

            },
            (data: any) => {
                if (data.status === 200) {
                    setMessagesQuick(data?.data?.quickMessages)
                }
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        )
    }

    const createSocketInstance = (ticket_id: string) => {
        const onReceiveMessage = (sentMessage: Message) => {
            setPendingMessage(sentMessage);
        }

        const onListMessages = (newMessages: Message[]) => {
            // let auxArray = [];
            // let auxDate = new Date();
            // newMessages.forEach((element)=>{
            //     let messageDate = new Date(element.createdAt)
            //     if (auxDate.getDate() !== messageDate.getDate()) {
            //         auxDate = messageDate
            //         auxArray.push({ date: Utils.formatedDate(messageDate) });
            //     }

            //     auxArray.push(element);
            // })

            // setMessages(auxArray);

            // let hasMoreMessages = newMessages.length == BackendConstants.limit;
            // setRequestMoreMessages(hasMoreMessages);

            // scrollToBottom();
            // setIsLoading(false);
        }

        const onGetOlderMessages = (oldMessages: Message[]) => {
            if (oldMessages.length > 0) {
                setOldMessages(oldMessages);
            }
            setIsLoading(false);
        }

        const onBotcxpressReactivate = () => {

        }

        const onError = (error: { response: { status: number; data: { message: any[]; code_cxpress: number } } }) => {
            setHasError(true);

            dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
        }

        const messageSocket = new MessageSocketClient(
            socket,
            ticket_id,
            messages,
            onReceiveMessage,
            onListMessages,
            onGetOlderMessages,
            onBotcxpressReactivate,
            onError,
            () => onConnect,
        );

        setMessageSocket(messageSocket);
    }

    const onConnect = () => {
        setMessages([]);
        setIsLoading(true);
    }

    const createNewSocketOnChangeTicket = () => {
        if (messageSocket && messageSocket.isConnected()) {
            messageSocket.disconect();
        }
        // Tentando definir o socket;
        setLastTicketId(props.currentTicket.id);

        createSocketInstance(props.currentTicket.id as string);
    }

    const setIsDefaultClick = () => {
        setIsDefault(false);
    }

    const onSelectEditIntegrationClient = (channel_id: string) => {
        switch(channel_id) {
            case Channels.WhatsApp:
                onSelectResponseType(t('home_tickets.messages_component.whatsapp'))
                break;
            case Channels.Facebook:
                onSelectResponseType(t('home_tickets.messages_component.facebook'))
                break;
            case Channels.Instagram:
                onSelectResponseType(t('home_tickets.messages_component.instagram'))
                break;
            case Channels.Telefonia:
                onSelectResponseType(t('home_tickets.messages_component.internal_note'))
                break;
            case Channels.Webchat:
                onSelectResponseType(t('home_tickets.messages_component.webchat'))
                break;
            default:
                onSelectResponseType(t('home_tickets.messages_component.email'))
                break;
        }
    }

    const onSelectResponseType = (value: string) => {
        if (value === t('home_tickets.messages_component.email') || value === "chamadaComNumero" || value === "chamadaSemNumero" || value === t('home_tickets.messages_component.public_reply') && defaultValue === t('home_tickets.messages_component.email')) {
            if (document.getElementById("idRequesterUserEmail"))
                document.getElementById("idRequesterUserEmail").style.display = 'inline';

            value = t('home_tickets.messages_component.public_reply');
            if (isDefault === false) {
                setDefaultValue(t('home_tickets.messages_component.email'));
            }
            setCurrentChannel(constsApi.channel[0].id);
            setCurrentMessageChannel(constsApi.channel[0].id);
        } else {
            if (document.getElementById("idRequesterUserEmail"))
                document.getElementById("idRequesterUserEmail").style.display = 'none';
        }

        if (value === t('home_tickets.messages_component.whatsapp') || value === t('home_tickets.messages_component.public_reply') && defaultValue === t('home_tickets.messages_component.whatsapp')) {
            if (document.getElementById("idRequesterUserWhatshapp"))
                document.getElementById("idRequesterUserWhatshapp").style.display = 'inline';

            if (isDefault === false) {
                setDefaultValue(t('home_tickets.messages_component.whatsapp'));
            }
            setCurrentChannel(constsApi.channel[2].id);
            setCurrentMessageChannel(constsApi.channel[2].id);

        } else {
            if (document.getElementById("idRequesterUserWhatshapp"))
                document.getElementById("idRequesterUserWhatshapp").style.display = 'none';
        }

        if (value === t('home_tickets.messages_component.facebook') || value === t('home_tickets.messages_component.public_reply') && defaultValue === t('home_tickets.messages_component.facebook')) {
            if (document.getElementById("idRequesterUserFacebook"))
                document.getElementById("idRequesterUserFacebook").style.display = 'inline';
            value = t('home_tickets.messages_component.public_reply');
            if (isDefault === false) {
                setDefaultValue(t('home_tickets.messages_component.facebook'));
            }
            setCurrentChannel(constsApi.channel[3].id);
            setCurrentMessageChannel(constsApi.channel[3].id);
        } else {
            if (document.getElementById("idRequesterUserFacebook"))
                document.getElementById("idRequesterUserFacebook").style.display = 'none';
        }

        if (value === t('home_tickets.messages_component.instagram') || value === t('home_tickets.messages_component.public_reply') && defaultValue === t('home_tickets.messages_component.instagram')) {
            if (document.getElementById("idRequesterUserInstagram"))
                document.getElementById("idRequesterUserInstagram").style.display = 'inline';
            value = t('home_tickets.messages_component.public_reply');
            if (isDefault === false) {
                setDefaultValue(t('home_tickets.messages_component.instagram'));
            }
            setCurrentChannel(constsApi.channel[4].id);
            setCurrentMessageChannel(constsApi.channel[4].id);
        } else {
            if (document.getElementById("idRequesterUserInstagram"))
                document.getElementById("idRequesterUserInstagram").style.display = 'none';
        }

        if (value === t('home_tickets.messages_component.webchat') || value === t('home_tickets.messages_component.public_reply') && defaultValue === t('home_tickets.messages_component.webchat')) {
            if (document.getElementById("idRequesterUserWebChat"))
                document.getElementById("idRequesterUserWebChat").style.display = 'inline';
            value = t('home_tickets.messages_component.public_reply');
            if (isDefault === false) {
                setDefaultValue(t('home_tickets.messages_component.webchat'));
            }
            setCurrentChannel(constsApi.channel[5].id);
            setCurrentMessageChannel(constsApi.channel[5].id);
        } else {
            if (document.getElementById("idRequesterUserWebChat"))
                document.getElementById("idRequesterUserWebChat").style.display = 'none';
        }

        if (document.getElementById("select_resposta")) {
            document.getElementById("select_resposta").innerHTML = "<span style='position:absolute; height:60%; align-items:center; display:flex;'>" + value + "</span>";

            if (value === t('home_tickets.messages_component.internal_note')) {
                document.getElementById("select_resposta").className = "dropdownButtonTicketsComunicationActive dropdown-toggle btn btn-primary";
                setCurrentChannel(null);
            } else {
                document.getElementById("select_resposta").className = "dropdownButtonTicketsComunication dropdown-toggle btn btn-primary";
            }
        }

        let isInternalResponse = (value === t('home_tickets.messages_component.internal_note'));
        setInternalResponse(isInternalResponse);
        setIsMessageInternalResponse(isInternalResponse);
    }

    const defaultChannel = () => {
        if (props.currentTicket.channel_id === constsApi.channel[0].id) {
            setDefaultValue(t('home_tickets.messages_component.email'));
        }
        else if (props.currentTicket.channel_id === constsApi.channel[2].id) {
            setDefaultValue(t('home_tickets.messages_component.whatsapp'));
        }
        else if (props.currentTicket.channel_id === constsApi.channel[3].id) {
            setDefaultValue(t('home_tickets.messages_component.facebook'));
        }
        else if (props.currentTicket.channel_id === constsApi.channel[4].id) {
            setDefaultValue(t('home_tickets.messages_component.instagram'));
        }
        else if (props.currentTicket.channel_id === constsApi.channel[5].id) {
            setDefaultValue(t('home_tickets.messages_component.webchat'));
        }
    }

    const openChannelTicket = () => {
        if (messages[messages.length - 1].channel_id === constsApi.channel[0].id) {
            setDefaultValue(t('home_tickets.messages_component.email'));
        }
        else if (messages[messages.length - 1].channel_id === constsApi.channel[2].id) {
            setDefaultValue(t('home_tickets.messages_component.whatsapp'));
        }
        else if (messages[messages.length - 1].channel_id === constsApi.channel[3].id) {
            setDefaultValue(t('home_tickets.messages_component.facebook'));
        }
        else if (props.currentTicket.channel_id === constsApi.channel[4].id) {
            setDefaultValue(t('home_tickets.messages_component.instagram'));
        }
        else if (props.currentTicket.channel_id === constsApi.channel[5].id) {
            setDefaultValue(t('home_tickets.messages_component.webchat'));
        }
    }

    const sendPendingMessage = () => {
        setMessages([...messages, pendingMessage]);
        setMustScrollDown(true);

        setPendingMessage(null);
        props.updateList();
    }

    const createMessageBody = (messageContent: string): CreateMessageRequest => {
        return {
            ticket_id: props.currentTicket ? props.currentTicket.id : props.ticketIdAfterCreated,
            user_id: agent.id,
            channel_id: currentChannel,
            content: messageContent,
            is_internal_response: internalResponse,
            is_agent: true,
            temp_bucket_ids: tempAttachmentIds?.length > 0 ? tempAttachmentIds : undefined,
            cc: emailCC ? emailCC : [],
            cco: emailCCO ? emailCCO : [],
            id_template: templateMessage ? templateMessage.id : undefined,
            id_quick_message: quickMessage ? quickMessage.id : undefined /* passando o id da quickMessage */
        };
    }

    const sendMessageThroughSocket = (messsage: CreateMessageRequest) => {
        if (templateParamsGeneralType.length > 0) {
            messsage["template_params"] = templateParamsGeneralType;
            setTemplateParamsGeneralType([]);
        }

        setMessageSocket(state => {
            state.createMessage(messsage, attachmentFiles);

            setMessageSent(true);
            setTempAttachmentIds([]);
            return state;
        })
    }

    const getMessageInputContent = () => {
        return messageContentHtml;
    }

    const cleanMessageInput = () => {
        dispatch(setCleanMessageEditor(true));
        setTemplateMessage(null);
        setQuickMessage(null);
    }

    const sendMessage = () => {
        if (props.currentSubject != "") {
            setMessageContentHtml(state => {
                if (messageSocket) {
                    sendMessageThroughSocket(createMessageBody(state.replace(/<\/p><p>/g, "\n<br>").replace(/<p>/g, "<span>").replace(/<\/p>/g, "</span>").replace(/<span><\/span>/g, "\n<br>")));

                    cleanMessageInput();
                } else {
                    addTempMessage(state);
                }

                setMessageSent(true);

                //props.updateList();
                return state;
            });

        } else {
            dispatch(setShowAlertFeedback({
                visibility: true,
                message: t('home_tickets.messages_component.ticket_without_subject'),
                signalIcon: false
            }));
        }
    }

    const addOldMessagesToChat = (oldMessages: Message[]) => {
        if (oldMessages.length !== 0) {
            let allMessages: Message[] = [...oldMessages, ...messages];

            let hasMoreMessages = allMessages.length == BackendConstants.limit;
            setRequestMoreMessages(hasMoreMessages);

            setMessages(allMessages);
        } else {
            setRequestMoreMessages(false);
        }

        setOldMessages([]);
    }

    const itemDropOverlay = (id: string) => {
        Utils.changeStyleElementById(id + 'Check', 'display', 'inline');
    }

    const itemDropOut = (id: string) => {
        Utils.changeStyleElementById(id + 'Check', 'display', 'none');
    }

    const renderMessages = () => {
        return messages.map((elem: Message, index, row) => {
            if(elem?.channel_id === Channels.WhatsApp) {
                if((elem?.is_bot === false && elem?.is_agent === false) || elem?.is_agent === true) {
                    setHasConsumerMessage(true);
                }
                if(index + 1 === row.length && hasConsumerMessage === true) {
                    const twentyFourHoursAgo = new Date(Date.now() - 24 * 60 * 60 * 1000);
                    const createdAt = new Date(elem.createdAt);
                    setCreatedAtPassed24Hours(createdAt < twentyFourHoursAgo);
                } else if (index + 1 === row.length && hasConsumerMessage === false) {
                    setCreatedAtPassed24Hours(false)
                }
            }
            return (
                <MessageCardComponent elem={elem} key={index + "-" + props?.currentTicket?.ticket_reference_id} isAllowedMessage={isAllowedMessage} setIsAllowedMessage={setIsAllowedMessage}></MessageCardComponent>
            );
        })
    }

    const CheckIcon = () => {
        return (
            <svg id="Grupo_11300" data-name="Grupo 11300" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 18.762 18.762">
                <path id="Caminho_9697" data-name="Caminho 9697" d="M0,0H18.762V18.762H0Z" fill="none" />
                <path id="Caminho_9698" data-name="Caminho 9698" d="M5,10.909l3.909,3.909L16.726,7"
                    transform="translate(-1.091 -1.528)" fill="none" stroke="#707070" strokeLinecap="round"
                    strokeLinejoin="round" strokeWidth="1" />
            </svg>
        );
    }

    const showTemplateMessage = () => {
        if (showPopover == false) {
            setShowPopover(true);
        } else {
            setShowPopover(false);
        }
    }

    const showHistoryLog = () => {
        if (showPopoverHistoryLog === false) {
            setShowPopoverHistoryLog(true);
        } else {
            setShowPopoverHistoryLog(false);
        }
    }

    const callbackTemplateMessage = (message: any) => {
        const variables = checkGeneralTypeTag(message?.tags);
        if (variables.length > 0) {
            setAuxiTemplateMessage(message)
            setGeneralTypesVariables(variables);
            handleOpenCloseModalGeneralType();
        } else {   
            setTemplateMessage(message);
            setTemplateParamsGeneralType([]);
        }
        setIsLoading(true);
        setQuickMessage(null);
        showTemplateMessage();
        setIsLoading(false);
    }

    const checkGeneralTypeTag = (tags: any) => {
        return tags?.filter(item => { 
            if (item.tag === 'general.type') {
                item['tag_value'] = '';
                return item
            }
        }) || [];
    }

    const callbackQuickMessage = (message: string) => { /* setando o quickMessage de acordo com o que foi selecionado */
        setIsLoading(true);
        setQuickMessage(message);
        setTemplateMessage(null); /* ao setar o quickMessage, o templete deve ser zerado para evitar enviar os dois ao mesmo tempo */
        showTemplateMessage();
        setIsLoading(false);
    }

    const callbackGetStringHtml = (parentRef = null, htmlString: string) => {
        const htmlWithBreaks = htmlString.replace(/<\/span>\n/g, '</span><br>\n').replace(/<span><br><\/span><br>/g, '<br>');
        setMessageContentHtml(htmlWithBreaks);
    }

    const onDropFiles = (ids: string[]) => {
        setTempAttachmentIds(ids);
    }

    const afterMessageSent = () => {
        setMessageSent(false);
    }

    const showSendTicketCopyModal = (e: EventParentElementInterface) => {
        setShowSendTicketCopyModal(e.currentTarget.parentElement.parentElement.id.split("*ID*")[1]);
        dispatch(setShowSendTicketCopyModal(true));
    }

    const getTicketHeaderInfo = () => {
        let value = `#${props.currentTicket.ticket_reference_id}`;

        if (props?.currentRequester_user?.name) {
            value += ` - ${props.currentRequester_user.name}`;
        }
        if (props.currentTicket.channel_id === Channels.WhatsApp && props.currentTicket.requester_user?.whatsapp) {
            value += ` - ${props.currentTicket.requester_user?.whatsapp}`;
        }
        return value;
    }

    return <MessagesComponent 
        currentRequester_user={props?.currentRequester_user}
        currentTicket={currentTicket}
        onPostTicket={props.onPostTicket}
        showSubjectError={props.showSubjectError}
        showHistoryButton={props.showHistoryButton}
        showOptionsButton={props.showOptionsButton}
        deleteTicket={props.deleteTicket}
        selectModalTicket={selectModalTicket}
        templateMessageList={templateMessageList}
        callbackTemplateMessage={callbackTemplateMessage}
        callbackQuickMessage={callbackQuickMessage}
        showTemplateMessage={showTemplateMessage}
        setShowPopoverHistoryLog={setShowPopoverHistoryLog}
        showHistoryLog={showHistoryLog}
        showCustomFieldModalEdit={showCustomFieldModalEdit}
        handleCloseCustomFieldModalEdit={handleCloseCustomFieldModalEdit}
        callBackModalCustomField={callBackModalCustomField}
        updateListfunction={updateListfunction}
        values={values}
        ticketModal={ticketModal}
        handleShowCustomFieldModalEdit={handleShowCustomFieldModalEdit}
        showPopoverHistoryLog={showPopoverHistoryLog}
        onScroll={onScroll}
        renderMessages={renderMessages}
        messagesEndRef={messagesEndRef}
        dispatch={dispatch} 
        onSelectResponseType={onSelectResponseType} 
        setIsDefaultClick={setIsDefaultClick} 
        itemDropOverlay={itemDropOverlay} 
        itemDropOut={itemDropOut} 
        CheckIcon={CheckIcon} 
        setShowModalCC_CCO={setShowModalCC_CCO} 
        messagesQuick={messagesQuick} 
        showPopover={showPopover} 
        messageSent={messageSent} 
        afterMessageSent={afterMessageSent} 
        onDropFiles={onDropFiles} 
        cleanMessageEditor={cleanMessageEditor} 
        callbackGetStringHtml={callbackGetStringHtml} 
        sendMessage={sendMessage} 
        templateMessage={templateMessage} 
        // isAllowedValidation={isAllowedValidation} 
        showModalCC_CCO={showModalCC_CCO} 
        handleClose={handleClose}        
        getTicket={props.getTicket}
        audioBlob={props.audioBlob}
        audioChunks={props.audioChunks}
        audio={props.audio}
        setAudioChunks={props.setAudioChunks}
        setAudio={props.setAudio}
        setAudioBlob={props.setAudioBlob}
        currentChannelId={props.currentChannelId}
        currentChannel={currentChannel}
        disabledDropdown={props.disabledDropdown}
        updateCCandCCO={props.updateCCandCCO}
        getTicketHeaderInfo={getTicketHeaderInfo}
        editTicket={block_ticket.editTicket}
        setFilesIds={props?.setFilesIds}
        t={t}
        isAllowedMessage={block_ticket.isAllowedMessage}
        onlyRead={props.onlyRead}
        internalObservationBlock={props.internalObservationBlock}
        renderMessagesChat={props.renderMessagesChat}
        isHistoryHome={props.isHistoryHome}
        getShowAcquirePlatformComponent={valuesToken.internal.getShowAcquirePlatformComponent}
        ticketCreate={ticket.getTicketCreate()}
        channelSelected={props.channelSelected}
        setSelectedConsumers={props.setSelectedConsumers} 
        onSelectRequester={props.onSelectRequester}    
        setCurrentRequester_user={props.setCurrentRequester_user}
        quickMessage={quickMessage}
        setQuickMessage={setQuickMessage}
        changeChatHeight={changeChatHeight}
        setChangeChatHeight={setChangeChatHeight}
        createdAtPassed24Hours={createdAtPassed24Hours}
        editButtonsView={block_ticket.editButtonsView}
        hiddenChatEditor={props?.hiddenChatEditor}
        admBlocked={block_ticket.admBlocked}
        showModalGeneralType={showModalGeneralType}
        handleOpenCloseModalGeneralType={() => handleOpenCloseModalGeneralType()}
        generalTypesVariables={generalTypesVariables}
        auxiTemplateMessage={auxiTemplateMessage}
        setAuxiTemplateMessage={setAuxiTemplateMessage}
        setTemplateMessage={setTemplateMessage}
        setTemplateParamsGeneralType={setTemplateParamsGeneralType}
        templateParamsGeneralType={templateParamsGeneralType}
    />
}

export default MessagesComponentController;