import { Formik } from "formik";
import React, { FC, useState } from "react";
import { Col, FormControl, Form, InputGroup, ProgressBar, Row, Dropdown, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AuthenticationRequests } from "../../../services/authentication/authenticationControler";
import { setShowAlertFeedback } from "../../../store/internal";
import { RegisterControler } from "../registerDesktop/indexControler";
import Styles from '../registerDesktop/styles.module.css';
import './styles.css';
import InputMask from 'react-input-mask';
//import PhoneInputDropdown, { PhoneType } from "../../homeApp/components/PhoneInputDropdown";

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css'
import pt from 'react-phone-input-2/lang/pt.json'

import { RegisterFormInterface } from "./indexModel";

import { RegisterFormControler } from "./indexControler";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18next";
import { verifyCode } from "../../../services/codeCxpressInterface";
import allStyles from "../../homeApp/components/chatEditor/styles";
import MaskController from "../../../core/shared/mask-controller";
import SelectComponentController from "../../homeApp/components/selectComponent/indexController";

const AuthenticationRequestsConst = new AuthenticationRequests();


const RegisterForm: FC<RegisterFormInterface> = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [agent_phone, setAgent_phone] = useState(null as string || null);

  const [stage, setStage] = useState(1);
  const [progress, setProgress] = useState(34);

  const [company_name, Setcompany_name] = useState(null as string || null);
  const [company_employee_number, Setcompany_employee_number] = useState(null as string || null);
  const [user_email, Setuser_email] = useState(null as string || null);
  // const [user_country, Setuser_country] = useState(null as string || null);
  const [user_password, Setuser_password] = useState(null as string || null);
  const [user_name, Setuser_name] = useState(null as string || null);
  const [user_lastname, Setuser_lastname] = useState(null as string || null);
  const [user_whatsapp, Setuser_whatsapp] = useState(null as string || null);
  const [subdomain, Setsubdomain] = useState(null as string || null);
  const [showPassword, setShowPassword] = useState(false);
  const [showfeedbackEmail, setshowfeedbackEmail] = useState(false);
  const [showfeedbackName, setShowfeedbackName] = useState(false);
  const [showfeedbackLastname, setShowfeedbackLastname] = useState(false);
  const [showfeedbackPhone, setShowfeedbackPhone] = useState(true);
  // const [showfeedbackWhatsapp, setShowfeedbackWhatsapp] = useState(false);
  const [showfeedbackCompany_name, setShowfeedbackCompany_name] = useState(false);
  const [showfeedbackPassword, setShowfeedbackPassword] = useState(false);
  const [document_number, Setdocument_number] = useState(null as string || null);
  const [ShowfeedbackDocument_number, setShowfeedbackDocument_number] = useState(false);
  const [validateNumber, setValidateNumber] = useState(false);
  const [whatsappTag] = useState("whatsapp");
  const [whatsappError, setWhatsappError] = useState(null as Object || null);
  const [/*forceValidateWhats*/, setForceValidateWhats] = useState(false);

  const [validated] = useState(false);
  const [/*isLoading*/, setIsLoading] = useState(false);

  const validSelectDropdownCompanyEmployeeNumber = () => RegisterControlerConst.ValidSelectDropdownCompanyEmployeeNumber(props.isMobile);
  const RegisterControlerConst = new RegisterControler(setShowPassword,showPassword,setStage,stage,setProgress,progress,Setcompany_employee_number);
  const RegisterFormControlerConst = new RegisterFormControler(setShowfeedbackName, setShowfeedbackLastname, setForceValidateWhats, setWhatsappError,
    whatsappTag, setshowfeedbackEmail, setShowfeedbackCompany_name, setShowfeedbackPassword, validSelectDropdownCompanyEmployeeNumber, setShowfeedbackDocument_number,
    user_email, setShowPassword, showPassword, setStage, stage, setProgress, progress, Setcompany_employee_number, RegisterControlerConst, props);

  const resendEmailConfirmation = () => {

    const body = {
      "email": user_email,
      "subdomain": subdomain,
    };

    const headers = {
      "Content-Type": "application/json; charset=utf-8"
    };

    // setIsLoading(true);
    AuthenticationRequestsConst.SendEmailWithConfirmation(
      body,
      { headers },
      (response: Object) => {
        return response
      },
      (data: Object) => {
        return data
        // setIsLoading(false);
      },
      (error: Object) => {
        // return error
      }, navigate, dispatch, setIsLoading,
      // {
      //   error400: () => {
      //     setIsLoading(false);
      //   },
      //   error422: () => {
      //     setIsLoading(false);
      //   }
      // }
    );
  }

  let cpf_nif_mask;

  if(i18n.language === 'pt-PT') {
    cpf_nif_mask = '999.999.999'
  } else if(i18n.language === 'pt-BR') {
    cpf_nif_mask = '99.999.999/9999-99'
  }
  
  const [options_country, setOptions_country] = useState([
    { label: t("register.options_country.brazil"), value: 'PT-BR' },
    { label: t("register.options_country.portugal"), value: 'PT-PT' },
    { label: t("register.options_country.spain"), value: 'ES' },
    // { label: t("register.options_country.france"), value: 'FR' },
    { label: t("register.options_country.uk"), value: 'UK' },
  ])

  const switchLanguage = () => {
    if (selected_country[0]?.value === 'PT-BR') {
      return ['br']
    }
    if (selected_country[0]?.value === 'PT-PT') {
      return ['pt']
    }
    else if (selected_country[0]?.value === 'ES') {
      return ['es']
    }
    else if (selected_country[0]?.value === 'FR') {
      return ['fr']
    }
    else if (selected_country[0]?.value === 'UK') {
      return ['gb']
    }
    else {
      return ['br']
    }
  }

  const validatePhone = () => {
    if(selected_country[0]?.value === 'PT-BR' && agent_phone?.length === 13){
      return false;
    }
    else if(selected_country[0]?.value === 'PT-PT' && agent_phone?.length === 12){
      return false;
    }
    else if(selected_country[0]?.value === 'ES' && agent_phone?.length === 11){
      return false;
    }
    else if(selected_country[0]?.value === 'FR' && agent_phone?.length === 11){
      return false;
    }
    else if(selected_country[0]?.value === 'UK' && agent_phone?.length === 12){
      return false;
    }
    return true
  }

  const validateCountry = () => {
    if(selected_country[0] !== undefined) { // verifica se o país foi selecionado, e libera o botão para seguir com o cadastro
      return false
    }
    else{
      return true
    }
  }

  const switchDocument = () => {
    if (selected_country[0]?.value === 'PT-BR') {
      return 'CNPJ'
    }
    else if (selected_country[0]?.value === 'PT-PT') {
      return 'NIPC'
    }
    else if (selected_country[0]?.value === 'ES') {
      return 'NIF'
    }
    else if (selected_country[0]?.value === 'FR') {
      return 'SIREN'
    }
    else if (selected_country[0]?.value === 'UK') {
      return 'CRN'
    }
    else {
      return 'CNPJ'
    }
  }

  const countryCodes = {
    'PT-PT': 'PT',
    'PT-BR': 'BR',
    'UK': 'GB',
    'ES': 'ES'
  };

  const [selected_country, setSelected_country] = useState([]);
  //const [mask, setMask] = useState(false);

  return (
    <Col>
      <Row style={{ height: '5.5vh' }} className={"d-flex justify-content-center " + (props.isMobile ? 'mb-3' : 'mb-4')} >
        {stage !== 4 ?
          <>
            <Row className="d-flex justify-content-center ps-0" style={{ height: '2vh', paddingTop: '1vh', }}>
              <Col sm={props.isMobile ? 10 : 6} md={props.isMobile ? 10 : 6} className={Styles.textBlack + " ps-0"}
                style={props.isMobile ? { font: "var(--px20_24_600-font)" } : {}}
              >
                <p className={props.isMobile ? "fontStepMobile" : ""}>{t("register.step.label_step")} {stage} {t("register.step.label_of_3")}</p>
              </Col>
            </Row>
            <Row className={"d-flex justify-content-center ps-0 " + (props.isMobile ? "pt-1" : "")} style={{ marginBottom: "-1.5vh" }}>
              <Col sm={props.isMobile ? 10 : 6} md={props.isMobile ? 10 : 6} className="ps-0">
                <ProgressBar now={progress} style={{ height: '1vh', borderRadius: '0px' }} />
              </Col>
            </Row>
          </> :
          <>
            <Row className="d-flex justify-content-center" style={{ height: '4vh', paddingTop: '1vh' }}>
            </Row>
          </>
        }
      </Row>
      <Formik
        initialValues={{ email: ''}}
        validate={values => {    
          Setuser_email(values.email);
          return RegisterFormControlerConst.ValidateEmailForm(values)
        }}      

        onSubmit={(values, errors) => {          
            RegisterFormControlerConst.clickNextStage();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,

        }) => (
          <Form onSubmit={handleSubmit} noValidate validated={validated} id="stage1"
          //style={{display: 'none'}}
          >
            <Row className="d-flex justify-content-center align-self-start">
              <Col sm={props.isMobile ? 10 : 6} md={props.isMobile ? 10 : 6} style={{ backgroundColor: 'white', borderRadius: '10px', /* marginBottom: '33%' */ }} >
                <Row className={Styles.textFontBlue + " mt-3 " + (props.isMobile ? "mx-0 mb-0" : 'mx-2 pt-3 mb-3')}>
                  <Col className={props.isMobile ? "px-0 " + Styles.textHeaderMobile : ""} style={props.isMobile ? { font: "var(--px17_24_Bold-font)" } : {}}> <p>{t("register.register_label")}</p></Col>
                </Row>
                <Row className={props.isMobile ? (Styles.textFontGlayMobile + " mx-0") : (Styles.textFontGlay + " mx-2")}>

                  <Col sm={true}>
                    <Form.Label htmlFor="basic-url">{t("register.country_selected")}</Form.Label>
                    <SelectComponentController
                      className={"multiselectForm mb-4"}
                      options={options_country}
                      setOptions={setOptions_country}
                      selecteds={selected_country}
                      setSelecteds={setSelected_country}
                      singleSelect={true}
                      isFormNoFilter={true}
                      title={t("register.country_selected")}
                      overrideStrings={
                        {
                          "allItemsAreSelected": selected_country[0] ? selected_country[0].label : "",
                          "clearSearch": t("register.overrideStrings.clearSearch"),
                          "clearSelected": t("register.overrideStrings.clearSelected"),
                          "noOptions": t("register.overrideStrings.noOptions"),
                          "search": t("register.overrideStrings.search"),
                          "selectAll": t("register.overrideStrings.selectAll"),
                          "selectAllFiltered": t("register.overrideStrings.selectAllFiltered"),
                          "selectSomeItems": t("register.overrideStrings.selectSomeItems"),
                          "create": t("register.overrideStrings.create"),
                        }
                      }
                      hasCreateItem={false}
                      hasNullOption={false}
                      hasAllOption={false}
                    />
                  </Col>

                  <Form.Label className={props.isMobile ? "px-0" : ""} htmlFor="email">{t("register.label_comercial_email")} <span style={{ font: 'var(--px15_19_Bold-font)', letterSpacing: '0px', color: '#1E1E1E' }}>*</span></Form.Label>
                  <InputGroup className={props.isMobile ? "px-0" : ""} style={{ marginBottom: (props.isMobile ? '0' : "0"), }}>
                    <FormControl
                      style={{ height: 'calc(7vh - 1rem)', marginBottom: (showfeedbackEmail ? "0rem" : '1rem') }}
                      isInvalid={showfeedbackEmail}
                      className="form-control-Default"
                      placeholder={t("login.placeholder_email")}
                      aria-label={t("login.placeholder_email")}
                      aria-describedby={t("login.placeholder_email")}
                      size='lg'
                      id="user_email"
                      name="email"
                      autoComplete='email'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <Form.Control.Feedback type="invalid" id="feedbackTextEmail" style={{ font: (props.isMobile ? 'var(--px15_19-font)' : ""), height: '0.75rem' }}>
                      {errors.email}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Row>
                <Row className={"mb-4 justify-content-center " + (props.isMobile ? "mx-0 px-0" : 'mx-2 pb-3')} style={{ marginTop: '0.6vh' }}>
                  <div style={{ width: '100%', textDecoration: 'none' }} className={"justify-content-center " + (props.isMobile ? "px-0" : '')}>
                    <Button type='submit' className={props.isMobile ? "buttonBlueRegisterMobile" : "buttonBlue"} style={{ height: '5vh' }} disabled={validateCountry() === false && values.email !== '' ? false : true}>{t("register.button_next_register")}</Button>
                  </div>
                </Row>
              </Col>
            </Row>
            <div /* style={(!props.isMobile)? {display: 'none'}: {}} */>
              <Row className="d-flex justify-content-center ps-0">
                <Col sm={props.isMobile ? 10 : 6} md={props.isMobile ? 10 : 6} className={Styles.textFontGlayMobile + " ps-0 " + (props.isMobile ? Styles.paddingLarger : "")}>
                  <p
                    style={{
                      textAlign: 'left',
                      font: props.isMobile ? "" : 'normal normal normal 12px/20px Roboto',
                      letterSpacing: '0px',
                      color: '#1E1E1E',
                      marginTop: '0.8vh'
                    }}
                  >
                    {t("register.label_warning_register")}
                  </p>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
      <Formik
        initialValues={{ name: '', lastname: '', phone: ''}}
        validate={values => {
          Setuser_name(values.name);
          Setuser_lastname(values.lastname);
          //Setuser_whatsapp(values.whatsapp);
          return RegisterFormControlerConst.ValidateRegisterForm(values);
        }}


        onSubmit={async (values, errors) => {
          RegisterFormControlerConst.clickNextStage();
        }}
      >
        {({
          values,
          setValues,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,

        }) => (
          <Form onSubmit={handleSubmit} noValidate validated={validated} id="stage2"
            style={{ display: 'none' }}
          >
            <div >
              <Row className="d-flex justify-content-center">
                <Col sm={props.isMobile ? 10 : 6} md={props.isMobile ? 10 : 6} className={props.isMobile ? Styles.paddingLarger : ""} style={{ backgroundColor: 'white', borderRadius: '10px', /* marginBottom: '20.8%' */ }} >
                  <Row className={Styles.textFontBlue + " mt-3 " + (props.isMobile ? "mx-0 mb-1" : 'mx-2 pt-3 mb-3')}>
                    <Col className={props.isMobile ? "px-0 " + Styles.textHeaderMobile : ""} style={props.isMobile ? { font: "var(--px17_24_Bold-font)" } : {}}> <p>{t("register.register_label")}</p></Col>
                  </Row>
                  <Row className={props.isMobile ? (Styles.textFontGlayMobile + " mx-0") : (Styles.textFontGlay + " mx-2")}>
                    <Col className={props.isMobile ? "px-0" : ""} style={{ paddingRight: "0px" }}>
                      <Form.Label className={props.isMobile ? "px-0" : ""} htmlFor="name">{t("register.label_name_register")} <span style={{ font: 'var(--px15_19_Bold-font)', letterSpacing: '0px', color: '#1E1E1E' }}>*</span></Form.Label>
                      <InputGroup className={props.isMobile ? "px-0" : ""} style={{ marginBottom: "0vh" }}>
                        <FormControl
                          style={{ height: 'calc(7vh - 1rem)', marginBottom: (showfeedbackName ? "0rem" : '1rem') }}
                          isInvalid={showfeedbackName}
                          className="form-control-Default"
                          placeholder={t("register.placeholder_name_register")}
                          aria-label={t("register.placeholder_name_register")}
                          aria-describedby={t("register.placeholder_name_register")}
                          id="user_name"
                          name="name"
                          autoComplete='given-name'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                        />
                        <Form.Control.Feedback type="invalid" id="feedbackTextName" style={{ marginBottom: '2vh',font: (props.isMobile ? 'var(--px15_19-font)' : ""), height: '0.75rem' }}>
                          {errors.name}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Col>
                    <Col className={props.isMobile ? "pe-0" : ""}>
                      <Form.Label htmlFor="lastname">{t("register.label_surname_register")} <span style={{ font: 'var(--px15_19_Bold-font)', letterSpacing: '0px', color: '#1E1E1E' }}>*</span></Form.Label>
                      <InputGroup style={{ marginBottom: "0vh", font: (props.isMobile ? 'var(--px15_19-font)' : "") }}>
                        <FormControl
                          style={{ height: 'calc(7vh - 1rem)', marginBottom: (showfeedbackLastname ? "0rem" : '1rem') }}
                          className="form-control-Default"
                          placeholder={t("register.placeholder_surname_register")}
                          aria-label={t("register.placeholder_surname_register")}
                          aria-describedby={t("register.placeholder_surname_register")}
                          id="user_lastname"
                          name="lastname"
                          autoComplete='family-name'
                          isInvalid={showfeedbackLastname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.lastname}
                        />
                        <Form.Control.Feedback type="invalid" id="feedbackTextLastName" style={{ marginBottom: '2vh',font: (props.isMobile ? 'var(--px15_19-font)' : ""), height: '0.75rem' }}>
                          {errors.lastname}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row className={props.isMobile ? (Styles.textFontGlayMobile + " mx-0") : (Styles.textFontGlay + " mx-2")}>
                    <Col className={props.isMobile ? "px-0" : "pt-1"}>
                      <Form.Label htmlFor="basic-url">{t("register.label_whatsapp_number_register")} <span style={{ font: 'var(--px15_19_Bold-font)', letterSpacing: '0px', color: '#1E1E1E' }}>*</span></Form.Label>
                      {/* <PhoneInputDropdown type={PhoneType.WhatsApp} phoneNumber={values.whatsapp} values={values} setValues={setValues} tag={whatsappTag} setPhoneError={setWhatsappError} required={true} isMobile={props.isMobile} /> */}
                      <PhoneInput
                      placeholder={"Informe o telefone"}
                      value={values.phone}
                      onChange={(e) => setAgent_phone(e)}
                      inputProps={{
                        name: 'Telefone',
                        required: true,
                        border: 'none',
                        style: {
                          width: '100%',
                          font: 'var(--px15_19-font-Roboto)',
                          marginBottom: '1rem',
                          height: '3.4em'
                        }
                      }}
                      country={switchLanguage()[0]}
                      onlyCountries={['br','pt', 'es', 'fr', 'gb']}
                      disableDropdown={true}
                      localization={pt}
                      masks={{ pt: '... ... ...' }}
                      countryCodeEditable={false}
                    />
                    </Col>
                  </Row>
                  <Row className={"mb-4 justify-content-center " + (props.isMobile ? "mx-0 px-0" : 'mx-2 pb-3')} style={{ marginTop: '1vh' }} >
                    <Col className={"justify-content-center " + (props.isMobile ? "ps-0" : '')} style={{ paddingRight: "0px" }}>
                      <Button className={props.isMobile ? "buttonWhiteRegisterMobile" : "buttonWhite"} onClick={() => {RegisterFormControlerConst.clickPreviousStage(); Setdocument_number("");setAgent_phone("");}}>{t("register.button_back_register")}</Button>
                    </Col>
                    <Col className={"justify-content-center " + (props.isMobile ? "pe-0" : '')}>
                      <Button type='submit' disabled={validatePhone()} className={props.isMobile ? "buttonBlueRegisterMobile" : "buttonBlue"}>{t("register.button_next_register")}</Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
      <Formik
        initialValues={{ company_name: '', password: '', document_number: '' }}
        validate={values => {
          Setcompany_name(values.company_name);
          Setuser_password(values.password);
          Setdocument_number(values.document_number);
          return RegisterFormControlerConst.ValidateCompanyForm(values)

        }}

        onSubmit={async (values, errors) => {
          const country = countryCodes[selected_country[0]?.value] || selected_country[0]?.value?.toUpperCase();

          if (!validSelectDropdownCompanyEmployeeNumber()) {
            const body = {
              "company_name": company_name,
              "employee_amount": company_employee_number,
              "email": user_email,
              "password": user_password,
              "user_name": user_name,
              "user_lastname": user_lastname,
              "phone": agent_phone,
              "document_number": document_number.toLocaleUpperCase().replace(/[^a-zA-Z0-9]/g, ''),
              "country": country
            };

            const headers = {
              "Content-Type": "application/json; charset=utf-8"
            };

            // setIsLoading(true);
            AuthenticationRequestsConst.RegisterCompany(
              body,
              { headers },
              (response: Object) => {
                return response
              },
              (data: { data: { subdomain: string; }; }) => {
                Setsubdomain(data.data.subdomain);
                RegisterFormControlerConst.clickNextStage();
                // setIsLoading(false);
                return data
              },
              (error:{response:{status:number; data:{code_cxpress: number}}}) => {
                  if (error.response.status === 500) {
                    dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                  } else {
                    if(error?.response?.data?.code_cxpress === 1005){
                      document.getElementById("feedbackCNPJ").innerHTML = t("register.errors.already_used_error_document");
                      document.getElementById("feedbackCNPJ").style.display = "block";
                    }
                    else {
                      // document.getElementById("feedbackCNPJ").innerHTML = verifyCode(error.response.data.code_cxpress, t);
                      document.getElementById("feedbackCNPJ").innerHTML = t("register.errors.invalid_document");
                      document.getElementById("feedbackCNPJ").style.display = "block";
                      setShowfeedbackDocument_number(true);
                    }
                  }
                //}
              }, navigate, dispatch, setIsLoading,
            );
          } else {
            if (document.getElementById("feedbackCompany_employee_number")) {
              document.getElementById("feedbackCompany_employee_number").innerHTML = t("register.errors.select_number_employees");
              document.getElementById("feedbackCompany_employee_number").style.display = "block";
            }
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,

        }) => (
          <Form onSubmit={handleSubmit} noValidate validated={validated} id="stage3"
            style={{ display: 'none' }}
          >
            <div >
              <Row className=" d-flex justify-content-center ">
                <Col sm={props.isMobile ? 10 : 6} md={props.isMobile ? 10 : 6} className={props.isMobile ? Styles.paddingLarger : ""} style={{ backgroundColor: 'white', borderRadius: '10px', /* marginBottom: '8.5%' */ }} >
                  <Row className={Styles.textFontBlue + " mt-3 " + (props.isMobile ? "mx-0" : 'mx-2 pt-3 mb-3')}>
                    <Col className={props.isMobile ? "px-0 " + Styles.textHeaderMobile : ""} style={props.isMobile ? { font: "var(--px17_24_Bold-font)" } : {}}>
                      <p>{t("register.register_label")}</p>
                    </Col>
                  </Row>
                  <Row className={props.isMobile ? (Styles.textFontGlayMobile + " mx-0") : (Styles.textFontGlay + " mx-2")} >
                    <Col className={props.isMobile ? "px-0" : ""} style={{ paddingRight: "0px" }}>
                      <Form.Label className={props.isMobile ? "px-0" : ""} htmlFor="company_name">{t("register.label_name_company")} <span style={{ font: 'var(--px15_19_Bold-font)', letterSpacing: '0px', color: '#1E1E1E' }}>*</span></Form.Label>
                      <InputGroup className={props.isMobile ? "px-0" : ""}  /* style={{ marginBottom: "2vh", height: '7vh' }} */>
                        <FormControl
                          style={{ height: 'calc(7vh - 1rem)', marginBottom: (showfeedbackCompany_name ? "0rem" : '1.75rem'), paddingRight: '2px' }}
                          className="form-control-Default"
                          placeholder={t("register.label_name_company")}
                          aria-label="Nome da empresa"
                          aria-describedby="Nome da empresa"
                          id="company_name"
                          name="company_name"
                          autoComplete="organization"
                          isInvalid={showfeedbackCompany_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.company_name}
                        />
                        <Form.Control.Feedback type="invalid" id="feedbackTextCompanyName" style={{ marginBottom: '2vh', font: (props.isMobile ? 'var(--px15_19-font)' : ""), height: '1.5rem' }}>
                          {errors.company_name}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Col>
                    <Col className={props.isMobile ? "pe-0" : ""}>
                      <Form.Label htmlFor="basic-url">{t("register.label_number_employees")} <span style={{ font: 'var(--px15_19_Bold-font)', letterSpacing: '0px', color: '#1E1E1E' }}>*</span></Form.Label>
                      <InputGroup>
                        <Dropdown onSelect={(value) => RegisterFormControlerConst.OnSelectDropdownCompanyEmployeeNumber(value)} onBlur={handleBlur}>
                          <Dropdown.Toggle id="company_employee_number" /* style={props.isMobile? {font: "var(--px10_13-font)"}: {}} */ className={(props.isMobile ? "dropdownButtonRegisterMobile " + Styles.textFontGlayMobile : "dropdownButton")}>
                            {t("register.select_dropdown")}
                          </Dropdown.Toggle>
                          <Dropdown.Menu >
                            <Dropdown.Item eventKey={t("register.employees_numbers.1_10")}>{t("register.employees_numbers.1_10")}</Dropdown.Item>
                            <Dropdown.Item eventKey={t("register.employees_numbers.11_50")}>{t("register.employees_numbers.11_50")}</Dropdown.Item>
                            <Dropdown.Item eventKey={t("register.employees_numbers.51_100")} >{t("register.employees_numbers.51_100")}</Dropdown.Item>
                            <Dropdown.Item eventKey={t("register.employees_numbers.101_250")} >{t("register.employees_numbers.101_250")}</Dropdown.Item>
                            <Dropdown.Item eventKey={t("register.employees_numbers.more_than_250")} >{t("register.employees_numbers.more_than_250_employees")}</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <Form.Control.Feedback type="invalid" id="feedbackCompany_employee_number" style={{ font: (props.isMobile ? 'var(--px15_19-font)' : ""), height: '0.75rem' }}>
                          {t("register.errors.select_number_employees")}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row className={props.isMobile ? (Styles.textFontGlayMobile + " mx-0") : (Styles.textFontGlay + " mx-2")} >
                    <Col className={props.isMobile ? "px-0" : ""}>
                      <Form.Label htmlFor="basic-url">
                        <div>
                          {t(switchDocument())} <span style={{ font: 'var(--px15_19_Bold-font)', letterSpacing: '0px', color: '#1E1E1E' }}>*</span>
                        </div>
                      </Form.Label>
                      <InputMask
                        mask={MaskController.maskCpfCnpj(values.document_number, selected_country[0]?.value)}
                        maskChar="_"
                        value={values.document_number}
                        onChange={handleChange}
                      >
                        {(inputProps: any) => <InputGroup style={{ /* marginBottom: "2vh" *//* , height: '7vh' */ }}>
                          <FormControl
                            style={{ height: 'calc(7vh - 1rem)', marginBottom: (ShowfeedbackDocument_number ? "0rem" : '1rem') }}
                            className="form-control-Default"
                            // placeholder={`Informe o ${t('switchDocument()')} da empresa`}
                            // aria-label={`Informe o ${t('switchDocument()')} da empresa`}
                            // aria-describedby={`Informe o ${t('switchDocument()')} da empresa`}
                            size='lg'
                            id="document_number"
                            name="document_number"
                            isInvalid={ShowfeedbackDocument_number}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <Form.Control.Feedback type="invalid" id="feedbackCNPJ" style={{ font: (props.isMobile ? 'var(--px15_19-font)' : ""), height: '0.75rem' }}>
                            {errors.document_number}
                          </Form.Control.Feedback>
                        </InputGroup>
                        }
                      </InputMask>
                    </Col>
                  </Row>
                  <Row className={props.isMobile ? (Styles.textFontGlayMobile + " mx-0") : (Styles.textFontGlay + " mx-2")} >
                    <Col className={props.isMobile ? "px-0" : ""}>
                      <Form.Label htmlFor="basic-url">{t("register.label_password")}</Form.Label>
                      <InputGroup /* style={{ marginBottom: "1.5vh", height: '7vh' }} */>
                        <FormControl
                          style={{ height: 'calc(7vh - 1rem)', marginBottom: (showfeedbackPassword ? "0rem" : '1rem') }}
                          className="form-control-Default"
                          placeholder={t("login.placeholder_password")}
                          aria-label="Informe sua senha"
                          aria-describedby="Informe sua senha"
                          size='lg'
                          type='password'
                          id="user_password"
                          name="password"
                          isInvalid={showfeedbackPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                        />
                        <div className="d-flex justify-content-end align-self-start">
                          <Button style={{ position: 'absolute', backgroundColor: 'transparent', border: 'none', zIndex: "5" }} onClick={() => RegisterFormControlerConst.ClickToViewPassword()}>
                            {showPassword ?
                              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye" width="24" height="4.2vh" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#707070" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <circle cx="12" cy="12" r="2" />
                                <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                              </svg> :
                              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye-off" width="24" height="4.2vh" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#707070" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <line x1="3" y1="3" x2="21" y2="21" />
                                <path d="M10.584 10.587a2 2 0 0 0 2.828 2.83" />
                                <path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341" />
                              </svg>
                            }
                          </Button>
                        </div>
                        <Form.Control.Feedback type="invalid" id="feedbackTextPassword" style={{ marginBottom: '2vh', font: (props.isMobile ? 'var(--px15_19-font)' : ""), height: '0.75rem' }}>
                          {errors.password}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row className={"mb-3 justify-content-center " + (props.isMobile ? "mx-0 px-0" : 'mx-2 pb-3')} style={{ marginTop: '0.8vh' }} >
                    <Col className={"justify-content-center " + (props.isMobile ? "ps-0" : '')} style={{ paddingRight: "0px" }}>
                      <Button className={props.isMobile ? "buttonWhiteRegisterMobile" : "buttonWhite"} onClick={() => RegisterFormControlerConst.clickPreviousStage()}>{t("register.button_back_register")}</Button>
                    </Col>
                    <Col className={"justify-content-center " + (props.isMobile ? "pe-0" : '')}>
                      <Button type="submit" className={props.isMobile ? "buttonBlueRegisterMobile" : "buttonBlue"} disabled={values.password && values.document_number && values.company_name ? false : true}>{t("register.button_finish_register")}</Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
      <div id="stage4"
        style={{ display: 'none' }}
      >
        <Row /* style={{ height: '36%' }} */ className=" d-flex justify-content-center">
          <Col sm={props.isMobile ? 10 : 6} md={props.isMobile ? 10 : 6} className={props.isMobile ? Styles.paddingLarger : ""} style={{ backgroundColor: 'white', borderRadius: '10px', /* marginBottom: '30.5%' */ }} >
            <Row className={Styles.textFontBlue + " mt-3 mb-3 " + (props.isMobile ? "mx-0" : 'mx-2 pt-3')}>
              <Col className={props.isMobile ? ("px-0 " + Styles.textHeaderMobile) : ""} style={props.isMobile ? { font: "var(--px17_24_Bold-font)" } : {}}> <p>{t("register.last_step.verify_email")}</p></Col>
            </Row>
            <Row className={props.isMobile ? (Styles.textFontGlayMobile + " mx-0") : (Styles.textFontGlay + " mx-2")}>
              <Col className={props.isMobile ? "px-0" : ""}>
                <p style={{ color: '#1E1E1E', wordBreak: 'break-word', msWordBreak: 'break-word' }}>{t("register.last_step.after_verify")}
                  <a className={props.isMobile ? Styles.textBlackMobile : Styles.textBlack} href={("http://" + subdomain + "." + window.location.hostname.replace("http://", "")).replace("www.", "")}> {("http://" + subdomain + "." + window.location.hostname.replace("http://", "")).replace("www.", "")} </a>
                  {t("register.last_step.start_using_platform")}</p>
              </Col>
            </Row>
            <Row className={props.isMobile ? (Styles.textFontGlayMobile + " mx-0") : (Styles.textFontGlay + " mx-2")}>
              <Col className={props.isMobile ? "px-0" : ""}>
                <p className="my-3" style={{ color: '#1E1E1E' }}>{t("register.last_step.didnt_receive_email")} &nbsp;
                  <span className={props.isMobile ? Styles.textBlackBoldMobile : Styles.textBlackBold} style={{ textDecoration: "underline", cursor: 'pointer' }} onClick={resendEmailConfirmation}>{t("register.last_step.resend_email")}</span>
                </p>
              </Col>
            </Row>
            <Row className={"mb-3 justify-content-center " + (props.isMobile ? "mx-0 px-0" : 'mx-2 pb-3')} >
              <Col className={"justify-content-center " + (props.isMobile ? "px-0" : '')}>
                <div className="d-flex justify-content-center">
                  <a style={{ width: '100%', height: '100%', textDecoration: 'none' }} href={("http://" + subdomain + "." + window.location.hostname.replace("http://", "")).replace("www.", "")}>
                    <Button className={props.isMobile ? "buttonBlueRegisterMobile" : "buttonBlue"}>{t("register.last_step.signin_cx")} </Button>
                  </a>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Col>
  );
}

export default RegisterForm;